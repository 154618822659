import React from "react";
import { InputGroup, Input, FormGroup, Button, Label } from "reactstrap";
import { FaTimes } from "react-icons/fa";

const SearchComponent = ({ filterText, onFilter }) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleClear = () => {
    if (searchTerm) {
      setSearchTerm("");
    }
  };

  React.useEffect(() => {
    if (filterText === "" && searchTerm === "") return;

    const delayDebounceFn = setTimeout(() => {
      onFilter(searchTerm);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <FormGroup>
      <Label htmlFor="search">Search</Label>
      <InputGroup>
        <Input
          id="search"
          name="search"
          type="text"
          placeholder="search by title"
          aria-label="search input"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: "5px" }}
        />
        <Button onClick={handleClear} color="danger">
          <FaTimes />
        </Button>
      </InputGroup>
    </FormGroup>
  );
};

export default SearchComponent;
