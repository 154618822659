import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "views/components/Spinner";
import { Link } from "react-router-dom";
import {
  faPhone,
  faBriefcase,
  faBorderAll,
  faLocationDot,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import { getDashboardDetails } from "actions/admin/dashboard";
import { connect } from "react-redux";

const Dashboard = ({
  getDashboardDetails,
  dashboardDetails,
  dashboardLoading,
}) => {
  React.useEffect(async () => {
    await getDashboardDetails();
  }, []);

  return (
    <>
      {dashboardLoading ? (
        <Spinner />
      ) : (
        <div class="row">
          <div class="col-md-3">
            <div class="card-body box-outer">
              <h3>Inquiries</h3>
              <div class="d-flex align-items-end justify-content-between mt-3">
                <div>
                  <h4>
                    {dashboardDetails.contacts &&
                    dashboardDetails.contacts[0].contactsCount
                      ? dashboardDetails.contacts[0].contactsCount
                      : 0}
                  </h4>
                  <Link to="/dashboard/inquiry">View all Inquiries</Link>
                </div>
                <div class="icon-outer">
                  <Link to="/dashboard/inquiry">
                    <FontAwesomeIcon icon={faPhone} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card-body box-outer services-outer">
              <h3>Properties</h3>
              <div class="d-flex align-items-end justify-content-between mt-3">
                <div>
                  <h4>
                    {dashboardDetails.properties &&
                    dashboardDetails.properties[0].propertiesCount
                      ? dashboardDetails.properties[0].propertiesCount
                      : 0}
                  </h4>
                  <Link to="/dashboard/properties">View all Properties</Link>
                </div>
                <div class="icon-outer">
                  <Link to="/dashboard/properties">
                    <FontAwesomeIcon icon={faBriefcase} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card-body box-outer categories-outer">
              <h3>Subscribers</h3>
              <div class="d-flex align-items-end justify-content-between mt-3">
                <div>
                  <h4>
                    {dashboardDetails.subscribers &&
                    dashboardDetails.subscribers[0].subscribersCount
                      ? dashboardDetails.subscribers[0].subscribersCount
                      : 0}
                  </h4>
                  <Link to="/dashboard/subscribers">View all Subscribers</Link>
                </div>
                <div class="icon-outer">
                  <Link to="/dashboard/subscribers">
                    <FontAwesomeIcon icon={faBorderAll} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card-body box-outer location-outer">
              <h3>Manage Colors</h3>
              <div class="d-flex align-items-end justify-content-between mt-4 pt-1">
                <div style={{ paddingTop: "2px" }}>
                  {/* <h4>
                    {dashboardDetails.locations &&
                    dashboardDetails.locations[0].locationsCount
                      ? dashboardDetails.locations[0].locationsCount
                      : 0}
                  </h4> */}
                  <Link to="/dashboard/colors">Select Color</Link>
                </div>
                <div class="icon-outer">
                  <Link to="/dashboard/colors">
                    <FontAwesomeIcon icon={faPalette} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Dashboard.propTypes = {
  getDashboardDetails: PropTypes.func.isRequired,
  dashboardDetails: PropTypes.object.isRequired,
  dashboardLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  dashboardDetails: state.dashboard.dashboardDetails,
  dashboardLoading: state.dashboard.dashboardLoading,
});

export default connect(mapStateToProps, { getDashboardDetails })(Dashboard);
