import {
    OURPROPERTIES_ERROR,
    OURPROPERTIES_UPDATED,
    LOADING_ON_OURPROPERTIES_SUBMIT,
    LOADING_SUBBANNER_DATA,
    GET_SUBBANNER,
    LOAD_OURPROPERTIES,
    GET_OURPROPERTIES,
    LOADING_OURPROPERTIES
} from "actions/types";

const initialState = {
    currentOurProperties: null,
    loadingOurProperties: false,
    error: {},
};

export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case OURPROPERTIES_ERROR:
            return {
                ...state,
                error: payload,
                loadingOurProperties: false,
            };
        case GET_SUBBANNER:
            return {
                ...state,
                currentOurProperties: payload,
                loadingOurProperties: false,
            };
        case LOAD_OURPROPERTIES:
            return {
                ...state,
                loadingOurProperties: false,
            };
        case LOADING_OURPROPERTIES:
        case LOADING_ON_OURPROPERTIES_SUBMIT:
            return {
                ...state,
                loadingOurProperties: true,
            };
        case GET_OURPROPERTIES:
        case OURPROPERTIES_UPDATED:
            return {
                ...state,
                loadingOurProperties: false,
            }
        default:
            return state;
    }
}
