import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import slugify from "react-slugify";

import {
  create,
  cancelSave,
  loadPage,
  setErrors,
  removeErrors,
} from "actions/admin/service";
import Errors from "views/components/Notifications/Errors";
import Spinner from "views/components/Spinner";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router";
import Select from "react-select";
import { CATEGORY_STATUS } from "constants";
import Category from "./Category";
import Location from "./Location";
const CreateService = ({
  create,
  cancelSave,
  errorList,
  loading,
  setErrors,
  loadPage,
  removeErrors,
}) => {
  const history = useNavigate();

  const [formData, setFormData] = useState({
    status: 1,
    description: "",
    category: "",
    image: "",
    name: "",
    price: -1,
    locations: [],
  });

  const { status, description, category, name, image, price, locations } =
    formData;

  // const [loadingOption, setLoadingOption] = useState(false);
  const [onlyOnce, setOnce] = useState(true);
  let delayTimer;

  const onChange = (e) => {
    if (e.target.name === "image") {
      let image_file = e.target.files[0];

      let image_url = URL.createObjectURL(image_file);

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        image: image_url,
      });
    } else if (e.target.name === "visible_in_footer") {
      setFormData({ ...formData, [e.target.name]: e.target.checked ? 1 : 0 });
    } else if (e.target.name === "price") {
      if (e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: -1 });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const addCategory = (value) => {
    setFormData({ ...formData, status: value.value });
  };

  const handleCategorySelect = (value) => {
    setFormData({ ...formData, category: value.value });
  };
  // const handleDescriptionChange = (event /* editor */) => {
  //   const data = event.editor.getData(); //editor.getData();
  //   setFormData((ev) => ({ ...ev, description: data }));
  // };
  const onChangeDescription = (newDescription) => {
    setFormData((data) => ({ ...data, description: newDescription }));
  };
  const handleLocationSelect = (value) => {
    let location = [];
    value.map(function (val, ind) {
      location.push(val.value);
    });
    setFormData({ ...formData, locations: location });
  };

  /**
   * 1) IF form field is an object
   *    --- [{
   *          "param":"field_name",
   *          "value":"value that has to be matched from object",
   *          "actualParam":"to represent actual field name"
   *          "msg": "msg that has to be shown"
   *        }]
   * 2) IF form field is string
   *    --- [{
   *          "param":"field_name",
   *          "actualParam":"to represent actual field name",
   *          "msg": "msg that has to be shown"
   *        }]
   * @param {object} form , data which has to be verify
   * @param {array} requireFields , fields that has to be verify
   */
  const validateForm = (form, requireFields) => {
    const errors = [];
    for (let i in requireFields) {
      if (requireFields[i].type === "object") {
        if (
          !form[requireFields[i].param][requireFields[i].value].trim().length
        ) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (requireFields[i].type === "array") {
        if (!form[requireFields[i].param].length) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (requireFields[i].type === "number") {
        continue;
      } else if (!form[requireFields[i].param].trim().length) {
        errors.push({
          param: requireFields[i].actualParam || requireFields[i].param,
          msg: requireFields[i].msg,
        });
      }
    }
    if (errors.length) {
      setErrors(errors);
    }
    return !errors.length;
  };

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    if (price < 0) {
      setFormData({ ...formData, price: -1 });
    }
    if (
      !validateForm(formData, [
        {
          param: "name",
          msg: "Please enter the name of service",
        },
        // {
        //   param: "price",
        //   msg: "Please enter the price of service",
        // },
        {
          param: "image",
          msg: "Please select a page image",
        },
        {
          param: "description",
          msg: "Please provide the page description",
        },
        {
          param: "category",
          msg: "Please provide valid category",
        },
      ])
    )
      return;

    const submitData = {};
    const excludeList = { selectedCategory: 1, image: 1 };
    for (let i in formData) {
      if ((formData[i] !== 0 && !formData[i]) || excludeList[i]) continue;
      submitData[i] = formData[i];
    }
    create(submitData, history);
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe) {
        loadPage();
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Service Information</h5>
              </CardHeader>
              <Form
                className="form-horizontal"
                onSubmit={(e) => onSubmit(e)}
                encType="multipart/form-data"
              >
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="name">
                      Name <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      required
                      onChange={(e) => onChange(e)}
                      disabled={loading}
                      invalid={errorList.name ? true : false}
                    />
                    <Errors current_key="name" key="name" />
                  </FormGroup>
                  <FormGroup>
                    <Category
                      selected={category}
                      onSelect={(value) => handleCategorySelect(value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Location
                      selected={locations}
                      isEdit={0}
                      onSelect={(value) => handleLocationSelect(value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="image">
                      Image<span>*</span>
                    </Label>
                    <Input
                      type="file"
                      id="image"
                      name="image"
                      onChange={(e) => onChange(e)}
                      multiple
                      accept="image/png, image/jpeg"
                      invalid={errorList.image ? true : false}
                    />
                    {formData.image ? (
                      <img
                        style={{
                          width: "80px",
                          height: "50px",
                          marginTop: "10px",
                        }}
                        src={formData.image}
                        className="preview-img img-fluid"
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    {/* {image_preview} */}
                    <Errors current_key="image" key="image" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="description">
                      Description <span>*</span>
                    </Label>
                    {/* <CKEditor
                      data={description}
                      id="description"
                      name="description"
                      config={{
                        height: 100,
                        toolbar: [
                          ["Cut", "Copy", "Paste"],
                          ["Undo", "Redo"],
                          ["SpellChecker"],
                          ["Link", "Unlink", "Anchor"],
                          [
                            "Image",
                            "Table",
                            "Horizontal Line",
                            "Special Character",
                          ],
                          ["Maximize"],
                          ["Source"],
                          ["Bold", "Italic", "Strike"],
                          ["RemoveFormat"],
                          ["NumberedList", "BulletedList"],
                          ["DecreaseIndent", "IncreaseIndent"],
                          ["BlockQuote"],
                          ["Styles"],
                          ["Format"],
                          ["About"],
                        ],
                      }}
                      onChange={(event) => handleDescriptionChange(event)}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                      invalid={errorList.description ? true : false}
                    /> */}
                    <CKEditor
                      editor={Editor}
                      required
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        onChangeDescription(data);
                      }}
                    />
                    <Errors current_key="description" key="description" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="name">
                      Price{" "}
                      <span style={{ color: "red", fontSize: "14px" }}>
                        (Input 0 if you don't want the the Price on Pricing
                        page. Leave blank if you don't want to show this service
                        on Pricing Page)
                      </span>
                    </Label>
                    <Input
                      type="number"
                      id="price"
                      name="price"
                      value={price < 0 ? "" : price}
                      onChange={(e) => onChange(e)}
                      disabled={loading}
                      invalid={errorList.price ? true : false}
                    />
                    <Errors current_key="price" key="price" />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="status">
                      Status <span>*</span>
                    </Label>
                    <Select
                      options={CATEGORY_STATUS}
                      value={CATEGORY_STATUS.find(
                        (cat) => cat.value === status
                      )}
                      onChange={addCategory}
                      isClearable={false}
                    />
                    <Errors current_key="status" key="status" />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    {/* {loading?<Spinner/>: */}
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <a onClick={onClickHandel} href="#!">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </a>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

CreateService.propTypes = {
  create: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
  service: PropTypes.object,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadPage: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  service: state.service,
  errorList: state.errors,
  loading: state.service.loadingEmail,
});

export default connect(mapStateToProps, {
  create,
  cancelSave,
  loadPage,
  setErrors,
  removeErrors,
})(CreateService);
