import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  DASHBOARD_ERROR,
  DASHBOARD_LIST_UPDATED,
  LOADING_ON_DASHBOARD_SUBMIT,
  REMOVE_ERRORS,
  LOAD_PAGE,
  RESET,
  DASHBOARD_DETAILS_LOADING,
  DASHBOARD_DETAILS,
  DASHBOARD_DETAILS_ERROR,
} from "actions/types";
import { logout } from "actions/auth";

// Get Dashboard Details
export const getDashboard = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnDashboardSubmit());
    const res = await axios.get(`/api/admin/dashboard`, config);
    dispatch({
      type: DASHBOARD_LIST_UPDATED,
      payload: res.data.response,
    });
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: DASHBOARD_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const getDashboardDetails = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: DASHBOARD_DETAILS_LOADING });
    const res = await axios.get(`/admin/dashboard`, config);

    dispatch({
      type: DASHBOARD_DETAILS,
      payload: res.data.response,
    });
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: DASHBOARD_DETAILS_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Dispatch Loading
export const loadingOnDashboardSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_DASHBOARD_SUBMIT });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: DASHBOARD_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_PAGE });
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};
