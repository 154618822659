import axios from "actions/axios";
import { setAlert, removeAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";

import {
  PROPERTY_LIST_UPDATED,
  PROPERTY_CREATED,
  PROPERTY_ERROR,
  PROPERTY_UPDATED,
  GET_PROPERTY_BY_ID,
  DELETE_PROPERTY,
  PROPERTY_SEARCH_PARAMETERS_UPDATED,
  REMOVE_ERRORS,
  SET_ERRORS_LIST,
  LOADING_ON_USER_SUBMIT,
  LOADING_PROPERTY_LIST,
  LOAD_PROPERTY,
  RESET,
} from "actions/types";

import { logout } from "actions/auth";

export const getPropertyList = (params) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: REMOVE_ERRORS });
    const query = params.query ? params.query : "";
    params.query = query;
    config.params = params;
    // if (params.query.createdAt) {
    //   const date = new Date();
    //   if (
    //     new Date(params.query.createdAt.value.to) >
    //     new Date(
    //       date.getFullYear(),
    //       date.getMonth(),
    //       date.getDate(),
    //       23,
    //       59,
    //       59
    //     )
    //   ) {
    //     dispatch(setAlert("Select the correct date range.", "danger"));
    //     return;
    //   }
    // }
    dispatch({ type: LOADING_PROPERTY_LIST });

    const res = await axios.get("/admin/property", config);
    dispatch({
      type: PROPERTY_SEARCH_PARAMETERS_UPDATED,
      payload: params,
    });
    dispatch({
      type: PROPERTY_LIST_UPDATED,
      payload: res.data.response[0],
    });
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SET_ERRORS_LIST,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const createProperty = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnUserSubmit());
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };

  try {
    const res = await axios.post("/admin/property", formData, config);
    if (res.data.status === true) {
      dispatch({
        type: PROPERTY_CREATED,
        payload: res.data.response,
      });
      dispatch(setAlert("Property Created", "success"));
      history("/dashboard/properties");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: SET_ERRORS_LIST });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.trace(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SET_ERRORS_LIST,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const getPropertyByID = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnUserSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/admin/property/${id}`, config);
    if (res.data.status) {
      dispatch({
        type: GET_PROPERTY_BY_ID,
        payload: res.data.response,
      });
      return res.data ? res.data.response : { status: false };
    } else {
      dispatch({ type: SET_ERRORS_LIST });
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.trace(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SET_ERRORS_LIST,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const editProperty = (formData, history, id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnUserSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put(`/admin/property/${id}`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: PROPERTY_UPDATED,
        payload: res.data.response,
      });
      dispatch(setAlert("Property Updated", "success"));
      history("/dashboard/properties");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: SET_ERRORS_LIST });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.trace(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SET_ERRORS_LIST,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const deleteProperty = (id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(`/admin/property/${id}`, config);
    if (res.data.status === true) {
      dispatch({
        type: DELETE_PROPERTY,
        payload: id,
      });
      dispatch(setAlert("Property Deleted", "success"));
    } else {
      dispatch({ type: SET_ERRORS_LIST });
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.trace(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SET_ERRORS_LIST,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const cancelSave = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  history("/dashboard/properties");
};

export const notFound = (history) => async (dispatch) => {
  history.push("/dashboard/page-not-found");
};

export const loadingOnUserSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_USER_SUBMIT });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: SET_ERRORS_LIST });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const loadProperty = () => async (dispatch) => {
  await dispatch({ type: LOAD_PROPERTY });
};

export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};
