import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  CMS_CREATED,
  CMS_ERROR,
  DELETE_CMS,
  CMS_UPDATED,
  CMS_LIST_UPDATED,
  GET_CMS_BY_ID,
  CMS_SEARCH_PARAMATERS_UPDATE,
  REMOVE_ERRORS,
  CHANGE_CMS_STATUS,
  LOADING_ON_EMAIL_SUBMIT,
  LOADING_EMAIL_LIST_DATA,
  LOAD_PAGE,
  RESET,
} from "actions/types";
import { logout } from "actions/auth";
import { CMS } from "constants/api";

//get Email Template list

export const getCMSList = (cmsParams) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: LOADING_EMAIL_LIST_DATA });
    const query = cmsParams.query ? cmsParams.query : "";
    cmsParams.query = query;
    cmsParams.page = cmsParams.page ? cmsParams.page : 1;

    config.params = cmsParams;
    const res = await axios.get(CMS.LIST().ENDPOINT, config);
    // dispatch({ type: REMOVE_ALERT });

    dispatch({
      type: CMS_SEARCH_PARAMATERS_UPDATE,
      payload: cmsParams,
    });
    dispatch({
      type: CMS_LIST_UPDATED,
      payload: res.data.response,
    });
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CMS_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Get email template by id
export const getCMSById = (cms_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmailSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`${CMS.LIST().ENDPOINT}/${cms_id}`, config);
    // dispatch({ type: REMOVE_ALERT });

    await dispatch({
      type: GET_CMS_BY_ID,
      payload: res.data.response,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CMS_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Create Email Template
export const create = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmailSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json; multipart/form-data;",
    },
  };
  try {
    const data = new FormData();
    for (var i in formData) {
      data.append(i, formData[i]);
    }
    const res = await axios.post(CMS.CREATE().ENDPOINT, data, config);
    if (res.data.status === true) {
      dispatch({
        type: CMS_CREATED,
        payload: res.data.response,
      });
      // dispatch({ type: REMOVE_ALERT });
      dispatch(setAlert("Email Template Created.", "success"));
      history("/dashboard/cms");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CMS_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Edit Email Template
export const edit = (formData, history, cms_id) => async (dispatch) => {
  // -- Always remove the previous errors before sending new request
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmailSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json; multipart/form-data;",
    },
  };
  try {
    const data = new FormData();
    for (var i in formData) {
      data.append(i, formData[i]);
    }
    const res = await axios.post(
      `${CMS.LIST().ENDPOINT}/${cms_id}`,
      data,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: CMS_UPDATED,
        payload: res.data.response,
      });
      // dispatch({ type: REMOVE_ALERT });
      dispatch(setAlert("Cms Updated.", "success"));
      history("/dashboard/cms");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: CMS_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CMS_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Delete Email Template
export const deleteCMS = (cms_id, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(`${CMS.LIST().ENDPOINT}/${cms_id}`, config);
    // dispatch({ type: REMOVE_ALERT });

    dispatch({
      type: DELETE_CMS,
      payload: cms_id,
    });
    dispatch(setAlert("Email Template deleted", "success"));
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CMS_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

//change status
export const changeStatus = (cms_id, status) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/cms/change-status/${cms_id}`,
      { status },
      config
    );
    // dispatch({ type: REMOVE_ALERT });
    await dispatch({
      type: CHANGE_CMS_STATUS,
      payload: res.data.response,
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CMS_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Delete User
export const cancelSave = (history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history("/dashboard/cms");
};

// Dispatch Loading
export const loadingOnEmailSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_EMAIL_SUBMIT });
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history("/dashboard/page-not-found");
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: CMS_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_PAGE });
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};
