import axios from "./axios";
import { setAlert, removeAlert } from "./alert";
import { setErrorsList } from "./errors";
import { USER_ROLE } from "constants/index";
import { AUTH, PASSWORD, EMAIL } from "constants/api";
import {
  RESET_LINK_SUCCESS,
  RESET_LINK_FAIL,
  USER_LOADED,
  USER_PROFILE_LOADED,
  AUTH_ERROR,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOADING_ON_LOGIN_SUBMIT,
  LOADING_ON_PASSWORD_RESET,
  REMOVE_ERRORS,
  REMOVE_ALERT,
  LOAD_AUTH_PAGE,
  PENDING_ACTION_LOADED,
  VERIFICATION_LINK_SUCCESS,
  VERIFICATION_LINK_FAIL,
  LOADING_ON_EMAIL_VERIFICATION,
} from "./types";
import setAuthToken from "../utils/setAuthToken";

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

//Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(AUTH.USER().ENDPOINT);

    if (res.data.status === true) {
      if (res.data.response.role !== USER_ROLE.ADMIN) {
        dispatch(logout());
        return;
      }
      dispatch({
        type: USER_LOADED,
        payload: res.data.response,
      });
      dispatch({
        type: USER_PROFILE_LOADED,
        payload: res.data.response,
      });
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
      }
    }
  } catch (err) {
    if (err.response) {
      dispatch({
        type: AUTH_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    }
  }
};

//Logout / Clear Profile
export const logout = (history) => async (dispatch) => {
  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    const res = await axios.post(AUTH.LOGOUT().ENDPOINT);
    if (res.data.status === true) {
      setAuthToken();
      dispatch({ type: LOGOUT });
      if (history) history("/");
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(removeAlert());
        setAuthToken();
        dispatch({ type: REMOVE_ERRORS });
        dispatch({ type: LOGOUT });
      } else {
        dispatch({
          type: AUTH_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const custom_alert =
  (msg, type = "danger") =>
  (dispatch) => {
    dispatch(setAlert(msg, type));
  };

// Check User Existance for OTP Verification
export const checkUserExistence = (contact) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`/api/register/exists/${contact}`, config);
    if (res.data.status === true) {
      return res.data.response.exists;
    } else {
      dispatch(setAlert(res.data.message, "danger"));

      const errors = res.data.errors;
      if (errors) {
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    if (err.response) {
      dispatch(
        setAlert(err.response.data.message || err.response.statusText, "danger")
      );
    }
  }
};

//Login User
export const login = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    dispatch(loadingOnLoginSubmit());
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });

    const res = await axios.post(AUTH.SIGNIN().ENDPOINT, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.response,
      });
      dispatch(loadUser());
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
      const errors = res.data.errors;
      if (errors) {
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    if (err.response) {
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          msg: err.response.data.message || err.response.statusText,
          status: err.response.status,
        },
      });
      dispatch(
        setAlert(err.response.data.message || err.response.statusText, "danger")
      );
    }
  }
};

// Generate Reset Link
export const resetLink =
  ({ email }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email });

    try {
      dispatch(removeAlert());
      dispatch({ type: REMOVE_ERRORS });
      dispatch(loadingOnPasswordReset());
      const res = await axios.post(
        PASSWORD.GENERATE_RESET_LINK().ENDPOINT,
        body,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: RESET_LINK_SUCCESS,
          payload: res.data.response,
        });
        dispatch(
          setAlert("We have e-mailed you password reset link!", "success")
        );
      } else {
        const errors = res.data.errors;
        dispatch({ type: RESET_LINK_FAIL });
        if (errors) {
          dispatch(setAlert(res.data.message, "danger"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
    } catch (err) {
      if (err.response) {
        dispatch({
          type: RESET_LINK_FAIL,
          payload: {
            msg: err.response.data.message || err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  };

// Verify Reset Password Token Validity
export const getResetTokenStatus = (token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      PASSWORD.VERIFY_RESET_LINK(token).ENDPOINT,
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: RESET_LINK_SUCCESS,
        payload: res.data.response,
      });
    } else {
      dispatch(setAlert(res.data.message, "danger"));
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      dispatch({
        type: RESET_LINK_FAIL,
        payload: {
          msg: err.response.data.message || err.response.statusText,
          status: err.response.status,
        },
      });
    }
  }
};

// Token invalid
export const tokenInvalid = (history, type) => async () => {
  if (type === "email") return history("/");
  history("/forgot-password");
};

// Verify Email Token Validity
export const getEmailVerifyTokenStatus = (token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: LOADING_ON_EMAIL_VERIFICATION,
    });
    const res = await axios.put(EMAIL.VERIFY(token).ENDPOINT, config);
    if (res.data.status === true) {
      await dispatch({
        type: VERIFICATION_LINK_SUCCESS,
        payload: res.data.response,
      });
    } else {
      dispatch(setAlert(res.data.message, "danger"));
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      dispatch({
        type: VERIFICATION_LINK_FAIL,
        payload: {
          msg: err.response.data.message || err.response.statusText,
          status: err.response.status,
        },
      });
    }
  }
};

//Dispatch Confirm password error
export const setPasswordError = (msg, param) => async (dispatch) => {
  dispatch(setErrorsList(msg, param));
};

// Reset Password
export const resetPassword = (password, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnPasswordReset());
    const res = await axios.post(
      PASSWORD.RESET(token).ENDPOINT,
      { password },
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: RESET_LINK_SUCCESS,
        payload: res.data.response,
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: RESET_LINK_FAIL });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      dispatch({
        type: RESET_LINK_FAIL,
        payload: {
          msg: err.response.data.message || err.response.statusText,
          status: err.response.status,
        },
      });
    }
  }
};

// Redirect to forgot password screen
export const forgotPassword = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/forgotPassword");
};

// Redirect to Login screen
export const loginRedirect = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history("/");
};

// Dispatch Loading
export const loadingOnLoginSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_LOGIN_SUBMIT });
};

// Dispatch Password Reset Loading
export const loadingOnPasswordReset = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_PASSWORD_RESET });
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_AUTH_PAGE });
};

//Load Action pending count
export const loadPendingAction = () => async (dispatch) => {
  dispatch(loadingOnLoginSubmit());
  const res = await axios.get(`/api/auth/getAdminPendingAction`);
  if (res.data.status === true) {
    dispatch({
      type: PENDING_ACTION_LOADED,
      payload: res.data.response.action_pending,
    });
    dispatch(loadPage());
  }
};

//function to update sidebar pending action count
export const updateSidebar = (data) => async (dispatch) => {
  try {
    if (data && data.action_pending) {
      dispatch({
        type: PENDING_ACTION_LOADED,
        payload: data.action_pending,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
};
