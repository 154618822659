import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  BLOG_CREATED,
  BLOG_ERROR,
  DELETE_BLOG,
  BLOG_UPDATED,
  BLOG_LIST_UPDATED,
  GET_BLOG_BY_ID,
  BLOG_SEARCH_PARAMATERS_UPDATE,
  REMOVE_ERRORS,
  CHANGE_BLOG_STATUS,
  LOADING_ON_EMAIL_SUBMIT,
  LOADING_EMAIL_LIST_DATA,
  LOAD_PAGE,
  RESET,
} from "actions/types";
import { logout } from "actions/auth";
import { BLOG } from "constants/api";

//get Email Template list

export const getBLOGList = (blogParams) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: LOADING_EMAIL_LIST_DATA });
    const query = blogParams.query ? blogParams.query : "";
    blogParams.query = query;
    blogParams.page = blogParams.page ? blogParams.page : 1;

    config.params = blogParams;
    const res = await axios.get(BLOG.LIST().ENDPOINT, config);
    // dispatch({ type: REMOVE_ALERT });

    dispatch({
      type: BLOG_SEARCH_PARAMATERS_UPDATE,
      payload: blogParams,
    });
    dispatch({
      type: BLOG_LIST_UPDATED,
      payload: res.data.response[0],
    });
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: BLOG_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Get email template by id
export const getBLOGById = (blog_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmailSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`${BLOG.LIST().ENDPOINT}/${blog_id}`, config);
    // dispatch({ type: REMOVE_ALERT });

    await dispatch({
      type: GET_BLOG_BY_ID,
      payload: res.data.response,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: BLOG_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Create Email Template
export const create = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmailSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json; multipart/form-data;",
    },
  };
  try {
    const data = new FormData();
    for (var i in formData) {
      data.append(i, formData[i]);
    }
    const res = await axios.post(BLOG.CREATE().ENDPOINT, data, config);
    if (res.data.status === true) {
      dispatch({
        type: BLOG_CREATED,
        payload: res.data.response,
      });
      // dispatch({ type: REMOVE_ALERT });
      dispatch(setAlert("Blog Created.", "success"));
      history("/home/blog");
    } else {
      const errors = res.data.errors;
      if (errors) {
        console.log("error");
        dispatch(setAlert(res.data.msg, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: BLOG_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Edit Email Template
export const edit = (formData, history, blog_id) => async (dispatch) => {
  // -- Always remove the previous errors before sending new request
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmailSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json; multipart/form-data;",
    },
  };
  try {
    const data = new FormData();
    for (var i in formData) {
      data.append(i, formData[i]);
    }
    const res = await axios.post(
      `${BLOG.LIST().ENDPOINT}/${blog_id}`,
      data,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: BLOG_UPDATED,
        payload: res.data.response,
      });
      // dispatch({ type: REMOVE_ALERT });
      dispatch(setAlert("Blog Updated.", "success"));
      history("/home/blog");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: BLOG_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: BLOG_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Delete Email Template
export const deleteBLOG = (blog_id, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(`${BLOG.LIST().ENDPOINT}/${blog_id}`, config);
    // dispatch({ type: REMOVE_ALERT });

    dispatch({
      type: DELETE_BLOG,
      payload: blog_id,
    });
    dispatch(setAlert("Blog deleted", "success"));
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: BLOG_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

//change status
export const changeStatus = (blog_id, status) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/blog/change-status/${blog_id}`,
      { status },
      config
    );
    // dispatch({ type: REMOVE_ALERT });
    await dispatch({
      type: CHANGE_BLOG_STATUS,
      payload: res.data.response,
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: BLOG_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Delete User
export const cancelSave = (history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history("/home/blog");
};

// Dispatch Loading
export const loadingOnEmailSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_EMAIL_SUBMIT });
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history("/home/page-not-found");
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: BLOG_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_PAGE });
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};
