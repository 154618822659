import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes, { string } from "prop-types";
import { CKEditor } from "ckeditor4-react";
import queryString from 'query-string';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Errors from "views/components/Notifications/Errors";
import { FaPlusSquare, FaTrash } from "react-icons/fa";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  updateOurProperties,
  getOurProperties,
  removeErrors,
  setErrors,
  loadingOurProperties,
} from "actions/admin/ourProperties";
import Spinner from "views/components/Spinner";
import ReactPlayer from "react-player/lazy";

const Home = ({
  updateOurProperties,
  getOurProperties,
  loadingOurProperties,
  loading,
  removeErrors,
  setErrors,
}) => {
  const params = useParams();
  const history = useNavigate();

  const featuresField = {
    feature_icon: "",
    feature_icon_url: "",
    feature_title: "",
    feature_description: "",
  };
  const featuresField1 = {
    top_heading: "",
    heading: "",
    sub_heading: "",

    heading_color: "#000000",
    sub_heading_color: "#6b7280",

    button_name: "",
    features: featuresField,
    background_color: "#FFFFFF",
    box_color: "#FFFFFF",
    title_color: "#000000",
    content_color: "#6b7280",
  };

  const banner_section = {
    heading: "",
    banner_color: "#D97706",
    sub_heading_color: "#D97706",
    sub_heading: "",
    first_button: "",
    second_button: "",
    banner_file: "",
    banner_file_url: "",
  };
  const virtual_tour = {
    background_color: "#FFFFFF",
    heading: "",
    button_text: "",
    sub_heading: "",
    subsection_content: "",
    description: "",
    icon: "",
    virtual_tour_image: "",
    icon_url: "",
    virtual_tour_image_url: "",
    title_color: "#000000",
    content_color: "#6b7280",
  };
  const amenitiesFields = {
    background_color: "#FFFFFF",
    heading: "",
    button_text: "",
    description: "",
    icon: "",
    amenities_image: "",
    icon_url: "",
    amenities_image_url: "",
    content: "",
    sub_content: "",
    title_color: "#000000",
    content_color: "#6b7280",
  };
  const stayInLoopFields = {
    background_color: "#FFFFFF",
    heading: "",
    button_text: "",
    description: "",
    loop_image: "",
    sub_description: "",
    loop_image_url: "",
    text_color: "#FFFFFF",
  };

  const initialState = {
    banner_section: banner_section,
    feature_data: featuresField1,
    virtual_tour,
    amenities: amenitiesFields,
    stayInLoop: stayInLoopFields,
  };
  const [formData, setFormData] = useState(initialState);
  const [bannerFormData, setBannerFormData] = useState(banner_section);
  const [feature1, setFeature1] = useState(featuresField1);
  const [feature, setFeature] = useState([featuresField]);
  const [virtualTour, setVirtualTour] = useState(virtual_tour);
  const [amenities, setAmenities] = useState(amenitiesFields);
  const [stayInLoop, setStayInLoop] = useState(stayInLoopFields);
  const [open, setOpen] = useState("1");
  const [onlyOnce, setOnce] = useState(true);
  const [queryStringData, setQueryStringData] = useState("");
  const [isPrev, setIsPrev] = useState(false);

  const addFormFields = () => {
    if (feature.length > 9) {
      alert("You cannot add more than 9 sections.")
      return false;
    }
    setFeature([...feature, featuresField]);
  };

  const removeFormFields = (index) => {
    let newFeature = [...feature];
    newFeature.splice(index, 1);
    setFeature(newFeature);
  };

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  useEffect(async () => {
    const getData = await getOurProperties();
    if (getData) {
      setBannerFormData({
        ...bannerFormData,
        heading: getData?.banner_section?.heading,
        banner_color: getData?.banner_section?.banner_color,
        sub_heading_color: getData?.banner_section?.sub_heading_color,
        sub_heading: getData?.banner_section?.sub_heading,
        first_button: getData?.banner_section?.first_button,
        second_button: getData?.banner_section?.second_button,
        banner_file: getData?.banner_section?.banner_file?.path,
        banner_file_url: getData?.banner_section?.banner_file?.fullURL,
      });
      setFeature1({
        ...feature1,
        top_heading: getData?.feature_data?.top_heading,
        heading: getData?.feature_data?.heading,
        sub_heading: getData?.feature_data?.sub_heading,
        button_name: getData?.feature_data?.button_name,
        background_color: getData?.feature_data?.background_color,
        box_color: getData?.feature_data?.box_color,
        heading_color: getData?.feature_data?.heading_color,
        sub_heading_color: getData?.feature_data?.sub_heading_color,
        title_color: getData?.feature_data?.title_color,
        content_color: getData?.feature_data?.content_color,
      });

      const newFeature = [...feature];
      const editFeature = Object.entries(getData?.feature_data?.features ?? []);
      for (const [index, value] of editFeature ?? []) {
        newFeature[Number(index)] = {
          ...newFeature[Number(index)],
          feature_icon: value.feature_icon?.path,
          feature_icon_url: value.feature_icon?.fullURL,
          feature_title: value.feature_title,
          feature_description: value.feature_description,
        };
      }
      setFeature(newFeature);
      setVirtualTour({
        ...virtualTour,
        background_color: getData?.virtual_tour?.background_color,
        heading: getData?.virtual_tour?.heading,
        button_text: getData?.virtual_tour?.button_text,
        sub_heading: getData?.virtual_tour?.sub_heading,
        subsection_content: getData?.virtual_tour?.subsection_content,
        description: getData?.virtual_tour?.description,
        icon: getData?.virtual_tour?.icon?.path,
        virtual_tour_image: getData?.virtual_tour?.virtual_tour_image?.path,
        icon_url: getData?.virtual_tour?.icon?.fullURL,
        virtual_tour_image_url:
          getData?.virtual_tour?.virtual_tour_image?.fullURL,
        title_color: getData?.virtual_tour?.title_color,
        content_color: getData?.virtual_tour?.content_color
      });
      setAmenities({
        ...amenities,
        background_color: getData?.amenities?.background_color,
        heading: getData?.amenities?.heading,
        button_text: getData?.amenities?.button_text,
        description: getData?.amenities?.description,
        sub_content: getData?.amenities?.sub_content,
        content: getData?.amenities?.content,
        icon: getData?.amenities?.icon?.path,
        amenities_image: getData?.amenities?.amenities_image?.path,
        icon_url: getData?.amenities?.icon?.fullURL,
        amenities_image_url: getData?.amenities?.amenities_image?.fullURL,
        title_color: getData?.amenities?.title_color,
        content_color: getData?.amenities?.content_color,
      });
      setStayInLoop({
        ...stayInLoop,
        background_color: getData?.stayInLoop?.background_color,
        heading: getData?.stayInLoop.heading,
        button_text: getData?.stayInLoop.button_text,
        description: getData?.stayInLoop.description,
        loop_image: getData?.stayInLoop.loop_image?.path,
        sub_description: getData?.stayInLoop.sub_description,
        loop_image_url: getData?.stayInLoop.loop_image?.fullURL,
        text_color: getData?.stayInLoop.text_color
      });
    }
  }, [getOurProperties]);

  const onBannerChange = async (event) => {
    const { name, value } = event.target;
    const fields = [
      "banner_color",
      "sub_heading_color",
    ];
    if (fields.includes(name)) {
      setIsPrev(true)
    }
    switch (name) {
      case "banner_file":
        const banner_image_file = event.target.files[0];
        const banner_file_url = URL.createObjectURL(banner_image_file);

        const reader = new FileReader();
        reader.onload = async () => {
          console.log(reader.result, "gdgdfgdfgdfg");
          setBannerFormData({
            ...bannerFormData,
            banner_file: reader.result,
            banner_file_url,
          });
        };
        reader.readAsDataURL(banner_image_file);
        break;
      default:
        setBannerFormData({
          ...bannerFormData,
          [name]: value.replace(/[^a-z0-9]/gi, "")
            ? value.trimLeft()
            : value.trim(),
        });
    }
  };

  const onFeatureChange = (event, index) => {
    const { name, value } = event.target;
    switch (name) {
      case "features_icon":
        let image_file = event.target.files[0];
        let file_url = URL.createObjectURL(image_file);
        let imageFeature = [...feature];

        const reader = new FileReader();
        reader.onload = async () => {
          imageFeature[index]["feature_icon"] = reader.result;
          imageFeature[index]["feature_icon_url"] = file_url;
          setFeature(imageFeature);
        };
        reader.readAsDataURL(image_file);
        break;
      default:
        let newFeature = [...feature];
        newFeature[index][name] = value.replace(/[^a-z0-9]/gi, "")
          ? value.trimLeft()
          : value.trim();
        setFeature(newFeature);
    }
  };

  const onFeatureChange1 = (event) => {
    const { name, value } = event.target;
    const fields = [
      "background_color",
      "box_color",
      "heading_color",
      "sub_heading_color",
      "title_color",
      "content_color",
    ];
    if (fields.includes(name)) {
      setIsPrev(true)
    }
    setFeature1({
      ...feature1,
      [name]: value.replace(/[^a-z0-9]/gi, "")
        ? value.trimLeft()
        : value.trim(),
    });
  };

  const onAmenitiesChange = (event) => {
    const { name, value } = event.target;
    const fields = [
      "background_color",
      "title_color",
      "content_color",
    ];
    if (fields.includes(name)) {
      setIsPrev(true)
    }
    switch (name) {
      case "icon":
        let image_file = event.target.files[0];
        let file_url = URL.createObjectURL(image_file);

        const reader = new FileReader();
        reader.onload = async () => {
          setAmenities({
            ...amenities,
            icon: reader.result,
            icon_url: file_url,
          });
          setFormData({ ...formData, amenities });
        };
        reader.readAsDataURL(image_file);
        break;
      case "amenities_image":
        const amenities_file = event.target.files[0];
        const amenities_file_url = URL.createObjectURL(amenities_file);

        const fileReader = new FileReader();
        fileReader.onload = async () => {
          setAmenities({
            ...amenities,
            amenities_image: fileReader.result,
            amenities_image_url: amenities_file_url,
          });
          setFormData({ ...formData, amenities });
        };
        fileReader.readAsDataURL(amenities_file);
        break;
      default:
        setAmenities({
          ...amenities,
          [name]: value.replace(/[^a-z0-9]/gi, "")
            ? value.trimLeft()
            : value.trim(),
        });
    }
  };
  const on3DVirtualChange = (event) => {
    const { name, value } = event.target;
    const fields = [
      "background_color",
      "title_color",
      "content_color",
    ];
    if (fields.includes(name)) {
      setIsPrev(true)
    }
    switch (name) {
      case "icon":
        let image_file = event.target.files[0];
        let file_url = URL.createObjectURL(image_file);

        const fileReader = new FileReader();
        fileReader.onload = async () => {
          setVirtualTour({
            ...virtualTour,
            icon: fileReader.result,
            icon_url: file_url,
          });
        };
        fileReader.readAsDataURL(image_file);
        break;
      case "virtual_tour_image":
        let virtual_file = event.target.files[0];
        let virtual_file_url = URL.createObjectURL(virtual_file);
        const reader = new FileReader();
        reader.onload = async () => {
          setVirtualTour({
            ...virtualTour,
            virtual_tour_image: reader.result,
            virtual_tour_image_url: virtual_file_url,
          });
        };
        reader.readAsDataURL(virtual_file);
        break;
      default:
        setVirtualTour({
          ...virtualTour,
          [name]: value.replace(/[^a-z0-9]/gi, "")
            ? value.trimLeft()
            : value.trim(),
        });
    }
  };

  const onStayLoopChange = (event) => {
    const { name, value } = event.target;
    const fields = [
      "background_color",
      "text_color",
    ];
    if (fields.includes(name)) {
      setIsPrev(true)
    }
    switch (name) {
      case "loop_image":
        let loop_file = event.target.files[0];
        let loop_file_url = URL.createObjectURL(loop_file);

        const reader = new FileReader();
        reader.onload = async () => {
          setStayInLoop({
            ...stayInLoop,
            loop_image: reader.result,
            loop_image_url: loop_file_url,
          });
        };
        reader.readAsDataURL(loop_file);
        break;
      default:
        setStayInLoop({
          ...stayInLoop,
          [name]: value.replace(/[^a-z0-9]/gi, "")
            ? value.trimLeft()
            : value.trim(),
        });
    }
  };

  useEffect(() => {
    const queryData = {
      banner_section: {
        banner_color: bannerFormData.banner_color,
        sub_heading_color: bannerFormData.sub_heading_color
      },
      feature_data: {
        background_color: feature1.background_color,
        box_color: feature1.box_color,
        heading_color: feature1.heading_color,
        sub_heading_color: feature1.sub_heading_color,
        content_color: feature1.content_color,
        title_color: feature1.title_color
      },
      virtual_tour: {
        background_color: virtualTour.background_color,
        title_color: virtualTour.title_color,
        content_color: virtualTour.content_color
      },
      stayInLoop: {
        background_color: stayInLoop.background_color,
        text_color: stayInLoop.text_color
      },
      amenities: {
        background_color: amenities.background_color,
        title_color: amenities.title_color,
        content_color: amenities.content_color
      }
    }
    const setString = queryString.stringify({ prev: JSON.stringify(queryData) });
    const prevUrl = process.env.REACT_APP_CLIENT_URL;
    setQueryStringData(`${prevUrl}/?${setString}`)
  }, [bannerFormData, feature1, virtualTour, stayInLoop, amenities])

  const onSubmit = (e) => {
    e.preventDefault();
    formData.banner_section = bannerFormData;
    feature1.features = feature;
    formData.feature_data = feature1;
    formData.virtual_tour = virtualTour;
    formData.stayInLoop = stayInLoop;
    formData.amenities = amenities;
    console.log(formData, "sdfsdfsdfsdfsdfs");
    updateOurProperties(formData, history, "");
  };

  return (
    <div className="animated fadeIn">
      {loading && <Spinner applyClass={"loading-box"} />}
      <Row>
        <Col xs={12}>
          <Card className="mb-4">
            <CardHeader>
              <span className="pull-left h5">Home Information</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="1">
                    <strong>Banner Section</strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="1">
                    <Card>
                      <CardBody>
                        <h6>
                          <b>Banner Section</b>
                        </h6>
                        <Row>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="heading">
                                Heading<span>*</span>
                              </Label>
                              <Input
                                style={{ color: bannerFormData.banner_color }}
                                type="text"
                                id="heading"
                                name="heading"
                                value={bannerFormData.heading}
                                onChange={(e) => onBannerChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="banner_section.heading"
                                key="banner_section.heading"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="sub_heading">
                                Sub Heading <span>*</span>
                              </Label>
                              <Input
                                style={{ color: bannerFormData.sub_heading_color }}
                                type="text"
                                id="sub_heading"
                                name="sub_heading"
                                value={bannerFormData.sub_heading}
                                required
                                onChange={(e) => onBannerChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="banner_section.sub_heading"
                                key="banner_section.sub_heading"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="banner_color">
                                Heading Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="banner_color"
                                name="banner_color"
                                value={bannerFormData.banner_color}
                                onChange={(e) => onBannerChange(e)}
                              // invalid={errorList.banner_color ? true : false}
                              />
                              <Errors
                                current_key="banner_section.banner_color"
                                key="banner_section.banner_color"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="sub_heading_color">
                                Sub Heading Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="sub_heading_color"
                                name="sub_heading_color"
                                value={bannerFormData.sub_heading_color}
                                onChange={(e) => onBannerChange(e)}
                              // invalid={errorList.sub_heading_color ? true : false}
                              />
                              <Errors
                                current_key="banner_section.sub_heading_color"
                                key="banner_section.sub_heading_color"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="first_button">
                                First Button Name <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="first_button"
                                name="first_button"
                                value={bannerFormData.first_button}
                                required
                                onChange={(e) => onBannerChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="banner_section.first_button"
                                key="banner_section.first_button"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="second_button">
                                Second Button Name <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="second_button"
                                name="second_button"
                                value={bannerFormData.second_button}
                                required
                                onChange={(e) => onBannerChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="banner_section.second_button"
                                key="banner_section.second_button"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="banner_file">
                                Banner Image <span>*</span>
                              </Label>
                              <Input
                                type="file"
                                id="banner_file"
                                name="banner_file"
                                onChange={(e) => onBannerChange(e)}
                                accept="video/*"
                              //invalid={bannerFormData.banner_file ? false : true}
                              />
                              {/* {!bannerFormData.banner_file && <small className="text-danger">Please select a file.</small>} */}
                              <Errors
                                current_key="banner_section.banner_file"
                                key="banner_section.banner_file"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6} className="mt-5">
                            {bannerFormData.banner_file_url ? (
                              <ReactPlayer
                                url={bannerFormData.banner_file_url}
                                controls={true}
                                width={200}
                                height={100}
                                config={{
                                  file: {
                                    attributes: { controlsList: "nodownload" },
                                  },
                                }}
                                style={{ margin: "-15px 0 0" }}
                              />
                            ) : null}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="2">
                    <strong>Features of University Townhouses</strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="2">
                    <Card>
                      <CardBody>
                        <h6>
                          <b>Features of Colors</b>
                        </h6>
                        <Row>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="background_color">
                                Background Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="background_color"
                                name="background_color"
                                value={feature1.background_color}
                                maxLength="100"
                                required
                                onChange={(e) => onFeatureChange1(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="feature_data.background_color"
                                key="feature_data.background_color"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="box_color">
                                Box Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="box_color"
                                name="box_color"
                                value={feature1.box_color}
                                maxLength="100"
                                required
                                onChange={(e) => onFeatureChange1(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="feature_data.box_color"
                                key="feature_data.box_color"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="title_color">
                                Box Title Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="title_color"
                                name="title_color"
                                value={feature1.title_color}
                                maxLength="100"
                                required
                                onChange={(e) => onFeatureChange1(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="feature_data.title_color"
                                key="feature_data.title_color"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="content_color">
                                Box Content Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="content_color"
                                name="content_color"
                                value={feature1.content_color}
                                maxLength="100"
                                required
                                onChange={(e) => onFeatureChange1(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="feature_data.content_color"
                                key="feature_data.content_color"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <h6>
                          <b>Features of University Townhouses</b>
                        </h6>
                        <Row>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="top_heading">
                                Top heading <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="top_heading"
                                name="top_heading"
                                value={feature1.top_heading}
                                maxLength="100"
                                required
                                onChange={(e) => onFeatureChange1(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="feature_data.top_heading"
                                key="feature_data.top_heading"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={5}>
                            <FormGroup>
                              <Label htmlFor="heading">
                                Heading <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="heading"
                                name="heading"
                                value={feature1.heading}
                                required
                                onChange={(e) => onFeatureChange1(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="feature_data.heading"
                                key="feature_data.heading"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={1}>
                            <FormGroup>
                              <Input
                                type="color"
                                className="color-box"
                                id="heading_color"
                                name="heading_color"
                                value={feature1.heading_color}
                                required
                                onChange={(e) => onFeatureChange1(e)}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={5}>
                            <FormGroup>
                              <Label htmlFor="sub_heading">
                                Sub Heading <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="sub_heading"
                                name="sub_heading"
                                value={feature1.sub_heading}
                                required
                                onChange={(e) => onFeatureChange1(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="feature_data.sub_heading"
                                key="feature_data.sub_heading"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={1}>
                            <FormGroup>
                              <Input
                                type="color"
                                className="color-box"
                                id="sub_heading_color"
                                name="sub_heading_color"
                                value={feature1.sub_heading_color}
                                required
                                onChange={(e) => onFeatureChange1(e)}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="button_name">
                                Button Name <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="button_name"
                                name="button_name"
                                value={feature1.button_name}
                                required
                                onChange={(e) => onFeatureChange1(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="feature_data.button_name"
                                key="feature_data.button_name"
                              />
                            </FormGroup>
                          </Col>
                          <hr />
                          <Col xs={6}>
                            <h6>
                              <b>Features</b>
                            </h6>
                          </Col>
                          <Col xs={6}>
                            <Button
                              className="m-1"
                              size="sm"
                              color="primary"
                              style={{ float: "right" }}
                              onClick={() => addFormFields()}
                            >
                              <i className="fa fa-dot-circle-o"></i> Add More
                            </Button>
                          </Col>
                          {feature.map((element, index) => (
                            <>
                              <Col key={`feature-${index}`} xs={6}>
                                <FormGroup>
                                  <Label htmlFor="feature_title">
                                    Features Title <span>*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    id="feature_title"
                                    name="feature_title"
                                    value={feature[index]["feature_title"]}
                                    required
                                    onChange={(e) => onFeatureChange(e, index)}
                                  // invalid={errorList.heading ? true : false}
                                  />
                                  <Errors
                                    current_key={
                                      "feature_data.features[" +
                                      index +
                                      "].feature_title"
                                    }
                                    key={
                                      "feature_data.features[" +
                                      index +
                                      "].feature_title"
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col
                                key={`feature1-${index}`}
                                xs={feature[index].feature_icon_url ? 5 : 6}
                              >
                                <FormGroup>
                                  <Label htmlFor="features_icon">
                                    Features Icon <span>*</span>
                                  </Label>
                                  <Input
                                    type="file"
                                    id="features_icon"
                                    name="features_icon"
                                    onChange={(e) => onFeatureChange(e, index)}
                                    accept="image/*"
                                  // invalid={feature[index].feature_icon ? false : true}
                                  />
                                  {/* {!feature[index].feature_icon && <small className="text-danger">Please select a file.</small>} */}
                                  <Errors
                                    current_key={
                                      "feature_data.features[" +
                                      index +
                                      "].features_icon"
                                    }
                                    key={
                                      "feature_data.features[" +
                                      index +
                                      "].features_icon"
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              {feature && feature[index].feature_icon_url ? (
                                <Col xs={1} className="mt-5">
                                  <img
                                    src={feature[index].feature_icon_url}
                                    className="preview-img img-fluid"
                                    alt="image_file"
                                    style={{ backgroundColor: "#f59e0b", height: "40px" }}
                                  />
                                </Col>
                              ) : null}
                              <Col
                                key={`feature2-${index}`}
                                xs={index ? 10 : 12}
                              >
                                <FormGroup>
                                  <Label htmlFor="feature_description">
                                    Feature Description <span>*</span>
                                  </Label>
                                  <Input
                                    type="textarea"
                                    id="feature_description"
                                    name="feature_description"
                                    value={
                                      feature[index]["feature_description"]
                                    }
                                    required
                                    onChange={(e) => onFeatureChange(e, index)}
                                  // invalid={errorList.heading ? true : false}
                                  />
                                  <Errors
                                    current_key={
                                      "feature_data.features[" +
                                      index +
                                      "].feature_description"
                                    }
                                    key={
                                      "feature_data.features[" +
                                      index +
                                      "].feature_description"
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              {index ? (
                                <Col
                                  key={`feature3-${index}`}
                                  xs={2}
                                  className="mt-5"
                                >
                                  <Button
                                    size="md"
                                    color="danger"
                                    onClick={() => removeFormFields(index)}
                                  >
                                    <FaTrash color="white" />
                                  </Button>
                                  <Button
                                    className="m-1"
                                    size="md"
                                    color="primary"
                                    onClick={() => addFormFields()}
                                  >
                                    <FaPlusSquare color="white" />
                                  </Button>
                                </Col>
                              ) : null}
                            </>
                          ))}
                        </Row>
                      </CardBody>
                    </Card>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="3">
                    <strong>3D Virtual Tour</strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="3">
                    <Card>
                      <CardBody>
                        <h6>
                          <b>3D Virtual Colors</b>
                        </h6>
                        <Row>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="background_color">
                                Background Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="background_color"
                                name="background_color"
                                value={virtualTour.background_color}
                                maxLength="100"
                                required
                                onChange={(e) => on3DVirtualChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="virtual_tour.background_color"
                                key="virtual_tour.background_color"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="title_color">
                                Title Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="title_color"
                                name="title_color"
                                value={virtualTour.title_color}
                                maxLength="100"
                                required
                                onChange={(e) => on3DVirtualChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="virtual_tour.title_color"
                                key="virtual_tour.title_color"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="content_color">
                                Content Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="content_color"
                                name="content_color"
                                value={virtualTour.content_color}
                                maxLength="100"
                                required
                                onChange={(e) => on3DVirtualChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="virtual_tour.content_color"
                                key="virtual_tour.content_color"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <h6>
                          <b>3D Virtual Tour</b>
                        </h6>
                        <Row>
                          <Col xs={4}>
                            <FormGroup>
                              <Label htmlFor="heading">
                                Heading <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="heading"
                                name="heading"
                                value={virtualTour.heading}
                                maxLength="100"
                                required
                                onChange={(e) => on3DVirtualChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="virtual_tour.heading"
                                key="virtual_tour.heading"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={4}>
                            <FormGroup>
                              <Label htmlFor="button_text">
                                Button Text <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="button_text"
                                name="button_text"
                                value={virtualTour.button_text}
                                maxLength="100"
                                required
                                onChange={(e) => on3DVirtualChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="virtual_tour.button_text"
                                key="virtual_tour.button_text"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={4}>
                            <FormGroup>
                              <Label htmlFor="sub_heading">
                                Sub Heading <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="sub_heading"
                                name="sub_heading"
                                value={virtualTour.sub_heading}
                                required
                                onChange={(e) => on3DVirtualChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="virtual_tour.sub_heading"
                                key="virtual_tour.sub_heading"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12}>
                            <FormGroup>
                              <Label htmlFor="subsection_content">
                                Subsection Content <span>*</span>
                              </Label>
                              <Input
                                type="textarea"
                                id="subsection_content"
                                name="subsection_content"
                                value={virtualTour.subsection_content}
                                required
                                onChange={(e) => on3DVirtualChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="virtual_tour.subsection_content"
                                key="virtual_tour.subsection_content"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12}>
                            <FormGroup>
                              <Label htmlFor="description">
                                Description <span>*</span>
                              </Label>
                              <Input
                                type="textarea"
                                id="description"
                                name="description"
                                value={virtualTour.description}
                                onChange={(e) => on3DVirtualChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="virtual_tour.description"
                                key="virtual_tour.description"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={4}>
                            <FormGroup>
                              <Label htmlFor="icon">
                                Icon <span>*</span>
                              </Label>
                              <Input
                                type="file"
                                id="icon"
                                name="icon"
                                onChange={(e) => on3DVirtualChange(e)}
                                accept="image/*"
                              //invalid={virtualTour.icon ? false : true}
                              />
                              {/* {!virtualTour.icon && <small className="text-danger">Please select a file.</small>} */}
                              <Errors
                                current_key="virtual_tour.icon"
                                key="virtual_tour.icon"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={2} className="mt-5">
                            {virtualTour.icon_url ? (
                              <img
                                src={virtualTour.icon_url}
                                className="preview-img img-fluid"
                                alt="image_file"
                                style={{ backgroundColor: "#f59e0b", height: "40px" }}
                              />
                            ) : null}
                          </Col>
                          <Col xs={4}>
                            <FormGroup>
                              <Label htmlFor="virtual_tour_image">
                                Virtual Tour Image <span>*</span>
                              </Label>
                              <Input
                                type="file"
                                id="virtual_tour_image"
                                name="virtual_tour_image"
                                onChange={(e) => on3DVirtualChange(e)}
                                accept="image/*"
                              // invalid={virtualTour.virtual_tour_image ? false : true}
                              />
                              {/* {!virtualTour.virtual_tour_image && <small className="text-danger">Please select a file.</small>} */}
                              <Errors
                                current_key="virtual_tour.virtual_tour_image"
                                key="virtual_tour.virtual_tour_image"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={2} className="mt-5">
                            {virtualTour.virtual_tour_image_url ? (
                              <img
                                src={virtualTour.virtual_tour_image_url}
                                className="preview-img img-fluid"
                                alt="image_file"
                                style={{ height: "40px" }}
                              />
                            ) : null}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="4">
                    <strong>Amenities</strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="4">
                    <Card>
                      <CardBody>
                        <h6>
                          <b>Amenities Colors</b>
                        </h6>
                        <Row>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="background_color">
                                Background Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="background_color"
                                name="background_color"
                                value={amenities.background_color}
                                maxLength="100"
                                required
                                onChange={(e) => onAmenitiesChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="amenities.background_color"
                                key="amenities.background_color"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="title_color">
                                Title Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="title_color"
                                name="title_color"
                                value={amenities.title_color}
                                maxLength="100"
                                required
                                onChange={(e) => onAmenitiesChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="amenities.title_color"
                                key="amenities.title_color"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="content_color">
                                Content Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="content_color"
                                name="content_color"
                                value={amenities.content_color}
                                maxLength="100"
                                required
                                onChange={(e) => onAmenitiesChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="amenities.content_color"
                                key="amenities.content_color"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <h6>
                          <b>Amenities</b>
                        </h6>
                        <Row>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="heading">
                                Heading <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="heading"
                                name="heading"
                                value={amenities.heading}
                                maxLength="100"
                                onChange={(e) => onAmenitiesChange(e)}
                                required
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="amenities.heading"
                                key="amenities.heading"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="button_text">
                                Button Text <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="button_text"
                                name="button_text"
                                value={amenities.button_text}
                                maxLength="100"
                                required
                                onChange={(e) => onAmenitiesChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="amenities.button_text"
                                key="amenities.button_text"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12}>
                            <FormGroup>
                              <Label htmlFor="description">
                                Description <span>*</span>
                              </Label>
                              <Input
                                type="textarea"
                                id="description"
                                name="description"
                                value={amenities.description}
                                onChange={(e) => onAmenitiesChange(e)}
                                required
                                accept="image/*"
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="amenities.description"
                                key="amenities.description"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="content">
                                Content <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="content"
                                name="content"
                                value={amenities.content}
                                maxLength="100"
                                required
                                onChange={(e) => onAmenitiesChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="amenities.content"
                                key="amenities.content"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="sub_content">
                                Sub Content <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="sub_content"
                                name="sub_content"
                                value={amenities.sub_content}
                                maxLength="100"
                                required
                                onChange={(e) => onAmenitiesChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="amenities.sub_content"
                                key="amenities.sub_content"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={4}>
                            <FormGroup>
                              <Label htmlFor="icon">
                                Icon <span>*</span>
                              </Label>
                              <Input
                                type="file"
                                id="icon"
                                name="icon"
                                accept="image/*"
                                onChange={(e) => onAmenitiesChange(e)}
                              />

                              <Errors
                                current_key="amenities.icon"
                                key="amenities.icon"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={2} className="mt-5">
                            {amenities.icon_url ? (
                              <img
                                src={amenities.icon_url}
                                className="preview-img img-fluid"
                                alt="image_file"
                                style={{ backgroundColor: "#f59e0b", height: "40px" }}
                              />
                            ) : null}
                          </Col>
                          <Col xs={4}>
                            <FormGroup>
                              <Label htmlFor="amenities_image">
                                Amenities Image <span>*</span>
                              </Label>
                              <Input
                                type="file"
                                id="amenities_image"
                                name="amenities_image"
                                onChange={(e) => onAmenitiesChange(e)}
                                accept="image/*"
                                invalid={
                                  amenities.amenities_image ? false : true
                                }
                              />
                              {!amenities.amenities_image && (
                                <small className="text-danger">
                                  Please select a file.
                                </small>
                              )}
                              <Errors
                                current_key="amenities.amenities_image"
                                key="amenities.amenities_image"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={2} className="mt-5">
                            {amenities.amenities_image_url ? (
                              <img
                                src={amenities.amenities_image_url}
                                className="preview-img img-fluid"
                                alt="image_file"
                                style={{ height: "40px" }}
                              />
                            ) : null}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="5">
                    <strong>Stay in Loop</strong>
                  </AccordionHeader>
                  <AccordionBody accordionId="5">
                    <Card>
                      <CardBody>
                        <h6>
                          <b>Colors</b>
                        </h6>
                        <Row>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="background_color">
                                Background Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="background_color"
                                name="background_color"
                                value={stayInLoop.background_color}
                                maxLength="100"
                                required
                                onChange={(e) => onStayLoopChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="stayInLoop.background_color"
                                key="stayInLoop.background_color"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={3}>
                            <FormGroup>
                              <Label htmlFor="text_color">
                                Text Color <span>*</span>
                              </Label>
                              <Input
                                type="color"
                                id="text_color"
                                name="text_color"
                                value={stayInLoop.text_color}
                                maxLength="100"
                                required
                                onChange={(e) => onStayLoopChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="stayInLoop.text_color"
                                key="stayInLoop.text_color"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <h6>
                          <b>Stay in Loop</b>
                        </h6>
                        <Row>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="heading">
                                Heading <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="heading"
                                name="heading"
                                value={stayInLoop.heading}
                                maxLength="100"
                                required
                                onChange={(e) => onStayLoopChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="stayInLoop.heading"
                                key="stayInLoop.heading"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="button_text">
                                Button Text <span>*</span>
                              </Label>
                              <Input
                                type="text"
                                id="button_text"
                                name="button_text"
                                value={stayInLoop.button_text}
                                maxLength="100"
                                required
                                onChange={(e) => onStayLoopChange(e)}
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="stayInLoop.button_text"
                                key="stayInLoop.button_text"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12}>
                            <FormGroup>
                              <Label htmlFor="description">
                                Description <span>*</span>
                              </Label>
                              <Input
                                type="textarea"
                                id="description"
                                name="description"
                                value={stayInLoop.description}
                                onChange={(e) => onStayLoopChange(e)}
                                required
                                accept="image/*"
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="stayInLoop.description"
                                key="stayInLoop.description"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12}>
                            <FormGroup>
                              <Label htmlFor="sub_description">
                                Sub Description <span>*</span>
                              </Label>
                              <Input
                                type="textarea"
                                id="sub_description"
                                name="sub_description"
                                value={stayInLoop.sub_description}
                                onChange={(e) => onStayLoopChange(e)}
                                required
                                accept="image/*"
                              // invalid={errorList.heading ? true : false}
                              />
                              <Errors
                                current_key="stayInLoop.sub_description"
                                key="stayInLoop.sub_description"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6}>
                            <FormGroup>
                              <Label htmlFor="loop_image">
                                Image <span>*</span>
                              </Label>
                              <Input
                                type="file"
                                id="loop_image"
                                name="loop_image"
                                onChange={(e) => onStayLoopChange(e)}
                                accept="image/*"
                              />

                              <Errors
                                current_key="stayInLoop.loop_image"
                                key="stayInLoop.loop_image"
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={2} className="mt-5">
                            {stayInLoop.loop_image_url ? (
                              <img
                                src={stayInLoop.loop_image_url}
                                className="preview-img img-fluid"
                                alt="image_file"
                                style={{ height: "40px" }}
                              />
                            ) : null}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                    loading={loading}
                    disabled={loading}
                  >
                    <i className="fa fa-dot-circle-o"></i>{" "}
                    {loading ? (
                      <Spinner style={{ fontSize: "20px" }} />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  {isPrev && <a href={queryStringData} target={'_blank'}>
                    <Button
                      type="button"
                      className="m-1"
                      size="sm"
                      color="info"
                    >
                      <i className="fa fa-ban"></i> Preview
                    </Button>
                  </a>}
                  <Link to="/dashboard/cms">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Home.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateOurProperties: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  //currentSubBanner: state.subBanner.currentSubBanner,
  errorList: state.errors,
  loading: state.ourProperties.loadingOurProperties,
});

export default connect(mapStateToProps, {
  updateOurProperties,
  getOurProperties,
  loadingOurProperties,
  removeErrors,
  setErrors,
})(Home);
//export default Home;
