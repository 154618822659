import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CKEditor } from "ckeditor4-react";
import slugify from "react-slugify";

import {
  create,
  cancelSave,
  loadPage,
  setErrors,
  removeErrors,
} from "actions/admin/cms";
import Errors from "views/components/Notifications/Errors";
import Spinner from "views/components/Spinner";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router";

const CreateCMS = ({
  create,
  cancelSave,
  errorList,
  loading,
  setErrors,
  loadPage,
  removeErrors,
}) => {
  const history = useNavigate();

  const [formData, setFormData] = useState({
    visible_in_footer: 0,
    selectedCategory: {},
    page_title: "",
    page_header: "",
    slug: "",
    description: "",
    meta_description: "",
    thumbnail: "",
    selectedFile: "",
    image: "",
  });

  const {
    // selectedCategory,
    // visible_in_footer,
    page_title,
    page_header,
    slug,
    description,
    meta_description,
    image,
  } = formData;

  // const [loadingOption, setLoadingOption] = useState(false);
  const [onlyOnce, setOnce] = useState(true);
  let delayTimer;

  const onChange = (e) => {
    if (e.target.name === "page_title") {
      let new_slug = slugify(e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        slug: new_slug,
      });
    } else if (e.target.name === "thumbnail") {
      let image_file = e.target.files[0];

      let image_url = URL.createObjectURL(image_file);

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        image: image_url,
      });
    } else if (e.target.name === "visible_in_footer") {
      setFormData({ ...formData, [e.target.name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleDescriptionChange = (event /* editor */) => {
    const data = event.editor.getData(); //editor.getData();
    setFormData((ev) => ({ ...ev, description: data }));
  };

  /**
   * 1) IF form field is an object
   *    --- [{
   *          "param":"field_name",
   *          "value":"value that has to be matched from object",
   *          "actualParam":"to represent actual field name"
   *          "msg": "msg that has to be shown"
   *        }]
   * 2) IF form field is string
   *    --- [{
   *          "param":"field_name",
   *          "actualParam":"to represent actual field name",
   *          "msg": "msg that has to be shown"
   *        }]
   * @param {object} form , data which has to be verify
   * @param {array} requireFields , fields that has to be verify
   */
  const validateForm = (form, requireFields) => {
    const errors = [];
    for (let i in requireFields) {
      if (requireFields[i].type === "object") {
        if (
          !form[requireFields[i].param][requireFields[i].value].trim().length
        ) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (requireFields[i].type === "array") {
        if (!form[requireFields[i].param].length) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (!form[requireFields[i].param].trim().length) {
        errors.push({
          param: requireFields[i].actualParam || requireFields[i].param,
          msg: requireFields[i].msg,
        });
      }
    }
    if (errors.length) {
      setErrors(errors);
    }
    return !errors.length;
  };

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    if (
      !validateForm(formData, [
        {
          param: "page_title",
          msg: "Please enter the title of page",
        },
        {
          param: "page_header",
          msg: "Please enter the header of page",
        },
        {
          param: "thumbnail",
          msg: "Please select a page thumbnail",
        },
        {
          param: "description",
          msg: "Please provide the page description",
        },
      ])
    )
      return;

    const submitData = {};
    const excludeList = { selectedCategory: 1, thumbnail: 1 };
    for (let i in formData) {
      if ((formData[i] !== 0 && !formData[i]) || excludeList[i]) continue;
      submitData[i] = formData[i];
    }
    create(submitData, history);
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe) {
        loadPage();
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card>
              <CardHeader>
                <h5>Page Information</h5>
              </CardHeader>
              <Form
                className="form-horizontal"
                onSubmit={(e) => onSubmit(e)}
                encType="multipart/form-data"
              >
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="slug">
                      Slug <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="slug"
                      name="slug"
                      maxLength="100"
                      value={slug}
                      required
                      readOnly
                      // onChange={e => onChange(e)}
                      invalid={errorList.slug ? true : false}
                    />
                    <Errors current_key="slug" key="slug" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="page_title">
                      Page Title <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="page_title"
                      name="page_title"
                      maxLength="100"
                      value={page_title}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.page_title ? true : false}
                    />
                    <Errors current_key="page_title" key="page_title" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="page_header">
                      Page Header <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="page_header"
                      name="page_header"
                      maxLength="100"
                      value={page_header}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.page_header ? true : false}
                    />
                    <Errors current_key="page_header" key="page_header" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="meta_description">Meta Description</Label>
                    <Input
                      type="textarea"
                      id="meta_description"
                      name="meta_description"
                      value={meta_description}
                      onChange={(e) => onChange(e)}
                      invalid={errorList.meta_description ? true : false}
                    />
                    <Errors
                      current_key="meta_description"
                      key="meta_description"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="thumbnail">
                      Thumbnail<span>*</span>
                    </Label>
                    <Input
                      type="file"
                      id="thumbnail"
                      name="thumbnail"
                      onChange={(e) => onChange(e)}
                      multiple
                      accept="image/png, image/jpeg"
                      invalid={errorList.thumbnail ? true : false}
                    />
                    <image src={image} className="preview-img img-fluid" alt="" />
                    {/* {image_preview} */}
                    <Errors current_key="thumbnail" key="thumbnail" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="description">
                      Body <span>*</span>
                    </Label>
                    <CKEditor
                      data={description}
                      id="description"
                      name="description"
                      config={{
                        height: 100,
                        toolbar: [
                          ["Cut", "Copy", "Paste"],
                          ["Undo", "Redo"],
                          ["SpellChecker"],
                          ["Link", "Unlink", "Anchor"],
                          [
                            "Image",
                            "Table",
                            "Horizontal Line",
                            "Special Character",
                          ],
                          ["Maximize"],
                          ["Source"],
                          ["Bold", "Italic", "Strike"],
                          ["RemoveFormat"],
                          ["NumberedList", "BulletedList"],
                          ["DecreaseIndent", "IncreaseIndent"],
                          ["BlockQuote"],
                          ["Styles"],
                          ["Format"],
                          ["About"],
                        ],
                      }}
                      onChange={(event) => handleDescriptionChange(event)}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                      invalid={errorList.description ? true : false}
                    />
                    <Errors current_key="description" key="description" />
                  </FormGroup>
                  {/* <FormGroup>
                    <FormGroup check>
                      <Label check title="Selected pages will be shown at Customer website footer">
                        <Input 
                          style={{ marginTop: 4 }}
                          type="checkbox" 
                          name="visible_in_footer"
                          checked={visible_in_footer === 1 }
                          onChange={e => onChange(e)}
                        />{' '}
                        Should this page be visible in footer?
                      </Label>
                    </FormGroup>
                    <Errors current_key="visible_in_footer" key="visible_in_footer" />
                  </FormGroup> */}
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    {/* {loading?<Spinner/>: */}
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <a onClick={onClickHandel} href="#!">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </a>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

CreateCMS.propTypes = {
  create: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
  cms: PropTypes.object,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadPage: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  cms: state.cms,
  errorList: state.errors,
  loading: state.cms.loadingEmail,
});

export default connect(mapStateToProps, {
  create,
  cancelSave,
  loadPage,
  setErrors,
  removeErrors,
})(CreateCMS);
