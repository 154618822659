import React from "react";
import { Input, InputGroup, Button, InputGroupText } from "reactstrap";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa";

const Password = ({ icon = false, ...rest }) => {
  const [visible, setVisibility] = React.useState(false);
  return (
    <InputGroup>
      {!!icon && (
        <InputGroupText>
          <FaLock />
        </InputGroupText>
      )}
      <Input type={visible ? "text" : "password"} {...rest} />
      <Button color="primary" onClick={() => setVisibility(!visible)}>
        {visible ? <FaEyeSlash /> : <FaEye />}
      </Button>
    </InputGroup>
  );
};

export default Password;
