import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";

const Page404 = () => {
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <div className="clearfix">
              <h4 className="float-left mr-4 mt-4">
                Session Expired / Page not found
              </h4>
              <h6 className="pt-3">Oops! You{"'"}re lost.</h6>
              <p className="text-muted float-left">
                <a href="/"> Click here </a> to login again
              </p>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Page404;
