import {
    BANNER_CREATED,
    BANNER_ERROR,
    DELETE_BANNER,
    BANNER_UPDATED,
    BANNER_LIST_UPDATED,
    GET_BANNER_BY_ID,
    LOADING_ON_BANNER_SUBMIT,
    BANNER_SEARCH_PARAMATERS_UPDATE,
    CHANGE_BANNER_STATUS,
    LOAD_PAGE,
    LOADING_BANNER_LIST_DATA,
    RESET,
  } from "actions/types";
  import * as Constants from "constants/index";
  
  const initialState = {
    bannerList: {
      page: 1,
      data: [],
      count: 0,
    },
    currentBanner: null,
    loadingBannerList: true,
    loadingBanner: true,
    loadingListData: true,
    performDelete: false,
    error: {},
    sortingParams: {
      limit: Constants.DEFAULT_PAGE_SIZE,
      page: 1,
      orderBy: "created_at",
      ascending: "desc",
      query: "",
    },
  };
  
  export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RESET:
        return {
          ...initialState,
          loadingBanner: state.loadingBanner,
          loadingBannerList: state.loadingBannerList,
          loadingListData: state.loadingListData,
        };
      case LOAD_PAGE:
        return {
          ...state,
          loadingBanner: false,
        };
      case BANNER_CREATED:
        return {
          ...state,
          loadingBanner: false,
        };
      case BANNER_UPDATED:
        return {
          ...state,
          currentBanner: payload,
          sortingParams: initialState.sortingParams,
          loadingBanner: false,
        };
      case BANNER_ERROR:
        return {
          ...state,
          error: payload,
          loadingBanner: false,
          loadingBannerList: false,
          loadingListData: false,
          performDelete: false,
        };
      case DELETE_BANNER:
        const currentCount = state.bannerList.count;
        const currentLimit = state.sortingParams.limit;
        const currentPage = parseInt(state.bannerList.page);
        let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
        remainingPages = remainingPages ? remainingPages : 1;
        return {
          ...state,
          bannerList: {
            data: state.bannerList.data.filter(
              (banner) => banner._id !== payload
            ),
            count: currentCount - 1,
            page:
              currentPage <= remainingPages
                ? currentPage.toString()
                : remainingPages.toString(),
          },
          loadingBannerList: false,
          performDelete: true,
        };
      case GET_BANNER_BY_ID:
        return {
          ...state,
          currentBanner: payload,
          loadingBanner: false,
        };
      case BANNER_LIST_UPDATED:
        return {
          ...state,
          bannerList: {
            data: payload.data,
            page: payload.metadata[0].current_page,
            count: payload.metadata[0].totalRecord,
          },
          loadingBannerList: false,
          loadingListData: false,
          performDelete: false,
        };
      case BANNER_SEARCH_PARAMATERS_UPDATE:
        return {
          ...state,
          sortingParams: { ...payload },
        };
  
      case CHANGE_BANNER_STATUS:
        return {
          ...state,
          bannerList: {
            ...state.bannerList,
            data: state.bannerList.data.map((banner) =>
              banner._id === payload._id
                ? { ...banner, status: payload.status }
                : banner
            ),
          },
        };
      case LOADING_BANNER_LIST_DATA:
        return {
          ...state,
          loadingListData: true,
        };
      case LOADING_ON_BANNER_SUBMIT:
        return {
          ...state,
          loadingBanner: true,
        };
  
      default:
        return state;
    }
  }
  