import {
  VERIFICATION_LINK_SUCCESS,
  VERIFICATION_LINK_FAIL,
  LOADING_ON_EMAIL_VERIFICATION
} from "actions/types";

const initialState = {
  loadingEmailVerification: true,
  error: {}
};


export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case VERIFICATION_LINK_SUCCESS:
      return {
        ...state,
        loadingEmailVerification: false,
      };
    case VERIFICATION_LINK_FAIL:
      return {
        ...state,
        error: payload,
        loadingEmailVerification: false,
      };
    case LOADING_ON_EMAIL_VERIFICATION:
      return {
        ...state,
        loadingEmailVerification: true,
      };
    default:
      return state;
  }
}
