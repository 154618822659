import {
  SUBBANNER_ERROR,
  SUBBANNER_UPDATED,
  LOADING_ON_SUBBANNER_SUBMIT,
  LOADING_SUBBANNER_DATA,
  GET_SUBBANNER
} from "actions/types";

const initialState = {
  currentSubBanner: null,
  loadingSubBanner: true,
  error: {},
};

export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SUBBANNER_ERROR:
      return {
        ...state,
        error: payload,
        loadingSubBanner: false,
      };
    case GET_SUBBANNER:
      return {
        ...state,
        currentSubBanner: payload,
        loadingSubBanner: false,
      };
    case LOADING_SUBBANNER_DATA:
      return {
        ...state,
        loadingSubBanner: true,
      };
    case LOADING_ON_SUBBANNER_SUBMIT:
      return {
        ...state,
        loadingSubBanner: true,
      };
    case SUBBANNER_UPDATED:
      return {
        ...state,
        loadingSubBanner: false,
      }
    default:
      return state;
  }
}
