import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  USER_PROFILE_ERROR,
  USER_PROFILE_UPDATED,
  USER_PROFILE_EMAIL_UPDATED,
  REMOVE_ERRORS,
  LOADING_ON_UPDATE_MOBILE,
  UPDATE_MOBILE,
  LOADING_ON_PROFILE_SUBMIT,
  LOADING_ON_CHANGING_PASSWORD,
  LOADING_ON_CHANGING_EMAIL,
  LOAD_PAGE,
  LOGIN_SUCCESS,
} from "actions/types";
import { logout } from "actions/auth";
import setAuthToken from "utils/setAuthToken";
import { PROFILE } from "constants/api";

// Edit Profile
export const edit = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnProfileSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(PROFILE.UPDATE().ENDPOINT, formData, config);

    if (res.data.status === true) {
      dispatch({
        type: USER_PROFILE_UPDATED,
        payload: res.data.response,
      });
      dispatch(setAlert("Profile Updated.", "success"));
      history.push("/dashboard/profile");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: USER_PROFILE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: USER_PROFILE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const updateMobile = (formData) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch({ type: LOADING_ON_UPDATE_MOBILE });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put(`/api/admin/profile/mobile`, formData, config);

    if (res.data.status === true) {
      dispatch({
        type: UPDATE_MOBILE,
        payload: res.data.response,
      });
      dispatch(setAlert("Phone number Updated.", "success"));
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: USER_PROFILE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: USER_PROFILE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Edit Password
export const editPassword = (formData) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnPasswordSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put(
      PROFILE.UPDATE_PASSWORD().ENDPOINT,
      formData,
      config
    );
    if (res.data.status === true) {
      const { token, user } = res.data.response;
      dispatch({
        type: USER_PROFILE_EMAIL_UPDATED,
        payload: user,
      });
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { token },
      });
      setAuthToken(token);
      dispatch(setAlert("Password updated successfully.", "success"));
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: USER_PROFILE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.length &&
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: USER_PROFILE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Edit Email
export const editEmail = (formData) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmailSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put(
      PROFILE.UPDATE_EMAIL().ENDPOINT,
      formData,
      config
    );
    if (res.data.status === true) {
      const { user } = res.data.response;
      dispatch({
        type: USER_PROFILE_EMAIL_UPDATED,
        payload: user,
      });
      dispatch(
        setAlert(
          "We have sent you the e-mail regarding email update verification. New e-mail will be visible once you verify the update request.",
          "success"
        )
      );
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: USER_PROFILE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.length &&
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: USER_PROFILE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};
// cancel
export const cancelSave = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  history.push("/admin");
};

// Dispatch Loading
export const loadingOnProfileSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_PROFILE_SUBMIT });
};

// Dispatch Password Loading
export const loadingOnPasswordSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_CHANGING_PASSWORD });
};

// Dispatch Email Loading
export const loadingOnEmailSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_CHANGING_EMAIL });
};

// page not found
export const notFound = (history) => async () => {
  history.push("/admin/page-not-found");
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: USER_PROFILE_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_PAGE });
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};
