import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Badge,
} from "reactstrap";
import moment from "moment";
import { FaEdit, FaTrash, FaImage } from "react-icons/fa";

import {
  getCategoryList,
  resetComponentStore,
  deleteCategory,
} from "actions/admin/category";
import * as Constants from "constants/index";

const Stack = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.alignItems || "initial"};
  -webkit-box-align: center;
  & > div:not(:first-child) {
    margin: 0px;
    margin-left: ${(props) =>
      props.direction !== "column" ? (props.gap || 1) * 8 + "px" : "0px"};
  }
`;

const CategoryWrapper = ({ _id, name, avatar }) => (
  <Link to={`/home/services/category/${_id}/edit`} title={name}>
    <Stack alignItems="center">
      <Stack direction="column">
        <div style={{ fontSize: 16 }} className="text-uppercase">
          {name}
        </div>
      </Stack>
    </Stack>
  </Link>
);

const CategoryList = ({
  getCategoryList,
  loadingListData,
  deleteCategory,
  categoryList: { data, count, page },
  sortingParams,
  resetComponentStore,
}) => {
  const [categoryParams, setCategoryParams] = useState({
    limit: sortingParams.limit,
    page: sortingParams.page,
    orderBy: sortingParams.orderBy,
    ascending: sortingParams.ascending,
    query: sortingParams.query,
    filters: sortingParams.filters,
    count: count,
  });
  console.log(sortingParams, count, page);
  const [onlyOnce, setOnce] = useState(true);

  const handleDeleteCategory = (_id) => {
    if (window.confirm("Are you sure you want to delete")) deleteCategory(_id);
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      width: "220px",
      field: "name",
      selector: (row) => row.name,
      format: (row) => {
        const { _id, avatar, name } = row;
        return <CategoryWrapper name={name} _id={_id} />;
      },
    },
    {
      name: "Description",
      sortable: true,
      width: "250px",
      field: "description",
      selector: (row) => row.description,
    },
    {
      name: "Creation Date",
      sortable: true,
      width: "250px",
      field: "createdAt",
      selector: (row) => row.createdAt,
      format: (row) => moment(row.createdAt).format("lll"),
    },
    {
      name: "Status",
      sortable: true,
      field: "status",
      width: "180px",
      selector: (row) => row.status,
      format: (row) => {
        return (
          <Badge color={row.status === 1 ? "success" : "danger"}>
            {row.status === 1 ? "Active" : "Inactive"}{" "}
          </Badge>
        );
      },
    },
    {
      name: "Actions",
      width: "200px",
      selector: (row) => row.actions,
      format: (row) => {
        const _id = row._id;
        return (
          <Stack>
            <div>
              <Link
                to={`/home/services/category/${_id}/edit`}
                title="edit"
                className="btn btn-outline-primary"
              >
                <FaEdit />
              </Link>
            </div>
            <div>
              <Link
                to={`/home/services/category/image/${_id}`}
                title="Image"
                className="btn btn-outline-success"
              >
                <FaImage />
              </Link>
            </div>
            <div onClick={() => handleDeleteCategory(_id)}>
              <span
                title="edit"
                target="_blank"
                className="btn btn-outline-danger"
              >
                <FaTrash />
              </span>
            </div>
          </Stack>
        );
      },
    },
  ];
  useEffect(() => {
    let subscribe = true;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          if (onlyOnce) {
            resetComponentStore();
            setOnce(false);
          }
          getCategoryList(categoryParams);
        }
      } catch (err) {
        // throw err;
      }
    };
    asyncParams();
    return () => {
      subscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryParams]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setCategoryParams((prevParams) => ({
      ...prevParams,
      limit: newPerPage,
      page,
    }));
  };

  const handlePageChange = async (page) => {
    setCategoryParams((prevParams) => ({ ...prevParams, page }));
  };

  const handleSort = (column, sortDirection) => {
    const orderBy = column.field;
    setCategoryParams((prevParams) => ({
      ...prevParams,
      orderBy,
      ascending: sortDirection,
    }));
  };

  useEffect(() => {
    let subscribe = true;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          console.log("page reload");
          setCategoryParams((params) => ({
            ...params,
            ...sortingParams,
            page: parseInt(page),
          }));
        }
      } catch (err) {
        // throw err;
      }
    };

    asyncParams();
    return () => {
      subscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Stack
                alignItems="center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <h2>Category List</h2>
                </div>
                <div className="add-button-div">
                  <Link to="/home/services/category/create" title="create">
                    <Button color="primary" size="sm">
                      <i className="fa fa-plus"></i> Add New Category
                    </Button>
                  </Link>
                </div>
              </Stack>
            </CardHeader>
            <CardBody>
              <DataTable
                columns={columns}
                data={data}
                progressPending={loadingListData}
                pagination={true}
                paginationServer
                onSort={handleSort}
                sortServer
                paginationTotalRows={count}
                paginationPerPage={sortingParams.limit}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

CategoryList.propTypes = {
  getCategoryList: PropTypes.func.isRequired,
  resetComponentStore: PropTypes.func.isRequired,
  sortingParams: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  categoryList: state.category.categoryList,
  loadingListData: state.category.loadingListData,
  sortingParams: state.category.sortingParams,
});

export default connect(mapStateToProps, {
  getCategoryList,
  resetComponentStore,
  deleteCategory,
})(CategoryList);
