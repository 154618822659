import {
  USER_PROFILE_ERROR,
  USER_PROFILE_PASSWORD_ERROR,
  USER_PROFILE_PASSWORD_UPDATED,
  USER_PROFILE_EMAIL_UPDATED,
  USER_PROFILE_UPDATED,
  USER_PROFILE_LOADED,
  UPDATE_MOBILE,
  LOADING_ON_UPDATE_MOBILE,
  LOADING_ON_PROFILE_SUBMIT,
  LOADING_ON_CHANGING_PASSWORD,
  LOADING_ON_CHANGING_EMAIL,
  ADMIN_STAFF_ADDRESS,
  ADMIN_STAFF_CITY,
} from "actions/types";

const initialState = {
  loading: true,
  loadingMobile: true,
  loadingChangePassword: true,
  loadingEmail: true,
  error: {},
  user: null,
};

export default function Profile(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_PROFILE_LOADED:
      return {
        ...state,
        user: payload,
        loading: false,
        loadingChangePassword: false,
        loadingEmail: false,
        loadingMobile: false,
      };
    case USER_PROFILE_UPDATED:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case UPDATE_MOBILE:
      return {
        ...state,
        user: {
          ...state.profile,
          ...payload,
        },
        loadingMobile: false,
      };
    case USER_PROFILE_PASSWORD_UPDATED:
      return {
        ...state,
        user: payload,
        loadingChangePassword: false,
      };
    case USER_PROFILE_EMAIL_UPDATED:
      return {
        ...state,
        user: payload,
        loadingEmail: false,
        loadingChangePassword: false,
      };
    case USER_PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        loadingChangePassword: false,
        loadingEmail: false,
      };
    case ADMIN_STAFF_ADDRESS:
      return {
        ...state,
        staffAddress: payload,
        loading: false,
        loadingChangePassword: false,
      };
    case ADMIN_STAFF_CITY:
      return {
        ...state,
        staffLocation: payload,
        loading: false,
        loadingChangePassword: false,
      };
    case USER_PROFILE_PASSWORD_ERROR:
      return {
        ...state,
        error: payload,
        loadingChangePassword: false,
      };
    case LOADING_ON_PROFILE_SUBMIT:
      return {
        ...state,
        loading: true,
      };
    case LOADING_ON_UPDATE_MOBILE:
      return {
        ...state,
        loadingMobile: true,
      };
    case LOADING_ON_CHANGING_PASSWORD:
      return {
        ...state,
        loadingChangePassword: true,
      };
    case LOADING_ON_CHANGING_EMAIL:
      return {
        ...state,
        loadingEmail: true,
      };
    default:
      return state;
  }
}
