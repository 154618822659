import axios from "actions/axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  ENQUIRY_LIST_ERROR,
  ENQUIRY_LIST_UPDATED,
  ENQUIRY_LIST_SEARCH_PARAMETERS_UPDATE,
  REMOVE_ERRORS,
  LOADING_ON_ENQUIRY_LIST_SUBMIT,
  LOAD_ENQUIRY,
  LOADING_ENQUIRY_LIST_DATA,
  RESET,
  DELETE_ENQUIRY,
  ENQUIRY_CSV_LOADING,
  ENQUIRY_CSV_LOADING_DONE,
} from "actions/types";

import { ENQUIRY } from "constants/api";
import { logout } from "actions/auth";

export const getEnquiryList =
  (userParams = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({ type: REMOVE_ERRORS });

      const query = userParams.query ? userParams.query : "";
      userParams.query = query;
      config.params = userParams;
      // if (userParams.query.createdAt) {
      //   const date = new Date();
      //   if (
      //     new Date(userParams.query.createdAt.value.to) >
      //     new Date(
      //       date.getFullYear(),
      //       date.getMonth(),
      //       date.getDate(),
      //       23,
      //       59,
      //       59
      //     )
      //   ) {
      //     dispatch(setAlert("Select the correct date range.", "danger"));
      //     return;
      //   }
      // }
      dispatch({ type: LOADING_ENQUIRY_LIST_DATA });

      const res = await axios.get(`${ENQUIRY.LIST().ENDPOINT}`, config);
      dispatch({
        type: ENQUIRY_LIST_SEARCH_PARAMETERS_UPDATE,
        payload: userParams,
      });
      dispatch({
        type: ENQUIRY_LIST_UPDATED,
        payload: res.data.response[0],
      });
      return res.data.response[0];
    } catch (err) {
      // console.log(err);
      err.response &&
        dispatch({
          type: ENQUIRY_LIST_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
    }
  };

export const getEnquiryListCsv =
  (userParams = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({ type: ENQUIRY_CSV_LOADING });
      const query = userParams.query ? userParams.query : "";
      userParams.query = query;
      config.params = userParams;
      const res = await axios.get(`${ENQUIRY.CSV().ENDPOINT}`, config);
      const blob = new Blob([res.data.response], { type: "text/csv" });
      // Create a link element to trigger the download
      const a = document.createElement("a");
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.download = "Inquiries.csv";
      a.click();
      // Revoke the object URL after the download has finished
      URL.revokeObjectURL(url);
      dispatch({ type: ENQUIRY_CSV_LOADING_DONE });
      return res.data.response;
    } catch (err) {
      err.response &&
        dispatch({
          type: ENQUIRY_LIST_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
    }
  };

export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: ENQUIRY_LIST_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const loadingOnEnquirySubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_ENQUIRY_LIST_SUBMIT });
};

export const deleteEnquiry = (inquiriesList) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEnquirySubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.delete(
      `${ENQUIRY.LIST().ENDPOINT}/delete`,
      { data: inquiriesList },
      config
    );
    if (res.data.status === true) {
      // dispatch({
      //   type: DELETE_ENQUIRY,
      //   payload: _id,
      // });
      dispatch(setAlert("Enquiry deleted successfully", "success"));
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ENQUIRY_LIST_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history.push("/admin/page-not-found");
};

// Load Page/Show Page
export const loadEnquiry = () => async (dispatch) => {
  await dispatch({ type: LOAD_ENQUIRY });
};
