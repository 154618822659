import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import avatar from "assets/img/avatar.png";
import { logoWithName } from "assets/img/logo/logo_stretch";
import { showSidebar } from "actions/admin/sidebar";
import { HeaderDropdown } from "views/components/Header";
import Notification from "./Notification";
import DefaultBreadcrumb from "./DefaultBreadcrumb";

const DefaultHeader = ({
  auth: { isAuthenticated, loading, user },
  logout,
  showSidebar: showSidebarAction,
  sidebarShow,
}) => {
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => showSidebarAction(!sidebarShow)}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/home">
          <CIcon icon={logoWithName} height={48} alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav>
          <Notification />
          <HeaderDropdown logout={logout} user={user} defaultImage={avatar} />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <DefaultBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebarShow: state.sidebar.sidebarShow,
});

DefaultHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  showSidebar: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  showSidebar,
})(DefaultHeader);
