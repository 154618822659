// -- TODO: INITIAL LOADING AND LOADING BOTH ARE DOING THE SAME WORK; FIX IT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroup,
} from "reactstrap";
import {
  edit,
  cancelSave,
  setErrors,
  removeErrors,
} from "actions/admin/profile";
import { FaEdit } from "react-icons/fa";

import Spinner from "views/components/Spinner";
import { checkUserExistence } from "actions/auth";
import ChangePassword from "./ChangePassword";
import EditEmail from "./EditEmail";

// Default Browser language
// auth().useDeviceLanguage();

const Profile = ({
  edit,
  cancelSave,
  history,
  errorList,
  checkUserExistence,
  loadingProfile,
  auth: { user },
  setErrors,
  removeErrors,
}) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    avatar: "",
  });
  const [editEmail, showEditEmail] = useState(false);

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe && user) {
        setFormData({
          username: user.username,
          email: user.email,
        });
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [user]);

  const { username, email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  /**
   * 1) IF form field is an object|array
   *    --- [{
   *          "param":"field_name",
   *          "value":"value that has to be matched from object",
   *          "actualParam":"to represent actual field name"
   *          "type":"object", // array
   *          "msg": "msg that has to be shown"
   *        }]
   * 2) IF form field is string
   *    --- [{
   *          "param":"field_name",
   *          "actualParam":"to represent actual field name"
   *          "msg": "msg that has to be shown"
   *        }]
   * 3) IF form field have specific condition test
   *    --- [{
   *          "param":"field_name",
   *          "cond":anyFunction,
   *          "value":"value to be checked using function",
   *          "msg":"msg to be shown"
   *        }]
   * @param {object} form , data which has to be verify
   * @param {array} requireFields , fields that has to be verify
   */
  const validateForm = (form, requireFields) => {
    const errors = [];
    for (let i in requireFields) {
      if (requireFields[i].type === "object") {
        if (
          !form[requireFields[i].param][requireFields[i].value].trim().length
        ) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (requireFields[i].type === "array") {
        if (!form[requireFields[i].param].length) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (requireFields[i].type === "number") {
        if (isNaN(form[requireFields[i].param])) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (!form[requireFields[i].param].trim().length) {
        errors.push({
          param: requireFields[i].actualParam || requireFields[i].param,
          msg: requireFields[i].msg,
        });
      } else if (requireFields[i].cond) {
        const trimData = requireFields[i].value.trim();
        if (!requireFields[i].cond(trimData)) {
          errors.push({
            param: requireFields[i].actualParam || requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      }
    }
    if (errors.length) {
      setErrors(errors);
    }
    return !errors.length;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    if (
      !validateForm(formData, [
        {
          param: "email",
          msg: "Enter a valid email id",
        },
      ])
    )
      return;
    const submitData = {};
    const excludeList = {};
    for (let i in formData) {
      if (!formData[i] || excludeList[i]) continue;
      submitData[i] = formData[i];
    }
    edit(submitData, history);
  };
  const [modal, setModal] = useState(false);

  const resetModal = () => {
    setModal(false);
  };

  const openModal = () => setModal(true);
  const showEmailEditingModal = (e) => {
    e.preventDefault();
    showEditEmail(true);
  };

  return (
    <div className="animated fadeIn">
      {loadingProfile ? (
        <Spinner />
      ) : (
        <Row>
          <Col md="12" lg="6">
            <Card>
              <CardHeader>
                <span className="pull-left h5">Profile</span>
              </CardHeader>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <ChangePassword
                    doOpen={modal}
                    email={email}
                    onClosed={resetModal}
                  />
                  <EditEmail
                    doOpen={editEmail}
                    currentEmail={email}
                    onClosed={() => showEditEmail(false)}
                  />
                  <FormGroup>
                    <Label htmlFor="username">
                      Username <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="username"
                      name="username"
                      maxLength="25"
                      value={username}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">
                      Email <span>*</span>
                    </Label>
                    <InputGroup>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        maxLength="50"
                        value={email}
                        required
                        readOnly
                        disabled
                        onChange={(e) => onChange(e)}
                        invalid={!!errorList.email}
                      />
                      {/* <Button color="primary" onClick={showEmailEditingModal}>
                        <FaEdit />
                      </Button> */}
                    </InputGroup>
                  </FormGroup>
                  <Row>
                    <Col xl="12">
                      <Col xl="12" className="text-right">
                        <Button size="sm" color="primary" onClick={openModal}>
                          <i className="fa fa-dot-circle-o"></i> Change Password
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

Profile.propTypes = {
  edit: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loadingProfile: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  loadingProfile: state.profile.loading,
  errorList: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  edit,
  cancelSave,
  setErrors,
  removeErrors,
  // updateMobile,
  checkUserExistence,
})(Profile);
