import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  LOCATION_CREATED,
  LOCATION_ERROR,
  DELETE_LOCATION,
  LOCATION_UPDATED,
  LOCATION_LIST_UPDATED,
  GET_LOCATION_BY_ID,
  LOCATION_SEARCH_PARAMATERS_UPDATE,
  REMOVE_ERRORS,
  CHANGE_LOCATION_STATUS,
  LOADING_ON_LOCATION_SUBMIT,
  LOADING_LOCATION_LIST_DATA,
  LOAD_PAGE,
  RESET,
} from "actions/types";
import { logout } from "actions/auth";
import { LOCATION } from "constants/api";

//get Location Template list

export const getLocationList =
  (locationParams) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({ type: REMOVE_ERRORS });
      dispatch({ type: LOADING_LOCATION_LIST_DATA });
      const query = locationParams.query ? locationParams.query : "";
      locationParams.query = query;
      locationParams.page = locationParams.page
        ? locationParams.page
        : 1;

      config.params = locationParams;
      const res = await axios.get(LOCATION.LIST().ENDPOINT, config);
      // dispatch({ type: REMOVE_ALERT });

      dispatch({
        type: LOCATION_SEARCH_PARAMATERS_UPDATE,
        payload: locationParams,
      });
      dispatch({
        type: LOCATION_LIST_UPDATED,
        payload: res.data.response[0],
      });
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: LOCATION_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// Get email template by id
export const getLocationById = (location_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnLocationSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${LOCATION.LIST().ENDPOINT}/${location_id}`,
      config
    );
    // dispatch({ type: REMOVE_ALERT });

    await dispatch({
      type: GET_LOCATION_BY_ID,
      payload: res.data.response,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: LOCATION_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Create Location Template
export const create = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnLocationSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json; multipart/form-data;",
    },
  };
  try {
    const data = new FormData();
    for(var i in formData){
      data.append(i, formData[i]);
    }
    const res = await axios.post(
      LOCATION.CREATE().ENDPOINT,
      data,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: LOCATION_CREATED,
        payload: res.data.response,
      });
      // dispatch({ type: REMOVE_ALERT });
      dispatch(setAlert("Location Template Created.", "success"));
      history("/home/services/location");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: LOCATION_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Edit Location Template
export const edit =
  (formData, history, location_id) => async (dispatch) => {
    // -- Always remove the previous errors before sending new request
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnLocationSubmit());
    const config = {
      headers: {
        "Content-Type": "application/json; multipart/form-data;",
      },
    };
    try {
      const data = new FormData();
      for(var i in formData){
        data.append(i, formData[i]);
      }
      const res = await axios.post(
        `${LOCATION.LIST().ENDPOINT}/${location_id}`,
        data,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: LOCATION_UPDATED,
          payload: res.data.response,
        });
        // dispatch({ type: REMOVE_ALERT });
        dispatch(setAlert("Location Updated.", "success"));
        history("/home/services/location");
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: LOCATION_ERROR });
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: LOCATION_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// Delete Location Template
export const deleteLocation =
  (location_id, history) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.delete(
        `${LOCATION.LIST().ENDPOINT}/${location_id}`,
        config
      );
      // dispatch({ type: REMOVE_ALERT });

      dispatch({
        type: DELETE_LOCATION,
        payload: location_id,
      });
      dispatch(setAlert("Location Template deleted", "success"));
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: LOCATION_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

//change status
export const changeStatus = (location_id, status) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/location/change-status/${location_id}`,
      { status },
      config
    );
    // dispatch({ type: REMOVE_ALERT });
    await dispatch({
      type: CHANGE_LOCATION_STATUS,
      payload: res.data.response,
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: LOCATION_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Delete User
export const cancelSave = (history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history("/home/services/location");
};

// Dispatch Loading
export const loadingOnLocationSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_LOCATION_SUBMIT });
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history("/home/page-not-found");
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: LOCATION_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_PAGE });
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};
