import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getSubBanner,
  updateSubBanner,
  removeErrors,
  setErrors,
} from "actions/admin/subBanner";
import Spinner from "views/components/Spinner";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  CardTitle,
} from "reactstrap";
import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate } from "react-router-dom";

const EditSubBanner = ({
  getSubBanner,
  currentSubBanner,
  loading,
  updateSubBanner,
  removeErrors,
  setErrors,
  errorList,
}) => {
  const params = useParams();
  const history = useNavigate();
  const initialState = {
    description: "",
    url: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [onlyOnce, setOnce] = useState(true);

  useEffect(() => {
    if (onlyOnce) {
      getSubBanner();
      setOnce(false);
    }
  });
  useEffect(() => {
    const asyncCall = async () => {
      try {
        if (onlyOnce == false && currentSubBanner) {
          const { title, url, description } = currentSubBanner;
          setFormData((formData) => ({
            ...formData,
            title: title,
            description: description,
            url: url,
          }));
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    asyncCall();

    return () => {
      //subscribe = false;
    };
  }, [currentSubBanner]);

  const validateForm = (form, requireFields) => {
    const errors = [];
    for (let i in requireFields) {
      if (requireFields[i].type === "object") {
        if (
          !form[requireFields[i].param][requireFields[i].value].trim().length
        ) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (requireFields[i].type === "array") {
        if (!form[requireFields[i].param].length) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (!form[requireFields[i].param].trim().length) {
        errors.push({
          param: requireFields[i].actualParam || requireFields[i].param,
          msg: requireFields[i].msg,
        });
      }
    }
    if (errors.length) {
      setErrors(errors);
    }

    return !errors.length;
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // const handleDescriptionChange = (event, field) => {
  //   const desc = event.editor.getData(); // editor.getData();
  //   setFormData((ev)=>{ return {...ev, [field]: desc }});
  // };
  const onChangeDescription = (newDescription) => {
    setFormData((data) => ({ ...data, description: newDescription }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    if (
      !validateForm(formData, [
        {
          param: "title",
          msg: "Please enter the title",
        },
        {
          param: "description",
          msg: "Please enter the description",
        },
        {
          param: "url",
          msg: "Please enter the url",
        },
      ])
    )
      return;
    console.log(formData);
    updateSubBanner(formData, history, "");
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    //cancelSave(history);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <span className="pull-left h5">Sub Banner Page</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Card style={{ marginTop: 10 }} body>
                  <FormGroup>
                    <Label htmlFor="slug">
                      Title <span></span>
                    </Label>
                    <Input
                      type="text"
                      id="name"
                      name="title"
                      value={formData.title}
                      invalid={errorList.title ? true : false}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors current_key="title" key="title" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="slug">
                      URL <span></span>
                    </Label>
                    <Input
                      type="text"
                      id="url"
                      name="url"
                      value={formData.url}
                      invalid={errorList.url ? true : false}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors current_key="url" key="url" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="origin_description">Description</Label>
                    {formData.title != null && (
                      // <CKEditor
                      //     initData={formData.description}
                      //     id="description"
                      //     name="description"
                      //     config={{
                      //       height: 100,
                      //       toolbar: [
                      //         ["Cut", "Copy", "Paste"],
                      //         ["Undo", "Redo"],
                      //         ["SpellChecker"],
                      //         ["Link", "Unlink", "Anchor"],
                      //         [
                      //           "Image",
                      //           "Table",
                      //           "Horizontal Line",
                      //           "Special Character",
                      //         ],
                      //         ["Maximize"],
                      //         ["Source"],
                      //         ["Bold", "Italic", "Strike"],
                      //         ["RemoveFormat"],
                      //         ["NumberedList", "BulletedList"],
                      //         ["DecreaseIndent", "IncreaseIndent"],
                      //         ["BlockQuote"],
                      //         ["Styles"],
                      //         ["Format"],
                      //         ["About"],
                      //       ],
                      //     }}
                      //     onChange={(event) => handleDescriptionChange(event, "description")}
                      //     onBeforeLoad={(CKEDITOR) =>
                      //       (CKEDITOR.disableAutoInline = true)
                      //     }
                      //     invalid={errorList.description ? true : false}
                      //   />
                      <CKEditor
                        editor={Editor}
                        required
                        data={formData.description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChangeDescription(data);
                        }}
                      />
                    )}
                    <Errors current_key="description" key="description" />
                  </FormGroup>
                </Card>
              </CardBody>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <a onClick={onClickHandel} href="#!">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </a>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditSubBanner.propTypes = {
  getSubBanner: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateSubBanner: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  currentSubBanner: state.subBanner.currentSubBanner,
  errorList: state.errors,
  loading: state.subBanner.loadingSubBanner,
});

export default connect(mapStateToProps, {
  getSubBanner,
  updateSubBanner,
  removeErrors,
  setErrors,
})(EditSubBanner);
