import {
  RESET_LINK_SUCCESS,
  RESET_LINK_FAIL,
  USER_LOADED,
  AUTH_TOKEN_REFRESH,
  AUTH_ERROR,
  LOGOUT,
  LOGIN_SUCCESS,
  LOADING_ON_LOGIN_SUBMIT,
  LOADING_ON_PASSWORD_RESET,
  LOGIN_FAIL,
  LOAD_AUTH_PAGE,
  PENDING_ACTION_LOADED,
  UPDATE_MOBILE,
} from "actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  loadingPasswordReset: false,
  user: null,
  //email:'',
  error: {},
  actionPending: {},
};

export default function Auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_AUTH_PAGE:
      return {
        ...state,
        loading: false,
      };
    case AUTH_TOKEN_REFRESH:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };
    case RESET_LINK_SUCCESS:
      return {
        ...state,
        loadingPasswordReset: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case RESET_LINK_FAIL:
      return {
        ...state,
        error: payload,
        loadingPasswordReset: false,
      };
    case AUTH_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        error: payload,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    case UPDATE_MOBILE:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    case LOGOUT:
    case LOGIN_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: null,
        loading: false,
        user: null,
      };
    case LOADING_ON_PASSWORD_RESET:
      return {
        ...state,
        loadingPasswordReset: true,
      };
    case LOADING_ON_LOGIN_SUBMIT:
      return {
        ...state,
        loading: true,
      };
    case PENDING_ACTION_LOADED:
      return {
        ...state,
        actionPending: payload,
      };
    default:
      return state;
  }
}
