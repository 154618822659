import React from "react";
import { CSpinner } from "@coreui/react";

const AppLoading = ({ style = {} }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <CSpinner color="primary" />
    </div>
  );
};

export default AppLoading;
