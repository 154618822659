import React from "react";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import moment from "moment";

function noop() {
  // no operation
}

const HeaderDropdownNotif = ({
  notifications = [],
  unopened = 0,
  resetCount = noop,
  onScroll = noop,
  onClick = noop,
}) => {
  return (
    <CDropdown inNav className="c-header-nav-item mx-2">
      <CDropdownToggle
        className="c-header-nav-link"
        caret={false}
        onClick={(e) => resetCount(e, Date.now())}
      >
        <CIcon name="cil-bell" />
        {!!unopened && (
          <CBadge shape="pill" color="danger">
            {unopened}
          </CBadge>
        )}
      </CDropdownToggle>
      <CDropdownMenu
        placement="bottom-end"
        className="pt-0 notification-menu"
        onScroll={onScroll}
      >
        {notifications.map((notification) => {
          return (
            <CDropdownItem
              key={notification._id}
              onClick={(e) =>
                onClick(
                  e,
                  notification._id,
                  notification.isOpened,
                  notification?.redirection?.web,
                  notification.variables
                )
              }
              className={notification.isOpened ? "" : "un-opened"}
            >
              <span>
                <CIcon
                  name="cil-user-follow"
                  className="mr-2 text-success"
                  size="xl"
                />
              </span>
              <div>
                <strong>{notification.title}</strong>
                <div className="small text-muted">
                  {moment()
                    .unix(notification.created_at / 1000)
                    .fromNow()}
                </div>
              </div>
            </CDropdownItem>
          );
        })}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default HeaderDropdownNotif;
