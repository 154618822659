import React, { Fragment } from "react";
import spinner from "assets/loader.gif";

const Spinner = ({ applyClass }) => (
  <Fragment >
    <div className={applyClass !== undefined ? "loading-box" : ""}>
      <img
        src={spinner}
        alt="Loading..."
        style={{ width: "20px", margin: "auto", display: "block" }}
      />
    </div>
  </Fragment>
);

export default Spinner;
