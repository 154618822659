import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty, isObject, isArray } from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import * as Constants from "constants/index";
import DataTable from "react-data-table-component";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  FormGroup,
  CardHeader,
} from "reactstrap";
import Spinner from "views/components/Spinner";
import styled from "styled-components";
import { FaAngleDown, FaAngleUp, FaPencilAlt, FaTrash } from "react-icons/fa";

import FilterComponent from "./Filters";
import {
  getPropertyList,
  resetComponentStore,
  deleteProperty,
} from "actions/admin/properties";

const Stack = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.alignItems || "initial"};
  -webkit-box-align: center;
  & > div:not(:first-child) {
    margin: 0px;
    margin-left: ${(props) =>
      props.direction !== "column" ? (props.gap || 1) * 8 + "px" : "0px"};
  }
`;

const CustomStyle = {
  table: {
    style: {
      zIndex: 10,
    },
  },
  headRow: {
    style: {
      height: "60px",
      borderTopWidth: "1px",
      borderTopStyle: "solid",
      borderBottomWidth: "2px",
      fontSize: "15px !important",
      backgroundColor: "#eeefef",
    },
  },
};

const getValue = (data) => {
  if (isObject(data) && data.hasOwnProperty("value")) return data.value;
  else if (isArray(data)) return data.map((obj) => obj.value);
  return data;
};

const addFilters = (element, filters) => {
  const set = new Set(filters);
  set.add(element);
  return Array.from(set);
};

const removeFilter = (element, filters) => {
  const set = new Set(filters);
  set.delete(element);
  return Array.from(set);
};

const PropertyList = ({
  getPropertyList,
  loadingListData,
  pageList: { data, count, page },
  sortingParams,
  resetComponentStore,
  deleteProperty,
}) => {
  const initialSortingParams = {
    limit: 30,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };
  const [pageParams, setPageParams] = useState({
    ...initialSortingParams,
  });
  const [onlyOnce, setOnce] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);

  const columns = [
    {
      name: "Title",
      sortable: true,
      field: "title",
      selector: (row) => row.title,
    },
    {
      name: "Price Full Unit - $",
      sortable: true,
      field: "price_full_unit",
      selector: (row) => row.price_full_unit || 0,
    },
    {
      name: "Price Per Room - $",
      sortable: true,
      field: "price_per_room",
      selector: (row) => row.price_per_room || 0,
    },
    // {
    //   name: "3D Tour Link",
    //   sortable: true,
    //   field: "tour_link",
    //   selector: (row) => row.tour_link,
    // },
    // {
    //   name: "Description",
    //   sortable: true,
    //   field: "description",
    //   selector: (row) => row.description,
    // },
    {
      name: "Created At",
      sortable: true,
      selector: (row) => row.createdAt,
      // headerSortingStyle,
      field: "createdAt",
      format: (cellContent, row) => moment(cellContent.createdAt).format("lll"),
    },
    {
      name: "Actions",
      selector: (row) => row,
      format: (cellContent, row) => (
        <div>
          <Link
            to={`/dashboard/properties/${cellContent._id}/edit`}
            title={`Edit ${cellContent.title}`}
          >
            <Button
              type="button"
              style={{ margin: 5, color: "white" }}
              size="sm"
              color="success"
            >
              <FaPencilAlt />
            </Button>
          </Link>

          <Button
            type="button"
            size="sm"
            title="Delete"
            color="danger"
            style={{ margin: 5, color: "white" }}
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to delete :\r ${cellContent.title}?`
                )
              ) {
                deleteProperty(cellContent._id);
              }
            }}
          >
            <FaTrash />
          </Button>
        </div>
      ),
    },
  ];

  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    let params = {
      page: type === "search" ? 1 : page === 0 ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      filters: sortingParams.filters,
      query: sortingParams.query,
    };
    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              value: searchText,
              type: "String",
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }
    setPageParams((prevParams) => ({ ...prevParams, ...params }));
  };

  const onSearch = (text) => {
    handleTableChange("search", {
      searchText: text,
      sizePerPage: pageParams.limit,
      sortOrder: pageParams.ascending,
    });
  };

  React.useEffect(() => {
    let subscribe = true;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          if (onlyOnce) {
            resetComponentStore();
            setOnce(false);
          }
          getPropertyList(pageParams);
        }
      } catch (err) {
        console.trace(err);
      }
    };
    asyncParams();
    return () => {
      subscribe = false;
    };
  }, [pageParams]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      limit: newPerPage,
      page,
    }));
  };

  const onFilterChange = (name, value) => {
    setPageParams((params) => {
      let filters = [];
      let query = params.query || {};
      if (isEmpty(value)) {
        filters = removeFilter(name, params.filters);
        delete query[name];
      } else {
        filters = addFilters(name, params.filters);
        query[name] = {
          value: getValue(value),
          type: "String",
        };
      }
      const filterParams = {
        filters,
        query,
      };
      return {
        ...params,
        ...filterParams,
      };
    });
  };

  const handlePageChange = async (page) => {
    setPageParams((prevParams) => ({ ...prevParams, page }));
  };

  const handleSort = (column, sortDirection) => {
    const orderBy = column.field;
    setPageParams((prevParams) => ({
      ...prevParams,
      orderBy,
      ascending: sortDirection,
    }));
  };

  React.useEffect(() => {
    let subscribe = true;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          setPageParams((params) => ({
            ...params,
            ...sortingParams,
            page: parseInt(page),
          }));
        }
      } catch (err) {
        console.trace(err);
      }
    };

    asyncParams();
    return () => {
      subscribe = false;
    };
  }, []);

  return (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Stack
                alignItems="center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <h4>Manage Properties List</h4>
                </div>
                <div className="add-button-div">
                  <Link to="/dashboard/properties/add" title="create">
                    <Button color="primary" size="sm">
                      <i className="fa fa-plus"></i> Add New Property
                    </Button>
                  </Link>
                </div>
              </Stack>
            </CardHeader>
            <CardBody>
              <div className="add-button-div border-bottom">
                <div
                  className="filters-row"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <div>
                    <h5>Filters</h5>
                  </div>
                  <div onClick={() => setFilterOpen(!filterOpen)}>
                    {filterOpen ? (
                      <FaAngleUp
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <FaAngleDown
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={data}
                subHeader={filterOpen}
                subHeaderComponent={
                  <FilterComponent
                    onSearch={onSearch}
                    onFilter={onFilterChange}
                  />
                }
                progressPending={loadingListData}
                pagination
                paginationServer
                onSort={handleSort}
                sortServer
                paginationTotalRows={count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationRowsPerPageOptions={[30, 50, 100]}
                paginationPerPage={pageParams.limit ? pageParams.limit : 30}
                customStyles={CustomStyle}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

PropertyList.prototype = {
  getPropertyList: PropTypes.func.isRequired,
  resetComponentStore: PropTypes.func.isRequired,
  sortingParams: PropTypes.object.isRequired,
  deleteProperty: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pageList: state.properties.pageList,
  loadingListData: state.properties.loadingPropertyList,
  sortingParams: state.properties.sortingParams,
});

export default connect(mapStateToProps, {
  getPropertyList,
  resetComponentStore,
  deleteProperty,
})(PropertyList);
