import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getSetting,
  updateSetting,
  removeErrors,
  setErrors,
} from "actions/admin/setting";
import Spinner from "views/components/Spinner";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  CardTitle,
} from "reactstrap";
import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate } from "react-router-dom";

const EditSetting = ({
  getSetting,
  currentSetting,
  loading,
  updateSetting,
  removeErrors,
  setErrors,
  errorList,
}) => {
  const params = useParams();
  const history = useNavigate();
  const initialState = {
    name: "",
    address: "",
    email: "",
    mobile: "",
    copyRightText: "",
    instagram: "",
    instagram_status: true,
    facebook: "",
    facebook_status: true,
    twitter: "",
    twitter_status: true,
    google: "",
    google_status: true,
  };
  const [companyData, setCompanyData] = useState(initialState);
  const [socialData, setSocialData] = useState(initialState);
  const [formData, setFormData] = useState(initialState);
  const [onlyOnce, setOnce] = useState(true);

  const {
    name,
    address,
    email,
    mobile,
    copyRightText,
    instagram,
    facebook,
    twitter,
    google,
  } = formData;
  const [showStrip, setShowStrip] = useState(true);
  const onChange = (e) => {
    switch (e.target.name) {
      case "mobile":
        const slug = e.target.value.replace(/[^0-9]/gi, "");
        setFormData({
          ...formData,
          [e.target.name]: slug,
        });
        break;
      default:
        const checkValue =
          e.target.value === "on"
            ? e.target.checked
            : e.target.value.replace(/[^a-z-0-9 @.:?/#&]/gi, "");
        setFormData({ ...formData, [e.target.name]: checkValue });
    }
  };
  useEffect(() => {
    if (onlyOnce) {
      getSetting();
      setOnce(false);
    }
  });
  useEffect(() => {
    const asyncCall = async () => {
      try {
        if (onlyOnce == false && currentSetting) {
          const { company, copyRightText, social } = currentSetting;

          setFormData((formData) => ({
            ...formData,
            name: company.name ? company.name : "",
            email: company.email ? company.email : "",
            mobile: company.mobile ? company.mobile : "",
            address: company.address ? company.address : "",
            copyRightText,
            facebook: social.facebook ? social.facebook : "",
            facebook_status: social.facebook_status ?? false,
            twitter: social.twitter ? social.twitter : "",
            twitter_status: social.twitter_status ?? false,
            google: social.google ? social.google : "",
            google_status: social.google_status ?? false,
            instagram: social.instagram ? social.instagram : "",
            instagram_status: social.instagram_status ?? false,
          }));
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    asyncCall();

    return () => {
      //subscribe = false;
    };
  }, [currentSetting]);

  const validateForm = (form, requireFields) => {
    const errors = [];
    for (let i in requireFields) {
      if (requireFields[i].param == "topStripMessage") {
        if (
          form["enableTopStrip"] == true &&
          form[requireFields[i].param].trim().length == 0
        ) {
          errors.push({
            param: requireFields[i].actualParam || requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (form[requireFields[i].param].trim().length == 0) {
        errors.push({
          param: requireFields[i].actualParam || requireFields[i].param,
          msg: requireFields[i].msg,
        });
      }
    }
    if (errors.length) {
      setErrors(errors);
    }

    return !errors.length;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    if (
      !validateForm(formData, [
        {
          param: "name",
          msg: "Please enter the name of company",
        },
        {
          param: "address",
          msg: "Please enter the address of company",
        },
        {
          param: "email",
          msg: "Please enter the email of company",
        },
        {
          param: "mobile",
          msg: "Please provide the mobile number of company",
        },
      ])
    )
      return;
    const submitData = {
      ...formData,
      name: formData.name.trim(),
      address: formData.address.trim(),
      email: formData.email.trim(),
      copyRightText: formData.copyRightText.trim(),
    };
    setFormData({ ...submitData });
    updateSetting(submitData, history, "");
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="6" sm="6">
          <Card>
            <CardHeader>
              <span className="pull-left h5">Setting Page</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Card style={{ marginTop: 10 }} body>
                  <CardHeader>
                    <span className="h6">Company</span>
                  </CardHeader>
                  <FormGroup>
                    <Label htmlFor="slug">
                      Name <span></span>
                    </Label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      invalid={errorList.name ? true : false}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors current_key="name" key="name" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="address">Address</Label>
                    <Input
                      type="text"
                      id="address"
                      name="address"
                      disabled=""
                      maxLength="100"
                      value={formData.address}
                      invalid={errorList.address ? true : false}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors current_key="address" key="address" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">Email</Label>
                    <Input
                      type="text"
                      id="email"
                      name="email"
                      maxLength="100"
                      value={formData.email}
                      invalid={errorList.email ? true : false}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors current_key="email" key="email" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="mobile">Mobile</Label>
                    <Input
                      type="text"
                      id="mobile"
                      name="mobile"
                      maxLength="100"
                      value={formData.mobile}
                      invalid={errorList.mobile ? true : false}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors current_key="mobile" key="mobile" />
                  </FormGroup>
                </Card>
                <Card style={{ marginTop: 10 }} body>
                  <FormGroup>
                    <Label htmlFor="copyRightText">Copy Right Text</Label>
                    <Input
                      type="text"
                      id="copyRightText"
                      name="copyRightText"
                      maxLength="100"
                      value={formData.copyRightText}
                      invalid={errorList.copyRightText ? true : false}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors current_key="copyRightText" key="copyRightText" />
                  </FormGroup>
                </Card>
                <Card style={{ marginTop: 10 }} body>
                  <CardHeader>
                    {" "}
                    <span className="h6">Social</span>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs={9}>
                        <FormGroup>
                          <Label htmlFor="facebook">Facebook</Label>
                          <Input
                            type="text"
                            id="facebook"
                            name="facebook"
                            maxLength="100"
                            value={formData.facebook}
                            onChange={(e) => onChange(e)}
                            invalid={errorList.facebook ? true : false}
                          />
                          <Errors current_key="facebook" key="facebook" />
                        </FormGroup>
                      </Col>
                      <Col xs={2}>
                        <FormGroup switch style={{ marginTop: "40px" }}>
                          <Input
                            type="switch"
                            name="facebook_status"
                            checked={formData.facebook_status}
                            onChange={(e) => onChange(e)}
                          />
                          <Label check>
                            {formData.facebook_status ? "Active" : "Inactive"}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={9}>
                        <FormGroup>
                          <Label htmlFor="twitter">Twitter</Label>
                          <Input
                            type="text"
                            id="twitter"
                            name="twitter"
                            maxLength="100"
                            value={formData.twitter}
                            invalid={errorList.twitter ? true : false}
                            onChange={(e) => onChange(e)}
                          />
                          <Errors current_key="twitter" key="twitter" />
                        </FormGroup>
                      </Col>
                      <Col xs={2}>
                        <FormGroup switch style={{ marginTop: "40px" }}>
                          <Input
                            type="switch"
                            name="twitter_status"
                            checked={formData.twitter_status}
                            onChange={(e) => onChange(e)}
                          />
                          <Label check>
                            {formData.twitter_status ? "Active" : "Inactive"}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={9}>
                        <FormGroup>
                          <Label htmlFor="instagram">Instagram</Label>
                          <Input
                            type="text"
                            id="instagram"
                            name="instagram"
                            maxLength="100"
                            value={formData.instagram}
                            invalid={errorList.instagram ? true : false}
                            onChange={(e) => onChange(e)}
                          />
                          <Errors current_key="instagram" key="instagram" />
                        </FormGroup>
                      </Col>
                      <Col xs={2}>
                        <FormGroup switch style={{ marginTop: "40px" }}>
                          <Input
                            type="switch"
                            name="instagram_status"
                            checked={formData.instagram_status}
                            onChange={(e) => onChange(e)}
                          />
                          <Label check>
                            {formData.instagram_status ? "Active" : "Inactive"}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={9}>
                        <FormGroup>
                          <Label htmlFor="google">Google</Label>
                          <Input
                            type="text"
                            id="google"
                            name="google"
                            value={formData.google}
                            invalid={errorList.google ? true : false}
                            onChange={(e) => onChange(e)}
                          />
                          <Errors current_key="google" key="google" />
                        </FormGroup>
                      </Col>
                      <Col xs={2}>
                        <FormGroup switch style={{ marginTop: "40px" }}>
                          <Input
                            type="switch"
                            name="google_status"
                            checked={formData.google_status}
                            onChange={(e) => onChange(e)}
                          />
                          <Label check>
                            {formData.google_status ? "Active" : "Inactive"}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardBody>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/dashboard">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditSetting.propTypes = {
  getSetting: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateSetting: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  currentSetting: state.setting.currentSetting,
  errorList: state.errors,
  loading: state.setting.loadingSetting,
});

export default connect(mapStateToProps, {
  getSetting,
  updateSetting,
  removeErrors,
  setErrors,
})(EditSetting);
