import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Spinner from "views/components/Spinner";
import styled from "styled-components";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import { FaTrash, FaAngleDown, FaAngleUp } from "react-icons/fa";
import { isArray, isEmpty, isObject } from "lodash";

import {
  getSubscribersList,
  resetComponentStore,
  deleteSubscriber,
  getSubscriberListCsv,
} from "actions/admin/subscribers";
import * as Constants from "constants/index";
import FilterComponent from "./Filters";

const Stack = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.alignItems || "initial"};
  -webkit-box-align: center;
  & > div:not(:first-child) {
    margin: 0px;
    margin-left: ${(props) =>
      props.direction !== "column" ? (props.gap || 1) * 8 + "px" : "0px"};
  }
`;

const CustomStyle = {
  table: {
    style: {
      zIndex: 10,
    },
  },
  headRow: {
    style: {
      height: "60px",
      borderTopWidth: "1px",
      borderTopStyle: "solid",
      borderBottomWidth: "2px",
      fontSize: "15px !important",
      backgroundColor: "#eeefef",
    },
  },
};

const SubscribersList = ({
  getSubscribersList,
  getSubscriberListCsv,
  loadingListData,
  deleteSubscriber,
  subscribersList: { data, count, page },
  sortingParams,
  resetComponentStore,
  subscribersCsvLoading,
}) => {
  const initialSortingParams = {
    limit: 30,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };
  const [subscriberParams, setSubscriberParams] =
    useState(initialSortingParams);
  const [onlyOnce, setOnce] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const handleDeleteSubscriber = async (subscribersList) => {
    if (window.confirm("Are you sure you want to delete")) {
      const deleted = await deleteSubscriber(subscribersList);
      if (deleted.status) {
        setToggleCleared(!toggleCleared);
        setSelectedRows([]);
        getSubscribersList();
      }
    }
  };

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const columns = [
    {
      name: "Email",
      sortable: true,
      minWidth: "300px",
      field: "email",
      selector: (row) => row.email,
    },
    {
      name: "Date",
      sortable: true,
      minWidth: "180px",
      field: "createdAt",
      selector: (row) => row.createdAt,
      format: (row) => moment(row.createdAt).format("lll"),
    },
    {
      name: "Actions",
      width: "160px",
      selector: (row) => row.actions,
      format: (row) => {
        const _id = row._id;
        return (
          <Stack>
            <div>
              <Button
                title="Delete"
                target="_blank"
                color="danger"
                size="sm"
                style={{ margin: 5, color: "white" }}
                onClick={() =>
                  handleDeleteSubscriber({ subscribersList: [row] })
                }
              >
                <FaTrash />
              </Button>
            </div>
          </Stack>
        );
      },
    },
  ];
  useEffect(() => {
    let subscribe = true;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          if (onlyOnce) {
            resetComponentStore();
            setOnce(false);
          }
          getSubscribersList(subscriberParams);
        }
      } catch (err) {
        // throw err;
      }
    };
    asyncParams();
    return () => {
      subscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberParams]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setSubscriberParams((prevParams) => ({
      ...prevParams,
      limit: newPerPage,
      page,
    }));
  };

  const handlePageChange = async (page) => {
    setSubscriberParams((prevParams) => ({ ...prevParams, page }));
  };

  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    const params = {
      page: type === "search" ? 1 : page || 1,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: type === "search" ? searchText : subscriberParams.query,
    };
    setSubscriberParams((param) => ({ ...param, ...params }));
  };

  const onSearch = (text) => {
    handleTableChange("search", {
      searchText: text,
      sizePerPage: subscriberParams.limit,
      sortOrder: subscriberParams.ascending,
    });
  };

  const handleSort = (column, sortDirection) => {
    const orderBy = column.field;
    setSubscriberParams((prevParams) => ({
      ...prevParams,
      orderBy,
      ascending: sortDirection,
    }));
  };

  const removeFilter = (element, filters) => {
    const set = new Set(filters);
    set.delete(element);
    return Array.from(set);
  };
  const addFilters = (element, filters) => {
    const set = new Set(filters);
    set.add(element);
    return Array.from(set);
  };
  const getValue = (data) => {
    if (isObject(data) && data.hasOwnProperty("value")) return data.value;
    else if (isArray(data)) return data.map((obj) => obj.value);
    return data;
  };

  const onFilterChange = (name, value) => {
    setSubscriberParams((params) => {
      let filters = [];
      let query = params.query || {};
      if (isEmpty(value)) {
        filters = removeFilter(name, params.filters);
        delete query[name];
      } else {
        filters = addFilters(name, params.filters);
        query[name] = {
          value: getValue(value),
          type: "String",
        };
      }
      const filterParams = {
        filters,
        query,
      };
      return {
        ...params,
        ...filterParams,
      };
    });
  };

  useEffect(() => {
    let subscribe = true;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          console.log("page reload");
          setSubscriberParams((params) => ({
            ...params,
            ...sortingParams,
            page: parseInt(page),
          }));
        }
      } catch (err) {
        // throw err;
      }
    };

    asyncParams();
    return () => {
      subscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickExport = async () => {
    await getSubscriberListCsv(subscriberParams);
  };

  return (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Stack
                alignItems="center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <h2>Subscribers List</h2>
                </div>
                <div>
                  {selectedRows.length ? (
                    <div style={{ display: "inline" }}>
                      <p style={{ display: "inline", marginRight: "10px" }}>
                        {selectedRows.length} Selected
                      </p>
                      <Button
                        disabled={subscribersCsvLoading}
                        onClick={() =>
                          handleDeleteSubscriber({
                            subscribersList: selectedRows,
                          })
                        }
                        style={{ backgroundColor: "red", marginRight: "20px" }}
                      >
                        {subscribersCsvLoading ? <Spinner /> : <>Delete</>}
                      </Button>
                    </div>
                  ) : null}
                  <Button
                    disabled={subscribersCsvLoading}
                    onClick={onClickExport}
                  >
                    {" "}
                    {subscribersCsvLoading ? <Spinner /> : <>Export CSV</>}
                  </Button>
                </div>
              </Stack>
            </CardHeader>
            <CardBody>
              <div className="add-button-div border-bottom">
                <div
                  className="filters-row"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h5>Filters</h5>
                  </div>
                  <div onClick={() => setFilterOpen(!filterOpen)}>
                    {filterOpen ? (
                      <FaAngleUp
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <FaAngleDown
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={data}
                subHeader={filterOpen}
                subHeaderComponent={
                  <FilterComponent
                    onSearch={onSearch}
                    onFilter={onFilterChange}
                  />
                }
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
                progressPending={loadingListData}
                pagination={true}
                paginationServer
                onSort={handleSort}
                sortServer
                paginationTotalRows={count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationRowsPerPageOptions={[30, 50, 100]}
                paginationPerPage={
                  subscriberParams.limit ? subscriberParams.limit : 30
                }
                customStyles={CustomStyle}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

SubscribersList.propTypes = {
  getSubscribersList: PropTypes.func.isRequired,
  getSubscriberListCsv: PropTypes.func.isRequired,
  deleteSubscriber: PropTypes.func.isRequired,
  resetComponentStore: PropTypes.func.isRequired,
  sortingParams: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  subscribersList: state.subscribers.subscribersList,
  loadingListData: state.subscribers.loadingSubscriberList,
  sortingParams: state.subscribers.sortingParams,
  subscribersCsvLoading: state.subscribers.subscribersCsvLoading,
});

export default connect(mapStateToProps, {
  getSubscribersList,
  resetComponentStore,
  deleteSubscriber,
  getSubscriberListCsv,
})(SubscribersList);
