import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getAboutUs,
  updateAboutUs,
  removeErrors,
  setErrors,
} from "actions/admin/aboutUs";
import Spinner from "views/components/Spinner";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  CardTitle,
} from "reactstrap";
import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate } from "react-router-dom";

const EditAboutUs = ({
  getAboutUs,
  currentAboutUs,
  loading,
  updateAboutUs,
  removeErrors,
  setErrors,
  errorList,
}) => {
  const params = useParams();
  const history = useNavigate();
  const initialState = {
    origin_description: "",
    origin_image: "",
    owner_title: "",
    owner_description: "",
    owner_image: "",
    logo_title: "",
    logo_description: "",
    logo_image: "",
    commitment_title: "",
    commitment_description: "",
    commitment_image: "",
    footer_content: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [onlyOnce, setOnce] = useState(true);

  useEffect(() => {
    if (onlyOnce) {
      getAboutUs();
      setOnce(false);
    }
  });
  useEffect(() => {
    const asyncCall = async () => {
      try {
        if (currentAboutUs) {
          const {
            origin = {},
            owner = {},
            logo = {},
            commitment = {},
            footer_content,
          } = currentAboutUs;
          setFormData((formData) => ({
            ...formData,
            origin_title: origin.title ? origin.title : "",
            origin_description: origin.description ? origin.description : "",
            owner_title: owner.title ? owner.title : "",
            owner_description: owner.description ? owner.description : "",
            logo_title: logo.title ? logo.title : "",
            logo_description: logo.description ? logo.description : "",
            commitment_title: commitment.title ? commitment.title : "",
            commitment_description: commitment.description
              ? commitment.description
              : "",
            origin_image_url:
              process.env.REACT_APP_SERVER_URL +
              "/documents/aboutUs/" +
              origin.image,
            owner_image_url:
              process.env.REACT_APP_SERVER_URL +
              "/documents/aboutUs/" +
              owner.image,
            logo_image_url:
              process.env.REACT_APP_SERVER_URL +
              "/documents/aboutUs/" +
              logo.image,
            commitment_image_url:
              process.env.REACT_APP_SERVER_URL +
              "/documents/aboutUs/" +
              commitment.image,
            footer_content: footer_content,
          }));
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    asyncCall();

    return () => {
      //subscribe = false;
    };
  }, [currentAboutUs]);

  const validateForm = (form, requireFields) => {
    const errors = [];
    for (let i in requireFields) {
      if (requireFields[i].type === "object") {
        if (
          !form[requireFields[i].param][requireFields[i].value].trim().length
        ) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (requireFields[i].type === "array") {
        if (!form[requireFields[i].param].length) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (!form[requireFields[i].param].trim().length) {
        errors.push({
          param: requireFields[i].actualParam || requireFields[i].param,
          msg: requireFields[i].msg,
        });
      }
    }
    if (errors.length) {
      setErrors(errors);
    }

    return !errors.length;
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onImageChange = (e, field) => {
    let dyna_var = field + "_url";
    let image_file = e.target.files[0];
    let image_url = URL.createObjectURL(image_file);
    setFormData({
      ...formData,
      [field]: image_file,
      [dyna_var]: image_url,
    });
  };
  // const handleDescriptionChange = (event, field) => {
  //   const desc = event.editor.getData(); // editor.getData();
  //   setFormData((ev) => {
  //     return { ...ev, [field]: desc };
  //   });
  // };
  const onChangeDescription = (newDescription, field) => {
    setFormData((data) => ({ ...data, [field]: newDescription }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    if (
      !validateForm(formData, [
        {
          param: "origin_title",
          msg: "Please enter the origin title",
        },
        {
          param: "origin_description",
          msg: "Please enter the origin description",
        },
        {
          param: "logo_title",
          msg: "Please enter the logo title",
        },
        {
          param: "logo_description",
          msg: "Please enter the logo description",
        },
        {
          param: "owner_title",
          msg: "Please enter the owner title",
        },
        {
          param: "owner_description",
          msg: "Please enter the owner description",
        },
        {
          param: "commitment_title",
          msg: "Please enter the commitment title",
        },
        {
          param: "commitment_description",
          msg: "Please enter the commitment description",
        },
        {
          param: "footer_content",
          msg: "Please enter the footer content",
        },
      ])
    )
      return;
    updateAboutUs(formData, history, "");
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    //cancelSave(history);
  };
  // console.log(formData.origin_description, currentAboutUs.origin)
  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <span className="pull-left h5">About Us Page</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Card style={{ marginTop: 10 }} body>
                  <FormGroup>
                    <Label htmlFor="slug">
                      Origin Title <span></span>
                    </Label>
                    <Input
                      type="text"
                      id="name"
                      name="origin_title"
                      value={formData.origin_title}
                      invalid={errorList.origin_title ? true : false}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors current_key="origin_title" key="origin_title" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="origin_image">Origin Image</Label>
                    <Input
                      type="file"
                      id="origin_image"
                      name="origin_image"
                      onChange={(e) => onImageChange(e, "origin_image")}
                      multiple
                      invalid={errorList.origin_image ? true : false}
                      accept="image/png, image/jpeg"
                    />
                    {formData.origin_image_url && (
                      <img
                        src={formData.origin_image_url}
                        style={{ padding: 10, height: "80px", width: "100px" }}
                        className="preview-img img-fluid"
                        alt=""
                      />
                    )}
                    <Errors current_key="origin_image" key="origin_image" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="origin_description">
                      Origin Description
                    </Label>
                    {formData.origin_title != null && (
                      // <CKEditor
                      //   initData={formData.origin_description}
                      //   data={formData.origin_description}
                      //   id="origin_description"
                      //   name="origin_description"
                      //   config={{
                      //     height: 100,
                      //     toolbar: [
                      //       ["Cut", "Copy", "Paste"],
                      //       ["Undo", "Redo"],
                      //       ["SpellChecker"],
                      //       ["Link", "Unlink", "Anchor"],
                      //       [
                      //         "Image",
                      //         "Table",
                      //         "Horizontal Line",
                      //         "Special Character",
                      //       ],
                      //       ["Maximize"],
                      //       ["Source"],
                      //       ["Bold", "Italic", "Strike"],
                      //       ["RemoveFormat"],
                      //       ["NumberedList", "BulletedList"],
                      //       ["DecreaseIndent", "IncreaseIndent"],
                      //       ["BlockQuote"],
                      //       ["Styles"],
                      //       ["Format"],
                      //       ["About"],
                      //     ],
                      //   }}
                      //   onChange={(event) =>
                      //     handleDescriptionChange(event, "origin_description")
                      //   }
                      //   onBeforeLoad={(CKEDITOR) =>
                      //     (CKEDITOR.disableAutoInline = true)
                      //   }
                      //   invalid={errorList.origin_description ? true : false}
                      // />
                      <CKEditor
                        editor={Editor}
                        required
                        data={formData.origin_description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChangeDescription(data, "origin_description");
                        }}
                      />
                    )}
                    <Errors
                      current_key="origin_description"
                      key="origin_description"
                    />
                  </FormGroup>
                </Card>
                <Card style={{ marginTop: 10 }} body>
                  <FormGroup>
                    <Label htmlFor="slug">
                      Owner Title <span></span>
                    </Label>
                    <Input
                      type="text"
                      id="name"
                      name="owner_title"
                      value={formData.owner_title}
                      invalid={errorList.owner_title ? true : false}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors current_key="owner_title" key="owner_title" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="owner_image">Owner Image</Label>
                    <Input
                      type="file"
                      id="owner_image"
                      name="owner_image"
                      onChange={(e) => onImageChange(e, "owner_image")}
                      multiple
                      invalid={errorList.owner_image ? true : false}
                      accept="image/png, image/jpeg"
                    />
                    {formData.owner_image_url && (
                      <img
                        src={formData.owner_image_url}
                        style={{ padding: 10, height: "80px", width: "100px" }}
                        className="preview-img img-fluid"
                        alt=""
                      />
                    )}
                    <Errors current_key="owner_image" key="owner_image" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="owner_description">Owner Description</Label>
                    {formData.origin_title != null && (
                      // <CKEditor
                      //   initData={formData.owner_description}
                      //   data={formData.owner_description}
                      //   id="owner_description"
                      //   name="owner_description"
                      //   config={{
                      //     height: 100,
                      //     toolbar: [
                      //       ["Cut", "Copy", "Paste"],
                      //       ["Undo", "Redo"],
                      //       ["SpellChecker"],
                      //       ["Link", "Unlink", "Anchor"],
                      //       [
                      //         "Image",
                      //         "Table",
                      //         "Horizontal Line",
                      //         "Special Character",
                      //       ],
                      //       ["Maximize"],
                      //       ["Source"],
                      //       ["Bold", "Italic", "Strike"],
                      //       ["RemoveFormat"],
                      //       ["NumberedList", "BulletedList"],
                      //       ["DecreaseIndent", "IncreaseIndent"],
                      //       ["BlockQuote"],
                      //       ["Styles"],
                      //       ["Format"],
                      //       ["About"],
                      //     ],
                      //   }}
                      //   onChange={(event) =>
                      //     handleDescriptionChange(event, "owner_description")
                      //   }
                      //   onBeforeLoad={(CKEDITOR) =>
                      //     (CKEDITOR.disableAutoInline = true)
                      //   }
                      //   invalid={errorList.owner_description ? true : false}
                      // />
                      <CKEditor
                        editor={Editor}
                        required
                        data={formData.owner_description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChangeDescription(data, "owner_description");
                        }}
                      />
                    )}
                    <Errors
                      current_key="owner_description"
                      key="owner_description"
                    />
                  </FormGroup>
                </Card>
                <Card style={{ marginTop: 10 }} body>
                  <FormGroup>
                    <Label htmlFor="slug">
                      Logo Title <span></span>
                    </Label>
                    <Input
                      type="text"
                      id="name"
                      name="logo_title"
                      value={formData.logo_title}
                      invalid={errorList.logo_title ? true : false}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors current_key="logo_title" key="logo_title" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="logo_image">Logo Image</Label>
                    <Input
                      type="file"
                      id="logo_image"
                      name="logo_image"
                      onChange={(e) => onImageChange(e, "logo_image")}
                      multiple
                      invalid={errorList.logo_image ? true : false}
                      accept="image/png, image/jpeg"
                    />
                    {formData.logo_image_url && (
                      <img
                        src={formData.logo_image_url}
                        style={{ padding: 10, height: "80px", width: "100px" }}
                        className="preview-img img-fluid"
                        alt=""
                      />
                    )}
                    <Errors current_key="logo_image" key="logo_image" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="logo_description">Logo Description</Label>
                    {formData.origin_title != null && (
                      // <CKEditor
                      //   initData={formData.logo_description}
                      //   id="logo_description"
                      //   name="logo_description"
                      //   config={{
                      //     height: 100,
                      //     toolbar: [
                      //       ["Cut", "Copy", "Paste"],
                      //       ["Undo", "Redo"],
                      //       ["SpellChecker"],
                      //       ["Link", "Unlink", "Anchor"],
                      //       [
                      //         "Image",
                      //         "Table",
                      //         "Horizontal Line",
                      //         "Special Character",
                      //       ],
                      //       ["Maximize"],
                      //       ["Source"],
                      //       ["Bold", "Italic", "Strike"],
                      //       ["RemoveFormat"],
                      //       ["NumberedList", "BulletedList"],
                      //       ["DecreaseIndent", "IncreaseIndent"],
                      //       ["BlockQuote"],
                      //       ["Styles"],
                      //       ["Format"],
                      //       ["About"],
                      //     ],
                      //   }}
                      //   onChange={(event) =>
                      //     handleDescriptionChange(event, "logo_description")
                      //   }
                      //   onBeforeLoad={(CKEDITOR) =>
                      //     (CKEDITOR.disableAutoInline = true)
                      //   }
                      //   invalid={errorList.logo_description ? true : false}
                      // />
                      <CKEditor
                        editor={Editor}
                        required
                        data={formData.logo_description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChangeDescription(data, "logo_description");
                        }}
                      />
                    )}
                    <Errors
                      current_key="logo_description"
                      key="logo_description"
                    />
                  </FormGroup>
                </Card>
                <Card style={{ marginTop: 10 }} body>
                  <FormGroup>
                    <Label htmlFor="slug">
                      Commitment Title <span></span>
                    </Label>
                    <Input
                      type="text"
                      id="name"
                      name="commitment_title"
                      value={formData.commitment_title}
                      invalid={errorList.commitment_title ? true : false}
                      onChange={(e) => onChange(e)}
                    />
                    <Errors
                      current_key="commitment_title"
                      key="commitment_title"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="commitment_image">Commitment Image</Label>
                    <Input
                      type="file"
                      id="commitment_image"
                      name="commitment_image"
                      onChange={(e) => onImageChange(e, "commitment_image")}
                      multiple
                      invalid={errorList.commitment_image ? true : false}
                      accept="image/png, image/jpeg"
                    />
                    {formData.commitment_image_url && (
                      <img
                        src={formData.commitment_image_url}
                        style={{ padding: 10, height: "80px", width: "100px" }}
                        className="preview-img img-fluid"
                        alt=""
                      />
                    )}
                    <Errors
                      current_key="commitment_image"
                      key="commitment_image"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="commitment_description">
                      Commitment Description
                    </Label>
                    {formData.origin_title != null && (
                      // <CKEditor
                      //   initData={formData.commitment_description}
                      //   id="commitment_description"
                      //   name="commitment_description"
                      //   config={{
                      //     height: 100,
                      //     toolbar: [
                      //       ["Cut", "Copy", "Paste"],
                      //       ["Undo", "Redo"],
                      //       ["SpellChecker"],
                      //       ["Link", "Unlink", "Anchor"],
                      //       [
                      //         "Image",
                      //         "Table",
                      //         "Horizontal Line",
                      //         "Special Character",
                      //       ],
                      //       ["Maximize"],
                      //       ["Source"],
                      //       ["Bold", "Italic", "Strike"],
                      //       ["RemoveFormat"],
                      //       ["NumberedList", "BulletedList"],
                      //       ["DecreaseIndent", "IncreaseIndent"],
                      //       ["BlockQuote"],
                      //       ["Styles"],
                      //       ["Format"],
                      //       ["About"],
                      //     ],
                      //   }}
                      //   onChange={(event) =>
                      //     handleDescriptionChange(
                      //       event,
                      //       "commitment_description"
                      //     )
                      //   }
                      //   onBeforeLoad={(CKEDITOR) =>
                      //     (CKEDITOR.disableAutoInline = true)
                      //   }
                      //   invalid={
                      //     errorList.commitment_description ? true : false
                      //   }
                      // />
                      <CKEditor
                        editor={Editor}
                        required
                        data={formData.commitment_description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChangeDescription(data, "commitment_description");
                        }}
                      />
                    )}
                    <Errors
                      current_key="commitment_description"
                      key="commitment_description"
                    />
                  </FormGroup>
                </Card>
                <FormGroup>
                  <Label htmlFor="footer_content">Footer Content</Label>
                  {/* <CKEditor
                    initData={formData.footer_content}
                    id="footer_content"
                    name="footer_content"
                    config={{
                      height: 100,
                      toolbar: [
                        ["Cut", "Copy", "Paste"],
                        ["Undo", "Redo"],
                        ["SpellChecker"],
                        ["Link", "Unlink", "Anchor"],
                        [
                          "Image",
                          "Table",
                          "Horizontal Line",
                          "Special Character",
                        ],
                        ["Maximize"],
                        ["Source"],
                        ["Bold", "Italic", "Strike"],
                        ["RemoveFormat"],
                        ["NumberedList", "BulletedList"],
                        ["DecreaseIndent", "IncreaseIndent"],
                        ["BlockQuote"],
                        ["Styles"],
                        ["Format"],
                        ["About"],
                      ],
                    }}
                    onChange={(event) =>
                      handleDescriptionChange(event, "footer_content")
                    }
                    onBeforeLoad={(CKEDITOR) =>
                      (CKEDITOR.disableAutoInline = true)
                    }
                    invalid={errorList.footer_content ? true : false}
                  /> */}
                  <CKEditor
                    editor={Editor}
                    required
                    data={formData.footer_content}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      onChangeDescription(data, "footer_content");
                    }}
                  />
                  <Errors current_key="footer_content" key="footer_content" />
                </FormGroup>
              </CardBody>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <a onClick={onClickHandel} href="#!">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </a>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditAboutUs.propTypes = {
  getAboutUs: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateAboutUs: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  currentAboutUs: state.aboutUs.currentAboutUs,
  errorList: state.errors,
  loading: state.aboutUs.loadingAboutUs,
});

export default connect(mapStateToProps, {
  getAboutUs,
  updateAboutUs,
  removeErrors,
  setErrors,
})(EditAboutUs);
