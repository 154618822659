import {
  ENQUIRY_LIST_ERROR,
  ENQUIRY_LIST_UPDATED,
  ENQUIRY_LIST_SEARCH_PARAMETERS_UPDATE,
  LOADING_ON_ENQUIRY_LIST_SUBMIT,
  LOAD_ENQUIRY,
  LOADING_ENQUIRY_LIST_DATA,
  DELETE_ENQUIRY,
  ENQUIRY_CSV_LOADING,
  ENQUIRY_CSV_LOADING_DONE,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  enquiryList: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingEnquiryList: true,
  loadingEnquiry: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: 30,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    filters: [],
    query: "",
  },
  enquiriesCsvLoading: false,
};

export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_ENQUIRY:
      return {
        ...state,
        loadingEnquiry: false,
      };
    case ENQUIRY_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loadingEnquiry: false,
        loadingEnquiryList: false,
        enquiriesCsvLoading: false,
      };
    case DELETE_ENQUIRY:
      const currentCount = state.enquiryList.count;
      const currentLimit = state.sortingParams.limit;
      const currentEnquiry = parseInt(state.enquiryList.page);
      let remainingEnquiry = Math.ceil((currentCount - 1) / currentLimit);
      remainingEnquiry = remainingEnquiry ? remainingEnquiry : 1;
      return {
        ...state,
        enquiryList: {
          data: state.enquiryList.data.filter((page) => page._id !== payload),
          count: currentCount - 1,
          page:
            currentEnquiry <= remainingEnquiry
              ? currentEnquiry.toString()
              : remainingEnquiry.toString(),
        },
        loadingEnquiry: false,
        performDelete: true,
      };
    case ENQUIRY_LIST_UPDATED:
      return {
        ...state,
        enquiryList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingEnquiry: false,
        loadingEnquiryList: false,
        enquiriesCsvLoading: false,
      };
    case ENQUIRY_LIST_SEARCH_PARAMETERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case LOADING_ENQUIRY_LIST_DATA:
      return {
        ...state,
        loadingEnquiryList: true,
      };
    case LOADING_ON_ENQUIRY_LIST_SUBMIT:
      return {
        ...state,
        loadingEnquiry: true,
      };
    case ENQUIRY_CSV_LOADING:
      return {
        ...state,
        enquiriesCsvLoading: true,
      };
    case ENQUIRY_CSV_LOADING_DONE:
      return {
        ...state,
        enquiriesCsvLoading: false,
      };
    default:
      return state;
  }
}
