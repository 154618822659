"use strict";
exports.__esModule = true;
/**
 * Remove all accentuated characters from a string
 */
var stripAccents = function (input) {
    var accents = "ÀÁÂÃÄÅĄàáâãäåąÒÓÔÕÕÖØòóôõöøÈÉÊËĘèéêëðęÇĆçćÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠŚšśŸÿýŽŹŻžźżŁłŃńàáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ";
    var fixes = "AAAAAAAaaaaaaaOOOOOOOooooooEEEEEeeeeeeCCccDIIIIiiiiUUUUuuuuNnSSssYyyZZZzzzLlNnaaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
    var split = accents.split('').join('|');
    var reg = new RegExp("(" + split + ")", 'g');
    function replacement(a) {
        return fixes[accents.indexOf(a)] || '';
    }
    return input.replace(reg, replacement);
};
var getSafeRegexpString = function (input) {
    return input
        .split('')
        .map(function (char) { return "\\" + char; })
        .join('');
};
/**
 * Harmonize a string by removing spaces, non-alphabetical caracters and by
 * adding delimiter
 */
var harmonize = function (input, delimiter, ignoreInvalid) {
    if (ignoreInvalid === void 0) { ignoreInvalid = false; }
    var harmonized = stripAccents(input).trim().toLowerCase();
    var safeDelimiter = getSafeRegexpString(delimiter);
    if (ignoreInvalid) {
        return harmonized.replace(/\s+/g, delimiter);
    }
    return harmonized
        .replace(new RegExp("[^a-z0-9" + safeDelimiter + "]+", 'g'), delimiter) // Replace all non-valid caracters by delimiter
        .replace(new RegExp(safeDelimiter + "+", 'g'), delimiter) // Remove multiple delimiters repetition
        .replace(new RegExp("^" + safeDelimiter, 'g'), '') // remove delimiter at the beginning
        .replace(new RegExp(safeDelimiter + "$", 'g'), ''); // remove delimiter at the end
};
/**
 * Slugify a React node
 */
var slugify = function (node, options) {
    if (options === void 0) { options = { delimiter: '-', prefix: '' }; }
    if (!options.delimiter)
        options.delimiter = '-';
    if (!options.prefix)
        options.prefix = '';
    if (!node || typeof node === 'boolean') {
        return '';
    }
    var delimiter = options.delimiter, prefix = options.prefix;
    // string, number
    if (typeof node === 'string' || typeof node === 'number') {
        var harmonizedPrefix = harmonize(prefix, delimiter, true);
        var harmonizedNode = harmonize(String(node), delimiter);
        if (harmonizedPrefix) {
            return "" + harmonizedPrefix + delimiter + harmonizedNode;
        }
        return harmonizedNode;
    }
    // empty object
    if (typeof node === 'object' && Object.keys(node).length === 0) {
        return '';
    }
    // ReactPortal
    if ('children' in node) {
        return slugify(node.children);
    }
    // ReactNodeArray
    if (node instanceof Array) {
        return slugify(node.map(function (subNode) { return slugify(subNode, { delimiter: delimiter }); }).join(delimiter), options);
    }
    // ReactElement
    if ('type' in node)
        return slugify(node.props.children, options);
    // unhandled case
    return '';
};
exports["default"] = slugify;
