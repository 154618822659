import React from "react";
import { debounce } from "lodash-es";
import { FormGroup, Row, Col, InputGroup, Label, Button } from "reactstrap";
import { DateRange } from "react-date-range";
import { FaTimes } from "react-icons/fa";

import SearchComponent from "./Search";

const initialDate = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};

const Filters = ({ onSearch, onFilter }) => {
  const [filterText, setFilterText] = React.useState("");
  const [filterDate, setFilterDate] = React.useState(initialDate);

  const onFilterUpdate = (type, value) => {
    switch (type) {
      case "search":
        debounce(() => {
          onSearch(value);
        }, 700)();
        break;
      case "createdAt":
        onFilter(type, value);
        break;
      default:
    }
  };

  const searchFilter = React.useMemo(() => {
    return (
      <SearchComponent
        onFilter={(value) => {
          setFilterText(value);
          onFilterUpdate("search", value);
        }}
        filterText={filterText}
      />
    );
  }, [filterText]);

  const dateFilter = React.useMemo(() => {
    const onCancelRange = () => {
      setFilterDate(initialDate);
      onFilterUpdate("createdAt", "");
    };
    return (
      <FormGroup>
        <Label>Created Date</Label>

        <InputGroup>
          <DateRange
            moveRangeOnFirstSelection
            // retainEndDateOnFirstSelection
            onChange={(item) => {
              setFilterDate(item.selection);
              var actualDate = new Date(item.selection.endDate);
              var endOfDayDate = new Date(
                actualDate.getFullYear(),
                actualDate.getMonth(),
                actualDate.getDate(),
                23,
                59,
                59
              );
              onFilterUpdate("createdAt", {
                from: item.selection.startDate,
                to: endOfDayDate,
              });
            }}
            ranges={[filterDate]}
          />
          <div className="input-group-append" style={{ marginLeft: "10px" }}>
            <span className="input-group-text-custom bg-transparent">
              <Button onClick={onCancelRange} color="danger">
                <FaTimes /> Clear
              </Button>
            </span>
          </div>
        </InputGroup>
      </FormGroup>
    );
  }, [JSON.stringify(filterDate)]);

  return (
    <>
      <Col xs={12} md={6}>
        {dateFilter}
      </Col>
      <Col xs={12} md={6}>
        <Row>{searchFilter}</Row>
      </Col>
    </>
  );
};

export default Filters;
