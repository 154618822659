import {
  SUBSCRIBERS_LIST_ERROR,
  SUBSCRIBERS_LIST_UPDATED,
  SUBSCRIBERS_LIST_SEARCH_PARAMETERS_UPDATE,
  LOADING_ON_SUBSCRIBERS_LIST_SUBMIT,
  LOAD_SUBSCRIBER,
  LOADING_SUBSCRIBERS_LIST_DATA,
  DELETE_SUBSCRIBER,
  SUBSCRIBERS_CSV_LOADING,
  SUBSCRIBERS_CSV_LOADING_DONE,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  subscribersList: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingSubscriberList: true,
  loadingSubscriber: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: 30,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    filters: [],
    query: "",
  },
  subscribersCsvLoading: false,
};

export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_SUBSCRIBER:
      return {
        ...state,
        loadingSubscriber: false,
      };
    case SUBSCRIBERS_LIST_ERROR:
      return {
        ...state,
        error: payload,
        loadingSubscriber: false,
        loadingSubscriberList: false,
        subscribersCsvLoading: false,
      };
    case DELETE_SUBSCRIBER:
      const currentCount = state.subscribersList.count;
      const currentLimit = state.sortingParams.limit;
      const currentSubscriber = parseInt(state.subscribersList.page);
      let remainingSubscriber = Math.ceil(
        (currentCount - payload.length) / currentLimit
      );
      remainingSubscriber = remainingSubscriber ? remainingSubscriber : 1;
      return {
        ...state,
        subscribersList: {
          data: state.subscribersList.data.filter((one) => {
            let keep = true;
            payload.map((two) => {
              if (one._id === two._id) {
                keep = false;
              }
            });
            if (keep) {
              return one;
            }
          }),
          count: currentCount - payload.length,
          page:
            currentSubscriber <= remainingSubscriber
              ? currentSubscriber.toString()
              : remainingSubscriber.toString(),
        },
        loadingSubscriber: false,
        performDelete: true,
      };
    case SUBSCRIBERS_LIST_UPDATED:
      return {
        ...state,
        subscribersList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingSubscriber: false,
        loadingSubscriberList: false,
        subscribersCsvLoading: false,
      };
    case SUBSCRIBERS_LIST_SEARCH_PARAMETERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case LOADING_SUBSCRIBERS_LIST_DATA:
      return {
        ...state,
        loadingSubscriberList: true,
      };
    case LOADING_ON_SUBSCRIBERS_LIST_SUBMIT:
      return {
        ...state,
        loadingSubscriber: true,
      };
    case SUBSCRIBERS_CSV_LOADING:
      return {
        ...state,
        subscribersCsvLoading: true,
      };
    case SUBSCRIBERS_CSV_LOADING_DONE:
      return {
        ...state,
        subscribersCsvLoading: false,
      };
    default:
      return state;
  }
}
