import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import "./scss/style.scss";
import { loadUser } from "./actions/auth";

import PublicRoutes from "AppRoutes/PublicRoutes";

import Signin from "views/containers/Signin";
import ForgotPassword from "views/containers/ForgotPassword";
import PageNotFound from "views/components/Page404";
import ResetPassword from "views/containers/ResetPassword";
import Dashboard from "views/containers/Dashboard";
import Layout from "views/containers/Layout";
import Profile from "views/containers/Profile";
import AppLoading from "views/components/AppLoading";
import VerifyEmail from "views/containers/VerifyEmail";
import BLOGList from "views/containers/Blog/List";
import BLOGCreate from "views/containers/Blog/Create";
import BLOGEdit from "views/containers/Blog/Edit";
import CMSList from "views/containers/CMS/List";
import CMSCreate from "views/containers/CMS/Create";
import CMSEdit from "views/containers/CMS/Edit";
import CategoryList from "views/containers/Service/Category";
import EditCategory from "views/containers/Service/Category/edit";
import CreateCategory from "views/containers/Service/Category/create";
import EditSetting from "views/containers/Setting/Edit";
import LocationList from "views/containers/Service/Location/List";
import EditLocation from "views/containers/Service/Location/Edit";
import CreateLocation from "views/containers/Service/Location/Create";
import ServiceList from "views/containers/Service/Service/List";
import EditService from "views/containers/Service/Service/Edit";
import CreateService from "views/containers/Service/Service/Create";
import ImageCategory from "views/containers/Service/Category/image";
import EditAboutUs from "views/containers/AboutUs/Edit";
import AddBanner from "views/containers/Banner/add";
import BannerList from "views/containers/Banner/List";
import EditBanner from "views/containers/Banner/edit";
import EditSubBanner from "views/containers/SubBanner/Edit";
import AddPackage from "views/containers/Package/add";
import PackageList from "views/containers/Package/List";
import EditPackage from "views/containers/Package/edit";
import WhyWtfList from "views/containers/WhyWall2floor/List";
import CreateCard from "views/containers/WhyWall2floor/Create";
import EditCard from "views/containers/WhyWall2floor/Edit";
import MetaData from "views/containers/Metadata";
import CreateMetaData from "views/containers/Metadata/Create";
import EditMetaData from "views/containers/Metadata/Edit";

import Home from "views/containers/CMS/Home";
import Amenities from "views/containers/CMS/Amenities";
import Gallery from "views/containers/CMS/Gallery";
import Availability from "views/containers/CMS/Availability";
import ContactUs from "views/containers/CMS/ContactUs";
import SubscribersList from "views/containers/SubscribersList/index";
import PropertyList from "views/containers/ManageProperties/index";
import AddProperty from "views/containers/ManageProperties/Create";
import EditProperty from "views/containers/ManageProperties/Edit";
import ManageColors from "views/containers/ManageColors";
import InquiryList from "views/containers/InquiryList";

const App = ({ loadUser, auth: { user, loading } }) => {
  const isLoggedIn = !!user?._id;

  useEffect(() => {
    let subscribe = true;
    async function asyncCall() {
      if (subscribe) {
        loadUser();
      }
    }
    asyncCall();
    return () => {
      subscribe = false;
    };
    //eslint react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      {/* <Route element={<AppRoutes />} path="/*" /> */}
      <Routes>
        <Route
          element={
            <PublicRoutes restricted={true}>
              <Signin />
            </PublicRoutes>
          }
          path=""
        />
        <Route
          element={
            <PublicRoutes>
              <Signin restricted={true} />
            </PublicRoutes>
          }
          path="signin"
        />
        <Route
          element={
            <PublicRoutes>
              <ForgotPassword />
            </PublicRoutes>
          }
          path="forgot-password"
        />
        <Route
          element={
            <PublicRoutes>
              <ResetPassword />
            </PublicRoutes>
          }
          path="reset-password/:token"
        />
        <Route
          element={
            <PublicRoutes>
              <VerifyEmail />
            </PublicRoutes>
          }
          path="verify/email/:token"
        />
        {isLoggedIn && (
          <Route element={<Layout />} path="/dashboard">
            <Route element={<Dashboard />} path="" />
            <Route element={<Home />} path="our-properties" />
            <Route element={<Amenities />} path="amenities" />
            <Route element={<Gallery />} path="gallery" />
            <Route element={<Availability />} path="availability" />
            <Route element={<ContactUs />} path="contact-us" />
            <Route element={<Profile />} path="profile" />
            <Route element={<InquiryList />} path="inquiry" />
            <Route element={<CMSList />} path="cms" />
            <Route element={<WhyWtfList />} path="whyWall2floor" />
            <Route element={<CreateCard />} path="whyWall2floor/create" />
            <Route element={<EditCard />} path="whyWall2floor/:card_id/edit" />
            <Route element={<MetaData />} path="metadata" />
            <Route element={<CreateMetaData />} path="metadata/create" />
            <Route element={<EditMetaData />} path="metadata/:id/edit" />
            <Route element={<CMSCreate />} path="cms/create" />
            <Route element={<CMSEdit />} path="cms/:cms_id" />
            <Route element={<BLOGList />} path="blog" />
            <Route element={<BLOGCreate />} path="blog/create" />
            <Route element={<BLOGEdit />} path="blog/:blog_id" />
            <Route element={<CategoryList />} path="services/category" />
            <Route element={<SubscribersList />} path="subscribers" />
            <Route element={<PropertyList />} path="properties" />
            <Route element={<AddProperty />} path="properties/add" />
            <Route element={<EditProperty />} path="properties/:id/edit" />
            <Route element={<ManageColors />} path="colors" />
            <Route
              element={<CreateCategory />}
              path="services/category/create"
            />
            <Route
              element={<EditCategory />}
              path="services/category/:category_id/edit"
            />
            <Route element={<EditSetting />} path="settings" />
            <Route element={<LocationList />} path="services/location" />
            <Route
              element={<CreateLocation />}
              path="services/location/create"
            />
            <Route
              element={<EditLocation />}
              path="services/location/:location_id/edit"
            />
            <Route element={<ServiceList />} path="services" />
            <Route element={<CreateService />} path="services/create" />
            <Route element={<EditService />} path="services/:service_id/edit" />
            <Route
              element={<ImageCategory />}
              path="services/category/image/:category_id"
            />
            <Route element={<EditAboutUs />} path="aboutUs" />

            <Route element={<AddBanner />} path="banner/create" />
            <Route element={<BannerList />} path="banner" />
            <Route element={<EditBanner />} path="banner/:banner_id/edit" />
            <Route element={<EditSubBanner />} path="subBanner" />
            <Route element={<AddPackage />} path="package/create" />
            <Route element={<PackageList />} path="package" />
            <Route element={<EditPackage />} path="package/:package_id/edit" />
          </Route>
        )}
        {
          <Route
            path="*"
            element={loading ? <AppLoading /> : <PageNotFound />}
          />
        }
      </Routes>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  loadUser,
})(App);
