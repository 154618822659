import {
  CARD_CREATED,
  CARD_ERROR,
  DELETE_CARD,
  CARD_UPDATED,
  CARD_LIST_UPDATED,
  GET_CARD_BY_ID,
  LOADING_ON_CARD_SUBMIT,
  CARD_SEARCH_PARAMETERS_UPDATE,
  LOAD_PAGE,
  LOADING_CARD_LIST_DATA,
  RESET,
} from "actions/types";

import * as Constants from "constants/index";

const initialState = {
  cardList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentCard: [],
  loadingCardList: true,
  loadingCard: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
  },
};

export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingCard: state.loadingCard,
        loadingCardList: state.loadingCardList,
        loadingListData: state.loadingListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingCard: false,
      };
    case CARD_CREATED:
      return {
        ...state,
        loadingCard: false,
      };
    case CARD_UPDATED:
      return {
        ...state,
        currentCard: payload,
        sortingParams: initialState.sortingParams,
        loadingCard: false,
      };
    case CARD_ERROR:
      return {
        ...state,
        error: payload,
        loadingCard: false,
        loadingCardList: false,
        loadingListData: false,
        performDelete: false,
      };
    case DELETE_CARD:
      const currentCount = state.cardList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.cardList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        cardList: {
          data: state.cardList.data.filter((card) => card._id !== payload),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingCardList: false,
        performDelete: true,
      };
    case GET_CARD_BY_ID:
      return {
        ...state,
        currentCard: payload,
        loadingCard: false,
      };
    case CARD_LIST_UPDATED:
      return {
        ...state,
        cardList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingCardList: false,
        loadingListData: false,
        performDelete: false,
      };
    case CARD_SEARCH_PARAMETERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case LOADING_CARD_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_ON_CARD_SUBMIT:
      return {
        ...state,
        loadingCard: true,
      };
    default:
      return state;
  }
}
