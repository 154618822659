import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  SERVICE_CATEGORY_CREATED,
  SERVICE_CATEGORY_ERROR,
  SERVICE_CATEGORY_LIST_UPDATED,
  GET_SERVICE_CATEGORY_BY_ID,
  SERVICE_CATEGORY_SEARCH_PARAMATERS_UPDATE,
  REMOVE_ERRORS,
  //INITIAL_LOADING,
  LOADING_ON_SERVICE_CATEGORY_SUBMIT,
  LOAD_PAGE,
  LOADING_CATEGORY_LIST_DATA,
  RESET
} from "actions/types";
import { SERVICE_CATEGORY} from "constants/api";
import { logout } from "../auth";

//get users list
export const getCategoryList = (userParams = {}, noRedux) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    !noRedux && dispatch({ type: REMOVE_ERRORS });
    !noRedux && dispatch({ type: LOADING_CATEGORY_LIST_DATA });

    const query = userParams.query ? userParams.query : "";
    userParams.query = query;
    config.params = userParams;
    const res = await axios.get(`${SERVICE_CATEGORY.LIST().ENDPOINT}`, config);
    !noRedux && dispatch({
      type: SERVICE_CATEGORY_SEARCH_PARAMATERS_UPDATE,
      payload: userParams,
    });
    !noRedux && dispatch({
      type: SERVICE_CATEGORY_LIST_UPDATED,
      payload: res.data.response[0],
    });
    return res.data.response[0];
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch({
        type: SERVICE_CATEGORY_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
  }
};
// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
    await dispatch({ type: RESET });
  };

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: SERVICE_CATEGORY_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};
// Dispatch Loading
export const loadingOnCategorySubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_SERVICE_CATEGORY_SUBMIT });
};

// Create Category
export const create = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnCategorySubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(SERVICE_CATEGORY.LIST().ENDPOINT, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: SERVICE_CATEGORY_CREATED,
        payload: res.data.response,
      });
      dispatch(
        setAlert("Category created", "success")
      );
      history("/home/services/category");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({
          type: SERVICE_CATEGORY_ERROR,
        });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SERVICE_CATEGORY_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Update Category
export const updateCategory = (formData, history, _id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnCategorySubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put(`${SERVICE_CATEGORY.LIST().ENDPOINT}/${_id}`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: SERVICE_CATEGORY_CREATED,
        payload: res.data.response,
      });
      dispatch(
        setAlert("Category updated successfully", "success")
      );
      history("/home/services/category");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({
          type: SERVICE_CATEGORY_ERROR,
        });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SERVICE_CATEGORY_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Update Category
export const deleteCategory = (_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnCategorySubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.delete(`${SERVICE_CATEGORY.LIST().ENDPOINT}/${_id}`, config);
    if (res.data.status === true) {
      dispatch(
        setAlert("Category deleted successfully", "success")
      );
      dispatch(getCategoryList())
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SERVICE_CATEGORY_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// get Category by id
export const getCategoryById = (category_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnCategorySubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`${SERVICE_CATEGORY.LIST().ENDPOINT}/${category_id}`, config);
    // dispatch({ type: REMOVE_ALERT });

    await dispatch({
      type: GET_SERVICE_CATEGORY_BY_ID,
      payload: res.data.response,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch({
        type: SERVICE_CATEGORY_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
  }
};

export const cancelSave = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  history("/home/services/category");
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history.push("/admin/page-not-found");
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_PAGE });
};

//upload Image category
export const uploadCategoryImage =
  (formData, history, category_id) => async (dispatch) => {
    // -- Always remove the previous errors before sending new request
    dispatch({ type: REMOVE_ERRORS });
    const config = {
      headers: {
        "Content-Type": "application/json; multipart/form-data;",
      },
    };
    try {
      const data = new FormData();
      for(var i in formData){
        data.append(i, formData[i]);
      } 
      const res = await axios.post(
        `${SERVICE_CATEGORY.UPLOAD_IMAGE().ENDPOINT}/${category_id}`,
        data,
        config
      );
      if (res.data.status === true) {
        dispatch(getCategoryById(category_id));
        dispatch(
          setAlert("Image uploaded!", "success")
        );
        history(`/home/services/category/image/${category_id}`);
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: SERVICE_CATEGORY_ERROR });
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: SERVICE_CATEGORY_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

//delete category Image
export const deleteCategoryImage= (category_id,_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnCategorySubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.delete(`${SERVICE_CATEGORY.DELETE_IMAGE().ENDPOINT}/${category_id}/${_id}`, config);
    if (res.data.status === true) {
      dispatch(
        setAlert("Category Image deleted successfully", "success")
      );
      dispatch(getCategoryById(category_id))
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SERVICE_CATEGORY_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};
