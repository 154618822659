import axios from "axios";
import {
  REMOVE_ERRORS,
  NOTIFICATION_ERROR,
  NOTIFICATION_PARAMATERS_UPDATE,
  NOTIFICATION_LIST_UPDATED,
  ADD_NOTIFICATION,
  LOADING_ON_NOTIFICATION_LIST,
  NOTIFICATION_LIST_LOAD_MORE,
  NOTIFICATION_BOX_OPENED,
  RESET_NOTIFICATION,
} from "actions/types";
import { logout } from "actions/auth";

//Get Notification List
export const getNotifications =
  (notificationParams, loadMore) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch(loadingOnNotificationList());
      dispatch({ type: REMOVE_ERRORS });
      notificationParams.query = notificationParams.query
        ? notificationParams.query
        : "";
      config.params = notificationParams;
      const res = await axios.get(`/api/notification`, config);
      if (loadMore) {
        dispatch({
          type: NOTIFICATION_LIST_LOAD_MORE,
          payload: res.data.response[0],
        });
      } else {
        dispatch({
          type: NOTIFICATION_LIST_UPDATED,
          payload: res.data.response[0],
        });
      }
      dispatch({
        type: NOTIFICATION_PARAMATERS_UPDATE,
        payload: notificationParams,
      });
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: NOTIFICATION_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
        }
      }
    }
  };

//Add a newly received notification
export const addNotification = (notification) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_NOTIFICATION,
      payload: notification,
    });
  } catch (err) {}
};

export const notificationViewed =
  (notification_id, params) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    try {
      const res = await axios.put(
        `/api/notification/${notification_id}/opened`,
        config
      );
      if (res.data.status === true) {
        dispatch(getNotifications(params));
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: NOTIFICATION_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
        }
      }
    }
  };

export const notificationCleared = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };
  try {
    const res = await axios.put(`/api/notification/cleared`, config);
    if (res.data.status === true) {
      dispatch({
        type: NOTIFICATION_BOX_OPENED,
        payload: res.data.response,
      });
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: NOTIFICATION_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  }
};

// Dispatch Loading
export const loadingOnNotificationList = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_NOTIFICATION_LIST });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET_NOTIFICATION });
};
