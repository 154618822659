import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getNotifications,
  notificationViewed,
  notificationCleared,
} from "actions/admin/notifications";

const Notification = ({
  getNotifications,
  notificationViewed,
  notificationCleared,
  notifications: { count, data },
  loading,
}) => {
  const history = useNavigate();
  const initialParams = {
    limit: 10,
    page: 1,
  };

  const [listParams, setListParams] = useState(initialParams);

  useEffect(() => {
    let subscribe = true;
    async function asyncCall() {
      if (subscribe) {
        getNotifications(listParams);
      }
    }
    asyncCall();
    return () => {
      subscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let subscribe = true;
    async function asyncCall() {
      if (subscribe) {
        if (listParams.page !== 1 && count !== data.length && !loading) {
          getNotifications(listParams, true);
        }
      }
    }
    asyncCall();
    return () => {
      subscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams]);

  const notificationClick = (
    e,
    notification_id,
    opened,
    route_name,
    variables
  ) => {
    e && e.preventDefault();
    if (!opened)
      notificationViewed(notification_id, initialParams, !!route_name);
    if (!!route_name) {
      switch (route_name) {
        case "agent":
          history(`/admin/${route_name}/${variables.agentId}/view`);
          break;
        default:
          history(`/admin/${route_name}`, {
            state: { variables },
          });
      }
    }
  };

  const resetCount = (e, before_time) => {
    e && e.preventDefault();
    //console.log("clicked");
    notificationCleared(before_time, initialParams);
  };

  const loadMore = () => {
    setListParams((params) => ({
      ...params,
      page: params.page + 1,
    }));
  };

  const handleScroll = (e) => {
    let sub = e.target.scrollHeight - e.target.scrollTop;
    sub = Math.floor(sub);
    const bottom = sub === e.target.clientHeight;
    if (bottom && count !== data.length && !loading) {
      loadMore();
    }
  };

  return (
    <></>
    // <HeaderDropdownNotif
    //   unopened={unopened_notification}
    //   onScroll={handleScroll}
    //   onClick={notificationClick}
    //   resetCount={resetCount}
    //   notifications={data}
    // />
  );
};

Notification.propTypes = {
  loading: PropTypes.bool.isRequired,
  getNotifications: PropTypes.func.isRequired,
  notificationViewed: PropTypes.func.isRequired,
  notificationCleared: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.notification.loadingNotificationList,
  notifications: state.notification.notifications,
  sortingParams: state.notification.sortingParams,
});

export default connect(mapStateToProps, {
  getNotifications,
  notificationViewed,
  notificationCleared,
})(Notification);
