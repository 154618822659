import {
  CMS_CREATED,
  CMS_ERROR,
  DELETE_CMS,
  CMS_UPDATED,
  CMS_LIST_UPDATED,
  GET_CMS_BY_ID,
  LOADING_ON_EMAIL_SUBMIT,
  CMS_SEARCH_PARAMATERS_UPDATE,
  CHANGE_CMS_STATUS,
  LOAD_PAGE,
  LOADING_EMAIL_LIST_DATA,
  RESET,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  cmsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentCMS: [],
  loadingCMSList: true,
  loadingCMS: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
  },
};

export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingCMS: state.loadingCMS,
        loadingCMSList: state.loadingCMSList,
        loadingListData: state.loadingListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingCMS: false,
      };
    case CMS_CREATED:
      return {
        ...state,
        loadingCMS: false,
      };
    case CMS_UPDATED:
      return {
        ...state,
        currentCMS: payload,
        sortingParams: initialState.sortingParams,
        loadingCMS: false,
      };
    case CMS_ERROR:
      return {
        ...state,
        error: payload,
        loadingCMS: false,
        loadingCMSList: false,
        loadingListData: false,
        performDelete: false,
      };
    case DELETE_CMS:
      const currentCount = state.cmsList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.cmsList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        cmsList: {
          data: state.cmsList.data.filter(
            (cms) => cms._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingCMSList: false,
        performDelete: true,
      };
    case GET_CMS_BY_ID:
      return {
        ...state,
        currentCMS: payload,
        loadingCMS: false,
      };
    case CMS_LIST_UPDATED:
      return {
        ...state,
        cmsList: {
          data: payload.data,
          page: payload.page,
          count: payload.total,
        },
        // loadingCMS: true,
        loadingCMSList: false,
        loadingListData: false,
        performDelete: false,
      };
    case CMS_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };

    case CHANGE_CMS_STATUS:
      return {
        ...state,
        cmsList: {
          ...state.cmsList,
          data: state.cmsList.data.map((cms) =>
            cms._id === payload._id
              ? { ...cms, status: payload.status }
              : cms
          ),
        },
      };
    case LOADING_EMAIL_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_ON_EMAIL_SUBMIT:
      return {
        ...state,
        loadingCMS: true,
      };

    default:
      return state;
  }
}
