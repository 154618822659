import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";

import { validateForm } from "utils/misc";
import {
  createMetaData,
  loadMetaData,
  cancelSave,
  setErrors,
  removeErrors,
} from "actions/admin/metadata";

import Errors from "views/components/Notifications/Errors";
import LoadingButton from "views/components/LoadingButton";
import Spinner from "views/components/Spinner";

const CreateMetaData = ({
  createMetaData,
  cancelSave,
  errorList,
  loadMetaData,
  loading,
  setErrors,
  removeErrors,
}) => {
  const history = useNavigate();

  const initialState = {
    page: "",
    title: "",
    description: "",
    keywords: "",
    image: "",
    image_url: "",
  };
  const [formData, setFormData] = React.useState(initialState);

  const { page, title, description, keywords } = formData;
  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  const onChange = (e) => {
    switch (e.target.name) {
      case "image":
        const image_file = e.target.files[0];
        const banner_file_url = URL.createObjectURL(image_file);
        const bannerReader = new FileReader();
        bannerReader.onload = async () => {
          setFormData({
            ...formData,
            image: bannerReader.result,
            image_url: banner_file_url,
          });
        };
        bannerReader.readAsDataURL(image_file);
        break;
      case "page":
        const page = e.target.value.replace(/[^a-z- ]/gi, "");
        setFormData({
          ...formData,
          [e.target.name]: page,
        });
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log({ formData });
    removeErrors();
    const validationParam = [
      {
        param: "page",
        msg: "Page is required.",
      },
      {
        param: "title",
        msg: "Title is required.",
      },
      {
        param: "description",
        msg: "Description is required.",
      },
      {
        param: "keywords",
        msg: "Keywords are required.",
      },
    ];
    if (!validateForm(formData, validationParam, setErrors)) return;

    console.log("submit data", formData);
    createMetaData(formData, history);
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };

  React.useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe) {
        loadMetaData();
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, []);

  return (
    <div className="animated fadeIn">
      <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <h2>Add New Meta Data</h2>
              </CardHeader>
            </Card>
          </Col>
          <Col xs="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="page">
                        Page <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="page"
                        name="page"
                        maxLength="100"
                        value={page}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.page ? true : false}
                      />
                      <Errors current_key="page" key="page" />
                    </FormGroup>
                  </Col>

                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="fullname">
                        Title <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="title"
                        name="title"
                        maxLength="250"
                        value={title}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.title ? true : false}
                      />
                      <Errors current_key="title" key="title" />
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="fullname">
                        Description <span>*</span>
                      </Label>
                      <Input
                        type="textarea"
                        id="description"
                        name="description"
                        maxLength="1000"
                        value={description}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.description ? true : false}
                      />
                      <Errors current_key="description" key="description" />
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="fullname">
                        Keywords <span>*</span> - Comma Separated
                      </Label>
                      <Input
                        type="text"
                        id="keywords"
                        name="keywords"
                        maxLength="1000"
                        value={keywords}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.keywords ? true : false}
                      />
                      <Errors current_key="keywords" key="keywords" />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <Label htmlFor="image">
                        Og Image <span>*</span>
                      </Label>
                      <Input
                        type="file"
                        id="image"
                        name="image"
                        onChange={(e) => onChange(e)}
                        accept="image/*"
                      // invalid={errorList.heading ? true : false}
                      />
                      <Errors current_key="image" key="image" />
                    </FormGroup>
                  </Col>
                  <Col xs={6} className="mt-1">
                    {formData.image_url ? (
                      <img
                        src={formData.image_url}
                        className="preview-img img-fluid"
                        alt="image_file"
                        style={{ height: "40px" }}
                      />
                    ) : null}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Card>
              <CardFooter>
                <Button
                  type="submit"
                  className="m-1"
                  size="sm"
                  color="primary"
                  loading={loading}
                  disabled={loading}
                  style={{ width: "60px" }}
                >
                  <i className="fa fa-dot-circle-o"></i>{" "}
                  {loading ? (
                    <Spinner style={{ fontSize: "20px" }} />
                  ) : (
                    <>Save</>
                  )}
                </Button>
                <Button
                  type="reset"
                  className="m-1"
                  size="sm"
                  color="danger"
                  onClick={onClickHandel}
                  disabled={loading}
                  style={{ width: "70px" }}
                >
                  Cancel
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

CreateMetaData.propTypes = {
  loading: PropTypes.bool.isRequired,
  createMetaData: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.metadata.loadingMetaData,
});
export default connect(mapStateToProps, {
  createMetaData,
  cancelSave,
  loadMetaData,
  setErrors,
  removeErrors,
})(CreateMetaData);
