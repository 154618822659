import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import AppLoading from "views/components/AppLoading";

const PublicRoutes = ({ children, auth: { user, loading }, restricted }) => {
  if (user && restricted) return <Navigate to="/dashboard" />;
  if (loading) return <AppLoading />;
  return children;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PublicRoutes);
