import React from "react";
import { isEmpty } from "lodash";
import { Linux, Windows } from "assets/icons";

const objectError = (data, param, msg, errors) => {
  if (!data.trim().length) {
    return errors.push({
      param,
      msg,
    });
  }
};

const arrayError = (data, param, msg, errors) => {
  if (isEmpty(data)) {
    return errors.push({
      param,
      msg,
    });
  }
};

const numberError = (data, param, msg, errors) => {
  if (isNaN(data) || !data) {
    return errors.push({
      param,
      msg,
    });
  }
  if (data < 0) {
    return errors.push({
      param,
      msg: "Price can't be Less than 0",
    });
  }
};

const condError = (data, param, msg, errors) => {
  if (!data) {
    return errors.push({
      param,
      msg,
    });
  }
};

/**
 * 1) IF form field is an object|array
 *    --- [{
 *          "param":"field_name",
 *          "value":"value that has to be matched from object",
 *          "actualParam":"to represent actual field name"
 *          "type":"object", // array
 *          "msg": "msg that has to be shown"
 *        }]
 * 2) IF form field is string
 *    --- [{
 *          "param":"field_name",
 *          "actualParam":"to represent actual field name"
 *          "msg": "msg that has to be shown"
 *        }]
 * 3) IF form field have specific condition test
 *    --- [{
 *          "param":"field_name",
 *          "cond":anyFunction,
 *          "value":"value to be checked using function",
 *          "msg":"msg to be shown"
 *        }]
 * @param {Object} form name of the package
 * @param {ArrayObject} requireFields type of the script
 */
export const validateForm = (form, requireFields, setErrors) => {
  const errors = [];
  for (let i of requireFields) {
    const { param, msg, value, type, cond, actualParam } = i;
    if (type === "object") {
      objectError(form[param][value], param, msg, errors);
    } else if (type === "array") {
      arrayError(form[param], param, msg, errors);
    } else if (type === "number") {
      numberError(form[param], param, msg, errors);
    } else if (cond) {
      const trimData = typeof value === "string" ? value.trim() : value;
      condError(cond(trimData), actualParam || param, msg, errors);
    } else if (
      !form[param].toString().length ||
      !form[param].toString().trim().length
    ) {
      errors.push({
        param: actualParam || param,
        msg,
      });
    }
  }
  if (errors.length) {
    setErrors(errors);
    return false;
  }
  return !errors.length;
};

export function osIcon(os, style = {}) {
  switch (os?.toLowerCase()) {
    case "linux":
      return <Linux style={{ height: "1rem", ...style }} />;
    case "windows":
      return <Windows style={{ height: "1rem", ...style }} />;
    default:
      return <Linux style={{ height: "1rem", ...style }} />;
  }
}

export function validateIP(ip) {
  const octets = ip.split(".");
  if (isEmpty(octets) || octets.length !== 4) return false;
  let validationPassed = false;
  for (let octet of octets) {
    if (Number.isSafeInteger(octet) && octet >= 0 && octet < 256) {
      validationPassed = true;
    } else {
      validationPassed = false;
      break;
    }
  }
  return validationPassed;
}

export function validateAMI(value) {
  const regex = /^(ami\-)[0-9a-fA-F]+/;
  return regex.test(value);
}

export function validateFileMime(file, mime) {
  return file.type === mime;
}

export function validateFileSize(file, maxSize) {
  return file.size <= maxSize;
}
