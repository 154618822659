import {
  META_DATA_LIST_UPDATED,
  META_DATA_CREATED,
  META_DATA_ERROR,
  META_DATA_UPDATED,
  GET_META_DATA_BY_ID,
  DELETE_META_DATA,
  META_DATA_SEARCH_PARAMETERS_UPDATE,
  LOADING_ON_USER_SUBMIT,
  LOADING_META_DATA_LIST,
  LOAD_META_DATA,
  RESET,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  pageList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentMetaData: null,
  loadingMetaDataList: true,
  loadingMetaData: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: {},
    filters: [],
  },
};

export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingMetaData: state.loadingMetaData,
        loadingMetaDataList: state.loadingMetaDataList,
        loadingListData: state.loadingListData,
      };
    case META_DATA_CREATED:
    case LOAD_META_DATA:
      return {
        ...state,
        loadingMetaData: false,
      };
    case GET_META_DATA_BY_ID:
    case META_DATA_UPDATED:
      return {
        ...state,
        currentMetaData: payload,
        loadingMetaData: false,
      };

    case META_DATA_ERROR:
      return {
        ...state,
        error: payload,
        loadingMetaData: false,
        loadingMetaDataList: false,
      };
    case DELETE_META_DATA:
      const currentCount = state.pageList.count;
      const currentLimit = state.sortingParams.limit;
      const currentMetaData = parseInt(state.pageList.page);
      let remainingMetaData = Math.ceil((currentCount - 1) / currentLimit);
      remainingMetaData = remainingMetaData ? remainingMetaData : 1;
      return {
        ...state,
        pageList: {
          data: state.pageList.data.filter((page) => page._id !== payload),
          count: currentCount - 1,
          page:
            currentMetaData <= remainingMetaData
              ? currentMetaData.toString()
              : remainingMetaData.toString(),
        },
        loadingMetaData: false,
        performDelete: true,
      };
    case META_DATA_LIST_UPDATED:
      return {
        ...state,
        pageList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingMetaDataList: false,
        loadingListData: false,
        performDelete: false,
      };
    case META_DATA_SEARCH_PARAMETERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case LOADING_META_DATA_LIST:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_ON_USER_SUBMIT:
      return {
        ...state,
        loadingMetaData: true,
      };
    default:
      return state;
  }
}
