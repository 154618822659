import {
    NOTIFICATION_ERROR,
    NOTIFICATION_PARAMATERS_UPDATE,
    NOTIFICATION_LIST_UPDATED,
    NOTIFICATION_BOX_OPENED,
    NOTIFICATION_LIST_LOAD_MORE,
    LOADING_ON_NOTIFICATION_LIST,
    ADD_NOTIFICATION,
    RESET_NOTIFICATION,
  } from "actions/types";
  import * as Constants from "constants/index";
  
  const initialState = {
    notifications: {
      page: 1,
      data: [],
      count: 0,
      unopened_notification: 0,
    },
    loadingNotificationList: true,
    error: {},
    sortingParams: {
      limit: Constants.DEFAULT_PAGE_SIZE,
      page: 1,
    },
  };
  
  export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RESET_NOTIFICATION:
        return {
          ...initialState,
          loadingNotificationList: state.loadingNotificationList,
        };
      case NOTIFICATION_BOX_OPENED:
        return {
          ...state,
          notifications: {
            ...state.notifications,
            unopened_notification:
              state.unopened_notification - payload.nModified,
          },
        };
      case ADD_NOTIFICATION:
        const items = state.notifications.data;
  
        items.unshift(payload);
        return {
          ...state,
          notifications: {
            data: items,
            count: state.notifications.count + 1,
            unopened_notification: state.notifications.unopened_notification + 1,
            page: state.notifications.page,
          },
        };
      case NOTIFICATION_ERROR:
        return {
          ...state,
          error: payload,
          loadingNotificationList: false,
        };
      case NOTIFICATION_LIST_UPDATED:
        return {
          ...state,
          notifications: {
            data: payload.data,
            page: payload.metadata[0].current_page,
            count: payload.metadata[0].totalRecord,
            unopened_notification: payload.metadata[0].unopened_notifications,
          },
          loadingNotificationList: false,
        };
      case NOTIFICATION_LIST_LOAD_MORE:
        return {
          ...state,
          notifications: {
            data: [...state.notifications.data, ...payload.data],
            page: payload.metadata[0].current_page,
            count: payload.metadata[0].totalRecord,
            unopened_notification: payload.metadata[0].unopened_notifications,
          },
          loadingNotificationList: false,
        };
      case NOTIFICATION_PARAMATERS_UPDATE:
        return {
          ...state,
          sortingParams: { ...payload },
        };
      case LOADING_ON_NOTIFICATION_LIST:
        return {
          ...state,
          loadingNotificationList: true,
        };
      default:
        return state;
    }
  }