import React from "react";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";

import routes from "AppRoutes/InternalRoutes";

import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";

const DefaultBreadcrumb = () => {
  const currentLocation = useLocation().pathname;

  const getRouteName = (pathname, paths) => {
    const currentRoute = paths.find((route) => matchPath(route.path, pathname));
    return currentRoute?.name;
  };

  const getBreadcrumbs = (location) => {
    const breadcrumbs = [];
    const splitted = location.split("/");
    splitted.reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      if (getRouteName(currentPathname, routes)) {
        breadcrumbs.push({
          pathname: currentPathname,
          name: getRouteName(currentPathname, routes),
          active: index + 1 === array.length ? true : false,
        });
      }
      return currentPathname;
    });
    return breadcrumbs;
  };

  const generated_breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <CBreadcrumb className="m-0 ms-2">
      {/* <CBreadcrumbItem href="/home">Home</CBreadcrumbItem> */}
      {generated_breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem
            {...(breadcrumb.active
              ? { active: true }
              : { href: breadcrumb.pathname })}
            key={index}
          >
            {breadcrumb.name}
          </CBreadcrumbItem>
        );
      })}
    </CBreadcrumb>
  );
};

export default React.memo(DefaultBreadcrumb);
