import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  getCMSList,
  deleteCMS,
  changeStatus,
  resetComponentStore,
} from "actions/admin/cms";
import * as Constants from "constants/index";
import DataTable from "react-data-table-component";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  FormGroup,
  CardHeader,
} from "reactstrap";
import Spinner from "views/components/Spinner";
import styled from "styled-components";
import { FaAngleDown, FaAngleUp, FaPencilAlt, FaTrash } from "react-icons/fa";

const Stack = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.alignItems || "initial"};
  -webkit-box-align: center;
  & > div:not(:first-child) {
    margin: 0px;
    margin-left: ${(props) =>
      props.direction !== "column" ? (props.gap || 1) * 8 + "px" : "0px"};
  }
`;

const CustomStyle = {
  table: {
    style: {
      zIndex: 10,
    },
  },
  headRow: {
    style: {
      height: "60px",
      borderTopWidth: "1px",
      borderTopStyle: "solid",
      borderBottomWidth: "2px",
      fontSize: "15px !important",
      backgroundColor: "#eeefef",
    },
  },
};

const CMSList = ({
  getCMSList,
  deleteCMS,
  history,
  cmsList: { data, count, page },
  sortingParams,
  changeStatus,
  performDelete,
  loading,
  loadingListData,
  resetComponentStore,
}) => {
  const initialSortingParams = {
    limit: 10,
    page: 1,
    orderBy: "updated_at",
    order: "desc",
    query: "",
    filters: [],
  };
  const [cmsParams, setCMSParams] = useState(initialSortingParams);
  const [onlyOnce, setOnce] = useState(true);
  const [filterOpen, setFilterOpen] = useState(true);

  let delayTimer;

  // const headerSortingStyle = { backgroundColor: '#c8e6c9' };
  // Removed and Replaced from sizePerPageRenderer
  // const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
  //   <li
  //     key={text}
  //     role="presentation"
  //     className="dropdown-item"
  //     onMouseDown={e => {
  //       e.preventDefault();
  //       onSizePerPageChange(page);
  //     }}
  //   >
  //     <Button
  //       tabIndex="-1"
  //       role="menuitem"
  //       data-page={page}
  //       className="sizePerPageaTag"
  //     >
  //       {text}
  //     </Button>
  //   </li>
  // );

  const columns = [
    {
      name: "Page Title",
      minWidth: "150px",
      sortable: true,
      selector: (row) => row.title,
    },
    {
      name: "Last Modified Date",
      sortable: true,
      selector: (row) => row.updatedAt,
      // headerSortingStyle,
      format: (cellContent, row) => moment(cellContent.updatedAt).format("lll"),
    },
    {
      name: "Actions",
      selector: (row) => row,
      format: (cellContent, row) => (
        <div>
          <Link
            to={`/dashboard/${cellContent.slug}`}
            title={`Edit ${cellContent.title}`}
          >
            <Button
              type="button"
              style={{ marginLeft: 4, color: "white" }}
              size="sm"
              color="success"
            >
              <FaPencilAlt />
            </Button>
          </Link>
          {cellContent.isDeletable && (
            <Button
              type="button"
              size="sm"
              color="danger"
              style={{ marginLeft: 4, color: "white" }}
              onClick={(e) => {
                if (
                  window.confirm(
                    `Are you sure you want to delete :\r ${cellContent.title}?`
                  )
                ) {
                  deleteCMS(cellContent._id, history);
                }
              }}
            >
              <FaTrash />
            </Button>
          )}
        </div>
      ),
    },
  ];

  const handleSearch = (e, onSearch, searchDelay) => {
    clearTimeout(delayTimer);
    const text = e.target.value;
    delayTimer = setTimeout(() => {
      onSearch(text);
    }, searchDelay);
  };

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <span className="custom-paginator">
      <span>Records per page: </span>
      <UncontrolledDropdown tag="span">
        <DropdownToggle caret className="dropdown-btn-col-w dropdown-p-28">
          {currSizePerPage}
        </DropdownToggle>
        <DropdownMenu>
          {options.map((option) => (
            <DropdownItem
              tag="li"
              key={option.text}
              onMouseDown={(e) => {
                e.preventDefault();
                onSizePerPageChange(option.page);
              }}
            >
              {option.text}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </span>
  );

  const options = {
    page: parseInt(cmsParams.page),
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    sizePerPageRenderer,
    //sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: count,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    const params = {
      page: type === "search" ? 1 : page || 1,
      limit: sizePerPage,
      orderBy: sortField,
      order: sortOrder,
      query: type === "search" ? searchText : cmsParams.query,
    };
    //console.log(params);
    // getCMSList(params);
    setCMSParams((param) => ({ ...param, ...params }));
  };
  useEffect(() => {
    let subscribe = true;
    let updateTimer;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          if (onlyOnce) {
            resetComponentStore();
            setOnce(false);
          }
          getCMSList(cmsParams);
          // updateTimer = setInterval(function() {
          //   getCMSList(cmsParams);
          // }, process.env.REACT_APP_UPDATE_LIST_TIMER);
        }
      } catch (err) {
        // throw err;
      }
    };
    asyncParams();
    return () => {
      subscribe = false;
      clearInterval(updateTimer);
    };
  }, [getCMSList, cmsParams, resetComponentStore, onlyOnce]);

  useEffect(() => {
    /**
     * Due to asynchronous behavior it is possible that we are dealing with
     * Old value using setState({...params, propery:value });
     * So, to eliminate this situation we are doing function update such as below
     */
    let subscribe = true;
    const asyncParams = async () => {
      if (subscribe) {
        performDelete &&
          setCMSParams((params) => ({
            ...params,
            ...sortingParams,
            page: parseInt(page),
          }));
      }
    };
    asyncParams();
    return () => {
      subscribe = false;
    };
  }, [page, performDelete, sortingParams]);

  const onSearch = (text) => {
    handleTableChange("search", {
      searchText: text,
      sizePerPage: cmsParams.limit,
      sortOrder: cmsParams.order,
    });
  };
  const handleSort = (column, sortDirection) => {
    const orderBy = column.field;
    setCMSParams((prevParams) => ({
      ...prevParams,
      orderBy,
      order: sortDirection,
    }));
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setCMSParams((prevParams) => ({
      ...prevParams,
      limit: newPerPage,
      page,
    }));
  };

  const handlePageChange = async (page) => {
    setCMSParams((prevParams) => ({ ...prevParams, page }));
  };
  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Stack
                alignItems="center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <h4>CMS List</h4>
                </div>
              </Stack>
            </CardHeader>
            <CardBody>
              <div className="add-button-div border-bottom">
                <div
                  className="filters-row"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* <div>
                    <h5>Filters</h5>
                  </div>
                  <div onClick={() => setFilterOpen(!filterOpen)}>
                    {filterOpen ? (
                      <FaAngleUp
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <FaAngleDown
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div> */}
                </div>
              </div>
              <DataTable
                columns={columns}
                data={data}
                // subHeader={filterOpen}
                progressPending={loadingListData}
                pagination
                paginationServer
                onSort={handleSort}
                sortServer
                paginationTotalRows={count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={CustomStyle}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

CMSList.propTypes = {
  getCMSList: PropTypes.func.isRequired,
  deleteCMS: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  cmsList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  sortingParams: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  cmsList: state.cms.cmsList,
  loading: state.cms.loadingCMSList,
  sortingParams: state.cms.sortingParams,
  performDelete: state.cms.performDelete,
  loadingListData: state.cms.loadingListData,
});

export default connect(mapStateToProps, {
  getCMSList,
  deleteCMS,
  changeStatus,
  resetComponentStore,
})(CMSList);
