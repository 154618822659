import { SHOW_SIDEBAR, UNFOLD_SIDEBAR } from "actions/types";

// Edit Setting
export const showSidebar = (value) => async (dispatch) => {
  dispatch({ type: SHOW_SIDEBAR, payload: value });
};

export const unfoldSidebar = (value) => async (dispatch) => {
  dispatch({ type: UNFOLD_SIDEBAR, payload: value });
};
