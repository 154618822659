import React from "react";
import { debounce } from "lodash-es";
import { Row, Col } from "reactstrap";

import SearchComponent from "./Search";

const Filters = ({ onSearch }) => {
  const [filterText, setFilterText] = React.useState("");

  const onFilterUpdate = (type, value) => {
    switch (type) {
      case "search":
        debounce(() => {
          onSearch(value);
        }, 500)();
        break;
    }
  };

  const searchFilter = React.useMemo(() => {
    return (
      <SearchComponent
        onFilter={(value) => {
          setFilterText(value);
          onFilterUpdate("search", value);
        }}
        filterText={filterText}
      />
    );
  }, [filterText]);

  return (
    <>
      <Col xs={12} md={3}>
        <Row>{searchFilter}</Row>
      </Col>
    </>
  );
};

export default Filters;
