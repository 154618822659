import {
    THEMECOLOR_ERROR,
    THEMECOLOR_UPDATED,
    LOADING_ON_THEMECOLOR_SUBMIT,
    LOADING_SUBBANNER_DATA,
    GET_SUBBANNER,
    LOAD_THEMECOLOR,
    GET_THEMECOLOR,
    LOADING_THEMECOLOR
} from "actions/types";

const initialState = {
    currentThemeColor: null,
    loadingThemeColor: false,
    error: {},
};

export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case THEMECOLOR_ERROR:
            return {
                ...state,
                error: payload,
                loadingThemeColor: false,
            };
        case GET_SUBBANNER:
            return {
                ...state,
                currentThemeColor: payload,
                loadingThemeColor: false,
            };
        case LOADING_THEMECOLOR:
            return {
                ...state,
                loadingThemeColor: true,
            };
        case LOAD_THEMECOLOR:
            return {
                ...state,
                loadingThemeColor: false,
            };
        case LOADING_ON_THEMECOLOR_SUBMIT:
            return {
                ...state,
                loadingThemeColor: true,
            };
        case GET_THEMECOLOR:
        case THEMECOLOR_UPDATED:
            return {
                ...state,
                currentThemeColor: payload,
                loadingThemeColor: false,
            }
        default:
            return state;
    }
}
