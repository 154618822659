import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { editEmail, setErrors, removeErrors } from "actions/admin/profile";
import Errors from "views/components/Notifications/Errors";
import Spinner from "views/components/Spinner";
import { validateForm } from "utils/misc";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupText,
  FormGroup,
} from "reactstrap";
import { FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Password from "views/components/Password";

const EditEmail = ({
  currentEmail,
  onClosed,
  editEmail,
  errorList,
  doOpen,
  loadingEmail,
  setErrors,
  removeErrors,
}) => {
  const initialData = {
    email: "",
    password: "",
  };

  const [formData, setFormData] = useState(initialData);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const navigate = useNavigate();

  const reset = () => {
    setModal(false);
    setFormData(initialData);
    onClosed();
  };

  useEffect(() => {
    setModal(doOpen);
  }, [doOpen]);

  const { password, email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const saveEmail = (e) => {
    e.preventDefault();
    removeErrors();
    if (
      !validateForm(
        formData,
        [
          {
            param: "password",
            msg: "Please provide the new password.",
          },
          {
            param: "email",
            msg: "Please provide the valid email id.",
          },
          {
            param: "email",
            cond: (input) => currentEmail !== input,
            msg: "New email should be different from the previous one.",
          },
        ],
        setErrors
      )
    )
      return;
    editEmail(formData, navigate).then((res) => {
      if (!res || !res.status) {
        // -- TODO --
        // -- PERFORM ON EDIT ERROR RELATED TASKS HERE
      } else {
        // -- IF NO ERROR RESET MODAL BOX
        reset();
      }
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} onClosed={reset}>
      <ModalHeader toggle={toggle}>
        <div>Change Email</div>
        <div>
          <small>{currentEmail}</small>
        </div>
      </ModalHeader>
      <ModalBody>
        <FormGroup className="mb-3">
          <Password
            placeholder="Current Password*"
            autoComplete="password"
            name="password"
            id="password"
            minLength="8"
            value={password}
            onChange={(e) => onChange(e)}
            invalid={errorList.password ? true : false}
          />
          <Errors current_key="password" key="password" />
        </FormGroup>
        <InputGroup className="mb-3">
          <InputGroupText>
            <FaEnvelope />
          </InputGroupText>
          <Input
            type="email"
            placeholder="New Email*"
            autoComplete="email"
            name="email"
            id="new_email"
            minLength="64"
            value={email}
            onChange={(e) => onChange(e)}
            invalid={errorList.email ? true : false}
          />
        </InputGroup>
        <Errors current_key="email" key="email" />
      </ModalBody>
      <ModalFooter>
        {loadingEmail ? (
          <Spinner />
        ) : (
          <>
            <a onClick={saveEmail} className="m-1" href="#!">
              <Button color="primary">Save</Button>
            </a>
            <Button color="secondary" className="m-1" onClick={toggle}>
              Close
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

EditEmail.propTypes = {
  editEmail: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loadingEmail: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  loadingEmail: state.profile.loadingEmail,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  editEmail,
  setErrors,
  removeErrors,
})(EditEmail);
