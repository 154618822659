import axios from "actions/axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";

import {
  META_DATA_LIST_UPDATED,
  META_DATA_CREATED,
  META_DATA_ERROR,
  META_DATA_UPDATED,
  GET_META_DATA_BY_ID,
  DELETE_META_DATA,
  META_DATA_SEARCH_PARAMETERS_UPDATE,
  REMOVE_ERRORS,
  LOADING_ON_USER_SUBMIT,
  LOADING_META_DATA_LIST,
  LOAD_META_DATA,
  RESET,
} from "actions/types";

import { logout } from "actions/auth";

export const getMetaDataList = (params) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: LOADING_META_DATA_LIST });
    const query = params.query ? params.query : "";
    params.query = query;
    config.params = params;
    const res = await axios.get("/admin/metadata", config);
    dispatch({
      type: META_DATA_SEARCH_PARAMETERS_UPDATE,
      payload: params,
    });
    dispatch({
      type: META_DATA_LIST_UPDATED,
      payload: res.data.response[0],
    });
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: META_DATA_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const createMetaData = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnUserSubmit());
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };

  try {
    const res = await axios.post("/admin/metadata", formData, config);
    if (res.data.status === true) {
      dispatch({
        type: META_DATA_CREATED,
        payload: res.data.response,
      });
      dispatch(setAlert("Meta Data Created", "success"));
      history("/dashboard/metadata");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: META_DATA_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.trace(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: META_DATA_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const getMetaDataByID = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnUserSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/admin/metadata/${id}`, config);
    if (res.data.status) {
      dispatch({
        type: GET_META_DATA_BY_ID,
        payload: res.data.response,
      });
      return res.data ? res.data.response : { status: false };
    } else {
      dispatch({ type: META_DATA_ERROR });
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.trace(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: META_DATA_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const editMetaData = (formData, history, id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnUserSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put(`/admin/metadata/${id}`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: META_DATA_UPDATED,
        payload: res.data.response,
      });
      dispatch(setAlert("Meta Data Updated", "success"));
      history("/dashboard/metadata");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: META_DATA_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.trace(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: META_DATA_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const deleteMetaData = (id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.delete(`/admin/metadata/${id}`, config);
    if (res.data.status === true) {
      dispatch({
        type: DELETE_META_DATA,
        payload: id,
      });
      dispatch(setAlert("Meta Data Deleted", "success"));
    } else {
      dispatch({ type: META_DATA_ERROR });
      dispatch(setAlert(res.data.message, "danger"));
    }
  } catch (err) {
    console.trace(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: META_DATA_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const cancelSave = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  history("/dashboard/metadata");
};

export const notFound = (history) => async (dispatch) => {
  history.push("/dashboard/page-not-found");
};

export const loadingOnUserSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_USER_SUBMIT });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: META_DATA_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const loadMetaData = () => async (dispatch) => {
  await dispatch({ type: LOAD_META_DATA });
};

export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};
