import axios from "actions/axios";

const setAuthToken = (token) => {
  return new Promise((resolve) => {
    if (token) {
      axios.defaults.headers.common["x-auth-token"] = token;
      resolve(true);
    } else {
      delete axios.defaults.headers.common["x-auth-token"];
      resolve(true);
    }
  });
};

export default setAuthToken;
