import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Container, Row, Alert } from "reactstrap";

import { getEmailVerifyTokenStatus, tokenInvalid } from "actions/auth";
import Spinner from "views/components/Spinner";

const VerifyEmail = ({ getEmailVerifyTokenStatus, loading }) => {
  const [successful, setSuccessful] = useState(false);
  const history = useNavigate();
  const params = useParams();

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      subscribe &&
        getEmailVerifyTokenStatus(params.token).then((data) => {
          if (subscribe && data) return setSuccessful(true);
          setSuccessful(false);
        });
    };
    asyncCall();

    return () => {
      subscribe = false;
    };
  }, [params.token, history]);

  const generateAlert = (value) => {
    return value ? (
      <Alert color="success">
        Email is verified successfully. Click here to{" "}
        <a href="/" title="login">
          Login
        </a>
      </Alert>
    ) : (
      <Alert color="danger">This link is not valid.</Alert>
    );
  };

  const renderAlert = React.useMemo(
    () => generateAlert(successful),
    [successful]
  );

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <Container>
        <Row className="justify-content-center text-center">
          <Col md={6}>{loading ? <Spinner /> : renderAlert}</Col>
        </Row>
      </Container>
    </div>
  );
};

VerifyEmail.propTypes = {
  getEmailVerifyTokenStatus: PropTypes.func.isRequired,
  tokenInvalid: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.verification.loadingEmailVerification,
});

export default connect(mapStateToProps, {
  getEmailVerifyTokenStatus,
  tokenInvalid,
})(VerifyEmail);
