import {
    PACKAGE_CREATED,
    PACKAGE_ERROR,
    DELETE_PACKAGE,
    PACKAGE_UPDATED,
    PACKAGE_LIST_UPDATED,
    GET_PACKAGE_BY_ID,
    LOADING_ON_PACKAGE_SUBMIT,
    PACKAGE_SEARCH_PARAMATERS_UPDATE,
    CHANGE_PACKAGE_STATUS,
    LOAD_PAGE,
    LOADING_PACKAGE_LIST_DATA,
    RESET,
  } from "actions/types";
  import * as Constants from "constants/index";
  
  const initialState = {
    packageList: {
      page: 1,
      data: [],
      count: 0,
    },
    currentPackage: null,
    loadingPackageList: true,
    loadingPackage: true,
    loadingListData: true,
    performDelete: false,
    error: {},
    sortingParams: {
      limit: Constants.DEFAULT_PAGE_SIZE,
      page: 1,
      orderBy: "created_at",
      ascending: "desc",
      query: "",
    },
  };
  
  export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RESET:
        return {
          ...initialState,
          loadingPackage: state.loadingPackage,
          loadingPackageList: state.loadingPackageList,
          loadingListData: state.loadingListData,
        };
      case LOAD_PAGE:
        return {
          ...state,
          loadingPackage: false,
        };
      case PACKAGE_CREATED:
        return {
          ...state,
          loadingPackage: false,
        };
      case PACKAGE_UPDATED:
        return {
          ...state,
          currentPackage: payload,
          sortingParams: initialState.sortingParams,
          loadingPackage: false,
        };
      case PACKAGE_ERROR:
        return {
          ...state,
          error: payload,
          loadingPackage: false,
          loadingPackageList: false,
          loadingListData: false,
          performDelete: false,
        };
      case DELETE_PACKAGE:
        const currentCount = state.packageList.count;
        const currentLimit = state.sortingParams.limit;
        const currentPage = parseInt(state.packageList.page);
        let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
        remainingPages = remainingPages ? remainingPages : 1;
        return {
          ...state,
          packageList: {
            data: state.packageList.data.filter(
              (package1) => package1._id !== payload
            ),
            count: currentCount - 1,
            page:
              currentPage <= remainingPages
                ? currentPage.toString()
                : remainingPages.toString(),
          },
          loadingPackageList: false,
          performDelete: true,
        };
      case GET_PACKAGE_BY_ID:
        return {
          ...state,
          currentPackage: payload,
          loadingPackage: false,
        };
      case PACKAGE_LIST_UPDATED:
        return {
          ...state,
          packageList: {
            data: payload.data,
            page: payload.metadata[0].current_page,
            count: payload.metadata[0].totalRecord,
          },
          loadingPackageList: false,
          loadingListData: false,
          performDelete: false,
        };
      case PACKAGE_SEARCH_PARAMATERS_UPDATE:
        return {
          ...state,
          sortingParams: { ...payload },
        };
  
      case CHANGE_PACKAGE_STATUS:
        return {
          ...state,
          packageList: {
            ...state.packageList,
            data: state.packageList.data.map((package1) =>
              package1._id === payload._id
                ? { ...package1, status: payload.status }
                : package1
            ),
          },
        };
      case LOADING_PACKAGE_LIST_DATA:
        return {
          ...state,
          loadingListData: true,
        };
      case LOADING_ON_PACKAGE_SUBMIT:
        return {
          ...state,
          loadingPackage: true,
        };
  
      default:
        return state;
    }
  }
  