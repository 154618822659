//alert constants
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//reset constant
export const RESET = "RESET";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";

//loading constant
export const LOAD_PAGE = "LOAD_PAGE";
export const LOAD_AUTH_PAGE = "LOAD_AUTH_PAGE";
export const INITIAL_LOADING = "INITIAL_LOADING";
export const LOADING_ON_SUBMIT = "LOADING_ON_SUBMIT";
export const LOADING_ON_EMAIL_SUBMIT = "LOADING_ON_EMAIL_SUBMIT";
export const LOADING_ON_SETTINGS_SUBMIT = "LOADING_ON_SETTINGS_SUBMIT";
export const LOADING_ON_PROFILE_SUBMIT = "LOADING_ON_PROFILE_SUBMIT";
export const LOADING_ON_CHANGING_PASSWORD = "LOADING_ON_CHANGING_PASSWORD";
export const LOADING_ON_LOGIN_SUBMIT = "LOADING_ON_LOGIN_SUBMIT";
export const LOADING_ON_DASHBOARD_SUBMIT = "LOADING_ON_DASHBOARD_SUBMIT";
export const LOADING_ON_EMAIL_VERIFICATION = "LOADING_ON_EMAIL_VERIFICATION";
export const LOADING_ON_CMS_SUBMIT = "LOADING_ON_CMS_SUBMIT";
export const LOADING_ON_USER_SUBMIT = "LOADING_ON_USER_SUBMIT";

export const LOADING_EMAIL_LIST_DATA = "LOADING_EMAIL_LIST_DATA";
export const LOADING_CMS_LIST_DATA = "LOADING_CMS_LIST_DATA";
export const LOADING_MACHINE_LIST_DATA = "LOADING_MACHINE_LIST_DATA";

export const LOADING_ON_PASSWORD_RESET = "LOADING_ON_PASSWORD_RESET";

export const LOADING_ON_NOTIFICATION_LIST = "LOADING_ON_NOTIFICATION_LIST";
export const LOADING_ON_UPDATE_MOBILE = "LOADING_ON_UPDATE_MOBILE";
export const LOADING_ON_CHANGING_EMAIL = "LOADING_ON_CHANGING_EMAIL";
export const LOADING_ON_MACHINE_SUBMIT = "LOADING_ON_MACHINE_SUBMIT";
export const LOADING_ON_TAG_SUBMIT = "LOADING_ON_TAG_SUBMIT";

export const LOADING_OS_LIST_DATA = "LOADING_OS_LIST_DATA";
export const LOADING_USER_SEARCH_ACTION_LIST_DATA =
  "LOADING_USER_SEARCH_ACTION_LIST_DATA";
export const LOADING_TAG_LIST_DATA = "LOADING_TAG_LIST_DATA";
export const LOADING_MACHINE_SEARCH_ACTION_LIST_DATA =
  "LOADING_MACHINE_SEARCH_ACTION_LIST_DATA";

//errors constnats
export const SET_ERRORS_LIST = "SET_ERRORS_LIST";
export const REMOVE_ERRORS = "REMOVE_ERRORS";

//CMS Constants
export const CMS_CREATED = "CMS_CREATED";
export const CMS_ERROR = "CMS_ERROR";
export const DELETE_CMS = "DELETE_CMS";
export const CMS_UPDATED = "CMS_UPDATED";
export const CMS_LIST_UPDATED = "CMS_LIST_UPDATED";
export const GET_CMS_BY_ID = "GET_CMS_BY_ID";
export const CHANGE_CMS_STATUS = "CHANGE_CMS_STATUS";
export const CMS_SEARCH_PARAMATERS_UPDATE = "CMS_SEARCH_PARAMATERS_UPDATE";

//BLOG Constants
export const BLOG_CREATED = "BLOG_CREATED";
export const BLOG_ERROR = "BLOG_ERROR";
export const DELETE_BLOG = "DELETE_BLOG";
export const BLOG_UPDATED = "BLOG_UPDATED";
export const BLOG_LIST_UPDATED = "BLOG_LIST_UPDATED";
export const GET_BLOG_BY_ID = "GET_BLOG_BY_ID";
export const CHANGE_BLOG_STATUS = "CHANGE_BLOG_STATUS";
export const BLOG_SEARCH_PARAMATERS_UPDATE = "BLOG_SEARCH_PARAMATERS_UPDATE";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const ACCOUNT_DELETED = "ACCOUNT_DELETED";

export const USER_LOADED = "USER_LOADED";
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";
export const AUTH_TOKEN_REFRESH = "AUTH_TOKEN_REFRESH";

export const GET_PROFILE = "GET_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const AUTH_ERROR = "AUTH_ERROR";
export const PENDING_ACTION_LOADED = "PENDING_ACTION_LOADED";

// Company Constants
export const COMPANY_ERROR = "COMPANY_ERROR";
export const COMPANY_UPDATED = "COMPANY_UPDATED";
export const GET_COMPANY = "GET_COMPANY";
export const LOADING_ON_COMPANY_SUBMIT = "LOADING_ON_COMPANY_SUBMIT";

// Notification Constants
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const NOTIFICATION_PARAMATERS_UPDATE = "NOTIFICATION_PARAMATERS_UPDATE";
export const NOTIFICATION_LIST_UPDATED = "NOTIFICATION_LIST_UPDATED";
export const NOTIFICATION_VIEWED = "NOTIFICATION_VIEWED";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const NOTIFICATION_LIST_LOAD_MORE = "NOTIFICATION_LIST_LOAD_MORE";
export const NOTIFICATION_BOX_OPENED = "NOTIFICATION_BOX_OPENED";

// Dashboard Constants
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";
export const DASHBOARD_LIST_UPDATED = "DASHBOARD_LIST_UPDATED";

// Verification Constants
export const VERIFICATION_LINK_SUCCESS = "VERIFICATION_LINK_SUCCESS";
export const VERIFICATION_LINK_FAIL = "VERIFICATION_LINK_FAIL";

//Users constants
export const USER_CREATED = "USER_CREATED";
export const USER_ERROR = "USER_CREATE_ERROR";
export const DELETE_USER = "DELETE_USER";
export const USER_UPDATED = "USER_UPDATED";
export const USER_LIST_UPDATED = "USER_LIST_UPDATED";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const USER_SEARCH_PARAMATERS_UPDATE = "USER_SEARCH_PARAMATERS_UPDATE";
export const USER_PROFILE_PASSWORD_ERROR = "USER_PROFILE_PASSWORD_ERROR";
export const USER_PROFILE_PASSWORD_UPDATED = "USER_PROFILE_PASSWORD_UPDATED";
export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";
export const USER_PROFILE_EMAIL_UPDATED = "USER_PROFILE_EMAIL_UPDATED";

//Email Template Constants
export const EMAIL_TEMPLATE_CREATED = "EMAIL_TEMPLATE_CREATED";
export const EMAIL_TEMPLATE_ERROR = "EMAIL_TEMPLATE_ERROR";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const EMAIL_TEMPLATE_UPDATED = "EMAIL_TEMPLATE_UPDATED";
export const EMAIL_TEMPLATE_LIST_UPDATED = "EMAIL_TEMPLATE_LIST_UPDATED";
export const GET_EMAIL_TEMPLATE_BY_ID = "GET_EMAIL_TEMPLATE_BY_ID";
export const CHANGE_EMAIL_TEMPLATE_STATUS = "CHANGE_EMAIL_TEMPLATE_STATUS";
export const EMAIL_TEMPLATE_SEARCH_PARAMATERS_UPDATE =
  "EMAIL_TEMPLATE_SEARCH_PARAMATERS_UPDATE";

//Machine Constants
export const MACHINE_CREATED = "MACHINE_CREATED";
export const MACHINE_ERROR = "MACHINE_ERROR";
export const DELETE_MACHINE = "DELETE_MACHINE";
export const MACHINE_UPDATED = "MACHINE_UPDATED";
export const MACHINE_LIST_UPDATED = "MACHINE_LIST_UPDATED";
export const GET_MACHINE_BY_ID = "GET_MACHINE_BY_ID";
export const CHANGE_MACHINE_STATUS = "CHANGE_MACHINE_STATUS";
export const MACHINE_SEARCH_PARAMATERS_UPDATE =
  "MACHINE_SEARCH_PARAMATERS_UPDATE";
export const REMOVE_MACHINE_AMI = "REMOVE_MACHINE_AMI";

// Setting Constants
export const SETTING_ERROR = "SETTING_ERROR";
export const SETTING_UPDATED = "SETTING_UPDATED";
export const GET_SETTING = "GET_SETTING";
export const LOADING_SETTING_DATA = "LOADING_SETTING_DATA";

// Profile Constants
export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";
export const USER_PROFILE_UPDATED = "USER_PROFILE_UPDATED";
export const ADMIN_STAFF_ADDRESS = "ADMIN_STAFF_ADDRESS";
export const ADMIN_STAFF_CITY = "ADMIN_STAFF_CITY";
export const INITIAL_MODAL_STATE = "INITIAL_MODAL_STATE";
export const UPDATE_MOBILE = "UPDATE_MOBILE";

// Forgot Password Constants
export const RESET_LINK_SUCCESS = "RESET_LINK_SUCCESS";
export const RESET_LINK_FAIL = "RESET_LINK_FAIL";

// Verify Email Constants
export const EMAIL_LINK_SUCCESS = "EMAIL_LINK_SUCCESS";
export const EMAIL_LINK_FAIL = "EMAIL_LINK_FAIL";

//Admin Sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const UNFOLD_SIDEBAR = "UNFOLD_SIDEBAR";

// Notification Constants
export const ACTION_ERROR = "ACTION_ERROR";
export const ACTION_PARAMATERS_UPDATE = "ACTION_PARAMATERS_UPDATE";
export const ACTION_LIST_UPDATED = "ACTION_LIST_UPDATED";
export const MODULE_ACTION_LIST_UPDATED = "MODULE_ACTION_LIST_UPDATED";
export const RESET_ACTION = "RESET_ACTION";
export const ADD_ACTION = "ADD_ACTION";
export const REOMVE_ACTION_FROM_ORDER = "REOMVE_ACTION_FROM_ORDER";
export const ACTION_LIST_LOAD_MORE = "ACTION_LIST_LOAD_MORE";
export const LOADING_ON_ACTION_LIST = "LOADING_ON_ACTION_LIST";

// OS Constants
export const OS_ERROR = "OS_ERROR";
export const OS_LIST_UPDATED = "OS_LIST_UPDATED";
export const OS_SEARCH_PARAMATERS_UPDATE = "OS_SEARCH_PARAMATERS_UPDATE";
export const RESET_OS = "RESET_OS";

//User Search Constants
export const USER_SEARCH_ACTION_ERROR = "USER_SEARCH_ACTION_ERROR";
export const USER_SEARCH_ACTION_LIST_UPDATED =
  "USER_SEARCH_ACTION_LIST_UPDATED";
export const USER_SEARCH_ACTION_SEARCH_PARAMATERS_UPDATE =
  "USER_SEARCH_ACTION_SEARCH_PARAMATERS_UPDATE";
export const RESET_USER_SEARCH_ACTION_SEARCH =
  "RESET_USER_SEARCH_ACTION_SEARCH";

//Tag Constants
export const TAG_ERROR = "TAG_ERROR";
export const TAG_LIST_UPDATED = "TAG_LIST_UPDATED";
export const TAG_SEARCH_PARAMATERS_UPDATE = "TAG_SEARCH_PARAMATERS_UPDATE";
export const RESET_TAG_SEARCH = "RESET_TAG_SEARCH";
export const TAG_CREATED = "TAG_CREATED";

//Machine Search Constants
export const MACHINE_SEARCH_ACTION_ERROR = "MACHINE_SEARCH_ACTION_ERROR";
export const MACHINE_SEARCH_ACTION_LIST_UPDATED =
  "MACHINE_SEARCH_ACTION_LIST_UPDATED";
export const MACHINE_SEARCH_ACTION_SEARCH_PARAMATERS_UPDATE =
  "MACHINE_SEARCH_ACTION_SEARCH_PARAMATERS_UPDATE";
export const RESET_MACHINE_SEARCH_ACTION_SEARCH =
  "RESET_MACHINE_SEARCH_ACTION_SEARCH";

// Challenges

export const SERVICE_CATEGORY_CREATED = "SERVICE_CATEGORY_CREATED";
export const SERVICE_CATEGORY_ERROR = "SERVICE_CATEGORY_ERROR";
export const SERVICE_CATEGORY_UPDATED = "SERVICE_CATEGORY_UPDATED";
export const SERVICE_CATEGORY_LIST_UPDATED = "SERVICE_CATEGORY_LIST_UPDATED";
export const GET_SERVICE_CATEGORY_BY_ID = "GET_SERVICE_CATEGORY_BY_ID";
export const SERVICE_CATEGORY_SEARCH_PARAMATERS_UPDATE =
  "SERVICE_CATEGORY_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_SERVICE_CATEGORY_STATUS = "CHANGE_SERVICE_CATEGORY_STATUS";
export const LOADING_ON_SERVICE_CATEGORY_SUBMIT =
  "LOADING_ON_SERVICE_CATEGORY_SUBMIT";
export const LOADING_CATEGORY_LIST_DATA = "LOADING_CATEGORY_LIST_DATA";

// bug report types

export const BUGREPORT_CATEGORY_ERROR = "BUGREPORT_CATEGORY_ERROR";
export const LOADING_BUGREPORT_LIST_DATA = "LOADING_BUGREPORT_LIST_DATA";
export const BUGREPORT_SEARCH_PARAMATERS_UPDATE =
  "BUGREPORT_SEARCH_PARAMATERS_UPDATE";
export const BUGREPORT_LIST_UPDATED = "BUGREPORT_LIST_UPDATED";
export const BUGREPORT_ERROR = "BUGREPORT_ERROR";
export const CHANGE_BUGREPORT_STATUS = "CHANGE_BUGREPORT_STATUS";

// user management types

export const USERS_LIST_LOADING = "USERS_LIST_LOADING";
export const USERS_LIST_LOADED = "USERS_LIST_LOADED";
export const USERS_LIST_LOADING_ERROR = "USERS_LIST_ERROR";
export const USERS_SEARCH_PARAMETERS_UPDATE = "USERS_SEARCH_PARAMETERS_UPDATE";
export const CHANGE_USER_STATUS_ERROR = "CHANGE_USER_STATUS_ERROR";
export const USER_SERVICES_LOADING = "USER_SERVICES_LOADING";
export const USER_SERVICES_LOADED = "USER_SERVICES_LOADED";
export const USER_SERVICES_LOADING_ERROR = "USER_SERVICES_LOADING_ERROR";
export const USER_MACHINES_LOADING = "USER_MACHINES_LOADING";
export const USER_MACHINES_LOADED = "USER_MACHINES_LOADED";
export const USER_MACHINES_LOADING_ERROR = "USER_MACHINES_LOADING_ERROR";

// team management type

export const TEAMS_LIST_LOADING = "TEAMS_LIST_LOADING";
export const TEAMS_LIST_LOADED = "TEAMS_LIST_LOADED";
export const TEAMS_LIST_LOADING_ERROR = "TEAMS_LIST_LOADING_ERROR";
export const TEAMS_SEARCH_PARAMETERS_UPDATE = "TEAMS_SEARCH_PARAMETERS_UPDATE";
export const CHANGE_TEAM_STATUS = "CHANGE_TEAM_STATUS";
export const CHANGE_TEAM_STATUS_ERROR = "CHANGE_TEAM_STATUS_ERROR";
export const TEAM_MEMBERS_LOADING = "TEAM_MEMBERS_LOADING";
export const TEAM_MEMBERS_LOADED = "TEAM_MEMBERS_LOADED";
export const TEAM_MEMBERS_LOADING_ERROR = "TEAM_MEMBERS_LOADING_ERROR";

//machine requests

export const MACHINE_REQUESTS_LOADING = "MACHINE_REQUESTS_LOADING";
export const MACHINE_REQUESTS_LOADED = "MACHINE_REQUESTS_LOADED";
export const MACHINE_REQUESTS_LOADING_ERROR = "MACHINE_REQUESTS_LOADING_ERROR";
export const MACHINE_REQUESTS_SEARCH_PARAMETERS_UPDATE =
  "MACHINE_REQUESTS_SEARCH_PARAMETERS_UPDATE";
export const CHANGE_MACHINE_REQUEST_STATUS = "CHANGE_MACHINE_REQUEST_STATUS";
export const CHANGE_MACHINE_REQUEST_STATUS_ERROR =
  "CHANGE_MACHINE_REQUEST_STATUS_ERROR";
export const MACHINE_REQUEST_LOADING = "MACHINE_REQUEST_LOADING";
export const MACHINE_REQUEST_LOADED = "MACHINE_REQUEST_LOADED";
export const MACHINE_REQUEST_LOADING_ERROR = "MACHINE_REQUEST_LOADING_ERROR";

// banner types

export const BANNER_CREATED = "BANNER_CREATED";
export const BANNER_ERROR = "BANNER_ERROR";
export const DELETE_BANNER = "DELETE_BANNER";
export const BANNER_UPDATED = "BANNER_UPDATED";
export const BANNER_LIST_UPDATED = "BANNER_LIST_UPDATED";
export const GET_BANNER_BY_ID = "GET_BANNER_BY_ID";
export const BANNER_SEARCH_PARAMATERS_UPDATE =
  "BANNER_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_BANNER_STATUS = "CHANGE_BANNER_STATUS";
export const LOADING_ON_BANNER_SUBMIT = "LOADING_ON_BANNER_SUBMIT";
export const LOADING_BANNER_LIST_DATA = "LOADING_BANNER_LIST_DATA";

// Quote constants
export const QUOTE_REQUEST_ERROR = "QUOTE_REQUEST_ERROR";
export const QUOTE_REQUEST_LIST_UPDATED = "QUOTE_REQUEST_LIST_UPDATED";
export const QUOTE_REQUEST_SEARCH_PARAMATERS_UPDATE =
  "QUOTE_REQUEST_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_QUOTE_REQUEST_STATUS = "CHANGE_QUOTE_REQUEST_STATUS";
export const LOADING_QUOTE_LIST = "LOADING_QUOTE_LIST";

// contact

export const CONTACT_ERROR = "CONTACT_ERROR";
export const CONTACT_LIST_UPDATED = "CONTACT_LIST_UPDATED";
export const CONTACT_UPDATED = "CONTACT_UPDATED";
export const CONTACT_SEARCH_PARAMATERS_UPDATE =
  "CONTACT_SEARCH_PARAMATERS_UPDATE";
export const LOADING_ON_CONTACT_SUBMIT = "LOADING_ON_CONTACT_SUBMIT";
export const LOADING_CONTACT_LIST_DATA = "LOADING_CONTACT_LIST_DATA";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const GET_CONTACT = "GET_CONTACT";
export const LOAD_CONTACT = "LOAD_CONTACT";
export const LOADING_CONTACT = "LOADING_CONTACT";

// location

export const LOCATION_CREATED = "LOCATION_CREATED";
export const LOCATION_ERROR = "LOCATION_ERROR";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const LOCATION_UPDATED = "LOCATION_UPDATED";
export const LOCATION_LIST_UPDATED = "LOCATION_LIST_UPDATED";
export const GET_LOCATION_BY_ID = "GET_LOCATION_BY_ID";
export const LOCATION_SEARCH_PARAMATERS_UPDATE =
  "LOCATION_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_LOCATION_STATUS = "CHANGE_LOCATION_STATUS";
export const LOADING_ON_LOCATION_SUBMIT = "LOADING_ON_LOCATION_SUBMIT";
export const LOADING_LOCATION_LIST_DATA = "LOADING_LOCATION_LIST_DATA";

// why choose wll2floor

export const CARD_CREATED = "CARD_CREATED";
export const CARD_ERROR = "CARD_ERROR";
export const DELETE_CARD = "DELETE_CARD";
export const CARD_UPDATED = "CARD_UPDATED";
export const CARD_LIST_UPDATED = "CARD_LIST_UPDATED";
export const GET_CARD_BY_ID = "GET_CARD_BY_ID";
export const CARD_SEARCH_PARAMETERS_UPDATE = "CARD_SEARCH_PARAMETERS_UPDATE";
export const LOADING_ON_CARD_SUBMIT = "LOADING_ON_CARD_SUBMIT";
export const LOADING_CARD_LIST_DATA = "LOADING_CARD_LIST_DATA";

// service
export const SERVICE_CREATED = "SERVICE_CREATED";
export const SERVICE_ERROR = "SERVICE_ERROR";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const SERVICE_UPDATED = "SERVICE_UPDATED";
export const SERVICE_LIST_UPDATED = "SERVICE_LIST_UPDATED";
export const GET_SERVICE_BY_ID = "GET_SERVICE_BY_ID";
export const SERVICE_SEARCH_PARAMATERS_UPDATE =
  "SERVICE_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_SERVICE_STATUS = "CHANGE_SERVICE_STATUS";
export const LOADING_ON_SERVICE_SUBMIT = "LOADING_ON_SERVICE_SUBMIT";
export const LOADING_SERVICE_LIST_DATA = "LOADING_SERVICE_LIST_DATA";

//about us constants
export const ABOUTUS_ERROR = "ABOUTUS_ERROR";
export const ABOUTUS_UPDATED = "ABOUTUS_UPDATED";
export const GET_ABOUTUS = "GET_ABOUTUS";
export const LOADING_ON_ABOUTUS_SUBMIT = "LOADING_ON_ABOUTUS_SUBMIT";
export const LOADING_ABOUTUS_DATA = "LOADING_ABOUTUS_DATA";

//sub banner constants
export const SUBBANNER_ERROR = "SUBBANNER_ERROR";
export const SUBBANNER_UPDATED = "SUBBANNER_UPDATED";
export const LOADING_ON_SUBBANNER_SUBMIT = "LOADING_ON_SUBBANNER_SUBMIT";
export const LOADING_SUBBANNER_DATA = "LOADING_SUBBANNER_DATA";
export const GET_SUBBANNER = "GET_SUBBANNER";

// Package types

export const PACKAGE_CREATED = "PACKAGE_CREATED";
export const PACKAGE_ERROR = "PACKAGE_ERROR";
export const DELETE_PACKAGE = "DELETE_PACKAGE";
export const PACKAGE_UPDATED = "PACKAGE_UPDATED";
export const PACKAGE_LIST_UPDATED = "PACKAGE_LIST_UPDATED";
export const GET_PACKAGE_BY_ID = "GET_PACKAGE_BY_ID";
export const PACKAGE_SEARCH_PARAMATERS_UPDATE =
  "PACKAGE_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_PACKAGE_STATUS = "CHANGE_PACKAGE_STATUS";
export const LOADING_ON_PACKAGE_SUBMIT = "LOADING_ON_PACKAGE_SUBMIT";
export const LOADING_PACKAGE_LIST_DATA = "LOADING_PACKAGE_LIST_DATA";

//meta data types

export const META_DATA_LIST_UPDATED = "META_DATA_LIST_UPDATED";
export const META_DATA_CREATED = "META_DATA_CREATED";
export const META_DATA_ERROR = "META_DATA_ERROR";
export const META_DATA_UPDATED = "META_DATA_UPDATED";
export const GET_META_DATA_BY_ID = "GET_META_DATA_BY_ID";
export const DELETE_META_DATA = "DELETE_META_DATA";
export const META_DATA_SEARCH_PARAMETERS_UPDATE =
  "META_DATA_SEARCH_PARAMETERS_UPDATE";
export const LOADING_META_DATA_LIST = "LOADING_META_DATA_LIST";
export const LOAD_META_DATA = "LOAD_META_DATA";

//properties types

export const PROPERTY_LIST_UPDATED = "PROPERTY_LIST_UPDATED";
export const PROPERTY_CREATED = "PROPERTY_CREATED";
export const PROPERTY_ERROR = "PROPERTY_ERROR";
export const PROPERTY_UPDATED = "PROPERTY_UPDATED";
export const GET_PROPERTY_BY_ID = "GET_PROPERTY_BY_ID";
export const DELETE_PROPERTY = "DELETE_PROPERTY";
export const PROPERTY_SEARCH_PARAMETERS_UPDATED =
  "PROPERTY_SEARCH_PARAMETERS_UPDATED";
export const LOADING_PROPERTY_LIST = "LOADING_PROPERTY_LIST";
export const LOAD_PROPERTY = "LOAD_PROPERTY";

export const DASHBOARD_DETAILS_LOADING = "DASHBOARD_DETAILS_LOADING";
export const DASHBOARD_DETAILS = "DASHBOARD_DETAILS";
export const DASHBOARD_DETAILS_ERROR = "DASHBOARD_DETAILS_ERROR";

// Our Properties
export const LOADING_ON_OURPROPERTIES_SUBMIT =
  "LOADING_ON_OURPROPERTIES_SUBMIT";
export const OURPROPERTIES_UPDATED = "OURPROPERTIES_UPDATED";
export const OURPROPERTIES_ERROR = "OURPROPERTIES_ERROR";
export const GET_OURPROPERTIES = "GET_OURPROPERTIES";
export const LOAD_OURPROPERTIES = "LOAD_OURPROPERTIES";
export const LOADING_OURPROPERTIES = "LOADING_OURPROPERTIES";

// Amenities
export const LOADING_ON_AMENITIES_SUBMIT = "LOADING_ON_AMENITIES_SUBMIT";
export const AMENITIES_UPDATED = "AMENITIES_UPDATED";
export const AMENITIES_ERROR = "AMENITIES_ERROR";
export const GET_AMENITIES = "GET_AMENITIES";
export const LOAD_AMENITIES = "LOAD_AMENITIES";
export const LOADING_AMENITIES = "LOADING_AMENITIES";

// Availability
export const LOADING_ON_AVAILABILITY_SUBMIT = "LOADING_ON_AVAILABILITY_SUBMIT";
export const AVAILABILITY_UPDATED = "AVAILABILITY_UPDATED";
export const AVAILABILITY_ERROR = "AVAILABILITY_ERROR";
export const GET_AVAILABILITY = "GET_AVAILABILITY";
export const LOAD_AVAILABILITY = "LOAD_AVAILABILITY";
export const LOADING_AVAILABILITY = "LOADING_AVAILABILITY";

// Gallery
export const LOADING_ON_GALLERY_SUBMIT = "LOADING_ON_GALLERY_SUBMIT";
export const GALLERY_UPDATED = "GALLERY_UPDATED";
export const GALLERY_ERROR = "GALLERY_ERROR";
export const GET_GALLERY = "GET_GALLERY";
export const LOAD_GALLERY = "LOAD_GALLERY";
export const LOADING_GALLERY = "LOADING_GALLERY";

// ThemeColor
export const LOADING_ON_THEMECOLOR_SUBMIT = "LOADING_ON_THEMECOLOR_SUBMIT";
export const THEMECOLOR_UPDATED = "THEMECOLOR_UPDATED";
export const THEMECOLOR_ERROR = "THEMECOLOR_ERROR";
export const GET_THEMECOLOR = "GET_THEMECOLOR";
export const LOAD_THEMECOLOR = "LOAD_THEMECOLOR";
export const LOADING_THEMECOLOR = "LOADING_THEMECOLOR";

// Enquiry
export const ENQUIRY_LIST_ERROR = "ENQUIRY_LIST_ERROR";
export const ENQUIRY_LIST_UPDATED = "ENQUIRY_LIST_UPDATED";
export const ENQUIRY_LIST_SEARCH_PARAMETERS_UPDATE =
  "ENQUIRY_LIST_SEARCH_PARAMETERS_UPDATE";
export const LOADING_ON_ENQUIRY_LIST_SUBMIT = "LOADING_ON_ENQUIRY_LIST_SUBMIT";
export const LOAD_ENQUIRY = "LOAD_ENQUIRY_LIST";
export const LOADING_ENQUIRY_LIST_DATA = "LOADING_ENQUIRY_LIST_DATA";
export const DELETE_ENQUIRY = "DELETE_ENQUIRY";
export const ENQUIRY_CSV_LOADING = "ENQUIRY_CSV_LOADING";
export const ENQUIRY_CSV_LOADING_DONE = "ENQUIRY_CSV_LOADING_DONE";

//Subscribers
export const SUBSCRIBERS_LIST_ERROR = "SUBSCRIBERS_LIST_ERROR";
export const SUBSCRIBERS_LIST_UPDATED = "SUBSCRIBERS_LIST_UPDATED";
export const SUBSCRIBERS_LIST_SEARCH_PARAMETERS_UPDATE =
  "SUBSCRIBERS_LIST_SEARCH_PARAMETERS_UPDATE";
export const LOADING_ON_SUBSCRIBERS_LIST_SUBMIT =
  "LOADING_ON_SUBSCRIBERS_LIST_SUBMIT";
export const LOAD_SUBSCRIBER = "LOAD_SUBSCRIBER";
export const LOADING_SUBSCRIBERS_LIST_DATA = "LOADING_SUBSCRIBERS_LIST_DATA";
export const DELETE_SUBSCRIBER = "DELETE_SUBSCRIBER";
export const SUBSCRIBERS_CSV_LOADING = "SUBSCRIBERS_CSV_LOADING";
export const SUBSCRIBERS_CSV_LOADING_DONE = "SUBSCRIBERS_CSV_LOADING_DONE";
