import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Errors from "views/components/Notifications/Errors";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";
import { getLocationList } from "actions/admin/location";
import { connect } from "react-redux";
import { LOCATION_ERROR } from "actions/types";

const Location = ({
  error,
  isEdit, 
  selected,
  onSelect,
  disabled,
  isRequired = true,
  getLocationList,
  isClearable = false,
}) => {
  const [location, setLocation] = useState([]);
  const [locationList , setLocationList] = useState([]);  
  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      
        if (subscribe) {
        getLocationList({limit:500}, true).then(res => {
            setLocationList(res.response[0].data || []);
          });
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [getLocationList]);

  useEffect(() => {
    let locArr=[];
    locationList.forEach((data) => {
      if(selected.indexOf(data._id) > -1){
        locArr.push({label:data.location.title,value:data._id})
      }
    });
    setLocation(locArr);
  }, [locationList, selected]);
  useEffect(() => {
    if(isEdit==0){
      let locArr=[];
      locationList.forEach((data) => {
        locArr.push({label : data.location.title, value : data._id});
      });
      setLocation(locArr);
      onSelect(locArr);
    }
  }, [locationList]);
  
  const addLocation = (value) => {
    setLocation(value);
    onSelect(value);
  };

  const options =
        locationList.map(
          (location) => ({label : location.location.title, value : location._id})
        );
  return (
    <FormGroup>
      <Label htmlFor="location">
        Location {isRequired && <span>*</span>}
      </Label>
      <Select
        options={options}
        value={location}
        onChange={addLocation}
        isDisabled={locationList.length === 0 || disabled}
        isClearable={isClearable}
        isMulti={true}
      />
      <Errors current_key="location" key="location" />
    </FormGroup>
  );
};

Location.propTypes = {
  error: PropTypes.object.isRequired,
};

Location.defaultProps = {
  error: {},
  selected: "",
  onSelect: () => {console.log("no function");},
};

const mapStateToProps = (state) => ({
    locationList: state.location.locationList,
  });
  

export default connect(mapStateToProps, {
    getLocationList
  })(Location);
