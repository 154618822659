import React from "react";
import CIcon from "@coreui/icons-react";
import { CNav, CNavGroup, CNavItem } from "@coreui/react";
import {
  cilSpeedometer,
  cilSettings,
  cilApplications,
  cilBook,
  cilContact,
  cilGradient,
  cilColorPalette,
} from "@coreui/icons";

const _nav = (actions) => {
  return [
    {
      component: CNavItem,
      name: "Dashboard",
      to: "/dashboard",
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: "Inquiries",
      to: "/dashboard/inquiry",
      icon: <CIcon icon={cilContact} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: "Subscribers",
      to: "/dashboard/subscribers",
      icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: "Manage Properties",
      to: "/dashboard/properties",
      icon: <CIcon icon={cilGradient} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: "CMS",
      to: "/dashboard/cms",
      icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: "General Settings",
      to: "/dashboard/settings",
      icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: "Manage Colors",
      to: "/dashboard/colors",
      icon: <CIcon icon={cilColorPalette} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: "Metadata",
      to: "/dashboard/metadata",
      icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
    },
  ];
};

export default _nav;
