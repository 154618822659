import { createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import RootReducer from "reducers";

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

export const middleware = [ReduxThunk];

export const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(...middleware)
)(createStore);

export const store = createStoreWithMiddleware(RootReducer);
