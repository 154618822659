import {
  CONTACT_ERROR,
  CONTACT_LIST_UPDATED,
  CONTACT_SEARCH_PARAMATERS_UPDATE,
  CHANGE_CONTACT_STATUS,
  LOADING_ON_CONTACT_SUBMIT,
  LOAD_PAGE,
  LOADING_CONTACT_LIST_DATA,
  CONTACT_UPDATED,
  LOAD_CONTACT,
  GET_CONTACT,
  LOADING_CONTACT
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  contactList: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingContactList: true,
  loadingContact: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    filters: [],
    query: "",
  },
};

export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_PAGE:
      return {
        ...state,
        loadingContact: false,
      };
    case CONTACT_ERROR:
      return {
        ...state,
        error: payload,
        loadingContact: false,
        loadingContactList: false,
      };
    case CONTACT_LIST_UPDATED:
      return {
        ...state,
        loadingListData: false,
        walkThroughSuccess: false,
        contactList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingContact: true,
        loadingContactList: false,
      };
    case CONTACT_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case LOAD_CONTACT:
      return {
        ...state,
        loadingContact: false,
      };
    case GET_CONTACT:
    case CONTACT_UPDATED:
      return {
        ...state,
        loadingContact: false,
      };
    case LOADING_CONTACT_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_CONTACT:
    case LOADING_ON_CONTACT_SUBMIT:
      return {
        ...state,
        loadingContact: true,
      };
    default:
      return state;
  }
}
