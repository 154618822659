import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import { connect } from "react-redux";
import {
  edit,
  cancelSave,
  getBLOGById,
  notFound,
  setErrors,
  removeErrors,
  resetComponentStore,
} from "actions/admin/blog";
import Spinner from "views/components/Spinner";
// import { create as createCategory, getBlogCategoryList } from "actions/admin/pageCategory";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
} from "reactstrap";
import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CATEGORY } from "constants";

const EditBLOG = ({
  getBLOGById,
  edit,
  cancelSave,
  notFound,
  currentBLOG,
  loading,
  errorList,
  setErrors,
  removeErrors,
  resetComponentStore,
}) => {
  const params = useParams();
  const history = useNavigate();
  const initialState = {
    category: "",
    visible_in_footer: 0,
    selectedCategory: {},
    name: "",
    title: "",
    slug: "",
    short_description: "",
    description: "",
    meta_description: "",
    thumbnail: "",
    imageURL: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [onlyOnce, setOnce] = useState(true);

  const onChange = (e) => {
    switch (e.target.name) {
      case "thumbnail":
        let image_file = e.target.files[0];
        let image_url = URL.createObjectURL(image_file);
        setFormData({
          ...formData,
          [e.target.name]: image_file,
          imageURL: image_url,
        });
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // const handleDescriptionChange = (event /* editor */) => {
  //   const data = event.editor.getData(); // editor.getData();
  //   setFormData({ ...formData, description: data });
  // };
  const onChangeDescription = (newDescription) => {
    setFormData((data) => ({ ...data, description: newDescription }));
  };
  /**
   * 1) IF form field is an object
   *    --- [{
   *          "param":"field_name",
   *          "value":"value that has to be matched from object",
   *          "actualParam":"to represent actual field name"
   *          "msg": "msg that has to be shown"
   *        }]
   * 2) IF form field is string
   *    --- [{
   *          "param":"field_name",
   *          "actualParam":"to represent actual field name",
   *          "msg": "msg that has to be shown"
   *        }]
   * @param {object} form , data which has to be verify
   * @param {array} requireFields , fields that has to be verify
   */
  const validateForm = (form, requireFields) => {
    const errors = [];
    for (let i in requireFields) {
      if (requireFields[i].type === "object") {
        if (
          !form[requireFields[i].param][requireFields[i].value].trim().length
        ) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (requireFields[i].type === "array") {
        if (!form[requireFields[i].param].length) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (!form[requireFields[i].param].trim().length) {
        errors.push({
          param: requireFields[i].actualParam || requireFields[i].param,
          msg: requireFields[i].msg,
        });
      }
    }
    if (errors.length) {
      setErrors(errors);
    }
    return !errors.length;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    if (
      !validateForm(formData, [
        {
          param: "name",
          msg: "Please enter the title of page",
        },
        {
          param: "category",
          msg: "Please enter the category of page",
        },
        {
          param: "title",
          msg: "Please enter the header of page",
        },
        {
          param: "imageURL",
          actualParam: "thumbnail",
          msg: "Please select a page thumbnail",
        },
        {
          param: "description",
          msg: "Please provide the page description",
        },
        {
          param: "short_description",
          msg: "Please provide the page short description",
        },
      ])
    )
      return;

    const submitData = {};
    const excludeList = { selectedCategory: 1, oldThumbnail: 1, imageURL: 1 };
    for (let i in formData) {
      if ((formData[i] !== 0 && !formData[i]) || excludeList[i]) continue;
      submitData[i] = formData[i];
    }
    edit(submitData, history, params.blog_id);
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };
  const handleCategoryAdd = (item) => {
    setFormData({ ...formData, category: item.value });
  };

  useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }
    getBLOGById(params.blog_id).then((res) => {
      if (res === undefined) notFound(history);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.blog_id, history]);

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      try {
        if (subscribe && currentBLOG) {
          const {
            name,
            title,
            category,
            slug,
            meta_description,
            short_description,
            description,
            thumbnail,
          } = currentBLOG;
          setFormData((form) => ({
            ...form,
            oldThumbnail: thumbnail,
            name,
            title,
            category,
            slug,
            meta_description,
            short_description,
            description,
            imageURL:
              process.env.REACT_APP_SERVER_URL + "/documents/blog/" + thumbnail,
          }));
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    asyncCall();

    return () => {
      subscribe = false;
    };
  }, [currentBLOG]);
  const {
    // visible_in_footer,
    // selectedCategory,
    name,
    category,
    title,
    slug,
    short_description,
    description,
    meta_description,
    imageURL,
  } = formData;
  const selectedCategory = CATEGORY.filter((itm) => itm.value === category)[0];

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="6" md="12">
          <Card>
            <CardHeader>
              <span className="pull-left h5">Blog Information</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                {/* <FormGroup>
                  <Label htmlFor="category">Category</Label>
                  <AsyncCreatableSelect
                    cacheOptions
                    isLoading={loadingOption}
                    defaultOptions
                    disabled={!is_deletable}
                    onChange={handleSelect("category")}
                    loadOptions={(input) => execWithDelay(loadPageCategoryOptions, input)()}
                    onCreateOption={handleCreate("category")}
                    value={selectedCategory}
                  />
                  <Errors current_key="category" key="category" />
                </FormGroup> */}
                <FormGroup>
                  <Label htmlFor="slug">
                    Slug <span>{slug}</span>
                  </Label>
                  <Input
                    type="text"
                    id="slug"
                    name="slug"
                    value={slug}
                    readOnly
                    disabled
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="category">
                    Category <span>*</span>
                  </Label>
                  <Select
                    options={CATEGORY}
                    value={selectedCategory}
                    onChange={handleCategoryAdd}
                  />
                  <Errors current_key="category" key="category" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="name">
                    Blog Name <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    maxLength="100"
                    value={name}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.name ? true : false}
                  />
                  <Errors current_key="name" key="name" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="title">
                    Blog Header <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    maxLength="100"
                    value={title}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.title ? true : false}
                  />
                  <Errors current_key="title" key="title" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="meta_description">Meta Description</Label>
                  <Input
                    type="textarea"
                    id="meta_description"
                    name="meta_description"
                    value={meta_description}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.meta_description ? true : false}
                  />
                  <Errors
                    current_key="meta_description"
                    key="meta_description"
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="thumbnail">
                    Thumbnail<span>*</span>
                  </Label>
                  <Input
                    type="file"
                    id="thumbnail"
                    name="thumbnail"
                    onChange={(e) => onChange(e)}
                    multiple
                    invalid={errorList.thumbnail ? true : false}
                    accept="image/png, image/jpeg"
                  />
                  <img
                    src={imageURL}
                    style={{ padding: 10, height: "150px", width: "150px" }}
                    className="preview-img img-fluid"
                    alt=""
                  />
                  <Errors current_key="thumbnail" key="thumbnail" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="short_description">Short Description</Label>
                  <Input
                    type="textarea"
                    id="short_description"
                    name="short_description"
                    maxLength="500"
                    value={short_description}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.short_description ? true : false}
                  />
                  <Errors
                    current_key="short_description"
                    key="short_description"
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="description">
                    Body <span>*</span>
                  </Label>
                  {/**
                   * CKEditor element conflict bug
                   * Fix: onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                   * Issue: https://github.com/ckeditor/ckeditor4-react/issues/57
                   */}

                  {/* <CKEditor
                    initData={
                      currentBLOG
                        ? currentBLOG.description
                          ? currentBLOG.description
                          : ""
                        : ""
                    }
                    id="description"
                    name="description"
                    config={{
                      height: 100,
                      toolbar: [
                        ["Cut", "Copy", "Paste"],
                        ["Undo", "Redo"],
                        ["SpellChecker"],
                        ["Link", "Unlink", "Anchor"],
                        [
                          "Image",
                          "Table",
                          "Horizontal Line",
                          "Special Character",
                        ],
                        ["Maximize"],
                        ["Source"],
                        ["Bold", "Italic", "Strike"],
                        ["RemoveFormat"],
                        ["NumberedList", "BulletedList"],
                        ["DecreaseIndent", "IncreaseIndent"],
                        ["BlockQuote"],
                        ["Styles"],
                        ["Format"],
                        ["About"],
                      ],
                    }}
                    onChange={(event) => handleDescriptionChange(event)}
                    onBeforeLoad={(CKEDITOR) =>
                      (CKEDITOR.disableAutoInline = true)
                    }
                    invalid={errorList.description ? true : false}
                  /> */}

                  <CKEditor
                    editor={Editor}
                    required
                    data={description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      onChangeDescription(data);
                    }}
                  />

                  <Errors current_key="description" key="description" />
                </FormGroup>
                {/* <FormGroup>
                    <FormGroup check>
                      <Label check title="Selected pages will be shown at Customer website footer">
                        <Input 
                          style={{ marginTop: 4 }}
                          type="checkbox" 
                          name="visible_in_footer"
                          checked={visible_in_footer === 1 }
                          onChange={e => onChange(e)}
                        />{' '}
                        Should this page be visible in footer?
                      </Label>
                    </FormGroup>
                    <Errors current_key="visible_in_footer" key="visible_in_footer" />
                  </FormGroup> */}
              </CardBody>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <a onClick={onClickHandel} href="#!">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </a>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditBLOG.propTypes = {
  getBLOGById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  // createCategory: PropTypes.func.isRequired,
  // getBlogCategoryList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  cancelSave: PropTypes.func.isRequired,
  notFound: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  currentBLOG: state.blog.currentBLOG,
  errorList: state.errors,
  loading: state.blog.loadingBLOG,
  // pageCategoryParams: state.pageCategory.sortingParams,
});

export default connect(mapStateToProps, {
  getBLOGById,
  edit,
  // createCategory,
  // getBlogCategoryList,
  cancelSave,
  notFound,
  setErrors,
  removeErrors,
  resetComponentStore,
})(EditBLOG);
