import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  ABOUTUS_ERROR,
  REMOVE_ERRORS,
  ABOUTUS_UPDATED,
  GET_ABOUTUS,
  LOADING_ON_ABOUTUS_SUBMIT,
  LOADING_ABOUTUS_DATA,
  RESET,
} from "actions/types";
import { ABOUTUS } from "constants/api";
import { logout } from "../auth";
//Get About Us Data
export const getAboutUs = (userParams = {}, noRedux) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch({ type :LOADING_ABOUTUS_DATA});
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${ABOUTUS.LIST().ENDPOINT}`,config
    );

    await dispatch({
      type: GET_ABOUTUS,
      payload: res.data.response,
    });
    
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ABOUTUS_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};
// Update About us data
export const updateAboutUs = (formData, history, _id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch({type : LOADING_ON_ABOUTUS_SUBMIT});
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    console.log(_id);
    const data = new FormData();
    for(var i in formData){
      data.append(i, formData[i]);
    }
    const res = await axios.put(`${ABOUTUS.UPDATE_ABOUTUS().ENDPOINT}/${_id}`, data, config);
    if (res.data.status === true) {
      dispatch({
        type: ABOUTUS_UPDATED,
      });
      dispatch(
        setAlert("About Us updated successfully", "success")
      );
      history("/home/aboutUs");
    } else {
      const errors = res.data.esrrors;
      if (errors) {
        dispatch({
          type: ABOUTUS_ERROR,
        });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: ABOUTUS_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
}; 
// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: ABOUTUS_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};
