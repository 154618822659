import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button} from "reactstrap"
export default function ImageCropper(props) {
  const [upImg, setUpImg] = useState(props.img);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 40, aspect: 4 / 4 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const generateDownload = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      (blob) => {
        const previewUrl = window.URL.createObjectURL(blob);
        props.handleCroppedImg(blob, previewUrl);
      },
      "image/png",
      1
    );
  };

  /* const onLoad = (img) => {
    imgRef.current = img;
  }; */

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  useEffect(() => {
    setUpImg(props.img);
  }, [props.img]);

  return (
    <div className="App">
      <ReactCrop
        src={upImg}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
      >
        <img src={upImg} alt="crop image" ref={imgRef} ></img>
      </ReactCrop>
      <div>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </div>
      <div style={{ float: "right" }}>
        <Button
          type="button"
          color="danger"
          //disabled={!completedCrop?.width || !completedCrop?.height}
          onClick={() => props.onCancel()}
        >
          Cancel
        </Button>
        <Button
          type="button"
          color="success"
          disabled={!completedCrop?.width || !completedCrop?.height}
          onClick={() =>
            generateDownload(previewCanvasRef.current, completedCrop)
          }
        >
          Done
        </Button>
      </div>
    </div>
  );
}
