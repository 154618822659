import React from "react";
import { isEmpty, isObject, isArray } from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import Spinner from "views/components/Spinner";

import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Badge,
} from "reactstrap";
import moment from "moment";
import { FaAngleDown, FaAngleUp, FaEdit, FaTrash } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";

import FilterComponent from "./Filters";

import {
  getMetaDataList,
  resetComponentStore,
  deleteMetaData,
} from "actions/admin/metadata";

const Stack = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.alignItems || "initial"};
  -webkit-box-align: center;
  & > div:not(:first-child) {
    margin: 0px;
    margin-left: ${(props) =>
      props.direction !== "column" ? (props.gap || 1) * 8 + "px" : "0px"};
  }
`;

const CustomStyle = {
  table: {
    style: {
      zIndex: 10,
    },
  },
  headRow: {
    style: {
      height: "60px",
      borderTopWidth: "1px",
      borderTopStyle: "solid",
      borderBottomWidth: "2px",
      fontSize: "15px !important",
      backgroundColor: "#eeefef",
    },
  },
};

const getValue = (data) => {
  if (isObject(data) && data.hasOwnProperty("value")) return data.value;
  else if (isArray(data)) return data.map((obj) => obj.value);
  return data;
};

const addFilters = (element, filters) => {
  const set = new Set(filters);
  set.add(element);
  return Array.from(set);
};

const removeFilter = (element, filters) => {
  const set = new Set(filters);
  set.delete(element);
  return Array.from(set);
};

const MetadataList = ({
  getMetaDataList,
  loadingListData,
  pageList: { data, count, page },
  sortingParams,
  resetComponentStore,
  deleteMetaData,
}) => {
  const [pageParams, setPageParams] = React.useState({
    limit: sortingParams.limit,
    page: sortingParams.page,
    orderBy: sortingParams.orderBy,
    ascending: sortingParams.ascending,
    query: sortingParams.query,
    filters: sortingParams.filters,
    count: count,
  });
  const [filterOpen, setFilterOpen] = React.useState(false);

  const [onlyOnce, setOnce] = React.useState(true);

  const columns = [
    {
      name: "Page",
      sortable: true,
      field: "page",
      selector: (row) => row.page,
      width: "140px",
    },
    {
      name: "Title",
      sortable: true,
      field: "title",
      selector: (row) => row.title,
      width: "280px",
    },
    {
      name: "Description",
      sortable: true,
      field: "description",
      selector: (row) => row.description,
      minWidth: "320px",
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      width: "140px",
      format: (row) => {
        const _id = row._id;
        return (
          <Stack>
            <div>
              <Link to={`/dashboard/metadata/${_id}/edit`} title="Edit">
                <Button
                  type="button"
                  style={{ margin: 5, color: "white" }}
                  size="sm"
                  color="success"
                >
                  <FaEdit />
                </Button>
              </Link>

              <Button
                type="button"
                size="sm"
                title="Delete"
                color="danger"
                style={{ margin: 5, color: "white" }}
                onClick={() => {
                  window.confirm(
                    "Are you sure you want to delete this metadata?"
                  ) && deleteMetaData(_id);
                }}
              >
                <FaTrash />
              </Button>
            </div>
          </Stack>
        );
      },
    },
  ];

  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    let params = {
      page: type === "search" ? 1 : page == 0 ? 1 : page,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      filters: sortingParams.filters,
      query: sortingParams.query,
    };
    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              value: searchText,
              type: "String",
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }
    setPageParams((prevParams) => ({ ...prevParams, ...params }));
  };

  const onSearch = (text) => {
    handleTableChange("search", {
      searchText: text,
      sizePerPage: pageParams.limit,
      sortOrder: pageParams.ascending,
    });
  };

  React.useEffect(() => {
    let subscribe = true;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          if (onlyOnce) {
            resetComponentStore();
            setOnce(false);
          }
          getMetaDataList(pageParams);
        }
      } catch (err) {
        console.trace(err);
      }
    };
    asyncParams();
    return () => {
      subscribe = false;
    };
  }, [pageParams]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setPageParams((prevParams) => ({
      ...prevParams,
      limit: newPerPage,
      page,
    }));
  };

  const onFilterChange = (name, value) => {
    setPageParams((params) => {
      let filters = [];
      let query = params.query || {};
      if (isEmpty(value)) {
        filters = removeFilter(name, params.filters);
        delete query[name];
      } else {
        filters = addFilters(name, params.filters);
        query[name] = {
          value: getValue(value),
          type: "String",
        };
      }
      const filterParams = {
        filters,
        query,
      };
      return {
        ...params,
        ...filterParams,
      };
    });
  };

  const handlePageChange = async (page) => {
    setPageParams((prevParams) => ({ ...prevParams, page }));
  };

  const handleSort = (column, sortDirection) => {
    const orderBy = column.field;
    setPageParams((prevParams) => ({
      ...prevParams,
      orderBy,
      ascending: sortDirection,
    }));
  };

  React.useEffect(() => {
    let subscribe = true;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          setPageParams((params) => ({
            ...params,
            ...sortingParams,
            page: parseInt(page),
          }));
        }
      } catch (err) {
        console.trace(err);
      }
    };

    asyncParams();
    return () => {
      subscribe = false;
    };
  }, []);

  return (
    <div className="animated fadeIn" style={{ marginBottom: "1.5rem" }}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Stack
                alignItems="center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <h2>Meta Data</h2>
                </div>
                <div className="add-button-div">
                  <Link to="/dashboard/metadata/create" title="create">
                    <Button color="primary" size="sm">
                      <i className="fa fa-plus"></i> Add New Meta Data
                    </Button>
                  </Link>
                </div>
              </Stack>
            </CardHeader>
            <CardBody>
              <div className="add-button-div border-bottom">
                <div
                  className="filters-row"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h5>Filters</h5>
                  </div>
                  <div onClick={() => setFilterOpen(!filterOpen)}>
                    {filterOpen ? (
                      <FaAngleUp
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <FaAngleDown
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={data}
                subHeader={filterOpen}
                subHeaderComponent={
                  <FilterComponent
                    onSearch={onSearch}
                    onFilter={onFilterChange}
                  />
                }
                progressPending={loadingListData}
                pagination
                paginationServer
                onSort={handleSort}
                sortServer
                paginationTotalRows={count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={CustomStyle}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

MetadataList.prototype = {
  getMetaDataList: PropTypes.func.isRequired,
  resetComponentStore: PropTypes.func.isRequired,
  sortingParams: PropTypes.object.isRequired,
  deleteMetaData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pageList: state.metadata.pageList,
  loadingListData: state.metadata.loadingListData,
  sortingParams: state.metadata.sortingParams,
});

export default connect(mapStateToProps, {
  getMetaDataList,
  resetComponentStore,
  deleteMetaData,
})(MetadataList);
