import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  PACKAGE_CREATED,
  PACKAGE_ERROR,
  DELETE_PACKAGE,
  PACKAGE_UPDATED,
  PACKAGE_LIST_UPDATED,
  GET_PACKAGE_BY_ID,
  PACKAGE_SEARCH_PARAMATERS_UPDATE,
  REMOVE_ERRORS,
  LOADING_ON_EMAIL_SUBMIT,
  LOAD_PAGE,
  RESET,
} from "actions/types";
import { logout } from "actions/auth";
import { PACKAGE } from "constants/api";

//get package list

export const getPackageList = (packageParams) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    dispatch({ type: REMOVE_ERRORS });
    const query = packageParams.query ? packageParams.query : "";
    packageParams.query = query;
    packageParams.page = packageParams.page ? packageParams.page : 1;

    config.params = packageParams;
    const res = await axios.get(PACKAGE.LIST().ENDPOINT, config);
    // dispatch({ type: REMOVE_ALERT });
    dispatch({
      type: PACKAGE_LIST_UPDATED,
      payload: res.data.response[0],
    });
    dispatch({
      type: PACKAGE_SEARCH_PARAMATERS_UPDATE,
      payload: packageParams,
    });
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: PACKAGE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Get Package by id
export const getPackageById = (package_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmailSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(
      `${PACKAGE.LIST().ENDPOINT}/${package_id}`,
      config
    );
    // dispatch({ type: REMOVE_ALERT });

    await dispatch({
      type: GET_PACKAGE_BY_ID,
      payload: res.data.response,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: PACKAGE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Create Package
export const create = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(PACKAGE.CREATE().ENDPOINT, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: PACKAGE_CREATED,
        payload: res.data.response,
      });
      // dispatch({ type: REMOVE_ALERT });
      dispatch(setAlert("Package Created.", "success"));
      history("/home/package");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: PACKAGE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Edit Package
export const edit = (formData, history, package_id) => async (dispatch) => {
  // -- Always remove the previous errors before sending new request
  dispatch({ type: REMOVE_ERRORS });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${PACKAGE.CREATE().ENDPOINT}/${package_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: PACKAGE_UPDATED,
        payload: res.data.response,
      });
      // dispatch({ type: REMOVE_ALERT });
      dispatch(setAlert("Package Updated.", "success"));
      history("/home/package");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({ type: PACKAGE_ERROR });
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: PACKAGE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Delete Package
export const deletePackage = (package_id, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(`${PACKAGE.LIST().ENDPOINT}/${package_id}`, config);
    // dispatch({ type: REMOVE_ALERT });

    dispatch({
      type: DELETE_PACKAGE,
      payload: package_id,
    });
    dispatch(setAlert("Package deleted", "success"));
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: PACKAGE_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const cancelSave = (history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history("/home/package");
};

// Dispatch Loading
export const loadingOnEmailSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_EMAIL_SUBMIT });
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history("/home/page-not-found");
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: PACKAGE_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_PAGE });
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};
