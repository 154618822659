import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ImageCropper from "./ImageCropper";
import {
  updateCategory,
  loadPage,
  getCategoryById,
  cancelSave,
  setErrors,
  removeErrors,
  uploadCategoryImage,
  deleteCategoryImage
} from "actions/admin/category";
import Errors from "views/components/Notifications/Errors";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FaTrash } from "react-icons/fa";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useNavigate, useParams } from "react-router";

const ImageCategory = ({
  getCategoryById,
  cancelSave,
  currentCategory,
  errorList,
  loadPage,
  removeErrors,
  uploadCategoryImage,
  deleteCategoryImage,
  setErrors
}) => {
  const history = useNavigate();
  const params = useParams();
  //########################## set the initial form data ##############################
  const initialState = {};
  const [imageArr, setImageArr] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const fileImg = useRef(null);
  //Start croping code
  const [openCrop, setOpenCrop] = useState(false);
  const [preview, setPreview] = useState(null);
  const [cropPreview, setCropPreview] = useState(null); 
  const handleCroppedImg = (blob, c_preview) => {
    const myFile = new File([blob], 'image.png', {
      type: blob.type,
    });
    setFormData({
      ...formData,
      image: blob,
    });
    setOpenCrop(false);
    setCropPreview(c_preview);
  };

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe && params.category_id) {
        getCategoryById(params.category_id);
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [getCategoryById, params.category_id]);

  useEffect(() => {
    if (currentCategory) {
      setImageArr(currentCategory.images);
    }
  }, [currentCategory]);

  //########################## change event for all inputs ##############################
 
  const onChange = e => {
    switch (e.target.name) {
      case "image":
        var src_url=URL.createObjectURL(e.target.files[0]);
        setPreview(src_url);
        setOpenCrop(true);
        setCropPreview(src_url);
        setFormData({
          ...formData,
          [e.target.name]: e.target.files[0],
        });
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    const submitData = {};
    const excludeList = {
      avatarURL: 1,
    };
    var errors=[];
    if(formData!== null){
      for (let i in formData) {
        const data = formData[i];
        if (data === "" || data === null || data === undefined || excludeList[i])
          continue;
        submitData[i] = data;
      }
      uploadCategoryImage(submitData, history, params.category_id);
      setFormData(null);
      setOpenCrop(false);
      setCropPreview(null);
    }
    else{
      errors.push({
        param:"image",
        msg: "Please Select Image",
      });
      setErrors(errors);
    }
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe) {
        loadPage();
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [loadPage]);

  const delImage = (id) => {
    if(window.confirm(`Are you sure you want to delete this image?`)){
      deleteCategoryImage(params.category_id, id);
    }
  }
  return (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
        <Row style={{ marginTop: "4%" }}>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <h2>Category Image</h2>
              </CardHeader>
            </Card>
          </Col>
          <Col xs="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xs="8" md="8">
                    <FormGroup>
                      <Label htmlFor="image">Image</Label>
                      <Input
                        type="file"
                        id="image"
                        name="image"
                        
                        onChange={(e) => onChange(e)}
                        multiple={false}
                        accept="image/png, image/jpeg"
                        ref={fileImg}
                        invalid={errorList.file ? true : false}
                      />
                      <Errors current_key="image" key="image" />
                    </FormGroup>
                  </Col>
                  <Col xs="4" md="4">
                      <img src={cropPreview} />
                  </Col>
                  <Col xs="12" /*style={{ marginLeft: 10, marginTop: "1%" }}*/>
                    <Button
                      className="me-3"
                      color="primary"
                      type="submit"
                    >Upload</Button>
                    <Button
                      type="reset"
                      size="md"
                      color="danger"
                      onClick={onClickHandel}
                    >Cancel</Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col>
          <Card>
            <CardBody>
                <ImageList cols={6} rowHeight={164}>
                  {Array.isArray(imageArr) && imageArr.map((item) => (
                    <ImageListItem key={item._id}>
                      <img
                        src={process.env.REACT_APP_SERVER_URL + "/documents/category/" + `${item.filename}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={process.env.REACT_APP_SERVER_URL + "/documents/category/" + `${item.filename}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt="images"
                      />
                      <a onClick={e => { delImage(item._id) }} href="#"><span style={{ position: "absolute", top: "3px", right: "2px", color: "red" }}><FaTrash /></span></a>
                    </ImageListItem>
                  ))}
                </ImageList>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={openCrop}>
          <ModalHeader>Image Cropping</ModalHeader>
          <ModalBody>
            <ImageCropper
              onCancel={() => setOpenCrop(false)}
              img={preview}
              handleCroppedImg={handleCroppedImg}
            />
          </ModalBody>
      </Modal>
    </div>
  );
};

ImageCategory.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateCategory: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  // showMsg: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
  uploadCategoryImage: PropTypes.func.isRequired,
  deleteCategoryImage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.category.loadingCategory,
  currentCategory: state.category.currentCategory,
});
export default connect(mapStateToProps, {
  updateCategory,
  getCategoryById,
  cancelSave,
  loadPage,
  setErrors,
  removeErrors,
  uploadCategoryImage,
  deleteCategoryImage
})(ImageCategory);
