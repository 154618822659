import Dashboard from "views/containers/Dashboard";
import Profile from "views/containers/Profile";
import CMSList from "views/containers/CMS/List";
import CMSCreate from "views/containers/CMS/Create";
import CMSEdit from "views/containers/CMS/Edit";
import BLOGList from "views/containers/Blog/List";
import BLOGCreate from "views/containers/Blog/Create";
import BLOGEdit from "views/containers/Blog/Edit";
import CategoryList from "views/containers/Service/Category";
import EditCategory from "views/containers/Service/Category/edit";
import CreateCategory from "views/containers/Service/Category/create";
import EditSetting from "views/containers/Setting/Edit";
import LocationList from "views/containers/Service/Location/List";
import EditLocation from "views/containers/Service/Location/Edit";
import CreateLocation from "views/containers/Service/Location/Create";
import ImageCategory from "views/containers/Service/Category/image";
import EditAboutUs from "views/containers/AboutUs/Edit";
import AddBanner from "views/containers/Banner/add";
import BannerList from "views/containers/Banner/List";
import EditBanner from "views/containers/Banner/edit";
import EditSubBanner from "views/containers/SubBanner/Edit";
import AddPackage from "views/containers/Package/add";
import PackageList from "views/containers/Package/List";
import EditPackage from "views/containers/Package/edit";
import WhyWtfList from "views/containers/WhyWall2floor/List";
import CreateCard from "views/containers/WhyWall2floor/Create";
import EditCard from "views/containers/WhyWall2floor/Edit";
import Metadata from "views/containers/Metadata";
import CreateMetaData from "views/containers/Metadata/Create";
import EditMetaData from "views/containers/Metadata/Edit";

import Home from "views/containers/CMS/Home";
import Amenities from "views/containers/CMS/Amenities";
import Gallery from "views/containers/CMS/Gallery";
import Availability from "views/containers/CMS/Availability";
import ContactUs from "views/containers/CMS/ContactUs";
import SubscribersList from "views/containers/SubscribersList/index";
import PropertyList from "views/containers/ManageProperties/index";
import AddProperty from "views/containers/ManageProperties/Create";
import EditProperty from "views/containers/ManageProperties/Edit";
import ManageColors from "views/containers/ManageColors";
import InquiryList from "views/containers/InquiryList";

const InternalRoutes = [
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/dashboard/our-properties",
    exact: true,
    name: "Our Properties",
    component: Home,
  },
  {
    path: "/dashboard/amenities",
    exact: true,
    name: "Amenities",
    component: Amenities,
  },
  {
    path: "/dashboard/gallery",
    exact: true,
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/dashboard/availability",
    exact: true,
    name: "Availability",
    component: Availability,
  },
  {
    path: "/dashboard/contact-us",
    exact: true,
    name: "Contact Us",
    compoenent: ContactUs,
  },
  {
    path: "/dashboard/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/dashboard/cms",
    name: "CMS",
    component: CMSList,
  },
  {
    path: "/dashboard/cms/create",
    name: "CMS",
    component: CMSCreate,
  },
  {
    path: "/dashboard/cms/:cms_id",
    name: "CMS",
    component: CMSEdit,
  },
  {
    path: "/dashboard/blog",
    name: "BLOG",
    component: BLOGList,
  },
  {
    path: "/dashboard/blog/create",
    name: "BLOG",
    component: BLOGCreate,
  },
  {
    path: "/dashboard/blog/:blog_id",
    name: "BLOG",
    component: BLOGEdit,
  },
  {
    path: "/dashboard/services",
    name: "Services",
    component: CategoryList,
  },
  {
    path: "/dashboard/services/create",
    name: "Create",
    component: CreateCategory,
  },
  {
    path: "/dashboard/services/:category_id/edit",
    name: "Edit",
    component: EditCategory,
  },
  {
    path: "/dashboard/inquiry",
    name: "Inquiries",
    component: InquiryList,
  },
  {
    path: "/dashboard/settings",
    name: "Setting",
    component: EditSetting,
  },
  {
    path: "/dashboard/services/location",
    name: "Location",
    component: LocationList,
  },
  {
    path: "/dashboard/services/location/create",
    name: "Location",
    component: CreateLocation,
  },
  {
    path: "/dashboard/services/location/:location_id/edit",
    name: "Location",
    component: EditLocation,
  },
  {
    path: "/dashboard/services/category/image/:category_id",
    name: "Image",
    component: ImageCategory,
  },
  {
    path: "/dashboard/aboutUs",
    name: "AboutUs",
    component: EditAboutUs,
  },
  {
    path: "/dashboard/banner/create",
    name: "Banner",
    component: AddBanner,
  },
  {
    path: "/dashboard/banner",
    name: "Banner",
    component: BannerList,
  },
  {
    path: "/dashboard/banner/:banner_id/edit",
    name: "Banner",
    component: EditBanner,
  },
  {
    path: "/dashboard/subBanner",
    name: "SubBanner",
    component: EditSubBanner,
  },
  {
    path: "/dashboard/package/create",
    name: "Package",
    component: AddPackage,
  },
  {
    path: "/dashboard/package",
    name: "Package",
    component: PackageList,
  },
  {
    path: "/dashboard/package/:package_id/edit",
    name: "Package",
    component: EditPackage,
  },
  {
    path: "/dashboard/whyWall2floor",
    name: "WHY WTF",
    component: WhyWtfList,
    exact: true,
  },
  {
    path: "/dashboard/whyWall2floor/create",
    name: "Create",
    component: CreateCard,
    exact: true,
  },
  {
    path: "/dashboard/whyWall2floor/:card_id/edit",
    name: "Edit",
    component: EditCard,
    exact: true,
  },
  {
    path: "dashboard/metadata",
    name: "Meta Data",
    component: Metadata,
    exact: true,
  },
  {
    path: "dashboard/metadata/create",
    name: "Create MetaData",
    component: CreateMetaData,
    exact: true,
  },
  {
    path: "dashboard/metadata/:id/edit",
    name: "Edit MetaData",
    component: EditMetaData,
    exact: true,
  },
  {
    path: "dashboard/subscribers",
    name: "Subscribers",
    component: SubscribersList,
    exact: true,
  },
  {
    path: "dashboard/properties",
    name: "Manage Properties",
    component: PropertyList,
    exact: true,
  },
  {
    path: "dashboard/properties/add",
    name: "Add New Property",
    component: AddProperty,
    exact: true,
  },
  {
    path: "dashboard/properties/:id/edit",
    name: "Edit Property",
    compoenent: EditProperty,
    exact: true,
  },
  {
    path: "dashboard/colors",
    name: "Manage Colors",
    component: ManageColors,
    exact: true,
  },
];

export default InternalRoutes;
