import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { connect } from "react-redux";
import {
  edit,
  cancelSave,
  getLocationById,
  notFound,
  setErrors,
  removeErrors,
  resetComponentStore,
} from "actions/admin/location";
import Spinner from "views/components/Spinner";
import Select from "react-select";
import { CATEGORY_STATUS } from "constants";
import GMaps from "./GMap";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
} from "reactstrap";
import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate } from "react-router-dom";

const EditLocation = ({
  getLocationById,
  edit,
  cancelSave,
  notFound,
  currentLocation,
  loading,
  errorList,
  setErrors,
  removeErrors,
  resetComponentStore,
}) => {
  const params = useParams();
  const history = useNavigate();
  const initialState = {
    status: 1,
    description: "",
    title: "",
    lat: "",
    lng: "",
    placeId: "",
    image: "",
    imageURL: "",
    short_name: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [onlyOnce, setOnce] = useState(true);

  const {
    status,
    description,
    title,
    lat,
    lng,
    placeId,
    image,
    imageURL,
    short_name,
  } = formData;

  const onChange = (e) => {
    switch (e.target.name) {
      case "image":
        let image_file = e.target.files[0];
        let image_url = URL.createObjectURL(image_file);
        setFormData({
          ...formData,
          [e.target.name]: image_file,
          imageURL: image_url,
        });
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // const handleDescriptionChange = (event) => {
  //   const desc = event.editor.getData(); // editor.getData();
  //   setFormData((ev)=>{ return {...ev, description: desc }});
  // };
  const onChangeDescription = (newDescription) => {
    setFormData((data) => ({ ...data, description: newDescription }));
  };
  const validateForm = (form, requireFields) => {
    const errors = [];
    for (let i in requireFields) {
      if (requireFields[i].type === "object") {
        if (
          !form[requireFields[i].param][requireFields[i].value].trim().length
        ) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (requireFields[i].type === "array") {
        if (!form[requireFields[i].param].length) {
          errors.push({
            param: requireFields[i].param,
            msg: requireFields[i].msg,
          });
        }
      } else if (!form[requireFields[i].param].trim().length) {
        errors.push({
          param: requireFields[i].actualParam || requireFields[i].param,
          msg: requireFields[i].msg,
        });
      }
    }
    if (errors.length) {
      setErrors(errors);
    }
    return !errors.length;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    if (
      !validateForm(formData, [
        {
          param: "title",
          msg: "Please select location",
        },
        {
          param: "description",
          msg: "Please provide the description",
        },
      ])
    )
      return;

    const submitData = {};
    const excludeList = { selectedCategory: 1, oldThumbnail: 1 };
    for (let i in formData) {
      if ((formData[i] !== 0 && !formData[i]) || excludeList[i]) continue;
      submitData[i] = formData[i];
    }
    edit(submitData, history, params.location_id);
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };

  const chnageStatus = (value) => {
    setFormData({ ...formData, status: value.value });
  };

  useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      getLocationById(params.location_id).then((res) => {
        if (res === undefined) notFound(history);
      });
      setOnce(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.location_id, history]);

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      try {
        if (subscribe && currentLocation) {
          const { location, description, status, image } = currentLocation;
          setFormData({
            ...formData,
            status: status,
            lat: location.lat,
            lng: location.lng,
            placeId: location.placeId,
            title: location.title,
            short_name: location.short_name,
            description: description,
            imageURL:
              process.env.REACT_APP_SERVER_URL + "/documents/location/" + image,
          });
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    asyncCall();

    return () => {
      subscribe = false;
    };
  }, [currentLocation]);
  const changeAdd = (data) => {
    setFormData((ev) => {
      return {
        ...ev,
        lng: data.geometry.location.lng(),
        lat: data.geometry.location.lat(),
        placeId: data.place_id,
        title: data.formatted_address,
        short_name: data.address_components[0].long_name,
      };
    });
  };
  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12">
            <Card>
              <CardHeader>
                <h5>Location Information</h5>
              </CardHeader>
              <Form
                className="form-horizontal"
                onSubmit={(e) => onSubmit(e)}
                encType="multipart/form-data"
              >
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="location">
                      Select Location <span>*</span>
                    </Label>
                    <GMaps changeAdd={changeAdd} data={formData.title} />
                    <Errors current_key="placeId" key="placeId" />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="image">
                      Image<span>*</span>
                    </Label>
                    <Input
                      type="file"
                      id="image"
                      name="image"
                      onChange={(e) => onChange(e)}
                      multiple
                      accept="image/png, image/jpeg"
                      invalid={errorList.image ? true : false}
                    />
                    <img
                      src={formData.imageURL}
                      className="preview-img img-fluid"
                      width="100px"
                      alt=""
                    />
                    <Errors current_key="image" key="image" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="description">
                      Description <span>*</span>
                    </Label>
                    {/* <CKEditor
                      initData={currentLocation.description}
                      id="description"
                      name="description"
                      config={{
                        height: 100,
                        toolbar: [
                          ["Cut", "Copy", "Paste"],
                          ["Undo", "Redo"],
                          ["SpellChecker"],
                          ["Link", "Unlink", "Anchor"],
                          [
                            "Image",
                            "Table",
                            "Horizontal Line",
                            "Special Character",
                          ],
                          ["Maximize"],
                          ["Source"],
                          ["Bold", "Italic", "Strike"],
                          ["RemoveFormat"],
                          ["NumberedList", "BulletedList"],
                          ["DecreaseIndent", "IncreaseIndent"],
                          ["BlockQuote"],
                          ["Styles"],
                          ["Format"],
                          ["About"],
                        ],
                      }}
                      onChange={(event) => handleDescriptionChange(event)}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                      invalid={errorList.description ? true : false}
                    /> */}
                    <CKEditor
                      editor={Editor}
                      required
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        onChangeDescription(data);
                      }}
                    />
                    <Errors current_key="description" key="description" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="status">
                      Status <span>*</span>
                    </Label>
                    <Select
                      options={CATEGORY_STATUS}
                      value={CATEGORY_STATUS.find(
                        (cat) => cat.value === status
                      )}
                      onChange={chnageStatus}
                      isClearable={false}
                    />
                    <Errors current_key="status" key="status" />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <a onClick={onClickHandel} href="#!">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </a>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

EditLocation.propTypes = {
  getLocationById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  cancelSave: PropTypes.func.isRequired,
  notFound: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  currentLocation: state.location.currentLocation,
  errorList: state.errors,
  loading: state.location.loadingLocation,
});

export default connect(mapStateToProps, {
  getLocationById,
  edit,
  cancelSave,
  notFound,
  setErrors,
  removeErrors,
  resetComponentStore,
})(EditLocation);
