import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash-es/isEmpty";
import Errors from "views/components/Notifications/Errors";
import Select from "react-select";
import { categoryList } from "constants";
import { FormGroup, Label } from "reactstrap";
import { getCategoryList } from "actions/admin/category";
import { connect } from "react-redux";

const Category = ({
  error,
  selected,
  onSelect,
  disabled,
  noSelection,
  isRequired = true,
  showAll,
  getCategoryList,
  isClearable = false,
}) => {
  const [category, setCategory] = useState(
    ""
  );
  const [categoryList , setCategoryList] = useState([]);  
  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      
        if (subscribe) {
        getCategoryList({limit:500}, true).then(res => {
            setCategoryList(res?.data || []);
          });
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [getCategoryList]);

  useEffect(() => {
    categoryList.forEach((cat) => {
      if(cat._id === selected){
        setCategory({label : cat.name, value : cat._id});
      }
    });
  }, [categoryList, selected]);
  
  const addCategory = (value) => {
    setCategory(value);
    onSelect(value);
  };

  const options =
        categoryList.map(
          (category) => ({label : category.name, value : category._id})
        );
  return (
    <FormGroup>
      <Label htmlFor="category">
        Category {isRequired && <span>*</span>}
      </Label>
      <Select
        options={options}
        value={category}
        onChange={addCategory}
        isDisabled={categoryList.length === 0 || disabled}
        isClearable={isClearable}
      />
      <Errors current_key="category" key="category" />
    </FormGroup>
  );
};

Category.propTypes = {
  error: PropTypes.object.isRequired,
};

Category.defaultProps = {
  error: {},
  selected: "",
  onSelect: () => {console.log("no function");},
};

const mapStateToProps = (state) => ({
    categoryList: state.category.categoryList,
  });
  

export default connect(mapStateToProps, {
    getCategoryList
  })(Category);
