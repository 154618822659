import React from "react";
import { useSelector } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import { logoWithName } from "assets/img/logo/logo_stretch";
import { logo } from "assets/img/logo/logo";
import { DefaultSidebarNav } from "./DefaultSidebarNav";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { unfoldSidebar } from "actions/admin/sidebar";
import CIcon from "@coreui/icons-react";

const DefaultSidebar = ({ showSidebar, navigation }) => {
  const show = useSelector((state) => state.sidebar.sidebarShow);
  const unfoldable = useSelector((state) => state.sidebar.unfoldableSidebar);
  console.log(navigation);
  return (
    <React.Suspense>
      <CSidebar
        position="fixed"
        unfoldable={unfoldable}
        visible={show}
        onVisibleChange={(visible) => {
          showSidebar(visible);
        }}
      >
        <CSidebarBrand className="d-none d-md-flex" to="/dashboard">
          <CIcon
            icon={logoWithName}
            style={{ height: 52, width: "80%" }}
            className="sidebar-brand-full mt-2 mb-2"
          />
          {/* <CIcon
            icon={logo}
            style={{ height: 48, width: "80%" }}
            className="sidebar-brand-narrow"
          /> */}
        </CSidebarBrand>
        <CSidebarNav>
          <SimpleBar>
            <DefaultSidebarNav items={navigation} />
          </SimpleBar>
        </CSidebarNav>
        <CSidebarToggler
          className="d-none d-lg-flex"
          onClick={() => {
            unfoldSidebar(!unfoldable);
          }}
        />
      </CSidebar>
    </React.Suspense>
  );
};

export default DefaultSidebar;
