import React, { Suspense } from "react";
import { CContainer, CSpinner } from "@coreui/react";
import "react-notifications/lib/notifications.css";
import { Outlet } from "react-router";

const DefaultContent = () => {
  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Outlet />
      </Suspense>
    </CContainer>
  );
};

export default React.memo(DefaultContent);
