export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PROPERTIES_SIZE = 30;
export const TABLE_BORDER_COLOR = "#f1f5f1";
export const NO_RECORD = "No Record Found.";

export const ACCESS_ROLE = {
  1: "ADMIN",
};

export const DIFFICULTY_COLOR = {
  0: "#5dc61a", // easy
  1: "#f0d43a", // medium
  2: "#cd3b4f", // hard
  4: "#9d0a1e", // insance
};

export const DIFFICULTY_LABEL = {
  0: "Easy",
  1: "Medium",
  2: "Hard",
  3: "Insane",
};

export const DIFFICULTY = [
  {
    name: "Easy",
    code: 0,
  },
  { name: "Medium", code: 1 },
  { name: "Hard", code: 2 },
  { name: "Insane", code: 3 },
];

export const OPERATING_SYSYTEMS = [
  { code: 0, value: "Linux", label: "Linux" },
  { code: 1, value: "Windows", label: "Windows" },
  { code: 2, value: "Android", label: "Android" },
  { code: 3, value: "FreeBSD", label: "FreeBSD" },
  { code: 4, value: "OpenBSD", label: "OpenBSD" },
  { code: 5, value: "Other", label: "Other" },
];

export const SCENARIO_CATEGORIES = [
  { code: 0, value: "Cryptography", label: "Cryptography" },
  { code: 1, value: "Stenograpgy", label: "Stenograpgy" },
  { code: 2, value: "Binary Exploitation", label: "Binary Exploitation" },
  { code: 3, value: "Web Exploitation", label: "Web Exploitation" },
  { code: 4, value: "API Explotation", label: "API Explotation" },
  { code: 5, value: "Forensics", label: "Forensics" },
  { code: 6, value: "Reverse Engineering", label: "Reverse Engineering" },
  {
    code: 7,
    value: "Programming - Code Review",
    label: "Programming - Code Review",
  },
  { code: 8, value: "Packet Analysis", label: "Packet Analysis" },
  { code: 9, value: "Hardware", label: "Hardware" },
  { code: 10, value: "IoT", label: "IoT" },
  { code: 11, value: "Miscellaneous", label: "Miscellaneous" },
  { code: 12, value: "Other", label: "Other" },
];

export const USER_ROLE = {
  ADMIN: 1,
};

export const USER_STATUS = [
  { code: 0, value: "Inactive", label: "Inactive" },
  { code: 1, value: "Active", label: "Active" },
];

export const MACHINE_REQUEST_STATUS = [
  {
    code: 0,
    value: "New",
    label: "New",
  },
  {
    code: 1,
    value: "Review",
    label: "Review",
  },
  {
    code: 2,
    value: "Approved",
    label: "Approved",
  },
  {
    code: 3,
    value: "Rejected",
    label: "Rejected",
  },
  {
    code: 4,
    value: "Rectification",
    label: "Rectification",
  },
  {
    code: 5,
    value: "Published",
    label: "Published",
  },
];

export const TASK_CATEGORY = [
  {
    code: 0,
    value: "draft",
    label: "Draft",
  },
  {
    code: 1,
    value: "active",
    label: "Active",
  },
  {
    code: 2,
    value: "retired",
    label: "Retired",
  },
  {
    code: 3,
    value: "removed",
    label: "Removed",
  },
];

export const BUG_STATUS = [
  {
    code: 0,
    value: "New",
    label: "New",
  },
  {
    code: 1,
    value: "Progress",
    label: "Progress",
  },
  {
    code: 2,
    value: "Completed",
    label: "Completed",
  },
];

export const SERVICE_ANSWER_TYPE = [
  {
    code: 0,
    value: "text",
    label: "Text",
  },
  {
    value: "button",
    label: "Button",
  },
];

export const CATEGORY_STATUS = [
  {
    value: 1,
    label: "Active",
  },
  {
    value: 0,
    label: "Inactive",
  },
];
export const CATEGORY = [
  {
    value: "CarpetCleaning",
    label: "Carpet Cleaning",
  },
  {
    value: "UpholsteryCleaning",
    label: "Upholstery Cleaning",
  },
  {
    value: "CommercialCleaning",
    label: "Commercial Cleaning",
  },
  {
    value: "AirDuct",
    label: "AirDuct & Vent Cleaning",
  },
  {
    value: "TileGrout",
    label: "Tile & Grout Cleaning",
  },
  {
    value: "RugCleaning",
    label: "Rug Cleaning",
  },
  {
    value: "Other",
    label: "Other",
  },
];
export const QUOTE_STATUS = [
  {
    value: 1,
    label: "Closed",
  },
  {
    value: 0,
    label: "Open",
  },
];

export const ACCESSIBLE_USERS = [
  {
    code: 0,
    value: "Free",
    label: "Free",
  },
  // {
  //   code: 1,
  //   value: "VIP",
  //   label: "VIP",
  // },
  // {
  //   code: 2,
  //   value: "VIP_Plus",
  //   label: "VIP Plus",
  // },
];

export const DIFFICULTY_RATING = [
  {
    label: "Piece of cake",
    value: 1,
    id: "PieceOfCake",
  },
  {
    label: "Very easy",
    value: 2,
    id: "VeryEasy",
  },
  {
    label: "Easy",
    value: 3,
    id: "Easy",
  },
  {
    label: "Not too easy",
    value: 4,
    id: "NotTooEasy",
  },
  {
    label: "Medium",
    value: 5,
    id: "Medium",
  },
  {
    label: "Bit hard",
    value: 6,
    id: "BitHard",
  },
  {
    label: "Hard",
    value: 7,
    id: "Hard",
  },
  {
    label: "Too hard",
    value: 8,
    id: "TooHard",
  },
  {
    label: "Extremely Hard",
    value: 9,
    id: "ExtremelyHard",
  },
  {
    label: "Insane",
    value: 10,
    id: "Insane",
  },
];

export const DIFFICULTY_LABELS_BY_KEYS = {
  PieceOfCake: {
    label: "Piece of cake",
    code: 1,
  },
  VeryEasy: {
    label: "Very easy",
    code: 2,
  },
  Easy: {
    label: "Easy",
    code: 3,
  },
  NotTooEasy: {
    label: "Not too easy",
    code: 4,
  },
  Medium: {
    label: "Medium",
    code: 5,
  },
  BitHard: {
    label: "Bit hard",
    code: 6,
  },
  Hard: {
    label: "Hard",
    code: 7,
  },
  TooHard: {
    label: "Too hard",
    code: 8,
  },
  ExtremelyHard: {
    label: "Extremely hard",
    code: 9,
  },
  Insane: {
    label: "Insane",
    code: 10,
  },
};

export const Hypervisors = ["hvm"];
export const SERVICE_TYPE = [
  {
    value: 0,
    label: "Commercial",
  },
  {
    value: 1,
    label: "Residential",
  },
  {
    value: 2,
    label: "Both",
  },
];
