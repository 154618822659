import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import Spinner from "views/components/Spinner";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import { FaTrash, FaAngleDown, FaAngleUp, FaEye } from "react-icons/fa";
import { isArray, isEmpty, isObject } from "lodash";

import {
  getEnquiryList,
  resetComponentStore,
  deleteEnquiry,
  getEnquiryListCsv,
} from "actions/admin/enquiry";
import * as Constants from "constants/index";
import FilterComponent from "./Filters";

const Stack = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.alignItems || "initial"};
  -webkit-box-align: center;
  & > div:not(:first-child) {
    margin: 0px;
    margin-left: ${(props) =>
      props.direction !== "column" ? (props.gap || 1) * 8 + "px" : "0px"};
  }
`;

const CustomStyle = {
  table: {
    style: {
      zIndex: 10,
    },
  },
  headRow: {
    style: {
      height: "60px",
      borderTopWidth: "1px",
      borderTopStyle: "solid",
      borderBottomWidth: "2px",
      fontSize: "15px !important",
      backgroundColor: "#eeefef",
    },
  },
};

const InquiryList = ({
  getEnquiryList,
  getEnquiryListCsv,
  loadingListData,
  deleteEnquiry,
  enquiryList: { data, count, page },
  sortingParams,
  resetComponentStore,
  enquiriesCsvLoading,
}) => {
  const initialSortingParams = {
    limit: 30,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };
  const [enquiryParams, setEnquiryParams] = useState(initialSortingParams);
  const [openModal, setOpenModal] = useState(false);
  const [onlyOnce, setOnce] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState({});
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const onOpenModal = (enquiry) => {
    setSelectedEnquiry(enquiry);
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteInquiry = async (inquiriesList) => {
    if (window.confirm("Are you sure you want to delete")) {
      const deleted = await deleteEnquiry(inquiriesList);
      if (deleted.status) {
        setToggleCleared(!toggleCleared);
        setSelectedRows([]);
        getEnquiryList();
      }
    }
  };

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const columns = [
    {
      name: "Name",
      sortable: true,
      width: "220px",
      field: "name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      sortable: true,
      width: "300px",
      field: "email",
      selector: (row) => row.email,
    },
    {
      name: "Mobile Number",
      sortable: true,
      width: "200px",
      field: "mobile",
      selector: (row) => row.mobile,
    },
    {
      name: "Date",
      sortable: true,
      width: "180px",
      field: "createdAt",
      selector: (row) => row.createdAt,
      format: (row) => moment(row.createdAt).format("lll"),
    },
    {
      name: "Actions",
      width: "160px",
      selector: (row) => row.actions,
      format: (row) => {
        const _id = row._id;
        return (
          <Stack>
            <div>
              <Button
                title="View"
                target="_blank"
                color="primary"
                size="sm"
                style={{ margin: 5, color: "white" }}
                onClick={() => onOpenModal(row)}
              >
                <FaEye />
              </Button>
              <Button
                title="Delete"
                target="_blank"
                color="danger"
                size="sm"
                style={{ margin: 5, color: "white" }}
                onClick={() => handleDeleteInquiry({ inquiriesList: [row] })}
              >
                <FaTrash />
              </Button>
            </div>
          </Stack>
        );
      },
    },
  ];
  useEffect(() => {
    let subscribe = true;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          if (onlyOnce) {
            resetComponentStore();
            setOnce(false);
          }
          getEnquiryList(enquiryParams);
        }
      } catch (err) {
        // throw err;
      }
    };
    asyncParams();
    return () => {
      subscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiryParams]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setEnquiryParams((prevParams) => ({
      ...prevParams,
      limit: newPerPage,
      page,
    }));
  };

  const handlePageChange = async (page) => {
    setEnquiryParams((prevParams) => ({ ...prevParams, page }));
  };

  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    const params = {
      page: type === "search" ? 1 : page || 1,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: type === "search" ? searchText : enquiryParams.query,
    };
    setEnquiryParams((param) => ({ ...param, ...params }));
  };

  const onSearch = (text) => {
    handleTableChange("search", {
      searchText: text,
      sizePerPage: enquiryParams.limit,
      sortOrder: enquiryParams.ascending,
    });
  };

  const handleSort = (column, sortDirection) => {
    const orderBy = column.field;
    setEnquiryParams((prevParams) => ({
      ...prevParams,
      orderBy,
      ascending: sortDirection,
    }));
  };

  const removeFilter = (element, filters) => {
    const set = new Set(filters);
    set.delete(element);
    return Array.from(set);
  };
  const addFilters = (element, filters) => {
    const set = new Set(filters);
    set.add(element);
    return Array.from(set);
  };
  const getValue = (data) => {
    if (isObject(data) && data.hasOwnProperty("value")) return data.value;
    else if (isArray(data)) return data.map((obj) => obj.value);
    return data;
  };

  const onFilterChange = (name, value) => {
    setEnquiryParams((params) => {
      let filters = [];
      let query = params.query || {};
      if (isEmpty(value)) {
        filters = removeFilter(name, params.filters);
        delete query[name];
      } else {
        filters = addFilters(name, params.filters);
        query[name] = {
          value: getValue(value),
          type: "String",
        };
      }
      const filterParams = {
        filters,
        query,
      };
      return {
        ...params,
        ...filterParams,
      };
    });
  };

  useEffect(() => {
    let subscribe = true;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          setEnquiryParams((params) => ({
            ...params,
            ...sortingParams,
            page: parseInt(page),
          }));
        }
      } catch (err) {
        // throw err;
      }
    };

    asyncParams();
    return () => {
      subscribe = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickExport = async () => {
    await getEnquiryListCsv(enquiryParams);
  };

  return (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Stack
                alignItems="center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <h2>Inquiry List</h2>
                </div>
                <div>
                  {selectedRows.length ? (
                    <div style={{ display: "inline" }}>
                      <p style={{ display: "inline", marginRight: "10px" }}>
                        {selectedRows.length} Selected
                      </p>
                      <Button
                        disabled={enquiriesCsvLoading}
                        onClick={() =>
                          handleDeleteInquiry({
                            inquiriesList: selectedRows,
                          })
                        }
                        style={{ backgroundColor: "red", marginRight: "20px" }}
                      >
                        {enquiriesCsvLoading ? <Spinner /> : <>Delete</>}
                      </Button>
                    </div>
                  ) : null}
                  <Button
                    disabled={enquiriesCsvLoading}
                    onClick={onClickExport}
                  >
                    {" "}
                    {enquiriesCsvLoading ? <Spinner /> : <>Export CSV</>}
                  </Button>
                </div>
              </Stack>
            </CardHeader>
            <CardBody>
              <div className="add-button-div border-bottom">
                <div
                  className="filters-row"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h5>Filters</h5>
                  </div>
                  <div onClick={() => setFilterOpen(!filterOpen)}>
                    {filterOpen ? (
                      <FaAngleUp
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <FaAngleDown
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={data}
                subHeader={filterOpen}
                subHeaderComponent={
                  <FilterComponent
                    onSearch={onSearch}
                    onFilter={onFilterChange}
                  />
                }
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
                progressPending={loadingListData}
                pagination={true}
                paginationServer
                onSort={handleSort}
                sortServer
                paginationTotalRows={count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationRowsPerPageOptions={[30, 50, 100]}
                paginationPerPage={
                  enquiryParams.limit ? enquiryParams.limit : 30
                }
                customStyles={CustomStyle}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={openModal} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>
          <label htmlFor="enquiry">Inquiry</label>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <p>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                Name:{" "}
              </span>
              {selectedEnquiry.name}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Email: </span>
              {selectedEnquiry.email}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Mobile Number: </span>
              {selectedEnquiry.mobile}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Inquiry: </span>
              {selectedEnquiry.message}
            </p>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

InquiryList.propTypes = {
  getEnquiryList: PropTypes.func.isRequired,
  getEnquiryListCsv: PropTypes.func.isRequired,
  deleteEnquiry: PropTypes.func.isRequired,
  resetComponentStore: PropTypes.func.isRequired,
  sortingParams: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  enquiryList: state.enquiry.enquiryList,
  loadingListData: state.enquiry.loadingEnquiryList,
  loadingEnquiry: state.enquiry.loadingEnquiry,
  sortingParams: state.enquiry.sortingParams,
  enquiriesCsvLoading: state.enquiry.enquiriesCsvLoading,
});

export default connect(mapStateToProps, {
  getEnquiryList,
  resetComponentStore,
  deleteEnquiry,
  getEnquiryListCsv,
})(InquiryList);
