import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CKEditor } from "ckeditor4-react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
} from "reactstrap";

import { validateForm } from "utils/misc";

import {
  getPropertyByID,
  editProperty,
  loadProperty,
  cancelSave,
  setErrors,
  removeErrors,
} from "actions/admin/properties";
import Spinner from "views/components/Spinner";

import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate, Link } from "react-router-dom";

let images = [];
let imageUrls = [];
let oldImageUrl = [];
const EditProperty = ({
  getPropertyByID,
  currentProperty,
  editProperty,
  cancelSave,
  errorList,
  loadProperty,
  loading,
  setErrors,
  removeErrors,
}) => {
  const params = useParams();
  const history = useNavigate();
  const initialState = {
    title: "",
    description: "",
    price_full_unit: "",
    price_per_room: "",
    tour_link: "",
    property_image: [],
    property_image_url: [],
    old_image_url: [],
    deleted_image: [],
  };
  const [formData, setFormData] = useState(initialState);

  React.useEffect(() => {
    let load = true;
    const asyncCall = async () => {
      if (load && params.id) {
        getPropertyByID(params.id);
      }
    };

    asyncCall();
    return () => {
      load = false;
    };
  }, [getPropertyByID, params.id]);

  React.useEffect(() => {
    if (!currentProperty) return;

    setFormData({
      ...formData,
      title: currentProperty.title,
      description: currentProperty.description,
      price_full_unit: currentProperty.price_full_unit,
      price_per_room: currentProperty.price_per_room,
      tour_link: currentProperty.tour_link,
      old_image_url: currentProperty.property_images,
    });
    if (currentProperty && currentProperty.property_images) {
      oldImageUrl = currentProperty.property_images;
    }
  }, [currentProperty]);

  const onChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "property_image":
        const property_image_file = event.target.files;
        // const property_file_url = URL.createObjectURL(property_image_file);
        // images.push(property_image_file);
        // imageUrls.push({ url: property_file_url });
        // setFormData({
        //   ...formData,
        //   property_image: images,
        //   property_image_url: imageUrls,
        // });
        for (let items of property_image_file) {
          const property_file_url = URL.createObjectURL(items);
          const propertyImageReader = new FileReader();
          propertyImageReader.onload = async () => {
            images.push(propertyImageReader.result);
            imageUrls.push(property_file_url);
            setFormData({
              ...formData,
              property_image: images,
              property_image_url: imageUrls,
            });
          };
          propertyImageReader.readAsDataURL(items);
        }
        break;
      case "title":
        setFormData({
          ...formData,
          [event.target.name]: value.replace(/[^a-z0-9 -]/gi, ""),
        });
        break;
      case "price_full_unit":
      case "price_per_room":
        setFormData({
          ...formData,
          [event.target.name]: value.replace(/[^0-9.]/gi, ""),
        });
        break;
      default:
        setFormData({
          ...formData,
          [event.target.name]: value.replace(/[^a-z0-9]/gi, "")
            ? value
            : value.trim(),
        });
    }
  };

  const deleteImages = (img, index) => {
    images.splice(index, 1);
    imageUrls.splice(index, 1);
    setFormData({
      ...formData,
      property_image: images,
      property_image_url: imageUrls,
    });
  };

  const deleteOldImages = (img, index) => {
    const image = oldImageUrl[index];
    oldImageUrl.splice(index, 1);
    setFormData({
      ...formData,
      old_image_url: oldImageUrl,
      deleted_image: [...formData.deleted_image, image],
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    if (!formData.property_image_url.length && !formData.old_image_url.length) {
      setErrors([
        {
          msg: "Please Select Atleast one Image",
          param: "property_image",
        },
      ]);
      return;
    }
    const validationParam = [
      {
        param: "title",
        msg: "Title is required.",
      },
      {
        param: "price_full_unit",
        msg: "Price is Required",
        type: "number",
      },
      {
        param: "price_per_room",
        msg: "Price is Required",
        type: "number",
      },
      {
        param: "description",
        msg: "Description is required.",
      },
    ];
    if (!validateForm(formData, validationParam, setErrors)) return;
    // const data = new FormData();

    // for (const key in formData) {
    //   if (key !== "property_image_url") {
    //     if (key === "property_image") {
    //       for (const id of formData.property_image) {
    //         data.append("property_image", id);
    //       }
    //     } else if (key === "old_image_url" || key === "deleted_image") {
    //       data.append(key, JSON.stringify(formData[key]));
    //     } else {
    //       data.append(key, formData[key]);
    //     }
    //   }
    // }

    // console.log("submit data", data.get("old_image_url"));
    editProperty(formData, history, params.id);
    images = [];
    imageUrls = [];
    oldImageUrl = [];
  };

  const clearImageSelections = function () {
    images = [];
    imageUrls = [];
    oldImageUrl = [];
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <span className="pull-left h5">Edit Property</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="title">
                          Title <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="title"
                          name="title"
                          value={formData.title}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                          // invalid={errorList.property_title ? true : false}
                        />
                        <Errors
                          current_key="property_title"
                          key="property_title"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="price_full_unit">
                          Price Full Unit - $ <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="price_full_unit"
                          name="price_full_unit"
                          required
                          value={formData.price_full_unit}
                          onChange={(e) => onChange(e)}
                          // invalid={errorList.price ? true : false}
                        />
                        <Errors
                          current_key="price_full_unit"
                          key="price_full_unit"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="price_per_room">
                          Price Per Room - $ <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="price_per_room"
                          name="price_per_room"
                          required
                          value={formData.price_per_room}
                          onChange={(e) => onChange(e)}
                          // invalid={errorList.price ? true : false}
                        />
                        <Errors
                          current_key="price_per_room"
                          key="price_per_room"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="tour_link">
                          3D Tour Link <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="tour_link"
                          name="tour_link"
                          value={formData.tour_link}
                          onChange={(e) => onChange(e)}
                          // invalid={errorList.tour_link ? true : false}
                        />
                        <Errors current_key="tour_link" key="tour_link" />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label htmlFor="property_image">
                          Property Images <span>*</span>
                        </Label>
                        <Input
                          type="file"
                          id="property_image"
                          name="property_image"
                          multiple
                          onChange={(e) => onChange(e)}
                          accept="image/*"
                          // invalid={errorList.heading ? true : false}
                        />
                        <Errors
                          current_key="property_image"
                          key="property_image"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} className="previewImages">
                      {formData.old_image_url &&
                        formData.old_image_url.map((item, ind) => (
                          <div key={`image-${ind}`}>
                            <Button
                              type="button"
                              className=""
                              size="sm"
                              color="danger"
                              onClick={() => deleteOldImages(item, ind)}
                            >
                              <i className="fa fa-ban"></i> X
                            </Button>
                            <img
                              src={item.fullURL}
                              className="preview-img img-fluid"
                              alt="image_file"
                            />
                          </div>
                        ))}
                      {formData.property_image_url &&
                        formData.property_image_url.map((item, ind) => (
                          <div key={`image-${ind}`}>
                            <Button
                              type="button"
                              className=""
                              size="sm"
                              color="danger"
                              onClick={() => deleteImages(item, ind)}
                            >
                              <i className="fa fa-ban"></i> X
                            </Button>
                            <img
                              src={item}
                              className="preview-img img-fluid"
                              alt="image_file"
                            />
                          </div>
                        ))}
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label htmlFor="description">
                          Description <span>*</span>
                        </Label>
                        <Input
                          type="textarea"
                          id="description"
                          name="description"
                          required
                          value={formData.description}
                          onChange={(e) => onChange(e)}
                          // invalid={errorList.description ? true : false}
                        />
                        <Errors current_key="description" key="description" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                    loading={loading}
                    disabled={loading}
                    style={{ width: "60px" }}
                  >
                    <i className="fa fa-dot-circle-o"></i>{" "}
                    {loading ? (
                      <Spinner style={{ fontSize: "20px" }} />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  <Link to="/dashboard/properties">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                      onClick={clearImageSelections}
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditProperty.propTypes = {
  loading: PropTypes.bool.isRequired,
  getPropertyByID: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.properties.loadingProperty,
  currentProperty: state.properties.currentProperty,
});
export default connect(mapStateToProps, {
  getPropertyByID,
  editProperty,
  cancelSave,
  loadProperty,
  setErrors,
  removeErrors,
})(EditProperty);
