import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  getServiceList,
  deleteService,
  changeStatus,
  resetComponentStore,
  updateServiceType,
} from "actions/admin/service";
import * as Constants from "constants/index";
import DataTable from "react-data-table-component";
import { SERVICE_TYPE } from "constants";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  FormGroup,
  CardHeader,
} from "reactstrap";
import Spinner from "views/components/Spinner";
import styled from "styled-components";
import { FaAngleDown, FaAngleUp, FaPencilAlt, FaTrash } from "react-icons/fa";
import FilterComponent from "./Filters";
import { isArray, isEmpty, isObject } from "lodash";

const Stack = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.alignItems || "initial"};
  -webkit-box-align: center;
  & > div:not(:first-child) {
    margin: 0px;
    margin-left: ${(props) =>
      props.direction !== "column" ? (props.gap || 1) * 8 + "px" : "0px"};
  }
`;

const ServiceList = ({
  getServiceList,
  deleteService,
  history,
  serviceList: { data, count, page },
  sortingParams,
  changeStatus,
  performDelete,
  loading,
  loadingListData,
  resetComponentStore,
  updateServiceType,
}) => {
  const initialSortingParams = {
    limit: 10,
    page: 1,
    orderBy: "updated_at",
    ascending: "desc",
    query: "",
    filters: [],
  };
  const [serviceParams, setServiceParams] = useState(initialSortingParams);
  const [onlyOnce, setOnce] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);

  let delayTimer;

  // const headerSortingStyle = { backgroundColor: '#c8e6c9' };
  // Removed and Replaced from sizePerPageRenderer
  // const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
  //   <li
  //     key={text}
  //     role="presentation"
  //     className="dropdown-item"
  //     onMouseDown={e => {
  //       e.preventDefault();
  //       onSizePerPageChange(page);
  //     }}
  //   >
  //     <Button
  //       tabIndex="-1"
  //       role="menuitem"
  //       data-page={page}
  //       className="sizePerPageaTag"
  //     >
  //       {text}
  //     </Button>
  //   </li>
  // );

  const columns = [
    {
      name: "Name",
      minWidth: "150px",
      sortable: true,
      selector: (row) => row.name,
    },
    {
      name: "Category",
      minWidth: "150px",
      sortable: true,
      selector: (row) => row.categoryDetail[0]?.name,
    },
    {
      name: "Location",
      minWidth: "200px",
      sortable: true,
      selector: (row) => row,
      format: (cellContent, row) =>
        cellContent.locationObjects.map(function (val, ind) {
          return <li>{val.location.title}</li>;
        }),
    },
    {
      name: "Price",
      minWidth: "150px",
      sortable: true,
      selector: (row) => row.price,
      format: (cellContent, row) =>
        cellContent.price < 0 ? "---" : cellContent.price,
    },
    {
      name: "Created At",
      sortable: true,
      selector: (row) => row.updated_at,
      // headerSortingStyle,
      format: (cellContent, row) => moment(cellContent.createdAt).format("ll"),
    },
    {
      name: "Service Type",
      sortable: true,
      selector: (row) => row.serviceType,
      format: (cellContent, row) => (
        <select
          onChange={(e) => handleServiceType(e, cellContent._id)}
          value={cellContent.serviceType}
        >
          <option value="0">Commercial</option>
          <option value="1">Residential</option>
          <option value="2">Both</option>
        </select>
      ),
    },
    {
      name: "Actions",
      selector: (row) => row,
      format: (cellContent, row) => (
        <div>
          <Link to={`/home/services/${cellContent._id}/edit`} title="edit/view">
            <Button
              type="button"
              style={{ marginLeft: 4, color: "white" }}
              size="sm"
              color="success"
            >
              <FaPencilAlt />
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            style={{ marginLeft: 4, color: "white" }}
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to delete :\r ${cellContent.name}?`
                )
              ) {
                handleDelete(cellContent._id, history);
              }
            }}
          >
            <FaTrash />
          </Button>
        </div>
      ),
    },
  ];

  // const handleSearch = (e, onSearch, searchDelay) => {
  //   clearTimeout(delayTimer);
  //   const text = e.target.value;
  //   delayTimer = setTimeout(() => {
  //     onSearch(text);
  //   }, searchDelay);
  // };

  // const defaultSorted = [
  //   {
  //     dataField: "created_at",
  //     order: "desc",
  //   },
  // ];

  // const customTotal = (from, to, size) => (
  //   <span className="react-bootstrap-table-pagination-total">
  //     Showing {from} to {to} of {size} Results
  //   </span>
  // );

  // const sizePerPageRenderer = ({
  //   options,
  //   currSizePerPage,
  //   onSizePerPageChange,
  // }) => (
  //   <span className="custom-paginator">
  //     <span>Records per page: </span>
  //     <UncontrolledDropdown tag="span">
  //       <DropdownToggle caret className="dropdown-btn-col-w dropdown-p-28">
  //         {currSizePerPage}
  //       </DropdownToggle>
  //       <DropdownMenu>
  //         {options.map((option) => (
  //           <DropdownItem
  //             tag="li"
  //             key={option.text}
  //             onMouseDown={(e) => {
  //               e.preventDefault();
  //               onSizePerPageChange(option.page);
  //             }}
  //           >
  //             {option.text}
  //           </DropdownItem>
  //         ))}
  //       </DropdownMenu>
  //     </UncontrolledDropdown>
  //   </span>
  // );

  // const options = {
  //   page: parseInt(serviceParams.page),
  //   pageStartIndex: 1,
  //   // alwaysShowAllBtns: true, // Always show next and previous button
  //   withFirstAndLast: false, // Hide the going to First and Last page button
  //   // hideSizePerPage: true, // Hide the sizePerPage dropdown always
  //   hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

  //   showTotal: true,
  //   paginationTotalRenderer: customTotal,
  //   totalSize: count,
  //   sizePerPageRenderer,
  //   //sizePerPageOptionRenderer,
  //   sizePerPageList: [
  //     {
  //       text: Constants.DEFAULT_PAGE_SIZE,
  //       value: Constants.DEFAULT_PAGE_SIZE,
  //     },
  //     {
  //       text: "20",
  //       value: 20,
  //     },
  //     {
  //       text: "All",
  //       value: count,
  //     },
  //   ], // A numeric array is also available. the purpose of above example is custom the text
  // };

  const handleTableChange = (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    const params = {
      page: type === "search" ? 1 : page || 1,
      limit: sizePerPage,
      orderBy: sortField,
      ascending: sortOrder,
      query: type === "search" ? searchText : serviceParams.query,
    };
    //console.log(params);
    // getServiceList(params);
    setServiceParams((param) => ({ ...param, ...params }));
  };
  useEffect(() => {
    let subscribe = true;
    let updateTimer;
    const asyncParams = async () => {
      try {
        if (subscribe) {
          if (onlyOnce) {
            resetComponentStore();
            setOnce(false);
          }
          getServiceList(serviceParams);
          // updateTimer = setInterval(function() {
          //   getServiceList(serviceParams);
          // }, process.env.REACT_APP_UPDATE_LIST_TIMER);
        }
      } catch (err) {
        // throw err;
      }
    };
    asyncParams();
    return () => {
      subscribe = false;
      clearInterval(updateTimer);
    };
  }, [getServiceList, serviceParams, resetComponentStore, onlyOnce]);

  useEffect(() => {
    /**
     * Due to asynchronous behavior it is possible that we are dealing with
     * Old value using setState({...params, propery:value });
     * So, to eliminate this situation we are doing function update such as below
     */
    let subscribe = true;
    const asyncParams = async () => {
      if (subscribe) {
        performDelete &&
          setServiceParams((params) => ({
            ...params,
            ...sortingParams,
            page: parseInt(page),
          }));
      }
    };
    asyncParams();
    return () => {
      subscribe = false;
    };
  }, [page, performDelete, sortingParams]);

  const onSearch = (text) => {
    handleTableChange("search", {
      searchText: text,
      sizePerPage: serviceParams.limit,
      sortOrder: serviceParams.ascending,
    });
  };
  const handleSort = (column, sortDirection) => {
    const orderBy = column.field;
    setServiceParams((prevParams) => ({
      ...prevParams,
      orderBy,
      ascending: sortDirection,
    }));
  };
  const handleDelete = (_id) => {
    /* if(window.confirm("Are you sure you want to delete")) */
    deleteService(_id, history);
  };

  const handleServiceType = (e, _id) => {
    if (window.confirm("Are you sure you want to change the type?"))
      updateServiceType(_id, e.target.value, history);
  };

  const removeFilter = (element, filters) => {
    const set = new Set(filters);
    set.delete(element);
    return Array.from(set);
  };
  const addFilters = (element, filters) => {
    const set = new Set(filters);
    set.add(element);
    return Array.from(set);
  };
  const getValue = (data) => {
    if (isObject(data) && data.hasOwnProperty("value")) return data.value;
    else if (isArray(data)) return data.map((obj) => obj.value);
    return data;
  };
  const onFilterChange = (name, value) => {
    setServiceParams((params) => {
      let filters = [];
      let query = params.query || {};
      if (isEmpty(value)) {
        filters = removeFilter(name, params.filters);
        delete query[name];
      } else {
        filters = addFilters(name, params.filters);
        query[name] = {
          value: getValue(value),
          type: "String",
        };
      }
      const filterParams = {
        filters,
        query,
      };
      return {
        ...params,
        ...filterParams,
      };
    });
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setServiceParams((prevParams) => ({
      ...prevParams,
      limit: newPerPage,
      page,
    }));
  };

  const handlePageChange = async (page) => {
    setServiceParams((prevParams) => ({ ...prevParams, page }));
  };
  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Stack
                alignItems="center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <h2>Service List</h2>
                </div>
                <div className="add-button-div">
                  <Link to="/home/services/create" title="create">
                    <Button color="primary" size="sm">
                      <i className="fa fa-plus"></i> Add New Service
                    </Button>
                  </Link>
                </div>
              </Stack>
            </CardHeader>
            <CardBody>
              <div className="add-button-div border-bottom">
                <div
                  className="filters-row"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h5>Filters</h5>
                  </div>
                  <div onClick={() => setFilterOpen(!filterOpen)}>
                    {filterOpen ? (
                      <FaAngleUp
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <FaAngleDown
                        style={{
                          height: 35,
                          marginRight: 15,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={data}
                subHeader={filterOpen}
                subHeaderComponent={
                  <FilterComponent
                    onSearch={onSearch}
                    onFilter={onFilterChange}
                  />
                }
                progressPending={loadingListData}
                pagination
                paginationServer
                onSort={handleSort}
                sortServer
                paginationTotalRows={count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

ServiceList.propTypes = {
  getServiceList: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  serviceList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  sortingParams: PropTypes.object.isRequired,
  updateServiceType: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  serviceList: state.service.serviceList,
  loading: state.service.loadingServiceList,
  sortingParams: state.service.sortingParams,
  performDelete: state.service.performDelete,
  loadingListData: state.service.loadingListData,
});

export default connect(mapStateToProps, {
  getServiceList,
  deleteService,
  changeStatus,
  resetComponentStore,
  updateServiceType,
})(ServiceList);
