import axios from "../axios";
import { setAlert } from "actions/alert";
import { AMENITIES } from "constants/api";
import { GET_AMENITIES, LOADING_ON_AMENITIES_SUBMIT, AMENITIES_ERROR, AMENITIES_UPDATED, REMOVE_ERRORS, LOAD_AMENITIES, LOADING_AMENITIES } from "actions/types";
import { setErrorsList } from "actions/errors";
import { logout } from "actions/auth";

export const updateAmenities = (formData, history, _id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: LOADING_ON_AMENITIES_SUBMIT });

    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    try {
        const res = await axios.put(`${AMENITIES.UPDATE_AMENITIES().ENDPOINT}`, formData, config);
        if (res.data.status === true) {
            dispatch({
                type: AMENITIES_UPDATED,
            });
            dispatch(
                setAlert(res.data.message, "success")
            );
            history("/dashboard/cms");
        } else {
            const errors = res.data.errors;
            if (errors) {
                dispatch({
                    type: AMENITIES_ERROR,
                });
                dispatch(setAlert(res.data.message, "danger"));
                errors.forEach((error) => {
                    dispatch(setErrorsList(error.msg, error.param));
                });
            }
        }
        return res.data ? res.data : { status: false };
    } catch (err) {
        if (err.response) {
            if (err.response.data && err.response.data.tokenStatus === 0) {
                dispatch(logout());
            } else {
                dispatch({
                    type: AMENITIES_ERROR,
                    payload: {
                        msg: err.response.statusText,
                        status: err.response.status,
                    },
                });
                dispatch(
                    setAlert(
                        err.response.data.message || err.response.statusText,
                        "danger"
                    )
                );
            }
        }
    }
}

export const getAmenities = () => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: LOADING_AMENITIES });
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await axios.get(
            `${AMENITIES.UPDATE_AMENITIES().ENDPOINT}`,
            config
        );
        // dispatch({ type: REMOVE_ALERT });

        await dispatch({
            type: GET_AMENITIES,
            payload: res.data.response,
        });
        return res.data ? res.data.response : { status: false };
    } catch (err) {
        // console.log(err);
        if (err.response) {
            if (err.response.data && err.response.data.tokenStatus === 0) {
                dispatch(logout());
            } else {
                dispatch({
                    type: AMENITIES_ERROR,
                    payload: {
                        msg: err.response.statusText,
                        status: err.response.status,
                    },
                });
                dispatch(
                    setAlert(
                        err.response.data.message || err.response.statusText,
                        "danger"
                    )
                );
            }
        }
    }
};
// reset errors
export const removeErrors = () => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
};

export const setErrors = (errors) => async (dispatch) => {
    if (errors) {
        dispatch({ type: AMENITIES_ERROR });
        dispatch(setAlert("Please correct the following errors", "danger"));
        errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
        });
    }
};

export const loadingAmenities = () => async (dispatch) => {
    await dispatch({ type: LOAD_AMENITIES });
};