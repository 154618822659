import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { connect } from "react-redux";
import { create, removeErrors, cancelSave } from "actions/admin/banner";

import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { validateForm } from "utils/misc";
import LoadingButton from "views/components/LoadingButton";
import Errors from "views/components/Notifications/Errors";

const AddBanner = ({
  create,
  errorList,
  loading,
  setErrors,
  removeErrors,
  cancelSave,
}) => {
  const history = useNavigate();
  //########################## set the initial form data ##############################

  const initialState = {
    title: "",
    heading: "",
    description: "",
  };
  const [formData, setFormData] = useState(initialState);

  const { title, heading, description } = formData;

  //########################## change event for all inputs ##############################
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    console.log("on submit", formData);
    removeErrors();
    const validationParam = [
      {
        param: "title",
        value: formData.title,
        cond: (value) => {
          const regex = new RegExp(/\w/);
          return regex.test(value);
        },
        msg: "Please, provide the title of the banner.",
      },
      {
        param: "heading",
        value: formData.heading,
        cond: (value) => {
          const regex = new RegExp(/\w/);
          return regex.test(value);
        },
        msg: "Please, provide the title of the banner.",
      },
      {
        param: "description",
        msg: "Please provide the description",
      },
    ];
    if (!validateForm(formData, validationParam, setErrors)) return;

    const submitData = {};
    const excludeList = {
      avatarURL: 1,
    };

    for (let i in formData) {
      const data = formData[i];
      if (data === "" || data === null || data === undefined || excludeList[i])
        continue;
      submitData[i] = data;
    }
    create(formData, history);
  };

  // const handleDescriptionChange = (event /* editor */) => {
  //   const data = event.editor.getData(); //editor.getData();
  //   setFormData((ev) => ({ ...ev, description: data }));
  // };
  const onChangeDescription = (newDescription) => {
    setFormData((data) => ({ ...data, description: newDescription }));
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe) {
        //loadPage();
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, []);

  return (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
        <Row style={{ marginLeft: 10, marginTop: "4%" }}>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <h2>Add Banner</h2>
              </CardHeader>
            </Card>
          </Col>
          <Col xs="12">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="title">
                        Title <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="title"
                        name="title"
                        maxLength="25"
                        value={title}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.title ? true : false}
                      />
                      <Errors current_key="title" key="title" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="title">
                        Heading <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="heading"
                        name="heading"
                        value={heading}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.heading ? true : false}
                      />
                      <Errors current_key="heading" key="heading" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="status">
                        Description <span>*</span>
                      </Label>
                      {/* <CKEditor
                        data={description}
                        id="description"
                        name="description"
                        config={{
                          height: 100,
                          toolbar: [
                            ["Cut", "Copy", "Paste"],
                            ["Undo", "Redo"],
                            ["SpellChecker"],
                            ["Link", "Unlink", "Anchor"],
                            [
                              "Image",
                              "Table",
                              "Horizontal Line",
                              "Special Character",
                            ],
                            ["Maximize"],
                            ["Source"],
                            ["Bold", "Italic", "Strike"],
                            ["RemoveFormat"],
                            ["NumberedList", "BulletedList"],
                            ["DecreaseIndent", "IncreaseIndent"],
                            ["BlockQuote"],
                            ["Styles"],
                            ["Format"],
                            ["About"],
                          ],
                        }}
                        onChange={(event) => handleDescriptionChange(event)}
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                        invalid={errorList.description ? true : false}
                      /> */}
                      <CKEditor
                        editor={Editor}
                        required
                        data={description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChangeDescription(data);
                        }}
                      />
                      <Errors current_key="description" key="description" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginLeft: 10, marginTop: "1%" }}>
          <Col>
            <Card>
              <CardFooter>
                <LoadingButton
                  className="me-3"
                  loading={loading}
                  disabled={loading}
                  color="primary"
                  type="submit"
                >
                  Submit
                </LoadingButton>
                <Button
                  type="reset"
                  size="md"
                  color="danger"
                  onClick={onClickHandel}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

AddBanner.propTypes = {
  loading: PropTypes.bool.isRequired,
  create: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: false,
});
export default connect(mapStateToProps, {
  create,
  removeErrors,
  cancelSave,
})(AddBanner);
