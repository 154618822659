/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

import { logout } from "actions/auth";
import { showSidebar } from "actions/admin/sidebar";

import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import DefaultSidebar from "./DefaultSidebar";
import DefaultContent from "./DefaultContent";

import { addNotification } from "actions/admin/notifications";
import { removeAlert } from "actions/alert";

// sidebar nav config
import navigation from "./_nav";

// This is required otherwise navbar will throw error
const navArray = navigation();

const Layout = ({ alerts, showSidebar, logout, removeAlert }) => {
  const [nav, setNavigation] = useState(navArray);
  const history = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe) {
        try {
          const updatedSidebar = navigation(actions);
          setNavigation(updatedSidebar);
        } catch (err) {}
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [location.pathname]);

  const createNotification = (type, message) => {
    switch (type) {
      case "info":
        return NotificationManager.info(message, "", 10000);
      case "success":
        return NotificationManager.success(message, "", 10000);
      case "warning":
        return NotificationManager.warning(message, "", 10000);
      case "danger":
        return NotificationManager.error(message, "", 10000);
    }
  };

  useEffect(() => {
    alerts !== null &&
      alerts.length > 0 &&
      alerts.map((alert) => {
        createNotification(`${alert.alertType}`, alert.msg);
      });
    if (alerts && alerts.length > 0) {
      setTimeout(() => removeAlert(), 0);
    }
  }, [alerts]);

  return (
    <div>
      {!!nav && nav.length > 0 && (
        <DefaultSidebar showSidebar={showSidebar} navigation={nav} />
      )}
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DefaultHeader logout={() => logout(history)} />
        <div className="body flex-grow-1 px-3">
          <NotificationContainer />
          <DefaultContent />
        </div>
        <DefaultFooter />
      </div>
    </div>
  );
};

Layout.prototype = {
  auth: PropTypes.object.isRequired,
  alerts: PropTypes.array.isRequired,
  showSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps, {
  removeAlert,
  addNotification,
  logout,
  showSidebar,
})(Layout);
