import Autocomplete from "react-google-autocomplete";
import React from "react";
const GMaps = (props) => {
  const val = props.data;

  const onChangeAddress = (place) => {
    props.changeAdd(place);
  };
  return (
    <Autocomplete
      className="form-control"
      apiKey={"AIzaSyAlVdtWDUZUKrZxSuTSj9wZF8IiQj2w0So"}
      style={{ width: "100%" }}
      onPlaceSelected={(place) => {
        onChangeAddress(place);
      }}
      options={{
        types: ["(regions)"],
        //componentRestrictions: { country: "in" },
      }}
      defaultValue={val}
    />
  );
};

export default GMaps;
