import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  CONTACT_ERROR,
  CONTACT_LIST_UPDATED,
  CONTACT_SEARCH_PARAMATERS_UPDATE,
  REMOVE_ERRORS,
  //INITIAL_LOADING,
  LOADING_ON_CONTACT_SUBMIT,
  LOAD_PAGE,
  LOADING_CONTACT_LIST_DATA,
  RESET,
  CONTACT_UPDATED,
  GET_CONTACT,
  LOAD_CONTACT,
  LOADING_CONTACT,
} from "actions/types";
import { CONTACT } from "constants/api";
import { logout } from "../auth";


export const updateContact = (formData, history, _id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch({ type: LOADING_ON_CONTACT_SUBMIT });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put(`${CONTACT.UPDATE_CONTACT().ENDPOINT}`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: CONTACT_UPDATED,
      });
      dispatch(
        setAlert(res.data.message, "success")
      );
      history("/dashboard/cms");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({
          type: CONTACT_ERROR,
        });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CONTACT_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
}

export const getContact = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch({ type: LOADING_CONTACT });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${CONTACT.GET_CONTACT().ENDPOINT}`,
      config
    );
    // dispatch({ type: REMOVE_ALERT });

    await dispatch({
      type: GET_CONTACT,
      payload: res.data.response,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CONTACT_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const loadingContact = () => async (dispatch) => {
  await dispatch({ type: LOAD_CONTACT });
};

//get users list
export const getContactList =
  (userParams = {}, noRedux) =>
    async (dispatch) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        dispatch({ type: REMOVE_ERRORS });
        dispatch({ type: LOADING_CONTACT_LIST_DATA });

        const query = userParams.query ? userParams.query : "";
        userParams.query = query;
        config.params = userParams;
        const res = await axios.get(`${CONTACT.LIST().ENDPOINT}`, config);
        dispatch({
          type: CONTACT_SEARCH_PARAMATERS_UPDATE,
          payload: userParams,
        });
        dispatch({
          type: CONTACT_LIST_UPDATED,
          payload: res.data.response[0],
        });
        return res.data.response[0];
      } catch (err) {
        // console.log(err);
        err.response &&
          dispatch({
            type: CONTACT_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
      }
    };
// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: CONTACT_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};
// Dispatch Loading
export const loadingOnContactSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_CONTACT_SUBMIT });
};
export const deleteContact = (_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnContactSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.delete(`${CONTACT.LIST().ENDPOINT}/${_id}`, config);
    if (res.data.status === true) {
      dispatch(setAlert("Contact deleted successfully", "success"));
      dispatch(getContactList());
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CONTACT_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history.push("/admin/page-not-found");
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_PAGE });
};
