import { SHOW_SIDEBAR, UNFOLD_SIDEBAR } from "actions/types";

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: true,
};

export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_SIDEBAR:
      return {
        ...state,
        sidebarShow: payload,
      };
    case UNFOLD_SIDEBAR:
      return {
        ...state,
        sidebarUnfoldable: payload,
      };
    default:
      return state;
  }
}
