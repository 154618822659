import React from "react";
import { InputGroup, Input, FormGroup, Button } from "reactstrap";
import { FaTimes } from "react-icons/fa";

const SearchComponent = ({ filterText, onFilter, onClear }) => {
  return (
    <FormGroup>
      <InputGroup>
        <Input
          id="search"
          name="search"
          type="text"
          placeholder="search by name"
          aria-label="search input"
          value={filterText}
          onChange={onFilter}
        />
        <Button onClick={onClear} color="danger">
          <FaTimes />
        </Button>
      </InputGroup>
    </FormGroup>
  );
};

export default SearchComponent;
