import React, { useState, useEffect, useRef } from "react";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import DateTimePicker from "react-datetime-picker";
import PropTypes from "prop-types";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { connect } from "react-redux";
import {
  updateCategory,
  loadPage,
  getCategoryById,
  cancelSave,
  setErrors,
  removeErrors,
} from "actions/admin/category";
import Errors from "views/components/Notifications/Errors";
// import Spinner from "views/Spinner";import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import { validateForm } from "utils/misc";
import { useNavigate, useParams } from "react-router";
import LoadingButton from "views/components/LoadingButton";
import { CATEGORY_STATUS } from "constants";
import Select from "react-select";
const EditCategory = ({
  updateCategory,
  getCategoryById,
  cancelSave,
  currentCategory,
  errorList,
  // showMsg,
  loadPage,
  loading,
  setErrors,
  removeErrors,
}) => {
  const history = useNavigate();
  const params = useParams();
  //########################## set the initial form data ##############################

  const initialState = {
    name: "",
    status: 1,
    description: "",
    slug: "",
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe && params.category_id) {
        getCategoryById(params.category_id);
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [getCategoryById, params.category_id]);

  useEffect(() => {
    const asyncCall = async () => {
      try {
        if (currentCategory) {
          const { name, status, description, slug } = currentCategory;
          setFormData({
            ...formData,
            name: name,
            status: status,
            description: description,
            slug: slug,
          });
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    asyncCall();
  }, [currentCategory]);
  //########################## change event for all inputs ##############################
  const onChange = (e) => {
    switch (e.target.name) {
      case "slug":
        const result = e.target.value.replace(/[^a-z-]/gi, "");
        setFormData({
          ...formData,
          [e.target.name]: result,
        });
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const onChangeDescription = (newDescription) => {
    setFormData((data) => ({ ...data, description: newDescription }));
  };
  const { name, status, description, slug } = formData;
  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    const validationParam = [
      {
        param: "name",
        value: formData.name,
        cond: (value) => {
          const regex = new RegExp(/\w/);
          return regex.test(value);
        },
        msg: "Please, provide the name of the category.",
      },
      {
        param: "slug",
        value: formData.slug,
        cond: (value) => {
          const regex = new RegExp(/\w/);
          return regex.test(value);
        },
        msg: "Please, provide the slug.",
      },
      {
        param: "description",
        msg: "Please provide the description",
      },
    ];
    if (!validateForm(formData, validationParam, setErrors)) return;

    const submitData = {};
    const excludeList = {
      avatarURL: 1,
    };

    for (let i in formData) {
      const data = formData[i];
      if (data === "" || data === null || data === undefined || excludeList[i])
        continue;
      submitData[i] = data;
    }
    updateCategory(submitData, history, params.category_id);
  };
  const addCategory = (value) => {
    console.log(value);
    setFormData({ ...formData, status: value.value });
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe) {
        loadPage();
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [loadPage]);

  return (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
        <Row style={{ marginLeft: 10, marginTop: "4%" }}>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <h2>Edit Category</h2>
              </CardHeader>
            </Card>
          </Col>
          <Col xs="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="name">
                        Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.name ? true : false}
                      />
                      <Errors current_key="name" key="name" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="slug">
                        Slug <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="slug"
                        name="slug"
                        value={slug}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.slug ? true : false}
                      />
                      <Errors current_key="slug" key="slug" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="status">
                        Status <span>*</span>
                      </Label>
                      <Select
                        options={CATEGORY_STATUS}
                        value={CATEGORY_STATUS.find(
                          (cat) => cat.value === status
                        )}
                        onChange={addCategory}
                        isClearable={false}
                      />
                      <Errors current_key="status" key="status" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="status">
                        Description <span>*</span>
                      </Label>
                      {/* <CKEditor
                        initData={currentCategory.description}
                        id="description"
                        name="description"
                        config={{
                          height: 100,
                          toolbar: [
                            ["Cut", "Copy", "Paste"],
                            ["Undo", "Redo"],
                            ["SpellChecker"],
                            ["Link", "Unlink", "Anchor"],
                            [
                              "Image",
                              "Table",
                              "Horizontal Line",
                              "Special Character",
                            ],
                            ["Maximize"],
                            ["Source"],
                            ["Bold", "Italic", "Strike"],
                            ["RemoveFormat"],
                            ["NumberedList", "BulletedList"],
                            ["DecreaseIndent", "IncreaseIndent"],
                            ["BlockQuote"],
                            ["Styles"],
                            ["Format"],
                            ["About"],
                          ],
                        }}
                        onChange={(event) => handleDescriptionChange(event)}
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                        invalid={errorList.description ? true : false}
                      /> */}
                      <CKEditor
                        editor={Editor}
                        required
                        data={
                          currentCategory.description
                            ? currentCategory.description
                            : ""
                        }
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChangeDescription(data);
                        }}
                      />
                      <Errors current_key="description" key="description" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginLeft: 10, marginTop: "1%" }}>
          <Col>
            <Card>
              <CardFooter>
                <LoadingButton
                  className="me-3"
                  loading={loading}
                  disabled={loading}
                  color="primary"
                  type="submit"
                >
                  Update
                </LoadingButton>
                <Button
                  type="reset"
                  size="md"
                  color="danger"
                  onClick={onClickHandel}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

EditCategory.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateCategory: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  // showMsg: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.category.loadingCategory,
  currentCategory: state.category.currentCategory,
});
export default connect(mapStateToProps, {
  updateCategory,
  getCategoryById,
  cancelSave,
  loadPage,
  setErrors,
  removeErrors,
})(EditCategory);
