import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { login, forgotPassword, loadUser } from "actions/auth";
import Alert from "views/components/Notifications/Alert";
import Errors from "views/components/Notifications/Errors";
import AppLoading from "views/components/AppLoading";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilUser } from "@coreui/icons";
import Password from "views/components/Password";

const Signin = ({ login, loading, errorList, history }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    userType: 1, // For admin
  });

  const { email, password } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(formData, history);
  };

  return loading ? (
    <AppLoading />
  ) : (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={(e) => onSubmit(e)}>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">
                      Sign In to your account
                    </p>
                    <Alert />
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        autoFocus={true}
                        placeholder="Username/Email"
                        autoComplete="email"
                        name="email"
                        maxLength="50"
                        value={email}
                        onChange={(e) => onChange(e)}
                        required
                        invalid={!!errorList.email}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <Password
                        icon={true}
                        placeholder="Password"
                        autoComplete="current-password"
                        name="password"
                        minLength={8}
                        maxLength={30}
                        required
                        value={password}
                        onChange={(e) => onChange(e)}
                        invalid={!!errorList.password}
                      />
                      <Errors current_key="password" key="password" />
                    </CInputGroup>
                    <CRow>
                      <CCol md="6">
                        <CButton color="primary" className="px-4" type="submit">
                          Login
                        </CButton>
                      </CCol>
                      <CCol md="6" className="text-end">
                        <Link className="px-0" to="/forgot-password">
                          Forgot password?
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

Signin.proTypes = {
  errorList: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  login,
  loadUser,
  forgotPassword,
})(Signin);
