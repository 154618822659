import {
    AMENITIES_ERROR,
    AMENITIES_UPDATED,
    LOADING_ON_AMENITIES_SUBMIT,
    LOADING_SUBBANNER_DATA,
    GET_SUBBANNER,
    LOAD_AMENITIES,
    LOADING_AMENITIES,
    GET_AMENITIES
} from "actions/types";

const initialState = {
    currentAmenities: null,
    loadingAmenities: false,
    error: {},
};

export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case AMENITIES_ERROR:
            return {
                ...state,
                error: payload,
                loadingAmenities: false,
            };
        case GET_SUBBANNER:
            return {
                ...state,
                currentAmenities: payload,
                loadingAmenities: false,
            };
        case LOADING_AMENITIES:
            return {
                ...state,
                loadingAmenities: true,
            };
        case LOAD_AMENITIES:
            return {
                ...state,
                loadingAmenities: false,
            };
        case LOADING_ON_AMENITIES_SUBMIT:
            return {
                ...state,
                loadingAmenities: true,
            };
        case GET_AMENITIES:
        case AMENITIES_UPDATED:
            return {
                ...state,
                loadingAmenities: false,
            }
        default:
            return state;
    }
}
