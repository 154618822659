import {
  DASHBOARD_DETAILS_LOADING,
  DASHBOARD_DETAILS,
  DASHBOARD_ERROR,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  dashboardDetails: {},
  dashboardLoading: false,
  error: {},
};

export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DASHBOARD_DETAILS_LOADING:
      return {
        ...state,
        dashboardLoading: true,
      };
    case DASHBOARD_DETAILS:
      return {
        ...state,
        dashboardDetails: payload,
        dashboardLoading: false,
      };
    case DASHBOARD_ERROR:
      return {
        ...state,
        error: payload,
        dashboardDetails: {},
        dashboardLoading: false,
      };
    default:
      return state;
  }
}
