import React, { useState, useEffect, useMemo } from "react";
import PropTypes, { string } from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import debounce from "lodash.debounce"
import Editor from "ckeditor5-custom-build/build/ckeditor";
import queryString from 'query-string';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
} from "reactstrap";
import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  updateGallery,
  getGallery,
  removeErrors,
  setErrors,
  loadingGallery,
} from "actions/admin/gallery";
import { FaPlusSquare, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import Spinner from "views/components/Spinner";

let addTourImage = [];
let addGalleryImage = [];

let images = [];
let imageUrls = [];
let tourImages = [];
let tourImageUrls = [];

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'contents',
  padding: grid,
  overflow: 'auto',
});


const Gallery = ({
  updateGallery,
  getGallery,
  loadingGallery,
  loading,
  removeErrors,
  setErrors,
}) => {
  const params = useParams();
  const history = useNavigate();

  const floorsFields = {
    label: "",
    button_text: "",
    image: "",
    image_url: "",
    description: "",
    sub_description: "",
  };
  const threeDImageFields = {
    threeDlink: ""
  };
  const initialState = {
    heading: "",
    banner_color: "#D97706",
    sub_heading_color: "#D97706",
    sub_heading: "",
    banner_image: "",
    banner_image_url: "",

    tour_top_heading: "",
    tour_heading: "",
    tour_sub_heading: "",

    tour_top_heading_color: "#FFFFFF",
    tour_sub_heading_color: "#FFFFFF",

    tour_images: [],
    tour_images_url: [],
    floor_background_color: "#FFFFFF",
    floor_box_color: "#FFFFFF",
    floor_title_color: "#000000",
    floor_content_color: "#6D7280",

    floor_heading: "",
    floor_description: "",

    floor_description_color: "#FFFFFF",

    gallery_heading: "",
    gallery_description: "",
    gallery_heading_color: "#FFFFFF",
    gallery_description_color: "#FFFFFF",
    gallery_image: [],
    gallery_image_url: [],
    deletedImage: [],
  };
  const [formData, setFormData] = useState(initialState);
  const [threeDImage, setThreeDImage] = useState([threeDImageFields]);

  const [floorsData, setFloorsData] = useState([floorsFields]);
  const [onlyOnce, setOnce] = useState(false);
  const [queryStringData, setQueryStringData] = useState("");
  const [dragDrop, setDragDrop] = useState(imageUrls);
  const [isPrev, setIsPrev] = useState(false);

  //const { heading, subHeading } = formData;

  const onChange = async (event) => {
    const { name, value } = event.target;

    const fields = [
      "banner_color",
      "sub_heading_color",
      "floor_background_color",
      "floor_box_color",
      "floor_title_color",
      "floor_content_color",
    ];
    if (fields.includes(name)) {
      setIsPrev(true)
    }
    switch (name) {
      case "banner_image":
        const banner_image_file = event.target.files[0];
        const banner_file_url = URL.createObjectURL(banner_image_file);

        const reader = new FileReader();
        reader.onload = async () => {
          setFormData({
            ...formData,
            banner_image: reader.result,
            banner_image_url: banner_file_url,
          });
        };
        reader.readAsDataURL(banner_image_file);
        break;
      case "gallery_image":
        //addGalleryImage
        const gallery_image_file = event.target.files;
        for (let items of gallery_image_file) {
          const checks = await addGalleryImage.find(checkName => checkName.name === items.name);
          if (checks === undefined) {
            const gallery_file_url = URL.createObjectURL(items);
            const galleryImageReader = new FileReader();
            galleryImageReader.onload = async () => {
              images.push(galleryImageReader.result);
              addGalleryImage.push({ name: items.name, image: galleryImageReader.result })
              imageUrls.push(gallery_file_url);
              setFormData({
                ...formData,
                gallery_image: images,
                gallery_image_url: imageUrls,
              });
            };
            galleryImageReader.readAsDataURL(items);
          }
        }
        break;
      case "tour_images":
        const tour_image_file = event.target.files;
        for (const item of tour_image_file) {
          const check = await addTourImage.find(checkName => checkName.name === item.name);
          if (check === undefined) {
            const tour_file_url = URL.createObjectURL(item);
            const tourImageReader = new FileReader();
            tourImageReader.onload = async () => {
              tourImages.push(tourImageReader.result);
              addTourImage.push({ name: item.name, image: tourImageReader.result })
              tourImageUrls.push(tour_file_url);
              setFormData({
                ...formData,
                tour_images: tourImages,
                tour_images_url: tourImageUrls,
              });
            };
            tourImageReader.readAsDataURL(item);
          }
        }
        break;
      default:
        setFormData({
          ...formData,
          [event.target.name]: value.replace(/[^a-z0-9]/gi, "")
            ? value.trimLeft()
            : value.trim(),
        });
    }
  };

  const onFloorChange = (event, index) => {
    const { name, value } = event.target;
    switch (name) {
      case "image":
        const image_file = event.target.files[0];
        const file_url = URL.createObjectURL(image_file);
        const imageFloorsData = [...floorsData];

        const reader = new FileReader();
        reader.onload = async () => {
          imageFloorsData[index]["image"] = reader.result;
          imageFloorsData[index]["image_url"] = file_url;
          setFloorsData(imageFloorsData);
        };
        reader.readAsDataURL(image_file);
        break;
      default:
        let newFloorsData = [...floorsData];
        newFloorsData[index][name] = value.replace(/[^a-z0-9]/gi, "")
          ? value.trimLeft()
          : value.trim();
        setFloorsData(newFloorsData);
    }
  };

  const on3DChange = (event, index) => {
    const { name, value } = event.target;
    let newThreeDImage = [...threeDImage];
    newThreeDImage[index][name] = value.replace(/[^a-z0-9]/gi, "")
      ? value.trimLeft()
      : value.trim();
    setThreeDImage(newThreeDImage);
  }

  const addFormFields = () => {
    if (floorsData.length > 9) {
      alert("You cannot add more than 9 sections.")
      return false;
    }
    setFloorsData([...floorsData, floorsFields]);
  };

  const add3DLink = () => {
    if (threeDImage.length > 9) {
      alert("You cannot add more than 9 sections.")
      return false;
    }
    setThreeDImage([...threeDImage, threeDImageFields]);
  };

  const removeFormFields = (index) => {
    let newFloorsData = [...floorsData];
    newFloorsData.splice(index, 1);
    setFloorsData(newFloorsData);
  };

  const remove3DLink = (index) => {
    let newThreeDImage = [...threeDImage];
    newThreeDImage.splice(index, 1);
    setThreeDImage(newThreeDImage);
  };

  const deleteImages = async (index, type) => {
    if (type === "tour") {
      const deleteTour = tourImages[index];
      const OldImdex = await addTourImage.findIndex((img) => img.image === deleteTour)

      if (OldImdex !== -1) {
        await addTourImage.splice(OldImdex, 1);
      }
      tourImages.splice(index, 1);
      tourImageUrls.splice(index, 1);
      setFormData({
        ...formData,
        tour_images: tourImages,
        tour_images_url: tourImageUrls,
        deletedImage: [...formData.deletedImage, deleteTour],
      });
    } else {
      const deleteImage = images[index];
      const galleryIndex = await addGalleryImage.findIndex((img) => img.image === deleteImage)

      if (galleryIndex !== -1) {
        await addGalleryImage.splice(galleryIndex, 1);
      }
      images.splice(index, 1);
      imageUrls.splice(index, 1);
      setFormData({
        ...formData,
        gallery_image: images,
        gallery_image_url: imageUrls,
        deletedImage: [...formData.deletedImage, deleteImage],
      });
    }
  };

  useEffect(() => {
    const queryData = {
      floor_background_color: formData.floor_background_color,
      floor_box_color: formData.floor_box_color,

      floor_title_color: formData.floor_title_color,
      floor_content_color: formData.floor_content_color,

      banner_color: formData.banner_color,
      sub_heading_color: formData.sub_heading_color,
    }
    const setString = queryString.stringify({ prev: JSON.stringify(queryData) });
    const prevUrl = process.env.REACT_APP_CLIENT_URL;
    setQueryStringData(`${prevUrl}/gallery?${setString}`)
  }, [formData])

  useEffect(async () => {
    const getData = await getGallery();
    if (getData) {
      searchHandler();
      tourImages = getData.tour_images?.map((item) => item.path);
      tourImageUrls = getData.tour_images?.map((item) => item.fullURL);
      images = getData.gallery_image?.map((item) => item.path);
      imageUrls = getData.gallery_image?.map((item) => item.fullURL);
      setFormData({
        ...formData,
        floor_background_color: getData.floor_background_color,
        floor_box_color: getData.floor_box_color,

        floor_title_color: getData.floor_title_color,
        floor_content_color: getData.floor_content_color,

        heading: getData.heading,
        banner_color: getData.banner_color,
        sub_heading_color: getData.sub_heading_color,
        sub_heading: getData.sub_heading,
        banner_image: getData.banner_image?.path,
        banner_image_url: getData.banner_image?.fullURL,

        tour_top_heading: getData.tour_top_heading,
        tour_heading: getData.tour_heading,
        tour_sub_heading: getData.tour_sub_heading,

        tour_top_heading_color: getData.tour_top_heading_color ?? "#FFFFFF",
        tour_sub_heading_color: getData.tour_sub_heading_color ?? "#FFFFFF",

        tour_images: getData.tour_images?.map((item) => item.path),
        tour_images_url: getData.tour_images?.map((item) => item.fullURL),
        floor_heading: getData.floor_heading,
        floor_description: getData.floor_description,

        floor_description_color: getData.floor_description_color ?? "#FFFFFF",

        gallery_heading: getData.gallery_heading,
        gallery_description: getData.gallery_description,

        gallery_heading_color: getData.gallery_heading_color ?? "#FFFFFF",
        gallery_description_color: getData.gallery_description_color ?? "#FFFFFF",

        gallery_image: getData.gallery_image?.map((item) => item.path),
        gallery_image_url: getData.gallery_image?.map((item) => item.fullURL),
      });
      //setThreeDImage
      const newThreeDImage = [...threeDImage];
      const editThreeDImage = Object.entries(getData?.three_d_link ?? []);
      for (const [index, value] of editThreeDImage ?? []) {
        newThreeDImage[Number(index)] = {
          ...newThreeDImage[Number(index)],
          threeDlink: value.threeDlink
        };
      }
      setThreeDImage(newThreeDImage);

      const newFloorsData = [...floorsData];
      const editaApartment = Object.entries(getData?.floorsData ?? []);
      for (const [index, value] of editaApartment ?? []) {
        newFloorsData[Number(index)] = {
          ...newFloorsData[Number(index)],
          label: value.label,
          button_text: value.button_text,
          image: value.image?.path,
          image_url: value.image?.fullURL,
          description: value.description,
          sub_description: value.sub_description,
        };
      }
      setFloorsData(newFloorsData);
    }
  }, []);

  const searchHandler = useMemo(() => {
    return debounce(() => {
      setOnce(true)
    }, 1000)
  }, [])

  const changeEditor = async (editor, index) => {

    const data = editor.getData();
    let editorFloorsData = await [...floorsData];
    editorFloorsData[index]["description"] = data
    setFloorsData(editorFloorsData);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    formData.floorsData = floorsData;
    formData.three_d_link = threeDImage;
    updateGallery(formData, history, "");
  };

  const onDragEnd = (result) => {

    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      formData.gallery_image_url,
      result.source.index,
      result.destination.index
    );
    const img = reorder(
      formData.gallery_image,
      result.source.index,
      result.destination.index
    );
    /* const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed); */

    setFormData({ ...formData, gallery_image: img, gallery_image_url: items });
  }

  return (
    <div className="animated fadeIn">
      {loading && <Spinner applyClass={"loading-box"} />}
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <span className="pull-left h5">Gallery Information</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <Card>
                <CardBody>
                  <h6>
                    <b>Banner Section</b>
                  </h6>
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Label htmlFor="heading">
                          Banner Heading <span>*</span>
                        </Label>
                        <Input
                          style={{ color: formData.banner_color }}
                          type="text"
                          id="heading"
                          name="heading"
                          value={formData.heading}
                          maxLength="100"
                          onChange={(e) => onChange(e)}
                          required
                        // invalid={errorList.banner_heading ? true : false}
                        />
                        <Errors current_key="heading" key="heading" />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label htmlFor="sub_heading">
                          Sub Heading <span>*</span>
                        </Label>
                        <Input
                          style={{ color: formData.sub_heading_color }}
                          type="textarea"
                          id="sub_heading"
                          name="sub_heading"
                          value={formData.sub_heading}
                          onChange={(e) => onChange(e)}
                          required
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors current_key="sub_heading" key="sub_heading" />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="banner_color">
                          Heading Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="banner_color"
                          name="banner_color"
                          value={formData.banner_color}
                          maxLength="100"
                          onChange={(e) => onChange(e)}
                          required
                        // invalid={errorList.banner_heading ? true : false}
                        />
                        <Errors current_key="banner_color" key="banner_color" />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="sub_heading_color">
                          Sub Heading Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="sub_heading_color"
                          name="sub_heading_color"
                          value={formData.sub_heading_color}
                          maxLength="100"
                          onChange={(e) => onChange(e)}
                          required
                        // invalid={errorList.banner_heading ? true : false}
                        />
                        <Errors current_key="sub_heading_color" key="sub_heading_color" />
                      </FormGroup>
                    </Col>
                    <Col xs={5}>
                      <FormGroup>
                        <Label htmlFor="banner_image">
                          Banner Image <span>*</span>
                        </Label>
                        <Input
                          type="file"
                          id="banner_image"
                          name="banner_image"
                          onChange={(e) => onChange(e)}
                          accept="image/*"
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors current_key="banner_image" key="banner_image" />
                      </FormGroup>
                    </Col>
                    <Col xs={1} className="mt-5">
                      {formData.banner_image_url ? (
                        <img
                          src={formData.banner_image_url}
                          className="preview-img img-fluid"
                          alt="image_file"
                          style={{ height: "40px" }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h6>
                    <b>3D Tour</b>
                  </h6>
                  <Row>
                    <Col xs={5}>
                      <FormGroup>
                        <Label htmlFor="tour_top_heading">
                          Top Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="tour_top_heading"
                          name="tour_top_heading"
                          value={formData.tour_top_heading}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.banner_heading ? true : false}
                        />
                        <Errors
                          current_key="tour_top_heading"
                          key="tour_top_heading"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={1}>
                      <FormGroup>
                        <Input
                          type="color"
                          className="color-box"
                          id="tour_top_heading_color"
                          name="tour_top_heading_color"
                          value={formData.tour_top_heading_color}
                          required
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="tour_heading">
                          Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="tour_heading"
                          name="tour_heading"
                          value={formData.tour_heading}
                          onChange={(e) => onChange(e)}
                          required
                        // invalid={errorList.tour_heading ? true : false}
                        />
                        <Errors current_key="tour_heading" key="tour_heading" />
                      </FormGroup>
                    </Col>
                    <Col xs={11}>
                      <FormGroup>
                        <Label htmlFor="tour_sub_heading">
                          Sub Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="tour_sub_heading"
                          name="tour_sub_heading"
                          value={formData.tour_sub_heading}
                          onChange={(e) => onChange(e)}
                          required
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors
                          current_key="tour_sub_heading"
                          key="tour_sub_heading"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={1}>
                      <FormGroup>
                        <Input
                          type="color"
                          className="color-box"
                          id="tour_sub_heading_color"
                          name="tour_sub_heading_color"
                          value={formData.tour_sub_heading_color}
                          required
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <Card>

                  <CardBody>
                    <Row>
                      <Col xs={12}>
                        <h6>
                          <b>Add 3d Link</b>
                          <Button
                            className="m-1"
                            size="sm"
                            color="primary"
                            style={{ float: "right" }}
                            onClick={() => add3DLink()}
                          >
                            <i className="fa fa-dot-circle-o"></i> Add More
                          </Button>
                        </h6>
                      </Col>
                    </Row>
                    {threeDImage.map((element, index) => (
                      <Row key={`${index + 1}`}>

                        <Col xs={index ? 10 : 12}>
                          <FormGroup>
                            <Label htmlFor="threeDlink">
                              3D Link <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="threeDlink"
                              name="threeDlink"
                              required
                              value={threeDImage[index]["threeDlink"]}
                              onChange={(e) => on3DChange(e, index)}
                            />
                            <Errors
                              current_key={
                                "threeDImage[" + index + "].threeDlink"
                              }
                              key={"threeDImage[" + index + "].threeDlink"}
                            />
                          </FormGroup>
                        </Col>
                        {index ? (
                          <Col xs={2} className="button-align" >
                            <Button
                              size="md"
                              color="danger"
                              onClick={() => remove3DLink(index)}
                            >
                              <FaTrash color="white" />
                            </Button>
                            <Button
                              className="m-1"
                              size="md"
                              color="primary"
                              onClick={() => add3DLink()}
                            >
                              <FaPlusSquare color="white" />
                            </Button>
                          </Col>
                        ) : null}
                      </Row>
                    ))}
                  </CardBody>
                </Card>
              </Card>
              <Card>
                <CardBody>
                  <h6>
                    <b>Image Gallery</b>
                  </h6>
                  <Row>
                    <Col xs={11}>
                      <FormGroup>
                        <Label htmlFor="gallery_heading">
                          Gallery Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="gallery_heading"
                          name="gallery_heading"
                          value={formData.gallery_heading}
                          onChange={(e) => onChange(e)}
                          required
                        // invalid={errorList.banner_heading ? true : false}
                        />
                        <Errors
                          current_key="gallery_heading"
                          key="gallery_heading"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={1}>
                      <FormGroup>
                        <Input
                          type="color"
                          className="color-box"
                          id="gallery_heading_color"
                          name="gallery_heading_color"
                          value={formData.gallery_heading_color}
                          required
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={11}>
                      <FormGroup>
                        <Label htmlFor="gallery_description">
                          Gallery Description <span>*</span>
                        </Label>
                        <Input
                          type="textarea"
                          id="gallery_description"
                          name="gallery_description"
                          value={formData.gallery_description}
                          onChange={(e) => onChange(e)}
                          required
                        // invalid={errorList.gallery_description ? true : false}
                        />
                        <Errors
                          current_key="gallery_description"
                          key="gallery_description"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={1}>
                      <FormGroup>
                        <Input
                          type="color"
                          className="color-box"
                          id="gallery_description_color"
                          name="gallery_description_color"
                          value={formData.gallery_description_color}
                          required
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label htmlFor="gallery_image">
                          Gallery Image <span>*</span>
                        </Label>
                        <Input
                          type="file"
                          id="gallery_image"
                          name="gallery_image"
                          multiple
                          onChange={(e) => onChange(e)}
                          accept="image/*"
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors
                          current_key="gallery_image"
                          key="gallery_image"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} className="previewImages">

                      <DragDropContext onDragEnd={onDragEnd} >
                        <Droppable droppableId="droppable" direction="horizontal" >
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {formData.gallery_image_url.map((item, index) => (
                                <Draggable key={item} draggableId={item} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Button
                                        type="button"
                                        className=""
                                        size="sm"
                                        color="danger"
                                        onClick={() => deleteImages(index, "gallery")}
                                      >
                                        <i className="fa fa-ban"></i> X
                                      </Button>
                                      <img
                                        src={item}
                                        className="preview-img img-fluid"
                                        alt="image_file"
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <Card>
                  <CardBody>
                    <h6>
                      <b>Floor Colors</b>
                    </h6>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="floor_background_color">
                            Background Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="floor_background_color"
                            name="floor_background_color"
                            value={formData.floor_background_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="floor_background_color"
                            key="floor_background_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="floor_box_color">
                            Box Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="floor_box_color"
                            name="floor_box_color"
                            value={formData.floor_box_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="floor_box_color"
                            key="floor_box_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="floor_title_color">
                            Box Title Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="floor_title_color"
                            name="floor_title_color"
                            value={formData.floor_title_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="floor_title_color"
                            key="floor_title_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="floor_content_color">
                            Box Content Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="floor_content_color"
                            name="floor_content_color"
                            value={formData.floor_content_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="floor_content_color"
                            key="floor_content_color"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <CardBody>
                  <h6>
                    <b>Floors</b>
                  </h6>
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="floor_heading">
                          Floor Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="floor_heading"
                          name="floor_heading"
                          value={formData.floor_heading}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.floor_heading ? true : false}
                        />
                        <Errors
                          current_key="floor_heading"
                          key="floor_heading"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={5}>
                      <FormGroup>
                        <Label htmlFor="floor_description">
                          Floor Description <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="floor_description"
                          name="floor_description"
                          value={formData.floor_description}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.floor_description ? true : false}
                        />
                        <Errors
                          current_key="floor_description"
                          key="floor_description"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={1}>
                      <FormGroup>
                        <Input
                          type="color"
                          className="color-box"
                          id="floor_description_color"
                          name="floor_description_color"
                          value={formData.floor_description_color}
                          required
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <hr />
                    <Col xs={6}>
                      <h6>
                        <b>Floor Description</b>
                      </h6>
                    </Col>
                    <Col xs={6}>
                      <Button
                        className="m-1"
                        size="sm"
                        color="primary"
                        style={{ float: "right" }}
                        onClick={() => addFormFields()}
                      >
                        <i className="fa fa-dot-circle-o"></i> Add More
                      </Button>
                    </Col>
                    {floorsData.map((element, index) => (
                      <>
                        <Col key={`a-${index}`} xs={6}>
                          <FormGroup>
                            <Label htmlFor="label">
                              Label <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="label"
                              name="label"
                              value={floorsData[index]["label"]}
                              required
                              onChange={(e) => onFloorChange(e, index)}
                            // invalid={errorList.heading ? true : false}
                            />
                            <Errors
                              current_key={"floorsData[" + index + "].label"}
                              key={"floorsData[" + index + "].label"}
                            />
                          </FormGroup>
                        </Col>
                        <Col key={`aas-${index}`} xs={6}>
                          <FormGroup>
                            <Label htmlFor="button_text">
                              Button Text <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="button_text"
                              name="button_text"
                              value={floorsData[index]["button_text"]}
                              required
                              onChange={(e) => onFloorChange(e, index)}
                            // invalid={errorList.heading ? true : false}
                            />
                            <Errors
                              current_key={
                                "floorsData[" + index + "].button_text"
                              }
                              key={"floorsData[" + index + "].button_text"}
                            />
                          </FormGroup>
                        </Col>
                        <Col key={`aas-${index}`} xs={12}>
                          <FormGroup>
                            <Label htmlFor="sub_description">
                              Sub Description <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="sub_description"
                              name="sub_description"
                              value={floorsData[index]["sub_description"]}
                              required
                              onChange={(e) => onFloorChange(e, index)}
                            // invalid={errorList.heading ? true : false}
                            />
                            <Errors
                              current_key={
                                "floorsData[" + index + "].sub_description"
                              }
                              key={"floorsData[" + index + "].sub_description"}
                            />
                          </FormGroup>
                        </Col>
                        <Col key={`ab-${index}`} xs={12}>
                          <FormGroup>
                            <Label htmlFor="description">
                              Description <span>*</span>
                            </Label>
                            {onlyOnce && <CKEditor
                              editor={Editor}
                              data={floorsData[index]["description"]}
                              onChange={(event, editor) => changeEditor(editor, index)}
                            />}
                            <Errors
                              current_key={
                                "floorsData[" + index + "].description"
                              }
                              key={"floorsData[" + index + "].description"}
                            />
                          </FormGroup>
                        </Col>
                        <Col
                          key={`zx-${index}`}
                          xs={
                            index
                              ? floorsData[index].image_url
                                ? 9
                                : 10
                              : floorsData[index].image_url
                                ? 10
                                : 12
                          }
                        >
                          <FormGroup>
                            <Label htmlFor="image">
                              Image <span>*</span>
                            </Label>
                            <Input
                              type="file"
                              id="image"
                              name="image"
                              accept="image/*"
                              onChange={(e) => onFloorChange(e, index)}
                            // invalid={errorList.heading ? true : false}
                            />
                            <Errors
                              current_key={"floorsData[" + index + "].image"}
                              key={"floorsData[" + index + "].image"}
                            />
                          </FormGroup>
                        </Col>
                        {floorsData && floorsData[index].image_url ? (
                          <Col xs={1} className="mt-5">
                            <img
                              src={floorsData[index].image_url}
                              className="preview-img img-fluid"
                              alt="image_file"
                              style={{ height: "40px" }}
                            />
                          </Col>
                        ) : null}
                        {index ? (
                          <Col key={`abc-${index}`} xs={2} className="button-align">
                            <Button
                              size="md"
                              color="danger"
                              onClick={() => removeFormFields(index)}
                            >
                              <FaTrash color="white" />
                            </Button>
                            <Button
                              className="m-1"
                              size="md"
                              color="primary"
                              onClick={() => addFormFields()}
                            >
                              <FaPlusSquare color="white" />
                            </Button>
                          </Col>
                        ) : null}
                      </>
                    ))}
                  </Row>
                </CardBody>
              </Card>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                    loading={loading}
                    disabled={loading}
                    style={{ width: "60px" }}
                  >
                    <i className="fa fa-dot-circle-o"></i>{" "}
                    {loading ? (
                      <Spinner style={{ fontSize: "20px" }} />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  {isPrev && <a href={queryStringData} target={'_blank'}>
                    <Button
                      type="button"
                      className="m-1"
                      size="sm"
                      color="info"
                    >
                      <i className="fa fa-ban"></i> Preview
                    </Button>
                  </a>}
                  <Link to="/dashboard/cms">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Gallery.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateGallery: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  //currentSubBanner: state.subBanner.currentSubBanner,
  errorList: state.errors,
  loading: state.gallery.loadingGallery,
});

export default connect(mapStateToProps, {
  updateGallery,
  getGallery,
  loadingGallery,
  removeErrors,
  setErrors,
})(Gallery);
