import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setAlert } from "actions/alert";
import { resetLink, loginRedirect } from "actions/auth";
import Errors from "views/components/Notifications/Errors";
import Alert from "views/components/Notifications/Alert";
import logo from "assets/img/logo/logo.png";

import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";

const ForgotPassword = ({ resetLink, errorList, loading }) => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    resetLink({ email });
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          {/* <img src={logo} style={{ width: 128, padding: 16 }} alt="logo" /> */}
        </Row>
        <Row className="justify-content-center">
          <Col md="9" lg="7" xl="6">
            <Card className="mx-4">
              <CardBody className="p-4">
                <Form onSubmit={(e) => onSubmit(e)}>
                  <h1>Reset Password</h1>
                  <p id="demo"></p>
                  <Alert />
                  <InputGroup className="mb-3">
                    <InputGroupText>@</InputGroupText>
                    <Input
                      type="text"
                      placeholder="Email"
                      autoComplete="email"
                      name="email"
                      maxLength="50"
                      value={email}
                      onChange={(e) => onChange(e)}
                      required
                      invalid={errorList.email ? true : false}
                    />
                    <Errors current_key="email" key="email" />
                  </InputGroup>
                  <Row>
                    {loading ? (
                      <Spinner />
                    ) : (
                      <>
                        <Col md="6">
                          <Button color="primary" className="px-6">
                            Send Password Reset Link
                          </Button>
                        </Col>
                        <Col md="6" className="text-end">
                          <Link to="/" className="px-0">
                            Have an account? Login
                          </Link>
                        </Col>
                      </>
                    )}
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ForgotPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  resetLink: PropTypes.func.isRequired,
  loginRedirect: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  errorList: state.errors,
  loading: state.auth.loadingPasswordReset,
});

export default connect(mapStateToProps, {
  setAlert,
  resetLink,
  loginRedirect,
})(ForgotPassword);
