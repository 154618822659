import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CKEditor } from "ckeditor4-react";
import queryString from 'query-string';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
} from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FaPlusSquare, FaTrash } from "react-icons/fa";
import {
  updateContact,
  getContact,
  removeErrors,
  setErrors,
  loadingContact,
} from "actions/admin/contact";
import Spinner from "views/components/Spinner";
const animatedComponents = makeAnimated();
let dayName = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const daysName = [
  { value: "mon", label: "Mon" },
  { value: "tue", label: "Tue" },
  { value: "wed", label: "Wed" },
  { value: "thu", label: "Thu" },
  { value: "fri", label: "Fri" },
  { value: "sat", label: "Sat" },
  { value: "sun", label: "Sun" },
];

const ContactUs = ({
  updateContact,
  getContact,
  loadingContact,
  loading,
  removeErrors,
  setErrors,
}) => {
  const params = useParams();
  const history = useNavigate();
  const faqQuestionAns = {
    question: "",
    answer: "",
  };
  const officeFields = {
    day_name: "",
    open_time: "",
    close_time: "",
    is_open: "",
  }
  const initialState = {
    heading: "",
    banner_color: "#D97706",
    sub_heading_color: "#D97706",
    background_color: "#1F2937",
    address_background_color: "#FFFFFF",
    text_color: "#FFFFFF",
    faq_background_color: "#FFFFFF",
    faq_text_color: "#FFFFFF",
    faq_sub_text_color: "#FFFFFF",
    sub_heading: "",
    map_address: "",
    banner_image: "",
    banner_image_url: "",
    start_time: "",
    end_time: "",
    visit_title: "",
    faq_heading: "",
    faq_sub_heading: "",
  };
  const [faq, setFaq] = useState([faqQuestionAns]);
  const [formData, setFormData] = useState(initialState);
  const [officeTimeData, setOfficeTimeData] = useState([]);
  const [queryStringData, setQueryStringData] = useState("");
  const [isPrev, setIsPrev] = useState(false);

  const [onlyOnce, setOnce] = useState(true);

  const onChange = (event) => {
    const { name, value } = event.target;
    const fields = [
      "banner_color",
      "sub_heading_color",
      "background_color",
      "address_background_color",
      "text_color",
      "faq_background_color",
      "faq_text_color",
      "faq_sub_text_color"
    ];
    if (fields.includes(name)) {
      setIsPrev(true)
    }
    switch (name) {
      case "banner_image":
        const banner_image_file = event.target.files[0];
        const banner_file_url = URL.createObjectURL(banner_image_file);

        const bannerReader = new FileReader();
        bannerReader.onload = async () => {
          setFormData({
            ...formData,
            banner_image: bannerReader.result,
            banner_image_url: banner_file_url,
          });
        };
        bannerReader.readAsDataURL(banner_image_file);
        break;
      default:
        setFormData({
          ...formData,
          [event.target.name]: value.replace(/[^a-z0-9]/gi, "")
            ? value.trimLeft()
            : value.trim(),
        });
    }
  };

  const onChangeTiming = (event, index) => {
    let newOfficeTimeData = [...officeTimeData];
    if (event.target.name === "is_open") {
      if (event.target.checked === false) {
        newOfficeTimeData[index]["open_time"] = "";
        newOfficeTimeData[index]["close_time"] = "";
      }
      newOfficeTimeData[index][event.target.name] = event.target.checked;
      setOfficeTimeData(newOfficeTimeData);
    } else {
      const { name, value } = event.target
      newOfficeTimeData[index][name] = value.replace(/[^a-z0-9]/gi, "")
        ? value.trimLeft()
        : value.trim();
      setOfficeTimeData(newOfficeTimeData);
    }
    //setOfficeTimeData
  };

  const onInputChange = (selectOption, fieldName) => {
    setFormData({ ...formData, [fieldName]: selectOption });
  };


  const onFaqChange = (event, index) => {
    const { name, value } = event.target;

    let newFaq = [...faq];
    newFaq[index][name] = value.replace(/[^a-z0-9]/gi, "")
      ? value.trimLeft()
      : value.trim();
    setFaq(newFaq);
  };

  const addFormFields = () => {
    setFaq([...faq, faqQuestionAns]);
  };

  const removeFormFields = (index) => {
    const newFaq = [...faq];
    newFaq.splice(index, 1);
    setFaq(newFaq);
  };

  useEffect(() => {
    for (const ele of dayName) {
      officeTimeData.push({ ...officeFields, day_name: ele });
    }
  }, []);

  useEffect(() => {
    const queryData = {
      banner_color: formData.banner_color,
      sub_heading_color: formData.sub_heading_color,
      background_color: formData.background_color,
      address_background_color: formData.address_background_color,
      text_color: formData.text_color,
      faq_background_color: formData.faq_background_color,
      faq_text_color: formData.faq_text_color,
      faq_sub_text_color: formData.faq_sub_text_color
    }
    const setString = queryString.stringify({ prev: JSON.stringify(queryData) });
    const prevUrl = process.env.REACT_APP_CLIENT_URL;
    setQueryStringData(`${prevUrl}/contact?${setString}`)
  }, [formData])

  useEffect(async () => {
    const getData = await getContact();
    if (getData) {
      setFormData({
        ...formData,
        background_color: getData.background_color,
        address_background_color: getData.address_background_color,
        text_color: getData.text_color,
        faq_background_color: getData.faq_background_color,
        faq_text_color: getData.faq_text_color,
        faq_sub_text_color: getData.faq_sub_text_color,
        heading: getData.heading,
        banner_color: getData.banner_color,
        sub_heading_color: getData.sub_heading_color,
        sub_heading: getData.sub_heading,
        map_address: getData.map_address,
        banner_image: getData.banner_image?.path,
        banner_image_url: getData.banner_image?.fullURL,
        start_time: getData.start_time,
        end_time: getData.end_time,
        visit_title: getData.visit_title,
        faq_heading: getData.faq_heading,
        faq_sub_heading: getData.faq_sub_heading,
      });

      const newFaq = [...faq];
      const editaApartment = Object.entries(getData?.faqQuestionAns ?? []);
      for (const [index, value] of editaApartment ?? []) {
        newFaq[Number(index)] = {
          ...newFaq[Number(index)],
          question: value.question,
          answer: value.answer,
        };
      }
      setFaq(newFaq);

      const newOfficeTime = [...officeTimeData];
      const officeTime = Object.entries(getData?.office_timing ?? []);
      for (const [index, value] of officeTime ?? []) {
        newOfficeTime[Number(index)] = {
          ...newOfficeTime[Number(index)],
          day_name: value.day_name,
          close_time: value.close_time,
          open_time: value.open_time,
          is_open: value.is_open,
        };
      }
      setOfficeTimeData(newOfficeTime);
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    formData.faqQuestionAns = faq;
    formData.office_timing = officeTimeData;
    updateContact(formData, history, "");
  };

  return (
    <div className="animated fadeIn">
      {loading && <Spinner applyClass={"loading-box"} />}
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <span className="pull-left h5">Contact Us Information</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <Card>
                <Card>
                  <CardBody>
                    <h6>
                      <b>Banner Colors</b>
                    </h6>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="background_color">
                            Background Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="background_color"
                            name="background_color"
                            value={formData.background_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="background_color"
                            key="background_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="banner_color">
                            Heading Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="banner_color"
                            name="banner_color"
                            value={formData.banner_color}
                            required
                            onChange={(e) => onChange(e)}
                          />
                          <Errors current_key="banner_color" key="banner_color" />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="sub_heading_color">
                            Sub Heading Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="sub_heading_color"
                            name="sub_heading_color"
                            value={formData.sub_heading_color}
                            required
                            onChange={(e) => onChange(e)}
                          />
                          <Errors current_key="sub_heading_color" key="sub_heading_color" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <CardBody>
                  <h6>
                    <b>Banner Section</b>
                  </h6>
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="heading">
                          Heading <span>*</span>
                        </Label>
                        <Input
                          style={{ color: formData.banner_color }}
                          type="text"
                          id="heading"
                          name="heading"
                          value={formData.heading}
                          required
                          onChange={(e) => onChange(e)}
                        />
                        <Errors current_key="heading" key="heading" />
                      </FormGroup>
                    </Col>

                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="sub_heading">
                          Sub Heading <span>*</span>
                        </Label>
                        <Input
                          style={{ color: formData.sub_heading_color }}
                          type="text"
                          id="sub_heading"
                          name="sub_heading"
                          value={formData.sub_heading}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors current_key="sub_heading" key="sub_heading" />
                      </FormGroup>
                    </Col>

                    <Col xs={5}>
                      <FormGroup>
                        <Label htmlFor="banner_image">
                          Banner Image <span>*</span>
                        </Label>
                        <Input
                          type="file"
                          id="banner_image"
                          name="banner_image"
                          onChange={(e) => onChange(e)}
                          accept="image/*"
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors current_key="banner_image" key="banner_image" />
                      </FormGroup>
                    </Col>
                    <Col xs={1} className="mt-5">
                      {formData.banner_image_url ? (
                        <img
                          src={formData.banner_image_url}
                          className="preview-img img-fluid"
                          alt="image_file"
                          style={{ height: "40px" }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <Card>
                  <CardBody>
                    <h6>
                      <b>Map & Address Colors</b>
                    </h6>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="address_background_color">
                            Background Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="address_background_color"
                            name="address_background_color"
                            value={formData.address_background_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="address_background_color"
                            key="address_background_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="text_color">
                            Text Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="text_color"
                            name="text_color"
                            value={formData.text_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="text_color"
                            key="text_color"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <CardBody>
                  <h6>
                    <b>Map and Address</b>
                  </h6>
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="map_address">
                          Map Address <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="map_address"
                          name="map_address"
                          value={formData.map_address}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.map_address ? true : false}
                        />
                        <Errors current_key="map_address" key="map_address" />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="visit_title">
                          Visit Title <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="visit_title"
                          name="visit_title"
                          value={formData.visit_title}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors current_key="visit_title" key="visit_title" />
                      </FormGroup>
                    </Col>
                    {officeTimeData && officeTimeData.map((items, index) => (
                      <Col key={items.day_name} xs={12}>
                        <FormGroup>
                          <Label htmlFor="day_name">
                            Office Timing <span>*</span>
                          </Label>
                          <div className="select-time">
                            <Input
                              type="text"
                              id="day_name"
                              name="day_name"
                              value={officeTimeData[index]['day_name']}
                              required
                              readOnly
                              onChange={(e) => onChangeTiming(e, index)}
                            // invalid={errorList.heading ? true : false}
                            />
                            <span className="mt-2">{"="}</span>
                            <Input
                              type="time"
                              id="open_time"
                              name="open_time"
                              value={officeTimeData[index]['open_time']}
                              readOnly={officeTimeData[index]['is_open'] ? false : true}
                              required={officeTimeData[index]['is_open'] ? true : false}
                              onChange={(e) => onChangeTiming(e, index)}
                            // invalid={errorList.heading ? true : false}
                            />
                            <span className="mt-2">To</span>
                            <Input
                              type="time"
                              id="close_time"
                              name="close_time"
                              value={officeTimeData[index]['close_time']}
                              readOnly={officeTimeData[index]['is_open'] ? false : true}
                              required={officeTimeData[index]['is_open'] ? true : false}
                              onChange={(e) => onChangeTiming(e, index)}
                            // invalid={errorList.heading ? true : false}
                            />
                            <Input type="checkbox" name="is_open" checked={officeTimeData[index]['is_open']} onChange={(e) => onChangeTiming(e, index)} />
                            <Label check>
                              {officeTimeData[index]['is_open'] ? "Open" : "Close"}
                            </Label>
                          </div>

                          <Errors
                            current_key="office_timing"
                            key="office_timing"
                          />

                        </FormGroup>
                      </Col>))}
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <Card>
                  <CardBody>
                    <h6>
                      <b>Faq Colors</b>
                    </h6>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="faq_background_color">
                            Background Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="faq_background_color"
                            name="faq_background_color"
                            value={formData.faq_background_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="faq_background_color"
                            key="faq_background_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="faq_text_color">
                            Text Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="faq_text_color"
                            name="faq_text_color"
                            value={formData.faq_text_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="faq_text_color"
                            key="faq_text_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="faq_sub_text_color">
                            Sub Text Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="faq_sub_text_color"
                            name="faq_sub_text_color"
                            value={formData.faq_sub_text_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="faq_sub_text_color"
                            key="faq_sub_text_color"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <CardBody>
                  <h6>
                    <b>FAQs</b>
                  </h6>
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="faq_heading">
                          FAQ Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="faq_heading"
                          name="faq_heading"
                          value={formData.faq_heading}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.faq_heading ? true : false}
                        />
                        <Errors current_key="faq_heading" key="faq_heading" />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="faq_sub_heading">
                          Faq Sub Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="faq_sub_heading"
                          name="faq_sub_heading"
                          value={formData.faq_sub_heading}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors
                          current_key="faq_sub_heading"
                          key="faq_sub_heading"
                        />
                      </FormGroup>
                    </Col>
                    <hr />
                    <Col xs={6}>
                      <h6>
                        <b>Faq Question Answer</b>
                      </h6>
                    </Col>
                    <Col xs={6}>
                      <Button
                        className="m-1"
                        size="sm"
                        color="primary"
                        style={{ float: "right" }}
                        onClick={() => addFormFields()}
                      >
                        <i className="fa fa-dot-circle-o"></i> Add More
                      </Button>
                    </Col>
                    {faq.map((element, index) => (
                      <>
                        <Col key={`faq-${index}`} xs={12}>
                          <FormGroup>
                            <Label htmlFor={`question-${index}`}>
                              Question <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id={`question-${index}`}
                              name="question"
                              value={faq[index]["question"]}
                              required
                              onChange={(e) => onFaqChange(e, index)}
                            />
                            <Errors
                              current_key={
                                "faqQuestionAns[" + index + "].question"
                              }
                              key={"faqQuestionAns[" + index + "].question"}
                            />
                          </FormGroup>
                        </Col>
                        <Col key={`faq2-${index}`} xs={index ? 10 : 12}>
                          <FormGroup>
                            <Label htmlFor="answer">
                              Answer <span>*</span>
                            </Label>
                            <Input
                              type="textarea"
                              id="answer"
                              name="answer"
                              value={faq[index]["answer"]}
                              required
                              onChange={(e) => onFaqChange(e, index)}
                            />
                            <Errors
                              current_key={
                                "faqQuestionAns[" + index + "].answer"
                              }
                              key={"faqQuestionAns[" + index + "].answer"}
                            />
                          </FormGroup>
                        </Col>
                        {index ? (
                          <Col key={`faq3-${index}`} xs={2} className="button-align">
                            <Button
                              size="md"
                              color="danger"
                              onClick={() => removeFormFields(index)}
                            >
                              <FaTrash color="white" />
                            </Button>
                            <Button
                              className="m-1"
                              size="md"
                              color="primary"
                              title="Add More"
                              onClick={() => addFormFields()}
                            >

                              <FaPlusSquare color="white" />
                            </Button>
                          </Col>
                        ) : null}
                      </>
                    ))}
                  </Row>
                </CardBody>
              </Card>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                    loading={loading}
                    disabled={loading}
                    style={{ width: "60px" }}
                  >
                    <i className="fa fa-dot-circle-o"></i>{" "}
                    {loading ? (
                      <Spinner style={{ fontSize: "20px" }} />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  {isPrev && <a href={queryStringData} target={'_blank'}>
                    <Button
                      type="button"
                      className="m-1"
                      size="sm"
                      color="info"
                    >
                      <i className="fa fa-ban"></i> Preview
                    </Button>
                  </a>}
                  <Link to="/dashboard/cms">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

ContactUs.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateContact: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  //currentSubBanner: state.subBanner.currentSubBanner,
  errorList: state.errors,
  loading: state.contact.loadingContact,
});

export default connect(mapStateToProps, {
  updateContact,
  getContact,
  loadingContact,
  removeErrors,
  setErrors,
})(ContactUs);
