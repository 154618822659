import axios from "../axios";
import { setAlert } from "actions/alert";
import { AVAILABILITY } from "constants/api";
import { GET_AVAILABILITY, LOADING_ON_AVAILABILITY_SUBMIT, AVAILABILITY_ERROR, AVAILABILITY_UPDATED, REMOVE_ERRORS, LOAD_AVAILABILITY, LOADING_AVAILABILITY } from "actions/types";
import { setErrorsList } from "actions/errors";
import { logout } from "actions/auth";

export const updateAvailability = (formData, history, _id) => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: LOADING_ON_AVAILABILITY_SUBMIT });

    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    try {
        const res = await axios.put(`${AVAILABILITY.UPDATE_AVAILABILITY().ENDPOINT}`, formData, config);
        if (res.data.status === true) {
            dispatch({
                type: AVAILABILITY_UPDATED,
            });
            dispatch(
                setAlert("Availability updated successfully", "success")
            );
            history("/dashboard/cms");
        } else {
            const errors = res.data.errors;
            if (errors) {
                dispatch({
                    type: AVAILABILITY_ERROR,
                });
                dispatch(setAlert(res.data.message, "danger"));
                errors.forEach((error) => {
                    dispatch(setErrorsList(error.msg, error.param));
                });
            }
        }
        return res.data ? res.data : { status: false };
    } catch (err) {
        if (err.response) {
            if (err.response.data && err.response.data.tokenStatus === 0) {
                dispatch(logout());
            } else {
                dispatch({
                    type: AVAILABILITY_ERROR,
                    payload: {
                        msg: err.response.statusText,
                        status: err.response.status,
                    },
                });
                dispatch(
                    setAlert(
                        err.response.data.message || err.response.statusText,
                        "danger"
                    )
                );
            }
        }
    }
}

export const getAvailability = () => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: LOADING_AVAILABILITY });
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await axios.get(
            `${AVAILABILITY.GET_AVAILABILITY().ENDPOINT}`,
            config
        );
        // dispatch({ type: REMOVE_ALERT });

        await dispatch({
            type: GET_AVAILABILITY,
            payload: res.data.response,
        });
        return res.data ? res.data.response : { status: false };
    } catch (err) {
        // console.log(err);
        if (err.response) {
            if (err.response.data && err.response.data.tokenStatus === 0) {
                dispatch(logout());
            } else {
                dispatch({
                    type: AVAILABILITY_ERROR,
                    payload: {
                        msg: err.response.statusText,
                        status: err.response.status,
                    },
                });
                dispatch(
                    setAlert(
                        err.response.data.message || err.response.statusText,
                        "danger"
                    )
                );
            }
        }
    }
};
// reset errors
export const removeErrors = () => async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
};

export const setErrors = (errors) => async (dispatch) => {
    if (errors) {
        dispatch({ type: AVAILABILITY_ERROR });
        dispatch(setAlert("Please correct the following errors", "danger"));
        errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
        });
    }
};

export const loadAvailability = () => async (dispatch) => {
    await dispatch({ type: LOAD_AVAILABILITY });
};