import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { connect } from "react-redux";
import {
  edit,
  loadPage,
  getPackageById,
  cancelSave,
  setErrors,
  removeErrors,
  resetComponentStore,
  notFound,
} from "actions/admin/package";
import Errors from "views/components/Notifications/Errors";
// import Spinner from "views/Spinner";import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import { validateForm } from "utils/misc";
import { useNavigate, useParams } from "react-router";
import LoadingButton from "views/components/LoadingButton";
import Spinner from "views/components/Spinner";

const EditPackage = ({
  edit,
  getPackageById,
  cancelSave,
  currentPackage,
  errorList,
  loadPage,
  loading,
  setErrors,
  removeErrors,
}) => {
  const history = useNavigate();
  const params = useParams();
  //########################## set the initial form data ##############################

  const initialState = {
    name: "",
    price: "",
    description: "",
    bonus_description: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [onlyOnce, setOnce] = useState(true);

  useEffect(() => {
    if (onlyOnce) {
      getPackageById(params.package_id);
      setOnce(false);
    }
    getPackageById(params.package_id).then((res) => {
      if (res === undefined) notFound(history);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.package_id, history]);

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      try {
        if (subscribe && currentPackage) {
          const { name, price, description, bonus_description } =
            currentPackage;
          setFormData((formData) => ({
            ...formData,
            name: name,
            price: price,
            description: description,
            bonus_description: bonus_description,
          }));
          console.log(currentPackage);
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    asyncCall();

    return () => {
      subscribe = false;
    };
  }, [currentPackage]);
  //########################## change event for all inputs ##############################
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // const handleDescriptionChange = (event) => {
  //   const desc = event.editor.getData(); // editor.getData();
  //   setFormData((ev) => {
  //     return { ...ev, description: desc };
  //   });
  // };
  // const handleBonusDescriptionChange = (event) => {
  //   const desc = event.editor.getData(); // editor.getData();
  //   setFormData((ev) => {
  //     return { ...ev, bonus_description: desc };
  //   });
  // };
  const onChangeDescription = (newDescription) => {
    setFormData((data) => ({ ...data, description: newDescription }));
  };
  const onChangeBonusDescription = (newDescription) => {
    setFormData((data) => ({ ...data, bonus_description: newDescription }));
  };
  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    const validationParam = [
      {
        param: "name",
        msg: "Please, provide the name of the package.",
      },
      {
        param: "price",
        msg: "Please provide the price",
      },
      {
        param: "description",
        msg: "Please provide the description",
      },
    ];
    if (!validateForm(formData, validationParam, setErrors)) return;

    const submitData = {};
    const excludeList = {
      avatarURL: 1,
    };

    for (let i in formData) {
      const data = formData[i];
      if (data === "" || data === null || data === undefined || excludeList[i])
        continue;
      submitData[i] = data;
    }
    edit(formData, history, params.package_id);
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe) {
        loadPage();
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [loadPage]);
  const { name, price, description, bonus_description } = formData;
  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
        <Row style={{ marginLeft: 10, marginTop: "4%" }}>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <h2>Edit Package</h2>
              </CardHeader>
            </Card>
          </Col>
          <Col xs="12">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="name">
                        Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        maxLength="25"
                        value={formData.name}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.name ? true : false}
                      />
                      <Errors current_key="name" key="name" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="price">
                        Price <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="price"
                        name="price"
                        value={formData.price}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.price ? true : false}
                      />
                      <Errors current_key="price" key="price" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="description">
                        Description <span>*</span>
                      </Label>
                      {formData.name != null && (
                        // <CKEditor
                        //   initData={
                        //     currentPackage
                        //       ? currentPackage.description
                        //         ? currentPackage.description
                        //         : ""
                        //       : ""
                        //   }
                        //   id="description"
                        //   name="description"
                        //   config={{
                        //     height: 100,
                        //     toolbar: [
                        //       ["Cut", "Copy", "Paste"],
                        //       ["Undo", "Redo"],
                        //       ["SpellChecker"],
                        //       ["Link", "Unlink", "Anchor"],
                        //       [
                        //         "Image",
                        //         "Table",
                        //         "Horizontal Line",
                        //         "Special Character",
                        //       ],
                        //       ["Maximize"],
                        //       ["Source"],
                        //       ["Bold", "Italic", "Strike"],
                        //       ["RemoveFormat"],
                        //       ["NumberedList", "BulletedList"],
                        //       ["DecreaseIndent", "IncreaseIndent"],
                        //       ["BlockQuote"],
                        //       ["Styles"],
                        //       ["Format"],
                        //       ["About"],
                        //     ],
                        //   }}
                        //   onChange={(event) => handleDescriptionChange(event)}
                        //   onBeforeLoad={(CKEDITOR) =>
                        //     (CKEDITOR.disableAutoInline = true)
                        //   }
                        //   invalid={errorList.description ? true : false}
                        // />
                        <CKEditor
                          editor={Editor}
                          required
                          data={
                            currentPackage !== null
                              ? currentPackage.description
                                ? currentPackage.description
                                : ""
                              : ""
                          }
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            onChangeDescription(data);
                          }}
                        />
                      )}
                      <Errors current_key="description" key="description" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="bonus_description">
                        Bonus Description
                      </Label>
                      {formData.name !== null && (
                        // <CKEditor
                        //   initData={
                        //     currentPackage
                        //       ? currentPackage.bonus_description
                        //         ? currentPackage.bonus_description
                        //         : ""
                        //       : ""
                        //   }
                        //   id="bonus_description"
                        //   name="bonus_description"
                        //   config={{
                        //     height: 100,
                        //     toolbar: [
                        //       ["Cut", "Copy", "Paste"],
                        //       ["Undo", "Redo"],
                        //       ["SpellChecker"],
                        //       ["Link", "Unlink", "Anchor"],
                        //       [
                        //         "Image",
                        //         "Table",
                        //         "Horizontal Line",
                        //         "Special Character",
                        //       ],
                        //       ["Maximize"],
                        //       ["Source"],
                        //       ["Bold", "Italic", "Strike"],
                        //       ["RemoveFormat"],
                        //       ["NumberedList", "BulletedList"],
                        //       ["DecreaseIndent", "IncreaseIndent"],
                        //       ["BlockQuote"],
                        //       ["Styles"],
                        //       ["Format"],
                        //       ["About"],
                        //     ],
                        //   }}
                        //   onChange={(event) =>
                        //     handleBonusDescriptionChange(event)
                        //   }
                        //   onBeforeLoad={(CKEDITOR) =>
                        //     (CKEDITOR.disableAutoInline = true)
                        //   }
                        //   invalid={errorList.bonus_description ? true : false}
                        // />
                        <CKEditor
                          editor={Editor}
                          required
                          data={
                            currentPackage !== null
                              ? currentPackage.bonus_description
                                ? currentPackage.bonus_description
                                : ""
                              : ""
                          }
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            onChangeBonusDescription(data);
                          }}
                        />
                      )}
                      <Errors
                        current_key="bonus_description"
                        key="bonus_description"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginLeft: 10, marginTop: "1%" }}>
          <Col>
            <Card>
              <CardFooter>
                <LoadingButton
                  className="me-3"
                  loading={loading}
                  disabled={loading}
                  color="primary"
                  type="submit"
                >
                  Update
                </LoadingButton>
                <Button
                  type="reset"
                  size="md"
                  color="danger"
                  onClick={onClickHandel}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

EditPackage.propTypes = {
  loading: PropTypes.bool.isRequired,
  edit: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  // showMsg: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
  getPackageById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.packages.loadingPackage,
  currentPackage: state.packages.currentPackage,
});
export default connect(mapStateToProps, {
  edit,
  getPackageById,
  cancelSave,
  loadPage,
  setErrors,
  removeErrors,
})(EditPackage);
