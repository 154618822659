import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { setAlert } from "actions/alert";
import {
  resetPassword,
  setPasswordError,
  getResetTokenStatus,
  tokenInvalid,
  loginRedirect,
} from "actions/auth";
import Errors from "views/components/Notifications/Errors";
import Alert from "views/components/Notifications/Alert";
import Spinner from "views/components/AppLoading";
import Password from "views/components/Password";
import logo from "assets/img/logo/logo.png";

import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Row,
  CardText,
  FormGroup,
} from "reactstrap";

const ResetPassword = ({
  setAlert,
  setPasswordError,
  resetPassword,
  getResetTokenStatus,
  tokenInvalid,
  errorList,
  loading,
  loginRedirect,
}) => {
  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });
  const [successful, setSuccessful] = useState(false);
  const history = useNavigate();
  const params = useParams();

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      subscribe &&
        getResetTokenStatus(params.token).then((data) => {
          !data && subscribe && tokenInvalid(history);
        });
    };
    asyncCall();

    return () => {
      subscribe = false;
    };
  }, [params.token, history]);

  const { password, confirm_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirm_password) {
      setPasswordError("Password do not match", "confirm_password");
      setAlert(
        "Errors! Please correct the following errors and submit again.",
        "danger"
      );
    } else {
      resetPassword(password, params.token).then((res) => {
        if (res && res.status === true) {
          setSuccessful(true);
        }
      });
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          {/* <img src={logo} style={{ width: 128, padding: 16 }} alt="logo" /> */}
        </Row>
        <Row className="justify-content-center">
          <Col md="9" lg="7" xl="6">
            <Card className="mx-4">
              <CardBody className="p-4">
                {successful ? (
                  <CardText>
                    <span>
                      Password has been reset successfully. Click here to{" "}
                    </span>
                    <Link to="/" title="login">
                      Login
                    </Link>
                  </CardText>
                ) : (
                  <Form onSubmit={(e) => onSubmit(e)}>
                    <h1>Reset Password</h1>
                    <Alert />
                    <FormGroup>
                      <Password
                        icon={true}
                        placeholder="Password"
                        name="password"
                        minLength={8}
                        maxLength={30}
                        required
                        value={password}
                        onChange={(e) => onChange(e)}
                        invalid={!!errorList.password}
                      />
                      <Errors current_key="password" key="password" />
                    </FormGroup>
                    <FormGroup>
                      <Password
                        icon={true}
                        placeholder="Confirm Password"
                        name="confirm_password"
                        minLength={8}
                        maxLength={30}
                        required
                        value={confirm_password}
                        onChange={(e) => onChange(e)}
                        invalid={!!errorList.confirm_password}
                      />
                      <Errors
                        current_key="confirm_password"
                        key="confirm_password"
                      />
                    </FormGroup>
                    <Row>
                      {loading ? (
                        <Spinner />
                      ) : (
                        <>
                          <Col xs="6">
                            <Button color="primary" className="px-6">
                              Reset Password
                            </Button>
                          </Col>
                          <Col xs="6">
                            <Button
                              color="link"
                              className="px-0 text-end"
                              onClick={(e) => loginRedirect(history)}
                            >
                              Have an account? Login
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  setPasswordError: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  getResetTokenStatus: PropTypes.func.isRequired,
  tokenInvalid: PropTypes.func.isRequired,
  loginRedirect: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  errorList: state.errors,
  loading: state.auth.loadingPasswordReset,
});

export default connect(mapStateToProps, {
  setAlert,
  setPasswordError,
  resetPassword,
  getResetTokenStatus,
  tokenInvalid,
  loginRedirect,
})(ResetPassword);
