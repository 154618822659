import React from "react";
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { cilAccountLogout, cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import avatar from "assets/img/avatar.png";
import { Link } from "react-router-dom";

const HeaderDropdown = ({ user, logout }) => {
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle
        placement="bottom-end"
        className="d-inline-flex py-0 align-items-center"
        caret={false}
      >
        <CAvatar src={user?.avatar || avatar} size="md" />
        <div className="d-flex flex-column ms-2">
          <p className="m-0 text-uppercase fw-bold">{user.username}</p>
          <p className="m-0 text-primary">Admin</p>
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">
          Account
        </CDropdownHeader>
        <CDropdownItem href="/dashboard/profile">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem onClick={logout} style={{ cursor: "pointer" }}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
        </CDropdownItem>
        to
      </CDropdownMenu>
    </CDropdown>
  );
};

export default HeaderDropdown;
