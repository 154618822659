import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  SETTING_ERROR,
  REMOVE_ERRORS,
  SETTING_UPDATED,
  GET_SETTING,
  LOADING_ON_SETTINGS_SUBMIT,
  LOADING_SETTING_DATA,
  RESET,
} from "actions/types";
import { SETTINGS } from "constants/api";
import { logout } from "../auth";
//Get Setting Data
export const getSetting =
  (userParams = {}, noRedux) =>
  async (dispatch) => {
    dispatch({ type: REMOVE_ERRORS });
    dispatch({ type: LOADING_SETTING_DATA });
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(`${SETTINGS.LIST().ENDPOINT}`, config);
      // dispatch({ type: REMOVE_ALERT });

      await dispatch({
        type: GET_SETTING,
        payload: res.data.response,
      });

      return res.data ? res.data.response : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: SETTING_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };
// Update Setting data
export const updateSetting = (formData, history, _id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch({ type: LOADING_ON_SETTINGS_SUBMIT });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.put(
      `${SETTINGS.UPDATE_SETTING().ENDPOINT}/${_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: SETTING_UPDATED,
      });
      dispatch(setAlert("Setting updated successfully", "success"));
      history("/dashboard/settings");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({
          type: SETTING_ERROR,
        });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SETTING_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};
// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: SETTING_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};
