import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes, { string } from "prop-types";
import queryString from 'query-string';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  FormGroup,
  CardHeader,
  Form,
  Label,
  CardFooter,
} from "reactstrap";
import Spinner from "views/components/Spinner";
import styled from "styled-components";
import Errors from "views/components/Notifications/Errors";
import {
  updateThemeColor,
  getThemeColor,
  removeErrors,
  setErrors,
  loadingThemeColor,
} from "actions/admin/themeColor";
import { useNavigate, Link } from "react-router-dom";

const Stack = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.alignItems || "initial"};
  -webkit-box-align: center;
  & > div:not(:first-child) {
    margin: 0px;
    margin-left: ${(props) =>
    props.direction !== "column" ? (props.gap || 1) * 8 + "px" : "0px"};
  }
`;

let resetColor = {
  primary_color: {
    main: "#d97706",
    contrastText: "#ffffff",
  },
  secondary_color: {
    main: "#fef3c7",
    contrastText: "#d97706",
  },
  warning_color: {
    main: "#f59e0b",
    contrastText: "#fafafa",
  },
  info_color: {
    main: "#ffffff",
    contrastText: "#d97706",
  },
  header: {
    main: "#FFFFFF",
    contrastText: "#6B7280",
  },
  footer: {
    main: "#FFFFFF",
    contrastText: "#6B7280",
  },
};

const ManageColors = ({
  updateThemeColor,
  getThemeColor,
  currentThemeColor,
  loadingThemeColor,
  loading,
  removeErrors,
  setErrors,
}) => {
  //let reset = false;
  const history = useNavigate();
  const initialState = {
    primary_color: {
      main: "",
      contrastText: "",
    },
    secondary_color: {
      main: "",
      contrastText: "",
    },
    warning_color: {
      main: "",
      contrastText: "",
    },
    info_color: {
      main: "",
      contrastText: "",
    },
    header: {
      main: "",
      contrastText: "",
    },
    footer: {
      main: "",
      contrastText: "",
    },
  };
  const [formData, setFormData] = useState(initialState);
  const [queryStringData, setQueryStringData] = useState("");
  const [reset, setReset] = useState(false);
  const [isPrev, setIsPrev] = useState(false);
  const onChange = (event, colorType) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [colorType]: { ...formData[colorType], [name]: value },
    });
    setIsPrev(true);
  };

  useEffect(() => {
    const setString = queryString.stringify({ preview: JSON.stringify(formData) });
    const prevUrl = process.env.REACT_APP_CLIENT_URL;
    setQueryStringData(`${prevUrl}/?${setString}`)
  }, [formData])

  useEffect(async () => {
    const getData = await getThemeColor();
    if (getData) {
      setFormData({
        ...formData,
        header: {
          main: getData?.header?.main,
          contrastText: getData?.header?.contrastText,
        },
        footer: {
          main: getData?.footer?.main,
          contrastText: getData?.footer?.contrastText,
        },
        primary_color: {
          main: getData?.primary_color?.main,
          contrastText: getData?.primary_color?.contrastText,
        },
        secondary_color: {
          main: getData.secondary_color.main,
          contrastText: getData.secondary_color.contrastText,
        },
        warning_color: {
          main: getData.warning_color.main,
          contrastText: getData.warning_color.contrastText,
        },
        info_color: {
          main: getData.info_color.main,
          contrastText: getData.info_color.contrastText,
        },
      });

    }
  }, [reset]);

  const onSubmit = (event) => {
    event.preventDefault();
    updateThemeColor(formData, history, "");
    setReset(false);
  };
  const resetTheme = () => {
    if (
      window.confirm(
        `Are you sure you want to reset the theme color?`
      )
    ) {
      updateThemeColor(resetColor, history, "").then((result, rrr) => {
        if (result.status) {
          setReset(true);
          setIsPrev(false);
        }
      });
    }
  };
  return (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      {loading && <Spinner applyClass={"loading-box"} />}
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Stack
                alignItems="center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <h4>Manage Colors</h4>
                </div>
                <div className="add-button-div">
                  {/* <Link to="/home/properties/add" title="create"> */}
                  <Button color="primary" size="sm" onClick={resetTheme}>
                    <i className="fa fa-plus"></i> Reset Color Theme
                  </Button>
                  {/* </Link> */}
                </div>
              </Stack>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <Card>
                <CardBody>
                  <h6>
                    <b>Header Color</b>
                  </h6>
                  <Row>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="main">
                          Background Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          pattern="#[a-f0-9]{6}"
                          id="main"
                          name="main"
                          value={formData.header.main}
                          required
                          onChange={(e) => onChange(e, "header")}
                        />
                        <Errors
                          current_key="header.main"
                          key="header.main"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="contrastText">
                          Contrast Text Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="contrastText"
                          name="contrastText"
                          value={formData.header.contrastText}
                          required
                          onChange={(e) => onChange(e, "header")}
                        />
                        <Errors
                          current_key="header.contrastText"
                          key="header.contrastText"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h6>
                    <b>Footer Color</b>
                  </h6>
                  <Row>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="main">
                          Background Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          pattern="#[a-f0-9]{6}"
                          id="main"
                          name="main"
                          value={formData.footer.main}
                          required
                          onChange={(e) => onChange(e, "footer")}
                        />
                        <Errors
                          current_key="footer.main"
                          key="footer.main"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="contrastText">
                          Contrast Text Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="contrastText"
                          name="contrastText"
                          value={formData.footer.contrastText}
                          required
                          onChange={(e) => onChange(e, "footer")}
                        />
                        <Errors
                          current_key="footer.contrastText"
                          key="footer.contrastText"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h6>
                    <b>Primary Section</b> <small>(Change Button Color & Section Background )</small>
                  </h6>
                  <Row>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="main">
                          Main Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          pattern="#[a-f0-9]{6}"
                          id="main"
                          name="main"
                          value={formData.primary_color.main}
                          required
                          onChange={(e) => onChange(e, "primary_color")}
                        />
                        <Errors
                          current_key="primary_color.main"
                          key="primary_color.main"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="contrastText">
                          Contrast Text Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="contrastText"
                          name="contrastText"
                          value={formData.primary_color.contrastText}
                          required
                          onChange={(e) => onChange(e, "primary_color")}
                        />
                        <Errors
                          current_key="primary_color.contrastText"
                          key="primary_color.contrastText"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h6>
                    <b>Secondary Section</b> <small>(Change Tenant Login, Schedule Showing button Color. )</small>
                  </h6>
                  <Row>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="main">
                          Main Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="main"
                          name="main"
                          value={formData.secondary_color.main}
                          required
                          onChange={(e) => onChange(e, "secondary_color")}
                        />
                        <Errors
                          current_key="secondary_color.main"
                          key="secondary_color.main"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="contrastText">
                          Contrast Text Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="contrastText"
                          name="contrastText"
                          value={formData.secondary_color.contrastText}
                          required
                          onChange={(e) => onChange(e, "secondary_color")}
                        />
                        <Errors
                          current_key="secondary_color.contrastText"
                          key="secondary_color.contrastText"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h6>
                    <b>Warning Section</b> <small>(Change Icons Color On Home Page. )</small>
                  </h6>
                  <Row>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="main">
                          Warning Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="main"
                          name="main"
                          value={formData.warning_color.main}
                          required
                          onChange={(e) => onChange(e, "warning_color")}
                        />
                        <Errors
                          current_key="warning_color.main"
                          key="warning_color.main"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="contrastText">
                          Contrast Text Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="contrastText"
                          name="contrastText"
                          value={formData.warning_color.contrastText}
                          required
                          onChange={(e) => onChange(e, "warning_color")}
                        />
                        <Errors
                          current_key="warning_color.contrastText"
                          key="warning_color.contrastText"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h6>
                    <b>Info Section</b> <small>(Change Notify me button color on home page. )</small>
                  </h6>
                  <Row>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="main">
                          Info Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="main"
                          name="main"
                          value={formData.info_color.main}
                          required
                          onChange={(e) => onChange(e, "info_color")}
                        />
                        <Errors
                          current_key="info_color.main"
                          key="info_color.main"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="contrastText">
                          Contrast Text Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="contrastText"
                          name="contrastText"
                          value={formData.info_color.contrastText}
                          required
                          onChange={(e) => onChange(e, "info_color")}
                        />
                        <Errors
                          current_key="info_color.contrastText"
                          key="info_color.contrastText"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    color="primary"
                    loading={loading}
                    disabled={loading}
                    style={{ width: "60px" }}
                  >
                    <i className="fa fa-dot-circle-o"></i>{" "}
                    {loading ? (
                      <Spinner style={{ fontSize: "20px" }} />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  {isPrev && <a href={queryStringData} target={'_blank'}>
                    <Button
                      type="button"
                      className="m-1"
                      size="sm"
                      color="info"
                    >
                      <i className="fa fa-ban"></i> Preview
                    </Button>
                  </a>}
                  <Link to="/dashboard">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>


                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

ManageColors.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateThemeColor: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  //currentSubBanner: state.subBanner.currentSubBanner,
  errorList: state.errors,
  loading: state.themeColor.loadingThemeColor,
  currentThemeColor: state.themeColor.currentThemeColor
});

export default connect(mapStateToProps, {
  updateThemeColor,
  getThemeColor,
  loadingThemeColor,
  removeErrors,
  setErrors,
})(ManageColors);
