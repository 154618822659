import {
    LOCATION_CREATED,
    LOCATION_ERROR,
    DELETE_LOCATION,
    LOCATION_UPDATED,
    LOCATION_LIST_UPDATED,
    GET_LOCATION_BY_ID,
    LOADING_ON_LOCATION_SUBMIT,
    LOCATION_SEARCH_PARAMATERS_UPDATE,
    CHANGE_LOCATION_STATUS,
    LOAD_PAGE,
    LOADING_LOCATION_LIST_DATA,
    RESET,
  } from "actions/types";
  import * as Constants from "constants/index";
  
  const initialState = {
    locationList: {
      page: 1,
      data: [],
      count: 0,
    },
    currentLocation: [],
    loadingLocationList: true,
    loadingLocation: true,
    loadingListData: true,
    performDelete: false,
    error: {},
    sortingParams: {
      limit: Constants.DEFAULT_PAGE_SIZE,
      page: 1,
      orderBy: "created_at",
      ascending: "desc",
      query: "",
    },
  };
  
  export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RESET:
        return {
          ...initialState,
          loadingLocation: state.loadingLocation,
          loadingLocationList: state.loadingLocationList,
          loadingListData: state.loadingListData,
        };
      case LOAD_PAGE:
        return {
          ...state,
          loadingLocation: false,
        };
      case LOCATION_CREATED:
        return {
          ...state,
          loadingLocation: false,
        };
      case LOCATION_UPDATED:
        return {
          ...state,
          currentLocation: payload,
          sortingParams: initialState.sortingParams,
          loadingLocation: false,
        };
      case LOCATION_ERROR:
        return {
          ...state,
          error: payload,
          loadingLocation: false,
          loadingLocationList: false,
          loadingListData: false,
          performDelete: false,
        };
      case DELETE_LOCATION:
        const currentCount = state.locationList.count;
        const currentLimit = state.sortingParams.limit;
        const currentPage = parseInt(state.locationList.page);
        let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
        remainingPages = remainingPages ? remainingPages : 1;
        return {
          ...state,
          locationList: {
            data: state.locationList.data.filter(
              (location) => location._id !== payload
            ),
            count: currentCount - 1,
            page:
              currentPage <= remainingPages
                ? currentPage.toString()
                : remainingPages.toString(),
          },
          loadingLocationList: false,
          performDelete: true,
        };
      case GET_LOCATION_BY_ID:
        return {
          ...state,
          currentLocation: payload,
          loadingLocation: false,
        };
      case LOCATION_LIST_UPDATED:
        return {
          ...state,
          locationList: {
            data: payload.data,
            page: payload.metadata[0].current_page,
            count: payload.metadata[0].totalRecord,
          },
          // loadingLocation: true,
          loadingLocationList: false,
          loadingListData: false,
          performDelete: false,
        };
      case LOCATION_SEARCH_PARAMATERS_UPDATE:
        return {
          ...state,
          sortingParams: { ...payload },
        };
  
      case CHANGE_LOCATION_STATUS:
        return {
          ...state,
          locationList: {
            ...state.locationList,
            data: state.locationList.data.map((location) =>
              location._id === payload._id
                ? { ...location, status: payload.status }
                : location
            ),
          },
        };
      case LOADING_LOCATION_LIST_DATA:
        return {
          ...state,
          loadingListData: true,
        };
      case LOADING_ON_LOCATION_SUBMIT:
        return {
          ...state,
          loadingLocation: true,
        };
  
      default:
        return state;
    }
  }
  