import {
  PROPERTY_LIST_UPDATED,
  PROPERTY_CREATED,
  PROPERTY_ERROR,
  PROPERTY_UPDATED,
  PROPERTY_SEARCH_PARAMETERS_UPDATED,
  LOADING_PROPERTY_LIST,
  LOAD_PROPERTY,
  RESET,
  LOADING_ON_USER_SUBMIT,
  GET_PROPERTY_BY_ID,
  DELETE_PROPERTY,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  pageList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentProperty: null,
  loadingPropertyList: false,
  loadingProperty: false,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: 30,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: {},
    filters: [],
  },
};

export default function Index(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingProperty: state.loadingProperty,
        loadingPropertyList: state.loadingPropertyList,
      };
    case PROPERTY_CREATED:
    case LOAD_PROPERTY:
      return {
        ...state,
        loadingProperty: false,
      };
    case GET_PROPERTY_BY_ID:
    case PROPERTY_UPDATED:
      return {
        ...state,
        currentProperty: payload,
        loadingProperty: false,
      };
    case PROPERTY_ERROR:
      return {
        ...state,
        error: payload,
        loadingProperty: false,
        loadingPropertyList: false,
      };
    case DELETE_PROPERTY:
      const currentCount = state.pageList.count;
      const currentLimit = state.sortingParams.limit;
      const currentProperty = parseInt(state.pageList.page);
      let remainingProperty = Math.ceil((currentCount - 1) / currentLimit);
      remainingProperty = remainingProperty ? remainingProperty : 1;
      return {
        ...state,
        pageList: {
          data: state.pageList.data.filter((page) => page._id !== payload),
          count: currentCount - 1,
          page:
            currentProperty <= remainingProperty
              ? currentProperty.toString()
              : remainingProperty.toString(),
        },
        loadingProperty: false,
        performDelete: true,
      };
    case PROPERTY_LIST_UPDATED:
      return {
        ...state,
        pageList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingPropertyList: false,
        loadingProperty: false,
        performDelete: false,
      };
    case PROPERTY_SEARCH_PARAMETERS_UPDATED:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case LOADING_PROPERTY_LIST:
      return {
        ...state,
        loadingPropertyList: true,
      };
    case LOADING_ON_USER_SUBMIT:
      return {
        ...state,
        loadingProperty: true,
      };
    default:
      return state;
  }
}
