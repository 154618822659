import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { editPassword, setErrors, removeErrors } from "actions/admin/profile";
import Errors from "views/components/Notifications/Errors";
import Spinner from "views/components/Spinner";
import { validateForm } from "utils/misc";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Password from "views/components/Password";

const ChangePassword = ({
  email,
  onClosed,
  editPassword,
  errorList,
  doOpen,
  loadingChangePassword,
  setErrors,
  removeErrors,
}) => {
  const initialData = {
    email,
    password: "",
    current_password: "",
    confirm_password: "",
  };

  const [formData, setFormData] = useState(initialData);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const navigate = useNavigate();

  const reset = () => {
    setModal(false);
    setFormData(initialData);
    onClosed();
  };

  useEffect(() => {
    setModal(doOpen);
  }, [doOpen]);

  const { password, current_password, confirm_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const savePassword = (e) => {
    e.preventDefault();
    removeErrors();
    if (
      !validateForm(
        formData,
        [
          {
            param: "current_password",
            msg: "Please enter the current password.",
          },
          {
            param: "password",
            msg: "Please enter the new password.",
          },
          {
            param: "confirm_password",
            msg: "Please confirm the new password.",
          },
        ],
        setErrors
      )
    )
      return;
    editPassword(formData, navigate).then((res) => {
      if (!res || !res.status) {
        // -- TODO --
        // -- PERFORM ON EDIT ERROR RELATED TASKS HERE
      } else {
        // -- IF NO ERROR RESET MODAL BOX
        reset();
      }
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} onClosed={reset}>
      <ModalHeader toggle={toggle}>Change Password</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Password
            icon={true}
            placeholder="Current Password*"
            autoComplete="current_password"
            name="current_password"
            id="current_password"
            minLength="8"
            value={current_password}
            onChange={(e) => onChange(e)}
            invalid={errorList.current_password ? true : false}
          />
          <Errors current_key="current_password" key="current_password" />
        </FormGroup>
        <FormGroup>
          <Password
            icon={true}
            placeholder="New password*"
            name="password"
            id="password"
            minLength="8"
            value={password}
            onChange={(e) => onChange(e)}
            invalid={errorList.password ? true : false}
          />
          <Errors current_key="password" key="password" />
        </FormGroup>
        <FormGroup>
          <Password
            icon={true}
            placeholder="Confirm Password*"
            name="confirm_password"
            id="confirm_password"
            minLength="8"
            value={confirm_password}
            onChange={(e) => onChange(e)}
            invalid={errorList.confirm_password ? true : false}
          />
          <Errors current_key="confirm_password" key="confirm_password" />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {loadingChangePassword ? (
          <Spinner />
        ) : (
          <>
            <a onClick={savePassword} className="m-1" href="#!">
              <Button color="primary">Save</Button>
            </a>
            <Button color="secondary" className="m-1" onClick={toggle}>
              Close
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

ChangePassword.propTypes = {
  editPassword: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loadingChangePassword: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  loadingChangePassword: state.profile.loadingChangePassword,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  editPassword,
  setErrors,
  removeErrors,
})(ChangePassword);
