import {
    SETTING_ERROR,
    SETTING_UPDATED,
   LOADING_ON_SETTINGS_SUBMIT,
    LOADING_SETTING_DATA,
    GET_SETTING
  } from "actions/types";
  
  const initialState = {
    currentSetting: [],
    loadingSetting: true,
    error: {},
  };
  
  export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case SETTING_ERROR:
        return {
          ...state,
          error: payload,
          loadingSetting: false,
        };
      case GET_SETTING:
        return {
          ...state,
          currentSetting: payload,
          loadingSetting: false,
        };
      case LOADING_SETTING_DATA:
        return {
          ...state,
          loadingSetting: true,
        };
      case LOADING_ON_SETTINGS_SUBMIT:
        return {
          ...state,
          loadingSetting: true,
        };
      case SETTING_UPDATED:
        return{
          ...state,
          loadingSetting: false,
        }
      default:
        return state;
    }
  }
  