import React from "react";
import { CFooter } from "@coreui/react";
import moment from "moment";

const DefaultFooter = () => {
  return (
    <CFooter>
      <div>
        <a
          href={process.env.REACT_APP_ADMIN_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {process.env.REACT_APP_ADMIN_APP_NAME}
        </a>
        <span className="ms-1">&copy; {moment().format("YYYY")}</span>
      </div>
    </CFooter>
  );
};

export default React.memo(DefaultFooter);
