import {
    GALLERY_ERROR,
    GALLERY_UPDATED,
    LOADING_ON_GALLERY_SUBMIT,
    LOADING_SUBBANNER_DATA,
    GET_SUBBANNER,
    LOAD_GALLERY,
    LOADING_GALLERY,
    GET_GALLERY
} from "actions/types";

const initialState = {
    currentGallery: null,
    loadingGallery: false,
    error: {},
};

export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GALLERY_ERROR:
            return {
                ...state,
                error: payload,
                loadingGallery: false,
            };
        case GET_SUBBANNER:
            return {
                ...state,
                currentGallery: payload,
                loadingGallery: false,
            };
        case LOAD_GALLERY:
            return {
                ...state,
                loadingGallery: false,
            };
        case LOADING_GALLERY:
        case LOADING_ON_GALLERY_SUBMIT:
            return {
                ...state,
                loadingGallery: true,
            };
        case GET_GALLERY:
        case GALLERY_UPDATED:
            return {
                ...state,
                loadingGallery: false,
            }
        default:
            return state;
    }
}
