import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  SUBBANNER_ERROR,
  REMOVE_ERRORS,
  SUBBANNER_UPDATED,
  GET_SUBBANNER,
  LOADING_ON_SUBBANNER_SUBMIT,
  LOADING_SUBBANNER_DATA,
  RESET,
} from "actions/types";
import { SUBBANNER } from "constants/api";
import { logout } from "../auth";
//Get About Us Data
export const getSubBanner = (userParams = {}, noRedux) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch({ type: LOADING_SUBBANNER_DATA });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${SUBBANNER.LIST().ENDPOINT}`, config
    );

    await dispatch({
      type: GET_SUBBANNER,
      payload: res.data.response,
    });

    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SUBBANNER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};
// Update About us data
export const updateSubBanner = (formData, history, _id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch({ type: LOADING_ON_SUBBANNER_SUBMIT });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`${SUBBANNER.UPDATE_SUBBANNER().ENDPOINT}/${_id}`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: SUBBANNER_UPDATED,
      });
      dispatch(
        setAlert("Sub Banner updated successfully", "success")
      );
      history("/home/subBanner");
    } else {
      const errors = res.data.esrrors;
      if (errors) {
        dispatch({
          type: SUBBANNER_ERROR,
        });
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: SUBBANNER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};
// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: SUBBANNER_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};
