import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import moment from "moment";
import { FaTimes } from "react-icons/fa";
import { debounce } from "lodash-es";
import { FormGroup, Row, Col, InputGroup, Label, Button } from "reactstrap";
import Select from "react-select";

import SearchComponent from "./Search";
import Category from "../Category";
import { CATEGORY_STATUS } from "constants";


const initialDate = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};

const Filters = ({ onSearch, onFilter }) => {
  const [filterText, setFilterText] = React.useState("");
  const [filterCategory, setFilterCategory] = React.useState(null);
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [filterDate, setFilterDate] = React.useState(initialDate);

  const searchIt = React.useCallback(
    debounce(function (inputValue) {
      onSearch(inputValue);
    }, 700),
    []
  );

  const onFilterUpdate = (type, value) => {
    switch (type) {
      case "search":
        searchIt(value);
        break;
      case "createdAt":
      case "category":
      case "status":
        onFilter(type, value);
        break;
      default:
    }
  };

  const searchFilter = React.useMemo(() => {
    const handleClear = () => {
      setFilterText("");
      onFilterUpdate("search", "");
    };

    return (
      <SearchComponent
        onFilter={(e) => {
          setFilterText(e.target.value);
          onFilterUpdate("search", e.target.value);
        }}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, onFilterUpdate]);


  const dateFilter = React.useMemo(() => {
    const onCancelRange = () => {
      setFilterDate(initialDate);
      onFilterUpdate("createdAt", "");
    };
    return (
      <FormGroup>
        <Label>Created Date</Label>

        <InputGroup>
          <DateRange
            moveRangeOnFirstSelection
            // retainEndDateOnFirstSelection
            onChange={(item) => {
              setFilterDate(item.selection);
              var actualDate = new Date(item.selection.endDate);
              var endOfDayDate = new Date(actualDate.getFullYear()
              ,actualDate.getMonth()
              ,actualDate.getDate()
              ,23,59,59);
              onFilterUpdate("createdAt", {
                from: item.selection.startDate,
                to: endOfDayDate,
              });
            }}
            ranges={[filterDate]}
          />
          <div className="input-group-append">
            <span className="input-group-text-custom bg-transparent">
              <Button onClick={onCancelRange} color="danger">
                <FaTimes /> Clear
              </Button>
            </span>
          </div>
        </InputGroup>
      </FormGroup>
    );
  }, [JSON.stringify(filterDate)]);

  const categoryFilter = React.useMemo(() => {
    return (
      <Category
        onSelect={(value) => {
          if (!value) {
            setFilterCategory(null);
            onFilterUpdate("category", null);
          } else {
            setFilterCategory(value.value);
            onFilterUpdate("category", value);
          }
        }}
        noSelection
        showAll
        selected={filterCategory}
        isClearable={true}
        isRequired= {false}
      />
    );
  }, [JSON.stringify(filterCategory)]);

  const statusFilter = React.useMemo(() => {
    return (
      <FormGroup>
      <Label htmlFor="category">
        Status
      </Label>
      <Select
        options={CATEGORY_STATUS}
        value={filterStatus}
        onChange={(value) => {
          if (!value) {
            setFilterStatus(null);
            onFilterUpdate("status", null);
          } else {
            setFilterStatus(value.value);
            onFilterUpdate("status", value);
          }
        }}
        isClearable={true}
      />
    </FormGroup>
    );
  }, [JSON.stringify(filterStatus)]);
  return (
    <>
      <Col xs={12} md={6}>
        {dateFilter}
      </Col>
      <Col xs={12} md={6}>
        <Row>{searchFilter}</Row>
        {/* <Row>{challengeCategory}</Row>
        <Row>{difficultyFilter}</Row> */}
        <Row>{categoryFilter}</Row>
        <Row>{statusFilter}</Row>
      </Col>
    </>
  );
};

export default Filters;
