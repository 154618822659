import {
    AVAILABILITY_ERROR,
    AVAILABILITY_UPDATED,
    LOADING_ON_AVAILABILITY_SUBMIT,
    LOADING_SUBBANNER_DATA,
    GET_SUBBANNER,
    LOAD_AVAILABILITY,
    LOADING_AVAILABILITY,
    GET_AVAILABILITY
} from "actions/types";

const initialState = {
    currentAvailability: null,
    loadingAvailability: false,
    error: {},
};

export default function Index(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case AVAILABILITY_ERROR:
            return {
                ...state,
                error: payload,
                loadingAvailability: false,
            };
        case GET_SUBBANNER:
            return {
                ...state,
                currentAvailability: payload,
                loadingAvailability: false,
            };
        case LOAD_AVAILABILITY:
            return {
                ...state,
                loadingAvailability: false,
            };
        case LOADING_AVAILABILITY:
        case LOADING_ON_AVAILABILITY_SUBMIT:
            return {
                ...state,
                loadingAvailability: true,
            };
        case GET_AVAILABILITY:
        case AVAILABILITY_UPDATED:
            return {
                ...state,
                loadingAvailability: false,
            }
        default:
            return state;
    }
}
