import axios from "../axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "actions/errors";
import {
  BANNER_CREATED,
  BANNER_ERROR,
  DELETE_BANNER,
  BANNER_UPDATED,
  BANNER_LIST_UPDATED,
  GET_BANNER_BY_ID,
  BANNER_SEARCH_PARAMATERS_UPDATE,
  REMOVE_ERRORS,
  CHANGE_BANNER_STATUS,
  LOADING_ON_EMAIL_SUBMIT,
  LOADING_EMAIL_LIST_DATA,
  LOAD_PAGE,
  RESET,
} from "actions/types";
import { logout } from "actions/auth";
import { BANNER } from "constants/api";

//get Email Template list

export const getBannerList =
  (bannerParams) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({ type: REMOVE_ERRORS });
      const query = bannerParams.query ? bannerParams.query : "";
      bannerParams.query = query;
      bannerParams.page = bannerParams.page
        ? bannerParams.page
        : 1;

      config.params = bannerParams;
      const res = await axios.get(BANNER.LIST().ENDPOINT, config);
      // dispatch({ type: REMOVE_ALERT });
      dispatch({
        type: BANNER_LIST_UPDATED,
        payload: res.data.response[0],
      });
      dispatch({
        type: BANNER_SEARCH_PARAMATERS_UPDATE,
        payload: bannerParams,
      });
      return res.data ? res.data : { status: false };
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: BANNER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// Get email template by id
export const getBannerById = (banner_id) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnEmailSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${BANNER.LIST().ENDPOINT}/${banner_id}`,
      config
    );
    // dispatch({ type: REMOVE_ALERT });

    await dispatch({
      type: GET_BANNER_BY_ID,
      payload: res.data.response,
    });
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: BANNER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Create Email Template
export const create = (formData, history) => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      BANNER.CREATE().ENDPOINT,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: BANNER_CREATED,
        payload: res.data.response,
      });
      // dispatch({ type: REMOVE_ALERT });
      dispatch(setAlert("Banner Created.", "success"));
      history("/home/banner");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: BANNER_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// Edit Email Template
export const edit =
  (formData, history, banner_id) => async (dispatch) => {
    // -- Always remove the previous errors before sending new request
    dispatch({ type: REMOVE_ERRORS });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BANNER.CREATE().ENDPOINT}/${banner_id}`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: BANNER_UPDATED,
          payload: res.data.response,
        });
        // dispatch({ type: REMOVE_ALERT });
        dispatch(setAlert("Banner Updated.", "success"));
        history("/home/banner");
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch({ type: BANNER_ERROR });
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: BANNER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// Delete Email Template
export const deleteBanner =
  (banner_id, history) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.delete(
        `${BANNER.LIST().ENDPOINT}/${banner_id}`,
        config
      );
      // dispatch({ type: REMOVE_ALERT });

      dispatch({
        type: DELETE_BANNER,
        payload: banner_id,
      });
      dispatch(setAlert("Banner deleted", "success"));
    } catch (err) {
      // console.log(err);
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch({
            type: BANNER_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status,
            },
          });
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

/* //change status
export const changeStatus = (cms_id, status) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/cms/change-status/${cms_id}`,
      { status },
      config
    );
    // dispatch({ type: REMOVE_ALERT });
    await dispatch({
      type: CHANGE_CMS_STATUS,
      payload: res.data.response,
    });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    // console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch({
          type: CMS_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};
 */
// Delete User
export const cancelSave = (history) => async (dispatch) => {
  // dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history("/home/banner");
};

// Dispatch Loading
export const loadingOnEmailSubmit = () => async (dispatch) => {
  await dispatch({ type: LOADING_ON_EMAIL_SUBMIT });
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history("/home/page-not-found");
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch({ type: BANNER_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  await dispatch({ type: LOAD_PAGE });
};

// reset errors
export const removeErrors = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERRORS });
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch({ type: RESET });
};
