import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CKEditor } from "ckeditor4-react";
import queryString from 'query-string';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
} from "reactstrap";
import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import {
  updateAvailability,
  getAvailability,
  removeErrors,
  setErrors,
} from "actions/admin/availability";
import Spinner from "views/components/Spinner";

const Availability = ({
  updateAvailability,
  getAvailability,
  loading,
  removeErrors,
  setErrors,
}) => {
  const params = useParams();
  const history = useNavigate();

  const initialState = {
    heading: "",
    banner_color: "#D97706",
    sub_heading_color: "#D97706",
    background_color: "#FFFFFF",
    box_color: "#FFFFFF",
    title_color: "#D97706",
    content_color: "#6D7280",
    sub_heading: "",
    banner_image: "",
    banner_image_url: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [onlyOnce, setOnce] = useState(true);
  const [queryStringData, setQueryStringData] = useState("");
  const [isPrev, setIsPrev] = useState(false);

  const onChange = (event) => {
    const { name, value } = event.target;
    const fields = [
      "banner_color",
      "sub_heading_color",
      "background_color",
      "box_color",
      "title_color",
      "content_color",
    ];
    if (fields.includes(name)) {
      setIsPrev(true)
    }
    switch (name) {
      case "banner_image":
        const banner_image_file = event.target.files[0];
        const banner_file_url = URL.createObjectURL(banner_image_file);

        const reader = new FileReader();
        reader.onload = async () => {
          setFormData({
            ...formData,
            banner_image: reader.result,
            banner_image_url: banner_file_url,
          });
        };
        reader.readAsDataURL(banner_image_file);
        break;
      default:
        setFormData({
          ...formData,
          [event.target.name]: value.replace(/[^a-z0-9]/gi, "")
            ? value.trimLeft()
            : value.trim(),
        });
    }
  };


  useEffect(() => {
    const queryData = {
      banner_color: formData.banner_color,
      sub_heading_color: formData.sub_heading_color,
      background_color: formData.background_color,
      box_color: formData.box_color,
      title_color: formData.title_color,
      content_color: formData.content_color
    }
    const setString = queryString.stringify({ prev: JSON.stringify(queryData) });
    const prevUrl = process.env.REACT_APP_CLIENT_URL;
    setQueryStringData(`${prevUrl}/availability?${setString}`)
  }, [formData])

  useEffect(async () => {
    const getData = await getAvailability();
    if (getData) {
      setFormData({
        ...formData,
        background_color: getData.background_color,
        box_color: getData.box_color,

        title_color: getData.title_color,
        content_color: getData.content_color,

        heading: getData.heading,
        banner_color: getData.banner_color,
        sub_heading_color: getData.sub_heading_color,
        sub_heading: getData.sub_heading,
        banner_image: getData.banner_image?.path,
        banner_image_url: getData.banner_image?.fullURL,
      });


    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    updateAvailability(formData, history, "");
  };

  return (
    <div className="animated fadeIn">
      {loading && <Spinner applyClass={"loading-box"} />}
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <span className="pull-left h5">Availability Information</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <Card>
                <CardBody>
                  <h6>
                    <b>Banner Section</b>
                  </h6>
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="heading">
                          Banner Heading <span>*</span>
                        </Label>
                        <Input
                          style={{ color: formData.banner_color }}
                          type="text"
                          id="heading"
                          name="heading"
                          value={formData.heading}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        />
                        <Errors current_key="heading" key="heading" />
                      </FormGroup>
                    </Col>

                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="sub_heading">
                          Sub Heading <span>*</span>
                        </Label>
                        <Input
                          style={{ color: formData.sub_heading_color }}
                          type="text"
                          id="sub_heading"
                          name="sub_heading"
                          value={formData.sub_heading}
                          onChange={(e) => onChange(e)}
                          required
                        />
                        <Errors current_key="sub_heading" key="sub_heading" />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="banner_color">
                          Heading Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="banner_color"
                          name="banner_color"
                          value={formData.banner_color}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        />
                        <Errors current_key="banner_color" key="banner_color" />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="sub_heading_color">
                          Sub Heading Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="sub_heading_color"
                          name="sub_heading_color"
                          value={formData.sub_heading_color}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        />
                        <Errors current_key="sub_heading_color" key="sub_heading_color" />
                      </FormGroup>
                    </Col>
                    <Col xs={5}>
                      <FormGroup>
                        <Label htmlFor="banner_image">
                          Banner Image <span>*</span>
                        </Label>
                        <Input
                          type="file"
                          id="banner_image"
                          name="banner_image"
                          onChange={(e) => onChange(e)}
                          accept="image/*"
                        />
                        <Errors current_key="banner_image" key="banner_image" />
                      </FormGroup>
                    </Col>
                    <Col xs={1} className="mt-5">
                      {formData.banner_image_url ? (
                        <img
                          src={formData.banner_image_url}
                          className="preview-img img-fluid"
                          alt="image_file"
                          style={{ height: "40px" }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h6>
                    <b>Color Section</b>
                  </h6>
                  <Row>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="background_color">
                          Background Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="background_color"
                          name="background_color"
                          value={formData.background_color}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors
                          current_key="background_color"
                          key="background_color"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="box_color">
                          Box Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="box_color"
                          name="box_color"
                          value={formData.box_color}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors
                          current_key="box_color"
                          key="box_color"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="title_color">
                          Title Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="title_color"
                          name="title_color"
                          value={formData.title_color}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors
                          current_key="title_color"
                          key="title_color"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="content_color">
                          Content Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="content_color"
                          name="content_color"
                          value={formData.content_color}
                          maxLength="100"
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors
                          current_key="content_color"
                          key="content_color"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    loading={loading}
                    disabled={loading}
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i>{" "}
                    {loading ? (
                      <Spinner style={{ fontSize: "20px" }} />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  {isPrev && <a href={queryStringData} target={'_blank'}>
                    <Button
                      type="button"
                      className="m-1"
                      size="sm"
                      color="info"
                    >
                      <i className="fa fa-ban"></i> Preview
                    </Button>
                  </a>}
                  <Link to="/dashboard/cms">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Availability.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateAvailability: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.availability.loadingAvailability,
});
export default connect(mapStateToProps, {
  updateAvailability,
  getAvailability,
  removeErrors,
  setErrors,
})(Availability);
