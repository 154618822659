import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes, { string } from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import debounce from "lodash.debounce"
import Editor from "ckeditor5-custom-build/build/ckeditor";
import queryString from 'query-string';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
} from "reactstrap";
import Errors from "views/components/Notifications/Errors";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FaTrash, FaPlusSquare } from "react-icons/fa";
import {
  updateAmenities,
  getAmenities,
  removeErrors,
  setErrors,
  loadingAmenities,
} from "actions/admin/amenities";
import Spinner from "views/components/Spinner";
import Alert from "reducers/alert";
let addGalleryImage = [];
let images = [];
let imageUrls = [];
const Amenities = ({
  updateAmenities,
  getAmenities,
  loadingAmenities,
  loading,
  removeErrors,
  setErrors,
}) => {
  const params = useParams();
  const history = useNavigate();
  const amenitiesFields = {
    heading: "",
    description: "",
    icon: "",
    icon_url: "",
  };
  const apartmentAmenitiesFields = {
    label: "",
    description: "",
  };
  const initialState = {
    banner_heading: "",
    banner_color: "#D97706",
    sub_heading_color: "#D97706",
    sub_heading: "",
    banner_image: "",
    banner_image_url: "",
    amenities_sec_background_color: "#FFFFFF",
    amenities_sec_box_color: "#FFFFFF",

    amenities_sec_title_color: "#000000",
    amenities_sec_content_color: "#6b7280",

    gallery_background_color: "#FFFFFF",
    amenities_background_color: "#FFFFFF",
    amenities_box_color: "#FFFFFF",

    amenities_title_color: "#FFFFFF",
    amenities_content_color: "#FFFFFF",

    gallery_heading: "",
    gallery_top_heading: "",
    gallery_description: "",
    gallery_heading_color: "#FFFFFF",
    gallery_top_heading_color: "#FFFFFF",
    gallery_description_color: "#FFFFFF",
    gallery_image: [],
    gallery_image_url: [],

    apartment_top_heading: "",
    apartment_heading: "",
    apartment_sub_heading: "",

    apartment_heading_color: "#FFFFFF",
    apartment_sub_heading_color: "#FFFFFF",
    deletedImage: [],
  };
  const [formData, setFormData] = useState(initialState);
  const [amenities, setAmenities] = useState([amenitiesFields]);
  const [apartmentAmenities, setApartmentAmenities] = useState([
    apartmentAmenitiesFields,
  ]);
  const [onlyOnce, setOnce] = useState(false);
  const [queryStringData, setQueryStringData] = useState("");
  const [isPrev, setIsPrev] = useState(false);

  const { heading, subHeading } = formData;

  const onChange = async (event) => {
    const { name, value } = event.target;
    const fields = [
      "banner_color",
      "sub_heading_color",
      "amenities_sec_background_color",
      "amenities_sec_box_color",

      "amenities_sec_title_color",
      "amenities_sec_content_color",

      "gallery_background_color",
      "amenities_background_color",
      "amenities_box_color",
      "amenities_title_color",
      "amenities_content_color",

      "gallery_top_heading_color",
      "gallery_heading_color",
      "gallery_description_color",

      "apartment_heading_color",
      "apartment_sub_heading_color",
    ];
    if (fields.includes(name)) {
      setIsPrev(true)
    }
    switch (name) {
      case "banner_image":
        const banner_image_file = event.target.files[0];
        const banner_file_url = URL.createObjectURL(banner_image_file);
        const bannerReader = new FileReader();
        bannerReader.onload = async () => {
          setFormData({
            ...formData,
            banner_image: bannerReader.result,
            banner_image_url: banner_file_url,
          });
        };
        bannerReader.readAsDataURL(banner_image_file);
        break;
      case "gallery_image":
        const gallery_image_file = event.target.files;
        for (let items of gallery_image_file) {
          const checks = await addGalleryImage.find(checkName => checkName.name === items.name);
          if (checks === undefined) {
            const gallery_file_url = URL.createObjectURL(items);
            const galleryImageReader = new FileReader();
            galleryImageReader.onload = async () => {
              images.push(galleryImageReader.result);
              addGalleryImage.push({ name: items.name, image: galleryImageReader.result })
              imageUrls.push(gallery_file_url);
              setFormData({
                ...formData,
                gallery_image: images,
                gallery_image_url: imageUrls,
              });
            };
            galleryImageReader.readAsDataURL(items);
          }
        }
        break;
      default:

        setFormData({
          ...formData,
          [event.target.name]: value.replace(/[^a-z0-9]/gi, "")
            ? value.trimLeft()
            : value.trim(),
        });
        console.log("Error reading", formData.banner_color)
    }
  };

  const addFormFields = () => {
    if (amenities.length > 9) {
      alert("You cannot add more than 9 sections.")
      return false;
    }
    setAmenities([...amenities, apartmentAmenitiesFields]);
  };

  const removeFormFields = (index) => {
    let newAmenities = [...amenities];
    newAmenities.splice(index, 1);
    setAmenities(newAmenities);
  };

  const removeApartmentFormFields = (index) => {
    let newApartment = [...apartmentAmenities];
    newApartment.splice(index, 1);
    setApartmentAmenities(newApartment);
  };

  const addAmenitiesFormFields = () => {
    if (apartmentAmenities.length > 9) {
      alert("You cannot add more than 9 sections.")
      return false;
    }
    setApartmentAmenities([...apartmentAmenities, apartmentAmenitiesFields]);
  };

  const onApartmentAmenitiesChange = (event, index) => {
    const { name, value } = event.target;
    let newApartmentAmenities = [...apartmentAmenities];
    newApartmentAmenities[index][name] = value.replace(/[^a-z0-9]/gi, "")
      ? value.trimLeft()
      : value.trim();
    setApartmentAmenities(newApartmentAmenities);
  };

  const onAmenitiesChange = (event, index) => {
    const { name, value } = event.target;
    switch (name) {
      case "icon":
        let image_file = event.target.files[0];
        let file_url = URL.createObjectURL(image_file);
        let imageAmenities = [...amenities];

        const reader = new FileReader();
        reader.onload = async () => {
          imageAmenities[index]["icon"] = reader.result;
          imageAmenities[index]["icon_url"] = file_url;
          setAmenities(imageAmenities);
        };
        reader.readAsDataURL(image_file);
        break;
      default:
        let newAmenities = [...amenities];
        newAmenities[index][name] = value.replace(/[^a-z0-9]/gi, "")
          ? value.trimLeft()
          : value.trim();
        setAmenities(newAmenities);
    }
  };

  useEffect(() => {
    const queryData = {
      banner_color: formData.banner_color,
      sub_heading_color: formData.sub_heading_color,
      amenities_sec_background_color: formData.amenities_sec_background_color,
      amenities_sec_box_color: formData.amenities_sec_box_color,

      amenities_sec_title_color: formData.amenities_sec_title_color,
      amenities_sec_content_color: formData.amenities_sec_content_color,

      gallery_background_color: formData.gallery_background_color,
      amenities_background_color: formData.amenities_background_color,
      amenities_box_color: formData.amenities_box_color,
      amenities_title_color: formData.amenities_title_color,
      amenities_content_color: formData.amenities_content_color,

      gallery_top_heading_color: formData.gallery_top_heading_color,
      gallery_heading_color: formData.gallery_heading_color,
      gallery_description_color: formData.gallery_description_color,

      apartment_heading_color: formData.apartment_heading_color,
      apartment_sub_heading_color: formData.apartment_sub_heading_color
    }
    const setString = queryString.stringify({ prev: JSON.stringify(queryData) });
    const prevUrl = process.env.REACT_APP_CLIENT_URL;
    setQueryStringData(`${prevUrl}/amenities?${setString}`)
  }, [formData])

  useEffect(async () => {
    const getData = await getAmenities();
    if (getData) {

      searchHandler();
      images = getData.gallery_image?.map((item) => item.path);
      imageUrls = getData.gallery_image?.map((item) => item.fullURL);
      setFormData({
        ...formData,
        amenities_sec_background_color: getData.amenities_sec_background_color,
        amenities_sec_box_color: getData.amenities_sec_box_color,

        amenities_sec_title_color: getData.amenities_sec_title_color,
        amenities_sec_content_color: getData.amenities_sec_content_color,

        gallery_background_color: getData.gallery_background_color,
        amenities_background_color: getData.amenities_background_color,
        amenities_box_color: getData.amenities_box_color,

        amenities_title_color: getData.amenities_title_color,
        amenities_content_color: getData.amenities_content_color,

        banner_heading: getData.banner_heading,
        sub_heading_color: getData.sub_heading_color,
        banner_color: getData.banner_color,
        sub_heading: getData.sub_heading,
        banner_image: getData.banner_image?.path,
        banner_image_url: getData.banner_image?.fullURL,
        gallery_heading: getData.gallery_heading,
        gallery_top_heading: getData.gallery_top_heading,
        gallery_description: getData.gallery_description,
        gallery_heading_color: getData.gallery_heading_color ?? "#FFFFFF",
        gallery_top_heading_color: getData.gallery_top_heading_color ?? "#FFFFFF",
        gallery_description_color: getData.gallery_description_color ?? "#FFFFFF",
        gallery_image: getData.gallery_image?.map((item) => item.path),
        gallery_image_url: getData.gallery_image?.map((item) => item.fullURL),
        apartment_top_heading: getData.apartment_top_heading,
        apartment_heading: getData.apartment_heading,
        apartment_sub_heading: getData.apartment_sub_heading,
        apartment_heading_color: getData.apartment_heading_color ?? "#FFFFFF",
        apartment_sub_heading_color: getData.apartment_sub_heading_color ?? "#FFFFFF",
      });
      const newApartmentAmenities = [...apartmentAmenities];
      const editaApartment = Object.entries(getData?.apartment_amenities ?? []);
      for (const [index, value] of editaApartment ?? []) {
        newApartmentAmenities[Number(index)] = {
          ...newApartmentAmenities[Number(index)],
          label: value.label,
          description: value.description,
        };
      }
      setApartmentAmenities(newApartmentAmenities);

      const newAmenities = [...amenities];
      const editAmenities = Object.entries(getData?.amenities_section ?? []);
      for (const [index, value] of editAmenities ?? []) {
        newAmenities[Number(index)] = {
          ...newAmenities[Number(index)],
          heading: value.heading,
          description: value.description,
          icon: value.icon?.path,
          icon_url: value.icon?.fullURL,
        };
      }
      setAmenities(newAmenities);
    }
  }, []);

  const searchHandler = useMemo(() => {
    return debounce(() => {
      setOnce(true)
    }, 1000)
  }, [])

  const deleteImages = async (index) => {
    const deleteImage = images[index];
    const galleryIndex = await addGalleryImage.findIndex((img) => img.image === deleteImage)

    if (galleryIndex !== -1) {
      await addGalleryImage.splice(galleryIndex, 1);
    }
    images.splice(index, 1);
    imageUrls.splice(index, 1);
    setFormData({
      ...formData,
      gallery_image: images,
      gallery_image_url: imageUrls,
      deletedImage: [...formData.deletedImage, deleteImage],
    });
  };
  const changeEditor = async (editor, index) => {

    const data = editor.getData();
    let editorApartmentAmenities = await [...apartmentAmenities];
    editorApartmentAmenities[index]["description"] = data
    setApartmentAmenities(editorApartmentAmenities);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    formData.apartment_amenities = apartmentAmenities;
    formData.amenities_section = amenities;
    updateAmenities(formData, history, "");
  };

  return (
    <div className="animated fadeIn">
      {loading && <Spinner applyClass={"loading-box"} />}
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <span className="pull-left h5">Amenities Information</span>
            </CardHeader>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <Card>
                <CardBody>
                  <h6>
                    <b>Banner Section</b>
                  </h6>
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="banner_heading">
                          Banner Heading <span>*</span>
                        </Label>
                        <Input
                          style={{ color: formData.banner_color }}
                          type="text"
                          id="banner_heading"
                          name="banner_heading"
                          value={formData.banner_heading}
                          // required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.banner_heading ? true : false}
                        />
                        <Errors
                          current_key="banner_heading"
                          key="banner_heading"
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="sub_heading">
                          Sub Heading <span>*</span>
                        </Label>
                        <Input
                          style={{ color: formData.sub_heading_color }}
                          type="text"
                          id="sub_heading"
                          name="sub_heading"
                          value={formData.sub_heading}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors current_key="sub_heading" key="sub_heading" />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="banner_color">
                          Heading Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="banner_color"
                          name="banner_color"
                          value={formData.banner_color}
                          // required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.banner_color ? true : false}
                        />
                        <Errors
                          current_key="banner_color"
                          key="banner_color"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup>
                        <Label htmlFor="sub_heading_color">
                          Sub Heading Color <span>*</span>
                        </Label>
                        <Input
                          type="color"
                          id="sub_heading_color"
                          name="sub_heading_color"
                          value={formData.sub_heading_color}
                          // required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.sub_heading_color ? true : false}
                        />
                        <Errors
                          current_key="sub_heading_color"
                          key="sub_heading_color"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={5}>
                      <FormGroup>
                        <Label htmlFor="banner_image">
                          Banner Image <span>*</span>
                        </Label>
                        <Input
                          type="file"
                          id="banner_image"
                          name="banner_image"
                          onChange={(e) => onChange(e)}
                          accept="image/*"
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors current_key="banner_image" key="banner_image" />
                      </FormGroup>
                    </Col>
                    <Col xs={1} className="mt-5">
                      {formData.banner_image_url ? (
                        <img
                          src={formData.banner_image_url}
                          className="preview-img img-fluid"
                          alt="image_file"
                          style={{ height: "40px" }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <Card>
                  <CardBody>
                    <h6>
                      <b>Colors</b>
                    </h6>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="amenities_sec_background_color">
                            Background Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="amenities_sec_background_color"
                            name="amenities_sec_background_color"
                            value={formData.amenities_sec_background_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="amenities_sec_background_color"
                            key="amenities_sec_background_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="amenities_sec_box_color">
                            Box Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="amenities_sec_box_color"
                            name="amenities_sec_box_color"
                            value={formData.amenities_sec_box_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="amenities_sec_box_color"
                            key="amenities_sec_box_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="amenities_sec_title_color">
                            Box Title Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="amenities_sec_title_color"
                            name="amenities_sec_title_color"
                            value={formData.amenities_sec_title_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="amenities_sec_title_color"
                            key="amenities_sec_title_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="amenities_sec_content_color">
                            Box Content Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="amenities_sec_content_color"
                            name="amenities_sec_content_color"
                            value={formData.amenities_sec_content_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="amenities_sec_content_color"
                            key="amenities_sec_content_color"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <CardBody>
                  <h6>
                    <b>Amenities Section</b>
                    <Button
                      className="m-1"
                      size="sm"
                      color="primary"
                      style={{ float: "right" }}
                      onClick={() => addFormFields()}
                    >
                      <i className="fa fa-dot-circle-o"></i> Add More
                    </Button>
                  </h6>
                  <Row>
                    {amenities.map((element, index) => (
                      <>
                        <Col key={`z-${index}`} xs={6}>
                          <FormGroup>
                            <Label htmlFor="heading">
                              Heading <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="heading"
                              name="heading"
                              value={amenities[index]["heading"]}
                              required
                              onChange={(e) => onAmenitiesChange(e, index)}
                            // invalid={errorList.heading ? true : false}
                            />
                            <Errors
                              current_key={
                                "amenities_section[" + index + "].heading"
                              }
                              key={"amenities_section[" + index + "].heading"}
                            />
                          </FormGroup>
                        </Col>
                        <Col
                          key={`zx-${index}`}
                          xs={amenities[index].icon_url ? 5 : 6}
                        >
                          <FormGroup>
                            <Label htmlFor="features_icon">
                              Icon <span>*</span>
                            </Label>
                            <Input
                              type="file"
                              id="icon"
                              name="icon"
                              accept="image/*"
                              onChange={(e) => onAmenitiesChange(e, index)}
                            // invalid={errorList.heading ? true : false}
                            />
                            <Errors
                              current_key={
                                "amenities_section[" + index + "].icon"
                              }
                              key={"amenities_section[" + index + "].icon"}
                            />
                          </FormGroup>
                        </Col>
                        {amenities && amenities[index].icon_url ? (
                          <Col xs={1} className="mt-5">
                            <img
                              src={amenities[index].icon_url}
                              className="preview-img img-fluid"
                              alt="image_file"
                              style={{ height: "40px" }}
                            />
                          </Col>
                        ) : null}
                        <Col key={`zxs-${index}`} xs={index ? 10 : 11}>
                          <FormGroup>
                            <Label htmlFor="description">
                              Description <span>*</span>
                            </Label>
                            <Input
                              type="textarea"
                              id="description"
                              name="description"
                              value={amenities[index]["description"]}
                              required
                              onChange={(e) => onAmenitiesChange(e, index)}
                            // invalid={errorList.description ? true : false}
                            />
                            <Errors
                              current_key={
                                "amenities_section[" + index + "].description"
                              }
                              key={
                                "amenities_section[" + index + "].description"
                              }
                            />
                          </FormGroup>
                        </Col>
                        {index ? (
                          <Col key={`zxcd-${index}`} xs={2} className="mt-5">
                            <Button
                              size="md"
                              color="danger"
                              onClick={() => removeFormFields(index)}
                            >
                              <FaTrash color="white" />
                            </Button>
                            <Button
                              className="m-1"
                              size="md"
                              color="primary"
                              title="Add More"
                              onClick={() => addFormFields()}
                            >

                              <FaPlusSquare color="white" />
                            </Button>
                          </Col>
                        ) : null}
                      </>
                    ))}
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <Card>
                  <CardBody>
                    <h6>
                      <b>Furnished Gallery Colors</b>
                    </h6>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="gallery_background_color">
                            Background Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="gallery_background_color"
                            name="gallery_background_color"
                            value={formData.gallery_background_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="gallery_background_color"
                            key="gallery_background_color"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <CardBody>
                  <h6>
                    <b>Furnished Gallery</b>
                  </h6>
                  <Row >
                    <Col xs={5}>
                      <FormGroup>
                        <Label htmlFor="gallery_top_heading">
                          Gallery Top Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="gallery_top_heading"
                          name="gallery_top_heading"
                          value={formData.gallery_top_heading}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.gallery_top_heading ? true : false}
                        />
                        <Errors
                          current_key="gallery_top_heading"
                          key="gallery_top_heading"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={1}>
                      <FormGroup>
                        <Input
                          type="color"
                          className="color-box"
                          id="gallery_top_heading_color"
                          name="gallery_top_heading_color"
                          value={formData.gallery_top_heading_color}
                          required
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={5}>
                      <FormGroup>
                        <Label htmlFor="gallery_heading">
                          Gallery Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="gallery_heading"
                          name="gallery_heading"
                          value={formData.gallery_heading}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.gallery_heading ? true : false}
                        />
                        <Errors
                          current_key="gallery_heading"
                          key="gallery_heading"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={1}>
                      <FormGroup>
                        <Input
                          type="color"
                          className="color-box"
                          id="gallery_heading_color"
                          name="gallery_heading_color"
                          value={formData.gallery_heading_color}
                          required
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={11}>
                      <FormGroup>
                        <Label htmlFor="gallery_description">
                          Description <span>*</span>
                        </Label>
                        <Input
                          type="textarea"
                          id="gallery_description"
                          name="gallery_description"
                          value={formData.gallery_description}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors
                          current_key="gallery_description"
                          key="gallery_description"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={1}>
                      <FormGroup>
                        <Input
                          type="color"
                          className="textarea-color-box"
                          id="gallery_description_color"
                          name="gallery_description_color"
                          value={formData.gallery_description_color}
                          required
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label htmlFor="gallery_image">
                          Gallery Image <span>*</span>
                        </Label>
                        <Input
                          type="file"
                          id="gallery_image"
                          name="gallery_image"
                          multiple
                          onChange={(e) => onChange(e)}
                          accept="image/*"
                        // invalid={errorList.heading ? true : false}
                        />
                        <Errors
                          current_key="gallery_image"
                          key="gallery_image"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} className="previewImages">
                      {formData.gallery_image_url &&
                        formData.gallery_image_url.map((items, ind) => (
                          <div key={`image-${ind}`}>
                            <Button
                              type="button"
                              className=""
                              size="sm"
                              color="danger"
                              onClick={() => deleteImages(ind)}
                            >
                              <i className="fa fa-ban"></i> X
                            </Button>
                            <img
                              src={items}
                              className="preview-img img-fluid"
                              alt="image_file"
                            />
                          </div>
                        ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <Card>
                  <CardBody>
                    <h6>
                      <b>Apartment Colors</b>
                    </h6>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="amenities_background_color">
                            Background Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="amenities_background_color"
                            name="amenities_background_color"
                            value={formData.amenities_background_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="amenities_background_color"
                            key="amenities_background_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="amenities_box_color">
                            Box Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="amenities_box_color"
                            name="amenities_box_color"
                            value={formData.amenities_box_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="amenities_box_color"
                            key="amenities_box_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="amenities_title_color">
                            Box Title Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="amenities_title_color"
                            name="amenities_title_color"
                            value={formData.amenities_title_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="amenities_title_color"
                            key="amenities_title_color"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="amenities_content_color">
                            Box Content Color <span>*</span>
                          </Label>
                          <Input
                            type="color"
                            id="amenities_content_color"
                            name="amenities_content_color"
                            value={formData.amenities_content_color}
                            maxLength="100"
                            required
                            onChange={(e) => onChange(e)}
                          // invalid={errorList.heading ? true : false}
                          />
                          <Errors
                            current_key="amenities_content_color"
                            key="amenities_content_color"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <CardBody>
                  <h6>
                    <b>Apartment Amenities</b>
                  </h6>
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <Label htmlFor="apartment_top_heading">
                          Top Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="apartment_top_heading"
                          name="apartment_top_heading"
                          value={formData.apartment_top_heading}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.apartment_top_heading ? true : false}
                        />
                        <Errors
                          current_key="apartment_top_heading"
                          key="apartment_top_heading"
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={5}>
                      <FormGroup>
                        <Label htmlFor="apartment_heading">
                          Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="apartment_heading"
                          name="apartment_heading"
                          value={formData.apartment_heading}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.apartment_heading ? true : false}
                        />
                        <Errors
                          current_key="apartment_heading"
                          key="apartment_heading"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={1}>
                      <FormGroup>
                        <Input
                          type="color"
                          className="color-box"
                          id="apartment_heading_color"
                          name="apartment_heading_color"
                          value={formData.apartment_heading_color}
                          required
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={5}>
                      <FormGroup>
                        <Label htmlFor="apartment_sub_heading">
                          Sub Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="apartment_sub_heading"
                          name="apartment_sub_heading"
                          value={formData.apartment_sub_heading}
                          required
                          onChange={(e) => onChange(e)}
                        // invalid={errorList.apartment_sub_heading ? true : false}
                        />
                        <Errors
                          current_key="apartment_sub_heading"
                          key="apartment_sub_heading"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={1}>
                      <FormGroup>
                        <Input
                          type="color"
                          className="color-box"
                          id="apartment_sub_heading_color"
                          name="apartment_sub_heading_color"
                          value={formData.apartment_sub_heading_color}
                          required
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <hr />
                    <Col xs={6}>
                      <h6>
                        <b>Amenities</b>
                      </h6>
                    </Col>
                    <Col xs={6}>
                      <Button
                        className="m-1"
                        size="sm"
                        color="primary"
                        style={{ float: "right" }}
                        onClick={() => addAmenitiesFormFields()}
                      >
                        <i className="fa fa-dot-circle-o"></i> Add More
                      </Button>
                    </Col>
                    {apartmentAmenities.map((element, index) => (
                      <>
                        <Col key={`a-${index}`} xs={12}>
                          <FormGroup>
                            <Label htmlFor="label">
                              Label <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="label"
                              name="label"
                              value={apartmentAmenities[index]["label"]}
                              required
                              onChange={(e) =>
                                onApartmentAmenitiesChange(e, index)
                              }
                            // invalid={errorList.heading ? true : false}
                            />
                            <Errors
                              current_key={
                                "apartment_amenities[" + index + "].label"
                              }
                              key={"apartment_amenities[" + index + "].label"}
                            />
                          </FormGroup>
                        </Col>
                        <Col key={`ab-${index}`} xs={index ? 10 : 12}>
                          <FormGroup>
                            <Label htmlFor="description">
                              Description <span>*</span>
                            </Label>
                            {onlyOnce && <CKEditor
                              editor={Editor}
                              data={apartmentAmenities[index]["description"]}
                              onChange={(event, editor) => changeEditor(editor, index)}
                            />}
                            <Errors
                              current_key={
                                "apartment_amenities[" + index + "].description"
                              }
                              key={
                                "apartment_amenities[" + index + "].description"
                              }
                            />
                          </FormGroup>
                        </Col>
                        {index ? (
                          <Col key={`abc-${index}`} xs={2} className="mt-4">
                            <Button
                              size="md"
                              color="danger"
                              onClick={() => removeApartmentFormFields(index)}
                            >
                              <FaTrash color="white" />
                            </Button>
                            <Button
                              className="m-1"
                              size="md"
                              color="primary"
                              onClick={() => addAmenitiesFormFields()}
                            >
                              <FaPlusSquare color="white" />
                            </Button>
                          </Col>
                        ) : null}
                      </>
                    ))}
                  </Row>
                </CardBody>
              </Card>
              <CardFooter>
                <>
                  <Button
                    type="submit"
                    className="m-1"
                    size="sm"
                    loading={loading}
                    disabled={loading}
                    color="primary"
                    style={{ width: "60px" }}
                  >
                    <i className="fa fa-dot-circle-o"></i>{" "}
                    {loading ? (
                      <Spinner style={{ fontSize: "20px" }} />
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  {isPrev && <a href={queryStringData} target={'_blank'}>
                    <Button
                      type="button"
                      className="m-1"
                      size="sm"
                      color="info"
                    >
                      <i className="fa fa-ban"></i> Preview
                    </Button>
                  </a>}
                  <Link to="/dashboard/cms">
                    <Button
                      type="reset"
                      className="m-1"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Amenities.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateAmenities: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  //currentSubBanner: state.subBanner.currentSubBanner,
  errorList: state.errors,
  loading: state.amenities.loadingAmenities,
});

export default connect(mapStateToProps, {
  updateAmenities,
  getAmenities,
  loadingAmenities,
  removeErrors,
  setErrors,
})(Amenities);

//export default Amenities;
