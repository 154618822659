import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { connect } from "react-redux";
import {
  edit,
  loadPage,
  getBannerById,
  cancelSave,
  setErrors,
  removeErrors,
} from "actions/admin/banner";
import Errors from "views/components/Notifications/Errors";
// import Spinner from "views/Spinner";import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import { validateForm } from "utils/misc";
import { useNavigate, useParams } from "react-router";
import LoadingButton from "views/components/LoadingButton";
const EditBanner = ({
  edit,
  getBannerById,
  cancelSave,
  currentBanner,
  errorList,
  loadPage,
  loading,
  setErrors,
  removeErrors,
}) => {
  const history = useNavigate();
  const params = useParams();
  //########################## set the initial form data ##############################

  const initialState = {
    heading: "",
    description: "",
  };
  const [formData, setFormData] = useState(initialState);
  const { title, heading, description } = formData;
  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe && params.banner_id) {
        getBannerById(params.banner_id);
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [getBannerById, params.banner_id]);

  useEffect(() => {
    if (currentBanner) {
      setFormData({
        ...formData,
        title: currentBanner?.title,
        heading: currentBanner.heading,
        description: currentBanner.description,
      });
    }
  }, [currentBanner]);
  //########################## change event for all inputs ##############################
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // const handleDescriptionChange = (event) => {
  //   const desc = event.editor.getData(); // editor.getData();
  //   setFormData((ev)=>{ return {...ev, description: desc }});
  // };
  const onChangeDescription = (newDescription) => {
    setFormData((data) => ({ ...data, description: newDescription }));
  };
  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();
    const validationParam = [
      {
        param: "title",
        msg: "Please, provide the title of the banner.",
      },
      {
        param: "heading",
        msg: "Please provide the heading",
      },
      {
        param: "description",
        msg: "Please provide the description",
      },
    ];
    if (!validateForm(formData, validationParam, setErrors)) return;

    const submitData = {};
    const excludeList = {
      avatarURL: 1,
    };

    for (let i in formData) {
      const data = formData[i];
      if (data === "" || data === null || data === undefined || excludeList[i])
        continue;
      submitData[i] = data;
    }
    edit(formData, history, params.banner_id);
  };
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(history);
  };

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe) {
        loadPage();
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [loadPage]);

  return (
    <div className="animated fadeIn" style={{ marginTop: "5%" }}>
      <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
        <Row style={{ marginLeft: 10, marginTop: "4%" }}>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <h2>Edit Banner</h2>
              </CardHeader>
            </Card>
          </Col>
          <Col xs="12">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="title">
                        Title <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="title"
                        name="title"
                        maxLength="25"
                        value={title}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.title ? true : false}
                      />
                      <Errors current_key="title" key="title" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="heading">
                        Heading <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="heading"
                        name="heading"
                        value={heading}
                        required
                        onChange={(e) => onChange(e)}
                        disabled={loading}
                        invalid={errorList.heading ? true : false}
                      />
                      <Errors current_key="heading" key="heading" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="status">
                        Description <span>*</span>
                      </Label>
                      {formData.title != null && (
                        //   <CKEditor
                        //   initData={currentBanner.description}
                        //   id="description"
                        //   name="description"
                        //   config={{
                        //     height: 100,
                        //     toolbar: [
                        //       ["Cut", "Copy", "Paste"],
                        //       ["Undo", "Redo"],
                        //       ["SpellChecker"],
                        //       ["Link", "Unlink", "Anchor"],
                        //       [
                        //         "Image",
                        //         "Table",
                        //         "Horizontal Line",
                        //         "Special Character",
                        //       ],
                        //       ["Maximize"],
                        //       ["Source"],
                        //       ["Bold", "Italic", "Strike"],
                        //       ["RemoveFormat"],
                        //       ["NumberedList", "BulletedList"],
                        //       ["DecreaseIndent", "IncreaseIndent"],
                        //       ["BlockQuote"],
                        //       ["Styles"],
                        //       ["Format"],
                        //       ["About"],
                        //     ],
                        //   }}
                        //   onChange={(event) => handleDescriptionChange(event)}
                        //   onBeforeLoad={(CKEDITOR) =>
                        //     (CKEDITOR.disableAutoInline = true)
                        //   }
                        //   invalid={errorList.description ? true : false}
                        // />
                        <CKEditor
                          editor={Editor}
                          required
                          data={description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            onChangeDescription(data);
                          }}
                        />
                      )}
                      <Errors current_key="description" key="description" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginLeft: 10, marginTop: "1%" }}>
          <Col>
            <Card>
              <CardFooter>
                <LoadingButton
                  className="me-3"
                  loading={loading}
                  disabled={loading}
                  color="primary"
                  type="submit"
                >
                  Update
                </LoadingButton>
                <Button
                  type="reset"
                  size="md"
                  color="danger"
                  onClick={onClickHandel}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

EditBanner.propTypes = {
  loading: PropTypes.bool.isRequired,
  edit: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  // showMsg: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.banner.loadingBanner,
  currentBanner: state.banner.currentBanner,
});
export default connect(mapStateToProps, {
  edit,
  getBannerById,
  cancelSave,
  loadPage,
  setErrors,
  removeErrors,
})(EditBanner);
